sbmn-tpv {
  display: block;
  min-width: 100%;

  iframe {
    width: 100%;
    min-height: 750px;
    border: 0;
  }

  form > img {
    visibility: hidden;
  }
}
