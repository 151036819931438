sbmn-youtube {
  .cssload-loader__container {
    position: relative;
    background: transparent;
  }
}

.youtube__video {
  margin: 5%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
