/*html, body {
  width: 100%;
  height: 100%;
}

body {
  background: white;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: black;
}*/

/*
.hero {
  background: transparent;
}

{
  width: 100%;
  height: auto;
  display: inline-block;
  border-radius: 10px;
  background: white;
}
.upload-box-header {
  width: 100%;
  height: 50px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.upload-box-header .control-circles {
  padding-left: 15px;
  height: 14px;
}
.upload-box-header .control-circles .circle {
  display: inline-block;
  margin: 0 4px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.upload-box-header .control-circles .circle.is-red {
  background: red;
}
.upload-box-header .header-title h2 + unselectable {
  font-size: 18px;
  font-weight: 400;
  color: grey;
}
.upload-box-header .gh-icon {
  padding-right: 15px;
}
.upload-box-header .gh-icon i {
  font-size: 24px;
  color: blue;
  margin-right: 5px;
  margin-left: 36px;
  cursor: pointer;
}*/

.upload-item,
.drop-container {
  width: 100%;
  margin: 20px auto;
}

.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: inset 0 0 22px -8px rgba($Bblack, 0.6);
  background-color: $Bwhite;
  &:before {
    @extend .Bborder-y;
  }
}

.drop-container p {
  font-size: 16px;
  font-weight: 400;
  color: darkgrey;
}

.upload-button {
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  padding: 0 2px;
  color: $Bprimary-lighter;

  &:hover {
    color: $Bblack;
  }
}

.upload-button input {
  display: none;
}

.upload-item {
  display: flex;
  align-items: center;
}

.upload-item-content {
  width: 100%;
}

.upload-item-content .filename {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-item-content .filename-left,
.upload-item-content .filename-right {
  display: flex;
  align-items: center;
  position: relative;
}

.upload-item-content .filename-right {
  color: $Borange-red;
  opacity: 0.5;
  font-size: 15px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.upload-item-content i.ko:hover {
  opacity: 1;
  color: $Bprimary;
}

.upload-item-content span {
  color: $Bprimary-light;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
}

.upload-item-content .progress-content {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.progress-content .progress {
  display: block;
  position: relative;
  width: 100%;
  height: 12px;
  border-radius: 10px;
}

.progress-content .progress .bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  background: linear-gradient(90deg, $Bprimary-light 0%, $Bgreen-light 100%);
}

.progress-content .progress .bar.is-done {
  background: $Bgreen;
}

.progress-text-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0 0;
}

.progress-text-content .progress-text {
  font-size: 13px;
  font-weight: 600;
  color: $Bprimary-lighter;
  transition: color 200ms ease-out;
}

.progress-text-content .progress-text.is-done {
  color: $Bgreen-light;
}

.progress-text-content .speed-and-eta-text {
  font-size: 13px;
  font-weight: 600;
  color: $Bblack;
}

.help-text {
  width: 100%;
  display: inline-block;
  padding: 10px 30px;
  font-size: 12px;
  color: $Bprimary-lighter;
  box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.6);
}

.help-text span {
  display: block;
  text-align: center;
  margin: 5px 0;
}

.help-text span img {
  display: block;
  width: 30px;
  margin: 0 auto 5px auto;
}

.manage_upload .Berror {
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  position: relative;
  padding: 0 0 20px 0;
  display: block;
  font-size: 1.6rem;
}

.manage_upload__items {
  font-family: basicregular, Arial, sans-serif;
  color: $Bgreen-light;
  margin: 10px 0 0;
}

.manage_upload__button {
  margin: 10px auto;
}
