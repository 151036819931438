sbmn-mta-users-manager {
  &:before {
    @extend .Bborder-y;
  }
  form {
    display: flex;
    flex-wrap: wrap;
  }

  fieldset {
    width: 49%;
    padding: 5px 2%;

    &.large {
      width: 100%;
      text-align: center;
      margin-top: 20px;
    }

    &.tpadding {
      padding-top: 20px;
    }

    @include mq('tablet', 'max') {
      width: 100%;
    }
  }
}

.musersm__roles {
  display: flex;
  max-width: 960px;
  flex-wrap: wrap;
  height: 0;
  max-height: 0;
  transition:
    opacity 0.3s linear,
    max-height 1s linear;
  overflow: hidden;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid $Bprimary-lighter;
  opacity: 0;

  &.allowopts {
    transition:
      opacity 0.3s linear,
      max-height 1s linear;
    max-height: 1000px;
    height: auto;
    opacity: 1;
  }

  &__group {
    min-width: 33%;
    width: 33%;
    padding: 0 3%;

    fieldset {
      width: 100%;
      margin: 0 2% 5px;
      padding: 5px 2% 0;
    }
    mat-checkbox {
      width: 100%;
      line-height: 2;
      display: block;
    }

    &__title {
      margin: 20px 0 0;
      border-bottom: 1px solid $Bgrey2;
    }

    &__role {
      font-size: 1.5rem;
      color: $Bprimary;
      font-family: basicregular, Arial, sans-serif;
    }

    &__subrole {
      font-size: 1.3rem;
      padding-left: 20px;
      width: 100%;
      color: $Bgrey5;
    }
  }
}
