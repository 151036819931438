sbmn-access {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  @include mq('tablet', 'max') {
    flex-direction: column;
    top: 0;
  }

  > header,
  figure,
  > section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > header {
    position: fixed;
    flex-direction: column;
    background-image: url('/assets/img/logos/avasa-login-bg.jpg');
    background-size: 100% 100%;
    //animation: backMove 10s linear infinite 1s;
    color: $Bwhite;
    width: 100%;
    padding: 3%;
    height: 100%;
    @include mq('tablet-wide', 'max') {
      width: 30%;
    }
    @include mq('tablet', 'max') {
      width: 100%;
      padding: 2% 3% 3%;
      position: relative;
      min-height: 140px;
      height: auto;
    }

    span {
      font-size: 1.6rem;
      color: $Bgrey5;
      position: relative;
      left: 20%;
    }
  }

  figure {
    height: 98%;
    position: relative;
    left: 20%;

    img {
      max-width: 400px;
      min-width: 200px;
      width: 80%;
    }
  }

  > section {
    position: relative;
    width: 40%;
    left: 10%;
    height: 100%;
    background-color: $Bwhite;

    &:after {
      @include cover();
      background: $Bwhite;
      opacity: 0.2;
      content: ' ';
      z-index: z(bottom);
    }

    @include mq('tablet-wide', 'max') {
      width: 70%;
      left: 30%;
    }
    @include mq('tablet', 'max') {
      align-items: flex-start;
      width: 100%;
      left: 0;
    }

    &.register {
      align-items: flex-start;
    }
  }

  sbmn-lang-selector {
    position: absolute;
    right: 10px;
    top: 10px;
    &:hover #langTrigger {
      color: $Bsecondary;
      border-color: $Bsecondary;
    }
  }
}
