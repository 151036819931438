sbmn-flights-messages {
  position: relative;
  overflow: hidden;
  font-size: 1.5rem;
  background: rgba($Bwhite, 0.9);
  &:before {
    @extend .Bborder-y;
  }
  @include mq('tablet', 'max') {
    padding: 20px 0;
  }

  section {
    padding: 0 9%;
    margin: 20px 10px 40px 40px;
    color: $Bprimary;
    @include mq('tablet', 'max') {
      height: 100%;
    }

    &._vuelos {
      border-color: $Bprimary-lighter;
    }

    &._avisos {
      border-color: rgba($Borange, 0.9);
    }

    &._info {
      border-color: $Bgrey4;
    }

    div {
      @extend %Btext_reg;
      color: $Bgrey8;
      display: inline-block;
    }
  }

  i {
    @extend %roundicon;
    border-bottom: 2px solid rgba($Bprimary, 0.5);
    position: absolute;
    left: 4%;

    &.avion:before {
      color: $Bsecondary;
    }

    &.megafono:before {
      font-size: 4rem;
      color: $Borange;
    }

    &.info3:before {
      font-size: 3rem;
      color: $Bgrey4;
    }
  }

  img.icoboom {
    max-width: 50px;
    position: absolute;
    left: 4.5%;
  }

  .owl-stage-outer {
    height: calc(100% - 30px);
  }

  .owl-theme .owl-dots {
    padding-left: 10%;
    text-align: left;
    position: absolute;
    right: 10px;
    bottom: 10px;
    @include mq('tablet', 'max') {
      padding-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .link {
    margin: 0 10px;
    text-transform: uppercase;

    &:after {
      content: ' >>';
    }
  }
}
