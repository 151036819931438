.stopper-modal.modal_psg_manager {
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  button {
    margin-top: 20px;
  }

  h2 {
    display: flex;
    align-items: center;
    margin: 20px 0 0;
    color: $Bwhite;
    border-bottom: 1px solid $Bgrey8;
    padding: 15px;
    @include mq('tablet', 'max') {
      margin-top: 70px;
    }
  }

  .personas {
    background: $Btransparent;
    border: 0;
    margin: 0 10px 0 0;
    opacity: 0.4;
    font-size: 3rem;
  }
}

.psg_manager {
  padding-top: 10px;
  display: flex;
  color: $Bwhite;
  font-size: 1.4rem;
  text-align: center;
  text-transform: uppercase;

  &__error {
    padding: 5px;
    margin: 0 30px;
    border-bottom: 1px solid rgba($Berror, 0.5);
    text-align: center;
    color: $Borange-red;
    opacity: 0;

    &.appear {
      padding: 5px 10px;
    }

    @include mq('tablet', 'max') {
      padding: 20px 0;
      margin: 0 auto;
    }
  }

  &__container {
    &__box {
      border-bottom: 1px solid $Bgrey8;
      padding: 10px 0;

      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        min-height: 22px;
      }

      &__control {
        min-width: 30px;
        display: flex;
        position: relative;
        i {
          top: 27px;
          position: relative;
        }
      }

      &__legend {
        margin: 5px 0;
        min-width: 40px;
        display: inline-block;
        text-align: center;
        font-size: 2.5rem;
      }
    }
  }

  &__residents {
    margin: 20px auto 0 auto;
    color: $Bgrey5;
    font-family: basicregular, Arial, sans-serif;
    font-size: 1.6rem;
    max-width: 300px;
    text-align: center;
    width: 90%;

    p {
      text-align: left;
      font-size: 0.85em;
    }
  }
}
