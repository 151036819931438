sbmn-my-messages-manager {
  + #mymessages {
    position: fixed;
    height: 100%;
  }

  span.msgmanager__header__nav__item:hover,
  span.msgmanager__header__nav__item:focus {
    color: $Bblack;
  }
}

.msgmanager {
  padding-top: 10px;

  &__header {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;

    &.reverse {
      flex-direction: row;
    }

    &.msg-general:before,
    &.msg-refunds:before,
    &.msg-admin:before {
      color: $Bgreen-dark;
    }

    &.msg-flights:before {
      color: $Bprimary;
    }

    &.msg-warnings:before,
    &.msg-web:before {
      color: $Borange;
    }

    &:before {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 4rem;
      @include mq('tablet', 'max') {
        top: 5px;
        right: 5px;
        font-size: 3rem;
      }
    }

    &__title {
      &__date {
        display: block;
        font-size: 0.7em;
        color: $Bgrey6;
      }
    }

    &__nav {
      display: flex;

      &__item {
        max-width: 200px;
        min-width: 90px;
        margin-bottom: 20px;
        padding-left: 10px;

        i.m_icon {
          @include circle(40px);
          display: flex;
          margin-top: 0;
        }

        i.conversacion:before {
          font-size: 3rem;
        }
      }
    }

    &__desc {
      min-width: 100%;
      padding: 10px 0;
      margin: 10px 0 0 0;
      border: solid $Bgrey2;
      border-width: 1px 0;
      font-size: 1.8rem;
      font-family: basiclight, Arial, sans-serif;
      &.reserveData {
        display: flex;
        border-top: 0;
        margin-top: 0;
        @include mq('tablet-small', 'max') {
          display: block;
        }
        div {
          flex: 1;
          @include mq('tablet-small', 'max') {
            margin-bottom: 5px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          strong {
            margin: 0 3% 0 0;
          }
        }
      }
    }
  }

  &__new,
  &__file {
    opacity: 0;
    height: 0;
    max-height: 0;
    overflow: hidden;

    &.in {
      animation: fadeOutHeight 0.6s linear forwards reverse;
    }

    &__title {
      margin: 10px 0 5px;
    }

    &__input {
      width: 100%;
      height: 150px;
    }

    &__button {
      margin: 10px auto;
      display: block;
    }
  }

  &__file {
    &__title {
      margin: 20px 0 0;
    }
  }

  &__chat {
    &__title {
      margin-bottom: 5px;
    }

    &__list {
      padding: 10px;
      background: $Bwhite;
      min-height: 200px;
      max-height: 400px;
      overflow-y: scroll;
      border: 1px solid $Bgrey2;

      &__item {
        padding: 3px 0;
        border-bottom: 1px solid $Bgrey2;

        &:last-child {
          border: 0;
        }
      }
    }
  }

  &__data {
    fieldset.reserveData {
      display: flex;
      @include mq('tablet', 'max') {
        display: block;
      }
      mat-form-field {
        margin: 0 3% 0 0;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
