//Used at admin-login component

sbmn-auto-login,
sbmn-admin-login {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    76deg,
    $Bprimary 25%,
    $Bprimary-dark-gradient3 33%,
    $Bprimary-dark-gradient2 50%,
    $Bprimary 71%
  );
  background-size: 100% 100%;
}

sbmn-auto-login {
  sbmn-change-password {
    background: rgba($Bwhite, 1);
    padding: 20px;
    border-radius: 12px;
    text-align: right;
    position: relative;
    display: block;

    .Berror {
      color: $Berror;
      display: block;
      text-align: left;
      width: 100%;
      padding-left: 20px;
      line-height: 0.8;
      bottom: 105px;
    }

    button {
      margin: 30px 0 10px;
    }
  }
}

.auto-login {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;

  h2 {
    margin: 30px auto;
  }

  h2._w {
    color: $Bwhite;
  }

  h2.Berror {
    font-size: 3rem;
  }

  figure,
  figcaption {
    max-width: 560px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  img {
    max-width: 400px;
    @include mq('phablet', 'max') {
      max-width: 100%;
    }
  }

  figcaption {
    justify-content: flex-start;
    min-height: 200px;
  }

  span {
    font-size: 1.6rem;
    color: $Bgrey5;
  }
}
