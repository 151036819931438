/*--------------- extendedinfo & extendedpriceinfo ------*/

.extendedinfo {
  padding-bottom: 50px;
  font-size: 1.5rem;

  &__header {
    background: $Bgrey6;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    margin-bottom: 30px;
    @include mq('tablet', 'max') {
      flex-wrap: wrap;
      padding: 10px 0;
    }

    &__icon {
      background: $Bwhite;
      display: flex;
      align-items: center;
      padding: 5px 15px 5px 10px;
      margin: 0 7px;
      border-radius: 30px;
      color: $Bgrey6;

      &:before {
        color: $Bgrey3;
        font-size: 1.5rem;
        margin-right: 5px;
      }

      @include mq('tablet', 'max') {
        flex-wrap: wrap;
        margin: 7px;
      }
    }
  }

  &__container {
    margin: 0 30px 0 25px;
    padding: 5px 0 20px 0;
    border-top: 2px dotted $Bgrey2;

    &:nth-of-type(1) {
      border: 0;
    }

    @include mq('tablet', 'max') {
      margin: 0 20px 0 15px;
    }

    &__buttons {
      display: flex;
      flex-direction: row;
    }

    &__print {
      display: flex;
      flex-direction: column;
      width: 49%;
      margin-right: 1%;
    }

    &__clipboard {
      display: flex;
      flex-direction: column;
      width: 49%;
      margin-right: 1%;
      margin-left: 1%;
    }

    &__copy {
      display: flex;
      flex-direction: column;
      width: 49%;
      margin-left: 1%;
    }

    &__title {
      color: $Bgrey7;
      margin: 20px 0 10px;
      font-size: 1.7rem;

      &.nomargin {
        margin-top: 0;
      }

      &.withborder {
        border-bottom: 2px dotted $Bgrey2;
        padding-bottom: 5px;
      }
    }

    &__subtitle {
      font-size: 1.3rem;
      margin: 0;
      color: $Bgrey5;
      line-height: 1;
    }

    &__warning {
      margin: 20px auto 0;
      color: $Bgreen-light;
    }

    &__code {
      padding: 10px;
      font-size: 1.5rem;
      border-radius: 5px;
      background: $Bgrey05;
      max-height: 300px;
      overflow-y: scroll;
    }

    &__details {
      padding: 0 0 10px 0;

      &__header {
        margin: 5px 0;
        font-family: basicbold, Arial, sans-serif;
        font-weight: lighter;
        display: flex;
        color: $Bprimary-dark;
        justify-content: space-between;
        padding-bottom: 5px;
        border-bottom: 1px solid $Bprimary;
        @include mq('phablet', 'max') {
          flex-direction: column;
        }

        &__title {
          font-size: 1.1em;
          display: flex;
          align-items: center;

          img {
            margin: 0 3px;
          }

          span {
            margin-left: 5px;
            font-family: basicregular, Arial, sans-serif;
          }
        }

        &__info {
          font-family: basicregular, Arial, sans-serif;
        }
      }

      &__scale {
        color: $Bgrey9;
        background: $Bgrey1;
        text-align: center;
        margin: 0 0 20px 0;
        padding: 5px 0;
      }

      &__flight {
        padding: 10px 0 5px;

        &__detail {
          display: flex;
          width: 100%;
          padding: 3px 0;
          @include mq('phablet', 'max') {
            flex-wrap: wrap;
          }

          > span {
            &:nth-of-type(1) {
              font-family: basicbold, Arial, sans-serif;
              font-weight: lighter;
              color: $Bgrey7;
              font-size: 1.1em;
              width: 110px;
              @include mq('phablet', 'max') {
                min-width: 100%;
              }
            }

            &:nth-of-type(2) {
              font-family: basicbold, Arial, sans-serif;
              font-weight: lighter;
              color: $Bgrey5;
              width: 50px;
            }

            &:nth-of-type(3) {
              flex-grow: 2;
            }

            &:nth-of-type(3),
            &:nth-of-type(4) {
              font-family: basiclight, Arial, sans-serif;
              color: $Bgrey9;
            }
          }
        }
      }

      &__footer {
        border-top: 1px solid $Bgrey2;
        font-family: basicregular, Arial, sans-serif;
        color: $Bgrey9;
        padding: 5px 0 10px 0;

        span {
          @include mq('phablet', 'max') {
            display: block;
          }

          &:before {
            margin-right: 5px;
            color: $Bgrey5;
            position: relative;
            top: 3px;
            font-size: 1.8rem;
          }

          margin-right: 15px;

          &.bono {
            text-transform: capitalize;
          }
        }

        strong {
          color: $Bgrey5;
        }
      }
    }

    &__icons {
      text-align: center;
    }

    &__table.table_responsive {
      width: 100%;
      text-align: center;
      border: 1px solid $Bprimary-lighter;
      border-collapse: unset;
      border-radius: 5px;
      @include mq('tablet-small', 'max') {
        border-color: $Bgrey2;
      }

      thead {
        background: $Bprimary-lighter;
        color: $Bwhite;
        text-transform: uppercase;
        padding: 15px;
      }

      tbody {
        padding: 15px;

        tr:nth-last-of-type(even) {
          background: $Bgrey05;
        }
      }

      td,
      th {
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0;
        font-size: 1.3rem;
        font-family: basicregular, Arial, sans-serif;
      }

      td {
        font-size: 1.4rem;
        font-weight: bold;
        color: $Bgrey6;

        &:last-child {
          color: $Bblack;
          font-family: basicbold, Arial, sans-serif;
          font-weight: lighter;
        }
      }
    }

    &__table-total {
      padding: 15px;
      text-transform: uppercase;
      text-align: right;
      font-family: basicregular, Arial, sans-serif;
    }

    &__list {
      font-size: 1.3rem;
      padding: 0;
      list-style-position: inside;
      margin: 5px 0 10px;

      li {
        margin-bottom: 5px;
        text-transform: none;
        color: $Bgrey7;
      }
    }

    &__loading {
      margin: 50px 40%;
    }
  }
}
