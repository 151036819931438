sbmn-html2pdf {
  .stopper-modal__scroll__container {
    color: $Bwhite;
    font-size: 3rem;
  }

  .cssload-loader {
    margin: 0 0 170px 0;
  }

  .pdf_icon {
    position: relative;
    top: 5px;
    @include mq('tablet', 'max') {
      top: 0;
    }
  }
}
