.agreement_form {
  &__section {
    margin: 20px 0;

    &_extra {
      display: flex;
      justify-content: center;
      margin: 50px 0 15px;
    }
  }
}
