sbmn-mta-cards-manager {
  @extend sbmn-mta-balance-manager;

  fieldset.center {
    margin-top: 30px;
  }
}

#card__number {
  background-image: url('/assets/img/logos/cardtypes.png');
  background-position: left bottom;
  background-repeat: no-repeat;
  transition: background-position 0.3s linear;
  padding-left: 60px;
  height: 37px;
  margin-bottom: 35px;

  &.VISA {
    background-position: 0 1px;
  }

  &.MASTERCARD {
    background-position: 0 -34px;
  }

  &.AMEX {
    background-position: 0 -72px;
  }

  &.DISCOVER {
    background-position: 0 -108px;
  }

  &.DINERS,
  &.DINERSBLANCHE {
    background-position: 0 -144px;
  }

  &.JCB {
    background-position: 0 -179px;
  }

  &.VISAELECTRON {
    background-position: 0 -216px;
  }
}
