//-------------------------------------------LOADER

.cssload-loader__container {
  @include cover();
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  margin: 0 auto;
  min-height: 100%;
  &:before {
    position: absolute;
    content: '';
    width: 50vh;
    height: 50vh;
    max-width: 600px;
    max-height: 600px;
    border-radius: 50%;
    background: rgba($Bblack, 0.1);
  }
  @include mq('tablet', 'max') {
    align-items: flex-start;
    padding-top: 200px;
  }
}

.loader_abs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2,
  h3 {
    display: block;
    margin: 20px auto;
  }
}

//-------------------------------------------LOADER

.cssload-loader,
.cssload-loader > div {
  box-sizing: border-box;
}
.cssload-loader {
  position: relative;
  display: block;
  font-size: 0;
  color: $Bsecondary;
  width: 50px;
  height: 96px;
  transform: rotate(90deg);
}
.cssload-loader > div {
  position: absolute;
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
  bottom: 0;
  left: 50%;
  width: 36px;
  height: 36px;
  border-radius: 2px;
  transform: translateY(0) translateX(-50%) rotate(45deg) scale(0);
  animation: fire-diamonds 2.5s infinite linear;
}
.cssload-loader > div:nth-child(1) {
  animation-delay: -0.85s;
}
.cssload-loader > div:nth-child(2) {
  animation-delay: -1.85s;
  color: $Bprimary-lighter;
}
.cssload-loader > div:nth-child(3) {
  animation-delay: -2.85s;
  color: $Bprimary-dark-gradient3;
}

.cssload-loader {
}
.cssload-loader > div {
}
/*
 * Animation
 */
@keyframes fire-diamonds {
  0% {
    transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
  }
  50% {
    transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1);
  }
  100% {
    transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0);
  }
}
