sbmn-lang-selector {
  display: flex;
  align-items: center !important;
  margin: 0 10px !important;
  font-size: 1.3rem;
  .mat-select {
    opacity: 0;
  }

  mat-form-field {
    max-width: 30px;
    max-height: 30px;
    width: unset !important;

    &:hover #langTrigger {
      border-color: $Bwhite;
      color: $Bwhite;
    }

    .mat-form-field-underline {
      display: none !important;
    }
    .mat-form-field-wrapper {
      padding: 0;
      align-items: center !important;
    }
  }
  @include mq('tablet', 'max') {
    transform: scale(0.9);
    right: 60px;
    position: absolute;
  }
}

#langTrigger {
  border-radius: 50%;
  border: 2px solid $Bgrey3;
  justify-content: center;
  display: flex;
  align-items: center !important;
  position: absolute;
  height: 30px;
  width: 30px;
  top: -12px;
  color: $Bgrey3;
  font-weight: bold;
  transition: all 0.2s linear;
  text-transform: uppercase;
}
