sbmn-mta-balance-manager {
  &:before {
    @extend .Bborder-y;
  }
  h4 {
    color: $Bgrey8;
    font-family: basicregular, Arial, sans-serif;
  }

  .Bborder-y {
    @extend %removeBdr;
  }

  .mbalancem {
    &__shoppingterms {
      color: $Bblue;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $Bviolet;
        text-decoration: none;
      }
    }

    &__file {
      sbmn-file-uploader {
        padding: 0 0 10px;
        margin-bottom: 15px;
        display: block;
        border-bottom: 1px solid $Bgrey2;
      }

      &__title {
        color: $Bprimary-lighter;

        small {
          font-family: basiclight, Arial, sans-serif;
          font-size: 0.8em;
          color: $Bgrey6;
        }
      }

      &__action {
        text-align: center;
      }
    }
  }
}
