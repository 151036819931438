.tpv__response {
  @include cover();
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  font-size: 2rem;

  &__header {
    width: 100%;
    margin: 3vh auto;
    text-align: center;
    img {
      max-height: 70px;
    }
  }

  &__loading {
    align-items: flex-start;
    padding-top: 3%;
  }

  &__container {
    margin-top: 6%;
    max-width: 600px;
    text-align: center;

    &__title {
      &.h2withbold {
        margin: 25px auto;
      }
    }

    &__text {
      margin: 30px;

      &:before {
        font-size: 3rem;
        margin: 20px auto;
        @include circle(80px);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px solid $Bgreen-light;
        color: $Bgreen-light;
      }

      &.delete:before {
        color: $Berror-light;
        border: 3px solid $Berror-light;
      }

      &__p {
        font-size: 0.8em;
      }
    }
  }
}
