sbmn-my-travel-agency {
  display: block;
  padding-bottom: 0px;
  min-height: 100%;

  sbmn-info {
    display: block;
    position: relative;
    margin: 20px auto;
    max-width: map_get($breakpoints, 'tablet');
    @include mq('tablet', 'max') {
      left: 0;
      padding: 20px 20px 20px 0;
      margin-bottom: 40px;
      margin-top: 10px;
    }
  }
}

#mta__data {
  min-height: 90vh;
  &.max-width-page {
    max-width: unset;
  }
}

.mta {
  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;

    h1 {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 90px;
      margin: 0;
      font-size: 4.2rem;

      strong {
        padding: 0 20px;
      }

      @include mq('tablet', 'max') {
        padding: 20px 30px;
        font-size: 3.5rem;
        flex-direction: column;
        justify-content: center;
        strong {
          display: block;
        }
      }
    }

    .Bplus {
      margin-left: 20px;
    }

    &__filter-button {
      position: absolute;
      cursor: pointer;
      color: $Bprimary;
      text-transform: uppercase;
      font-size: 1.4rem;
      height: 40px;
      width: 40px;
      margin: 0 20px 0 10px;
      left: 30px;

      &:before {
        line-height: 2;
        font-size: 2rem;
        padding: 8px;
        border: 2px solid $Bprimary;
        border-radius: 50%;
      }

      &:hover,
      &:focus {
        color: $Bsecondary;

        &:before {
          border: 2px solid $Bsecondary;
        }
      }

      @include mq('tablet', 'max') {
        position: relative;
        height: 30px;
        width: 30px;
        left: unset;
        &:before {
          line-height: 1.7;
          font-size: 1.8rem;
          padding: 5px;
        }
      }
    }
  }

  &__menu {
    border-bottom: 2px solid $Bwhite;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    position: relative;

    &-container {
      display: flex;
      justify-content: center;
    }

    &__link {
      text-decoration: none;
      text-align: center;
      font-size: 1.1rem;
      text-transform: uppercase;
      margin: 0 10px;

      &.active,
      &:hover i,
      &.active i {
        color: $Bthird;
        border-color: $Bthird;

        &:before {
          transform: scale(1.2);
        }
      }
    }

    i {
      color: $Bgrey3;
      font-size: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px auto;
      transition: all 0.2s linear;
      @include mq('tablet', 'max') {
        font-size: 2rem;
        display: flex;
      }
    }
  }

  &__submenu {
    display: flex;
    justify-content: center;
    border-bottom: 1px dashed $Bgrey3;
    padding-bottom: 10px;
    margin-bottom: 20px;
    @include mq('tablet', 'max') {
      color: $Btransparent;
      flex-direction: column;
    }

    &__link {
      margin: 0 10px;
      cursor: pointer;
      @include mq('tablet', 'max') {
        padding: 0 0 12px;
        border-bottom: 1px solid $Bgrey3;
        display: inline-block;
        width: 100%;
        text-align: center;
        margin: 0;
        text-decoration: none;
        &:last-child {
          border: 0;
        }
      }

      &:hover,
      &:focus,
      &.selected {
        color: $Bprimary-lighter;
        font-weight: bold;
      }
    }
  }
}
