sbmn-flights-dashboard {
  display: block;
  position: relative;
  margin-bottom: -6vh;
  .stopper-modal__scroll {
    @include mq('tablet', ' max') {
      width: calc(100% - 15px);
    }
  }

  .blurred {
    //position: fixed;
    //width:100%;
    //height: 100%;
    //top:0; left:0;
    //background: url("") bottom no-repeat;
    //background-size: cover;
    //background: $Bprimary-dark  ;
    display: none;
    //filter: saturate(85%);
  }

  .appear {
    display: unset;
  }

  .max-width-page {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: auto;
    justify-content: center;

    sbmn-flights-search-engine,
    sbmn-flights-highlight,
    sbmn-flights-offers,
    sbmn-flights-messages {
      border-radius: 0 20px 0;
      @include mq('tablet', 'max') {
        width: 100%;
        margin: 2% auto 40px;
      }
    }
    sbmn-flights-highlight,
    sbmn-flights-offers,
    sbmn-flights-messages {
      box-shadow: 0px 0px 18px -3px $Bprimary-dark-gradient3;
    }

    & > :nth-child(1) {
      order: 1;
    }
    & > :nth-child(2) {
      order: 2;
    }
    & > :nth-child(3) {
      order: 3;
    }
    & > :nth-child(4) {
      order: 5;
    }
    & > :nth-child(5) {
      order: 4;
    }
    @include mq('tablet', 'max') {
      flex-direction: column;
    }
  }

  sbmn-flights-welcome {
    width: 100%;
    height: 30vh;
    min-height: 500px;
    max-height: 500px;
    top: 0;
    left: 0;
    margin-bottom: -160px;
    img {
      opacity: 0.8;
    }
  }

  sbmn-flights-search-engine {
    width: 90%;
    margin: 0 10% -40px;
    top: -90px;
    max-width: 1150px;
    &:after {
      content: ' ';
      width: 100%;
    }
  }
  #main_search-engine {
    min-height: 287px;
  }

  sbmn-flights-highlight {
    display: none;
    width: 28%;
    max-width: calc(1440px * 0.28);
    min-height: 100%;
    overflow: hidden;
    margin: 0 1% 50px;
    &.appear {
      display: flex;
    }
    @include mq('tablet', 'max') {
      min-height: 0;
    }
  }

  sbmn-flights-offers {
    display: none;
    background: $Bgrey1;
    padding: 30px;
    flex-basis: 58%;
    max-width: 1440px;
    margin-bottom: 100px;
    flex-grow: 1;
  }

  sbmn-flights-messages {
    display: none;
    flex-basis: 68%;
    max-width: calc(1440px * 0.68);
    padding: 30px 0;
    z-index: z(floor);
    flex-grow: 1;
    margin: 0 1% 50px;
  }
}
