table.table_responsive {
  table-layout: fixed;
  overflow: hidden;
  border-spacing: 0;
  width: 96%;
  margin: 0 auto;
  border-collapse: collapse;

  thead {
    th {
      cursor: pointer;
      word-break: break-word;
      line-height: 1;

      &.l {
        text-align: left;
      }

      &.r {
        text-align: right;
      }
    }
  }

  tbody {
    td {
      text-align: center;
      word-break: break-word;
    }
  }

  @include mq('tablet', 'max') {
    display: block;
    width: fit-content;
    thead,
    tbody,
    th,
    td,
    tr {
      display: table-header-group;
    }
    table {
      border-collapse: collapse;
      max-width: 100%;
      width: 100%;
    }
    thead {
      position: absolute;
      top: -9999px;
      left: -9999px;
      visibility: hidden;
    }
    tbody {
      padding: 0;
    }
    tbody td,
    tbody td:first-child,
    tbody td:last-child {
      position: relative;
      width: 100%;
      min-width: 100%;
      min-height: 35px;
      padding: 5px 15px;
      text-align: right;
      word-break: break-word;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $Bgrey1;
    }
    tbody td:first-child {
      padding-top: 10px;
    }
    tbody td:last-child {
      padding-bottom: 10px;
    }
    tbody td:before {
      min-width: 25%;
      content: attr(title);
      text-align: left;
      text-transform: uppercase;
    }
  }
}

%table-tbody_responsive-st {
  @include mq('tablet', 'max') {
    tbody {
      tr {
        height: auto;
        position: relative;
      }

      td {
        border: none;
        position: relative;
      }

      width: 100%;
      margin: 0;
      font-size: 1.6rem;
      border-bottom: none;
    }
  }
}

.basic__table {
  width: 96%;
  margin: 0 auto;
  font-size: 1.6rem;
  border-bottom: 2px dotted #ccc;
  @extend %table-tbody_responsive-st;

  &__head,
  &__body {
    tr {
      height: 50px;
    }
  }

  &__head {
    text-transform: uppercase;
    background-color: $Bgrey3;
    cursor: pointer;

    tr {
      border-left: 10px solid $Bgrey3;
    }

    &__container {
      color: $Bblack;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;

      &:hover {
        color: $Bblack;
      }

      &.nohover:hover {
        cursor: auto;
        color: $Bwhite;
      }

      &__icon {
        margin-left: 5px;
        font-size: 18px;
        transition: transform 0.2s;
        transform-origin: 50% 45%;
      }
    }
  }

  &__body {
    tr {
      cursor: pointer;
      color: $Bgrey8;
      transition: background-color 0.2s;
      border-left: 10px solid $Bgrey05;
      background: $Bgrey05;

      &:nth-child(odd) {
        background: $Bwhite;
        border-left: 10px solid $Bwhite;
      }
    }

    td {
      text-align: center;
      vertical-align: middle;
      padding-left: 10px;
      padding-right: 10px;

      &.outdated {
        color: $Berror;
      }
    }

    &__cross.ko {
      top: 0;
      right: 0;
      position: relative;

      &:before {
        color: $Berror;
        font-weight: bold;
        font-size: 1.5rem;
      }
    }

    &__cross.ok {
      color: $Bgreen-dark;
    }

    border-bottom: 2px dotted $Bgrey2;

    &__pas,
    &__pto {
      font-weight: bold;
    }
  }
}
