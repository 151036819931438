sbmn-my-bookings-refund {
  width: 100%;
  h2 {
    padding-right: 50px;
  }
  .ok {
    position: absolute;
    top: 20px;
    right: -3%;
    border: 4px solid $Bgreen-light;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      font-size: 2rem;
      color: $Bgreen-light;
    }
  }
}
