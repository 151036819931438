.mtainfo {
  min-height: 400px;
  max-width: 950px;
  margin: 0 auto;
  width: 100%;
}

.newsletters {
  padding-bottom: 50px;

  .filters-form section {
    width: 96%;
    margin: auto;
    @include mq('tablet', 'max') {
      flex-direction: column;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    @include mq('phablet', 'max') {
      justify-content: center;
    }

    .h3withbold {
      width: 100%;
      text-align: center;
    }

    &__new {
      margin: 1.5%;
      border-radius: 10px;
      padding: 10px 1.5%;
      max-width: 30%;
      min-width: 30%;
      min-height: 100px;
      border: 1px solid $Bgrey2;
      box-shadow: 1px 1px 3px 1px rgba($Bblack, 0.1);
      background: $Bwhite;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition:
        background 0.2s linear,
        color 0.2s linear;

      &:hover {
        background: $Bprimary-light;

        h2 {
          color: $Bwhite;
        }
      }

      @include mq('tablet', 'max') {
        max-width: 48%;
        min-width: 48%;
        margin: 1.5% 1%;
      }
      @include mq('phablet', 'max') {
        max-width: 98%;
        min-width: 98%;
        margin: 1.5% 0.5%;
      }

      figure {
        margin: 0 10px 0 0;
        padding: 0 10px 0 0;
        border-right: 1px solid $Bprimary-link;
      }

      img {
        max-height: 70px;
      }

      h2 {
        font-size: 1.5rem;
        line-height: 1;
        font-family: basiclight, Arial, sans-serif;

        strong {
          padding-top: 5px;
          font-size: 0.8em;
          display: block;
        }
      }
    }
  }

  .stopper-modal h2 {
    width: 90%;
    margin: auto;
  }

  sbmn-cms-templates img {
    max-width: 80%;
    @include mq('tablet', 'max') {
      max-width: 100%;
    }
  }

  .cms__openHTML__content {
    padding-top: 0;
    text-align: center;
  }
}

.tutorials {
  display: flex;
  flex-wrap: wrap;
  @include mq('tablet', 'max') {
    justify-content: center;
  }

  figure {
    padding: 10px 1.5%;
    margin: 0;
    max-width: 33%;
    @include mq('tablet', 'max') {
      max-width: 50%;
    }
    @include mq('phablet', 'max') {
      max-width: 100%;
    }

    &:hover {
      cursor: pointer;

      img {
        filter: grayscale(0);
      }

      .h2withbold,
      p {
        color: $Bblack;
      }
    }

    .h2withbold {
      font-size: 2rem;
      color: $Bgrey6;
      @include mq('tablet', 'max') {
        font-size: 2.5rem;
        text-align: center;
      }
    }
  }

  img {
    max-width: 100%;
    filter: grayscale(0.7);
    transition: filter 0.2s linear;
  }

  figcaption {
    margin: 10px auto;
    color: $Bgrey6;
  }
}

.mta-contact {
  .cms__openHTML {
    &__content {
      padding: 10px 0 30px 0;
      i {
        display: flex;
        min-width: 50px;
        min-height: 50px;
        max-width: 50px;
        max-height: 50px;
        padding: 0;
        font-size: 2.5rem;
        img {
          width: 3rem;
        }
      }
      &__plane-ball {
        width: 450px;
        height: 450px;
      }
      &__yellow__block {
        font-size: 2.5rem;
        text-align: left;
        width: 100%;
        a {
          font-size: 2.2rem;
        }
      }
    }
  }
}
