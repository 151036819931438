sbmn-flights-filters {
  padding-bottom: 50px;

  h2 {
    top: 17px;
    font-family: basiclight, Arial, sans-serif;
    font-size: 3rem;
    font-weight: lighter;
    margin: 0;
    padding-bottom: 50px;
    display: block;
    position: relative;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
  }

  section {
    width: 100%;
    margin-bottom: 15px;
    min-width: 280px;
    padding: 0 3%;
    border-bottom: 1px dotted $Bgrey5;

    h3 {
      font-family: basicregular, Arial, sans-serif;
      font-size: 1.4rem;
      letter-spacing: 0.05em;
      width: 100%;
      color: $Bblack;
      margin-bottom: 10px;
      margin-right: 10px;
      display: inline;

      &:before {
        font-size: 2.5rem;
        color: $Bgrey4;
        margin-right: 5px;
        top: 4px;
        position: relative;
      }
    }

    span {
      width: auto;
      text-align: center;
      display: inline-block;
      font-family: basicregular, Arial, sans-serif;
      color: $Bgrey6;
      font-size: 1.5rem;
    }
  }

  .filters_checks {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 12px;

    mat-checkbox {
      width: 50%;
      min-width: fit-content;
      font-size: 1.7rem;
      margin: 10px 0 0;
    }
  }

  .airlines {
    .mat-checkbox-inner-container {
      margin-right: 4px;
    }

    .mat-checkbox-label {
      letter-spacing: -0.1rem;
    }

    img {
      position: relative;
      top: 4px;
      left: -1px;
    }
  }

  mat-slider {
    width: 100%;
    padding: 4px 8px 8px;
    margin-top: 10px;
  }

  .deptime_filter {
    position: relative;
    max-width: calc(100% - 20px);
    width: calc(100% - 20px);

    span {
      margin-top: 10px;
      text-align: left;
      width: 100%;
    }
    mat-slider {
      margin-top: 0;
      &:nth-of-type(2) {
        position: absolute;
        left: 10px;
      }
    }
  }
}
