sbmn-my-bookings-info {
  display: block;
  width: 100%;

  .bmanager__info__container__route {
    border-width: 0 0 0 5px;
  }
}

.bmanager__info {
  &__container {
    display: flex;
    align-items: flex-start;
    margin: 20px 0 30px 0;
    padding-bottom: 30px;
    border-bottom: 1px solid $Bgrey2;

    &:nth-of-type(even) .ida:before {
      transform: rotate(180deg);
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include mq('tablet', 'max') {
      flex-direction: column;
    }

    &__title {
      text-align: center;
      font-family: basicregular, Arial, sans-serif;
      min-width: 80px;

      &:before {
        font-size: 40px;
        color: $Bgrey3;
        border: 1px solid $Bgrey3;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        margin: 0 auto 10px;
      }
    }

    &__route {
      flex-grow: 2;
      margin-left: 20px;
      padding-left: 20px;
      @include mq('tablet', 'max') {
        margin-left: 0;
        padding-right: 20px;
        min-width: 100%;
      }

      &__flight {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;

        &__stop {
          background-color: $Bgrey05;
          padding: 3px 10px;
          border-radius: 3px;
          text-align: center;
          margin: 11px 0;
          min-width: 100%;
        }

        &__info {
          flex-grow: 2;

          > div {
            margin: 5px 0;
          }

          &__header {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $Bprimary-light;
            color: $Bprimary-light;
            font-family: basicregular, Arial, sans-serif;
            @include mq('tablet', 'max') {
              flex-direction: column;
            }
          }

          &__date {
            font-family: basicregular, Arial, sans-serif;
            color: $Bprimary;
          }

          &__desc {
            font-size: 1.7rem;

            strong {
              margin-right: 10px;
            }
          }
        }

        &__extrainfo {
          margin: 0 0 0 20px;
          display: flex;
          justify-content: flex-end;
          text-align: center;
          flex-direction: column;
          @include mq('tablet', 'max') {
            margin: 0;
            width: 100%;
          }

          &__fare {
            background-color: $Bsecondary;
            text-transform: uppercase;
            color: $Bblack;
            padding: 1px 5px;
            font-size: 0.7em;
            border-radius: 10px;
            @include mq('tablet', 'max') {
              padding: 7px 5px;
            }
          }

          &__duration {
            background-color: $Bgrey1;
            padding: 8px;
            border-radius: 50px;
            font-size: 0.9em;
            margin: 10px 0;

            strong {
              margin-right: 10px;
            }

            &:before {
              padding-right: 5px;
              color: $Bgrey5;
            }
          }
        }
      }
    }
  }
}
