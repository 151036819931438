sbmn-new-search {
  i.m_icon {
    border-width: 0;
  }

  .menuicons {
    margin-bottom: 10px;

    i.m_icon {
      @include circle(40px);
      display: flex;
      margin-right: 10px;
      border-width: 2px;
    }
  }

  .m_iconHide {
    position: absolute;
    font-size: 0;
    color: $Btransparent;
    background: $Btransparent;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;

    i.m_icon {
      display: none;
    }
  }
}
