sbmn-footer {
  border-top: 1px solid $Bprimary-lighter;
  z-index: z(floor);
}

#main_footer {
  color: $Bwhite;
  padding: 10px;
  font-size: 1.6rem;
  background: $Bprimary-dark;
  margin-top: 6vh;
  min-width: 100%;
}
