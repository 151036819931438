sbmn-my-bookings-messages {
  @include mq('tablet', 'max') {
    min-width: 100%;
  }

  .msg-flights:before {
    right: -3%;
  }

  .basic__table {
    width: 100%;

    &-paginator {
      width: 100%;
    }

    &__head {
      tr {
        border: 0;
      }

      th {
        text-align: center;

        &:first-child {
          width: 40%;
        }
      }
    }

    &__body {
      background: $Bwhite;

      tr {
        border: 0;
        cursor: default;

        td:last-child:before {
          display: inline-block;
        }

        &:hover,
        &:focus,
        &.active {
          cursor: pointer;
          background: rgba($Bprimary-light, 0.2);
          border-left: 10px solid $Bprimary-lighter;
          color: $Bblack;
        }
      }

      td:last-child:before {
        display: none;
      }

      td:first-child {
        width: 30%;
      }
    }
  }

  .Bplus {
    margin: 0 0 15px 15px;
  }
}

.bmanager__msg {
  &__header {
    &__title {
      display: inline-block;
    }
  }

  &__section {
    &.msgmanager {
      padding-bottom: 20px;
      margin-bottom: 20px;

      .ko {
        position: absolute;
        right: -3.5%;
        left: unset;
        top: 10px;
        color: $Bblack;
        font-size: 2rem;
        opacity: 0.3;
        width: auto;
        height: auto;

        &:hover {
          opacity: 0.7;

          &:before {
            color: $Bblack;
          }
        }
      }
    }

    .msgmanager__header:before {
      display: none;
    }
  }

  &__hide {
    height: 0;
    overflow: hidden;
  }
}
