@keyframes headerIn {
  0% {
    top: -120px;
  }
  100% {
    top: 0;
  }
}

@keyframes searchtype {
  0% {
    opacity: 0;
    bottom: -35px;
  }
  100% {
    bottom: 5px;
    opacity: 1;
  }
}

@keyframes toBig {
  0% {
    opacity: 0;
    margin-top: -450px;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

@keyframes fadeOutHeight {
  0% {
    opacity: 1;
    height: auto;
    max-height: 2000px;
  }
  90% {
    opacity: 0.2;
    height: auto;
    max-height: 2000px;
  }
  100% {
    opacity: 0;
    height: 0;
    max-height: 0;
  }
}

@keyframes heightOut {
  0% {
    opacity: 0;
    overflow: hidden;
    max-height: 50px;
  }
  100% {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }
}

@keyframes appears {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes pulsebullet {
  0% {
    background: $Bgrey6;
  }
  100% {
    background: $Bfourth;
  }
}

@keyframes hideLoading {
  0% {
    visibility: visible;
    opacity: 1;
  }
  99.9% {
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes cssload-rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes cssload-rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes cssload-rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@keyframes MSG-TOUCH {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
