/* Colores primarios y secundarios */
/* Otros colores  */
/* Grises  */
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@keyframes headerIn {
  0% {
    top: -120px;
  }
  100% {
    top: 0;
  }
}
@keyframes searchtype {
  0% {
    opacity: 0;
    bottom: -35px;
  }
  100% {
    bottom: 5px;
    opacity: 1;
  }
}
@keyframes toBig {
  0% {
    opacity: 0;
    margin-top: -450px;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}
@keyframes fadeOutHeight {
  0% {
    opacity: 1;
    height: auto;
    max-height: 2000px;
  }
  90% {
    opacity: 0.2;
    height: auto;
    max-height: 2000px;
  }
  100% {
    opacity: 0;
    height: 0;
    max-height: 0;
  }
}
@keyframes heightOut {
  0% {
    opacity: 0;
    overflow: hidden;
    max-height: 50px;
  }
  100% {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }
}
@keyframes appears {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes pulsebullet {
  0% {
    background: #666;
  }
  100% {
    background: #05c7fa;
  }
}
@keyframes hideLoading {
  0% {
    visibility: visible;
    opacity: 1;
  }
  99.9% {
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes cssload-rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@keyframes cssload-rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}
@keyframes cssload-rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
@keyframes MSG-TOUCH {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font-size: 112px;
  font-weight: 300;
  line-height: 112px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font-size: 56px;
  font-weight: 400;
  line-height: 56px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-datepicker-content .mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-dialog-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3960c9;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #6c98db;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3960c9;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3960c9;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #6c98db;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #6c98db;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #6c98db;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #6c98db;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #3960c9;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #6c98db;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field-disabled .mat-form-field-prefix,
.mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3960c9;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #6c98db;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3960c9;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #6c98db;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3960c9;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3960c9;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #6c98db;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #6c98db;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3960c9;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #6c98db;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3960c9;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #6c98db;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #3960c9;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #6c98db;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #6c98db;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3960c9;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #6c98db;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #3960c9;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #6c98db;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3960c9;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #6c98db;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #3960c9;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(57, 96, 201, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #6c98db;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(108, 152, 219, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(57, 96, 201, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(57, 96, 201, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(57, 96, 201, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #3960c9;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(57, 96, 201, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(57, 96, 201, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(57, 96, 201, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(108, 152, 219, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(108, 152, 219, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(108, 152, 219, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #6c98db;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(108, 152, 219, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(108, 152, 219, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(108, 152, 219, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #3960c9;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #6c98db;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

/*  :not(.mat-calendar-body-disabled):hover,
  .cdk-keyboard-focused .mat-calendar-body-active,
  .cdk-program-focused .mat-calendar-body-active {
    & > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected) {
      background-color: mat-color($background, hover);
    }
    & > .mat-calendar-body-semi-selected {
      background-color: red;
      color: mat-color($primary, default-contrast);
    }
  }*/
.mat-datepicker-content {
  min-height: 320px;
  max-height: 354px;
  width: auto !important;
  display: flex;
  justify-content: center;
}

.mat-calendar {
  height: auto !important;
  padding: 0 10px;
}

.mat-calendar-header {
  padding: 0 8px;
}

td.mat-calendar-body-label {
  opacity: 0;
}
td.mat-calendar-body-label[colspan="7"] {
  display: none;
}

.quick-select-sec,
.calendar-button-sec {
  display: none;
}

.mat-calendar-body-begin-range:not(.mat-calendar-body-end-range) {
  border-radius: 100% 0 0 100%;
  background-color: #fff;
}

.mat-calendar-body-end-range:not(.mat-calendar-body-begin-range) {
  border-radius: 0 100% 100% 0;
  background-color: #fff;
}

.mat-calendar-body > tr .mat-calendar-cell-semi-selected ~ .mat-calendar-cell-semi-selected {
  border-radius: 0;
}

.mat-calendar-cell-semi-selected,
.mat-calendar-body-begin-range,
.mat-calendar-body-end-range {
  background-color: #e5e5e5 !important;
}

.mat-button,
.mat-icon-button {
  background: #fff;
}
.mat-button[disabled],
.mat-icon-button[disabled] {
  color: #fff;
}

form {
  font-size: 1.5rem !important;
  font-family: special !important;
}
form .Berror {
  position: absolute;
  bottom: -17px;
  left: 0;
  padding: 0;
  font-size: 0.75em;
}

.mat-form-field {
  width: 100%;
}

.cdk-overlay-pane {
  width: auto !important;
  overflow: hidden !important;
  display: block;
}
@media only screen and (max-width: 1022px) {
  .cdk-overlay-pane {
    min-width: 82% !important;
  }
}

.mat-autocomplete-panel {
  width: calc(105% + 30px) !important;
  display: block;
  overflow-y: scroll !important;
  max-width: 200% !important;
}
.mat-autocomplete-panel.mat-autocomplete-hidden {
  display: none;
}
.mat-autocomplete-panel mat-option {
  background: #66c2ff;
  color: #fff;
  padding: 5px;
}
.mat-autocomplete-panel .mat-option:hover:not(.mat-option-disabled) {
  color: #000;
}

.mat-radio-group {
  width: 100%;
  margin-bottom: 20px;
}
.mat-radio-group mat-radio-button {
  margin-right: 20px;
}

body .mat-input-element {
  line-height: 1;
}
body .mat-option {
  font-size: 1.5rem;
  height: 30px;
  line-height: 30px;
  text-transform: uppercase;
}
@media only screen and (max-width: 1022px) {
  body .mat-option {
    font-size: 1.7rem;
    height: 45px;
    line-height: 30px;
  }
}
body .mat-select-panel {
  max-width: unset;
}
body .cdk-overlay-pane {
  box-shadow: 1px 2px 10px #ccc !important;
  border-radius: 5px;
}
@media only screen and (max-width: 1022px) {
  body .cdk-overlay-pane {
    transform: none !important;
    left: 2% !important;
    max-width: 96% !important;
  }
}

.mat-option.mat-active,
.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 153, 255, 0.3);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 50px #fff inset;
}

.selInputsGrey input:-webkit-autofill,
.selInputsGrey input:-webkit-autofill:hover,
.selInputsGrey input:-webkit-autofill:focus,
.selInputsGrey input:-webkit-autofill:active,
.selInputsGrey textarea:-webkit-autofill,
.selInputsGrey textarea:-webkit-autofill:hover,
.selInputsGrey textarea:-webkit-autofill:focus,
.selInputsGrey textarea:-webkit-autofill:active,
.selInputsGrey select:-webkit-autofill,
.selInputsGrey select:-webkit-autofill:hover,
.selInputsGrey select:-webkit-autofill:focus,
.selInputsGrey select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 50px #f1f1f1 inset;
}

input,
input:before,
input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

fieldset {
  border: 0;
  padding: 0;
}
fieldset.center {
  text-align: center;
}

textarea {
  resize: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px;
  font-family: basicregular, Arial, sans-serif;
  font-size: 1.4rem;
  display: block;
}

.wcheck .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.54);
}

.mat-checkbox-layout .mat-checkbox-label {
  white-space: normal;
  line-height: 1;
}

.readonly .mat-form-field-underline {
  background: transparent;
}

sbmn-my-bookings > header.mybookings__header div:last-child button, sbmn-my-bookings-import div.stopper-modal__scroll__container > div > div:nth-child(2) > div button, .voucher__sendBondForm button, .Bbutton, .buttonred, .buttonyellow, .buttontransparent, .buttontransparentb, .buttonwhite, .buttonbluelr, .stopper-modal__scroll__container__button, .buttonblue {
  padding: 15px 40px;
  font-size: 1.5rem;
  font-family: basicregular, Arial, sans-serif;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  transition: box-shadow 0.1s linear;
}
sbmn-my-bookings > header.mybookings__header div:last-child button[disabled], sbmn-my-bookings-import div.stopper-modal__scroll__container > div > div:nth-child(2) > div button[disabled], .voucher__sendBondForm button[disabled], [disabled].Bbutton, [disabled].buttonred, [disabled].buttonyellow, [disabled].buttontransparent, [disabled].buttontransparentb, [disabled].buttonwhite, [disabled].buttonbluelr, [disabled].stopper-modal__scroll__container__button, [disabled].buttonblue {
  background: #7f7f7f;
  color: #fff;
  cursor: not-allowed;
  border: 1px solid transparent;
}
sbmn-my-bookings > header.mybookings__header div:last-child button[disabled]:focus, sbmn-my-bookings-import div.stopper-modal__scroll__container > div > div:nth-child(2) > div button[disabled]:focus, .voucher__sendBondForm button[disabled]:focus, [disabled].Bbutton:focus, [disabled].buttonred:focus, [disabled].buttonyellow:focus, [disabled].buttontransparent:focus, [disabled].buttontransparentb:focus, [disabled].buttonwhite:focus, [disabled].buttonbluelr:focus, [disabled].stopper-modal__scroll__container__button:focus, [disabled].buttonblue:focus, sbmn-my-bookings > header.mybookings__header div:last-child button[disabled]:active, sbmn-my-bookings-import div.stopper-modal__scroll__container > div > div:nth-child(2) > div button[disabled]:active, .voucher__sendBondForm button[disabled]:active, [disabled].Bbutton:active, [disabled].buttonred:active, [disabled].buttonyellow:active, [disabled].buttontransparent:active, [disabled].buttontransparentb:active, [disabled].buttonwhite:active, [disabled].buttonbluelr:active, [disabled].stopper-modal__scroll__container__button:active, [disabled].buttonblue:active, sbmn-my-bookings > header.mybookings__header div:last-child button[disabled]:hover, sbmn-my-bookings-import div.stopper-modal__scroll__container > div > div:nth-child(2) > div button[disabled]:hover, .voucher__sendBondForm button[disabled]:hover, [disabled].Bbutton:hover, [disabled].buttonred:hover, [disabled].buttonyellow:hover, [disabled].buttontransparent:hover, [disabled].buttontransparentb:hover, [disabled].buttonwhite:hover, [disabled].buttonbluelr:hover, [disabled].stopper-modal__scroll__container__button:hover, [disabled].buttonblue:hover {
  border: 1px solid transparent;
  box-shadow: none;
}
sbmn-my-bookings > header.mybookings__header div:last-child button:focus, sbmn-my-bookings-import div.stopper-modal__scroll__container > div > div:nth-child(2) > div button:focus, .voucher__sendBondForm button:focus, .Bbutton:focus, .buttonred:focus, .buttonyellow:focus, .buttontransparent:focus, .buttontransparentb:focus, .buttonwhite:focus, .buttonbluelr:focus, .stopper-modal__scroll__container__button:focus, .buttonblue:focus, sbmn-my-bookings > header.mybookings__header div:last-child button:active, sbmn-my-bookings-import div.stopper-modal__scroll__container > div > div:nth-child(2) > div button:active, .voucher__sendBondForm button:active, .Bbutton:active, .buttonred:active, .buttonyellow:active, .buttontransparent:active, .buttontransparentb:active, .buttonwhite:active, .buttonbluelr:active, .stopper-modal__scroll__container__button:active, .buttonblue:active, sbmn-my-bookings > header.mybookings__header div:last-child button:hover, sbmn-my-bookings-import div.stopper-modal__scroll__container > div > div:nth-child(2) > div button:hover, .voucher__sendBondForm button:hover, .Bbutton:hover, .buttonred:hover, .buttonyellow:hover, .buttontransparent:hover, .buttontransparentb:hover, .buttonwhite:hover, .buttonbluelr:hover, .stopper-modal__scroll__container__button:hover, .buttonblue:hover {
  text-decoration: none;
}

.buttonblue {
  border: 1px solid #fff;
  background: #0099ff;
  color: #fff;
}
.buttonblue:focus, .buttonblue:active, .buttonblue:hover, .buttonblue.fsel {
  border: 1px solid #0099ff;
  background: #fff;
  color: #0099ff;
}

.buttonbluelr, .stopper-modal__scroll__container__button {
  border: 1px solid #fff;
  background: #66c2ff;
  color: #fff;
}
.buttonbluelr:focus, .stopper-modal__scroll__container__button:focus, .buttonbluelr:active, .stopper-modal__scroll__container__button:active, .buttonbluelr:hover, .stopper-modal__scroll__container__button:hover, .buttonbluelr.fsel, .fsel.stopper-modal__scroll__container__button {
  border: 1px solid #66c2ff;
  background: #fff;
  color: #66c2ff;
  box-shadow: 0 0 17px -1px inset #e5e5e5;
}

.buttonwhite {
  border: 1px solid #0099ff;
  background: #fff;
  color: #0099ff;
}
.buttonwhite:focus, .buttonwhite:active, .buttonwhite:hover {
  border: 1px solid #fff;
  background: #0099ff;
  color: #fff;
}
.buttonwhite[disabled]:hover {
  background: #7f7f7f;
}

.buttontransparent, .buttontransparentb {
  border: 1px solid #7f7f7f;
  background: transparent;
  color: #7f7f7f;
}
.buttontransparent:focus, .buttontransparentb:focus, .buttontransparent:active, .buttontransparentb:active, .buttontransparent:hover, .buttontransparentb:hover {
  border: 1px solid #e5e5e5;
  color: #0099ff;
  background: #e5e5e5;
}

.buttontransparent.emitError, .emitError.buttontransparentb {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}

.tpv__response__container__text.emitError {
  display: block;
  padding: 0;
}

.buttontransparentb {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
}

.buttonyellow {
  border: 1px solid #0099ff;
  background: #0099ff;
  color: #fff;
}
.buttonyellow:focus, .buttonyellow:active, .buttonyellow:hover {
  border: 1px solid #0099ff;
  box-shadow: 0 0 17px -1px inset #f48126;
}

.buttonred {
  border: 1px solid #f67b81;
  background: #f67b81;
  color: #fff;
}
.buttonred:focus, .buttonred:active, .buttonred:hover {
  border: 1px solid #f48126;
  box-shadow: 0 0 17px -1px inset #9a0b11;
  background: #ef1c25;
}

.Bbutton {
  text-decoration: none;
}

@font-face {
  font-family: "basicbold";
  src: url("/assets/fonts/MrEavesXLModOT-Heavy.woff2") format("woff2");
}
@font-face {
  font-family: "basiclight";
  src: url("/assets/fonts/MrEavesXLModOT-Light.woff2") format("woff2");
}
@font-face {
  font-family: "basicregular";
  src: url("/assets/fonts/MrEavesXLModOT-Reg.woff2") format("woff2");
}
@font-face {
  font-family: "special";
  src: url("/assets/fonts/Calibri.woff2") format("woff2");
}
@font-face {
  font-family: icons;
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/icons.woff") format("woff");
}
.ico__, .delete:before, .msg-web:before, .msg-refunds:before, .msg-admin:before, .qr_code:before, .pdf-insurance:before, .refund:before, .office:before, .waiting:before, .webcam:before, .videoconf:before, .play:before, .trolley:before, .megafono:before, .split:before, .ayuda:before, .phone:before, .mail:before, .can:before, .msg-leido:before, .conversacion:before, .less:before, .Bless:before, .plus:before, .Bplus:before, .bono:before, .msg-flights:before, .msg-warnings:before, .msg-general:before, .recycle:before, .pdf:before, .warning:before, .cash:before, .agency_data:before, .credit_card:before, .personas:before, .end:before, .poweroff:before, .pin:before, .multitrayecto:before, .menosopciones:before, .seguro:before, .money:before, .upload:before, .pasajerobuscador:before, .pasajero:before, .ok:before, .maleta:before, .localizador:before, .ko:before, .info3:before, .soloida:before, .idavuelta:before, .document:before, .gear:before, .filtrar:before, .escala:before, .editar:before, .clock:before, .calendar:before, .summary #simpledates_search-engine .day:before, .butaca:before, .billete:before, .ida:before, .vuelta:before, .avion:before, .arrow_next:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.arrow_next:before {
  content: "\e901";
}

.avion:before {
  content: "\e902";
}

.ida:before, .vuelta:before {
  content: "\e903";
}

.billete:before {
  content: "\e904";
}

.butaca:before {
  content: "\e905";
}

.calendar:before, .summary #simpledates_search-engine .day:before {
  content: "\e906";
}

.clock:before {
  content: "\e907";
}

.editar:before {
  content: "\e908";
}

.escala:before {
  content: "\e909";
}

.filtrar:before {
  content: "\e90a";
}

.gear:before {
  content: "\e90b";
}

.document:before {
  content: "\e90c";
}

.idavuelta:before {
  content: "\e90d";
}

.soloida:before {
  content: "\e90e";
}

.info3:before {
  content: "\e90f";
}

.ko:before {
  content: "\e910";
}

.localizador:before {
  content: "\e911";
}

.maleta:before {
  content: "\e912";
}

.ok:before {
  content: "\e913";
}

.pasajero:before {
  content: "\e914";
}

.pasajerobuscador:before {
  content: "\e915";
}

.upload:before {
  content: "\e916";
}

.money:before {
  content: "\e917";
}

.seguro:before {
  content: "\e918";
}

.menosopciones:before {
  content: "\e919";
}

.multitrayecto:before {
  content: "\e91a";
}

.pin:before {
  content: "\e91b";
}

.poweroff:before {
  content: "\e91c";
}

.end:before {
  content: "\e91d";
}

.personas:before {
  content: "\e91e";
}

.credit_card:before {
  content: "\e91f";
}

.agency_data:before {
  content: "\e920";
}

.cash:before {
  content: "\e921";
}

.warning:before {
  content: "\e922";
}

.pdf:before {
  content: "\e923";
}

.recycle:before {
  content: "\e924";
}

.msg-general:before {
  content: "\e925";
}

.msg-warnings:before {
  content: "\e926";
}

.msg-flights:before {
  content: "\e927";
}

.bono:before {
  content: "\e928";
}

.plus:before, .Bplus:before, .Bless:before {
  content: "\e929";
}

.less:before, .Bless:before {
  content: "\e92a";
}

.conversacion:before {
  content: "\e92b";
}

.msg-leido:before {
  content: "\e92c";
}

.can:before {
  content: "\e92d";
}

.mail:before {
  content: "\e92e";
}

.phone:before {
  content: "\e92f";
}

.ayuda:before {
  content: "\e930";
}

.split:before {
  content: "\e931";
}

.megafono:before {
  content: "\e932";
}

.trolley:before {
  content: "\e933";
}

.play:before {
  content: "\e934";
}

.videoconf:before {
  content: "\e935";
}

.webcam:before {
  content: "\e936";
}

.waiting:before {
  content: "\e937";
}

.office:before {
  content: "\e938";
}

.refund:before {
  content: "\e939";
}

.pdf-insurance:before {
  content: "\e93a";
}

.qr_code:before {
  content: "\e900";
}

/*----- extra -----*/
.msg-admin:before {
  content: "\e938";
}

.msg-refunds:before {
  content: "\e939";
}

.msg-web:before {
  content: "\e932";
}

.delete:before {
  content: "\e910";
}

.vuelta {
  transform: rotate(180deg);
}

table.table_responsive {
  table-layout: fixed;
  overflow: hidden;
  border-spacing: 0;
  width: 96%;
  margin: 0 auto;
  border-collapse: collapse;
}
table.table_responsive thead th {
  cursor: pointer;
  word-break: break-word;
  line-height: 1;
}
table.table_responsive thead th.l {
  text-align: left;
}
table.table_responsive thead th.r {
  text-align: right;
}
table.table_responsive tbody td {
  text-align: center;
  word-break: break-word;
}
@media only screen and (max-width: 1022px) {
  table.table_responsive {
    display: block;
    width: fit-content;
  }
  table.table_responsive thead,
  table.table_responsive tbody,
  table.table_responsive th,
  table.table_responsive td,
  table.table_responsive tr {
    display: table-header-group;
  }
  table.table_responsive table {
    border-collapse: collapse;
    max-width: 100%;
    width: 100%;
  }
  table.table_responsive thead {
    position: absolute;
    top: -9999px;
    left: -9999px;
    visibility: hidden;
  }
  table.table_responsive tbody {
    padding: 0;
  }
  table.table_responsive tbody td,
  table.table_responsive tbody td:first-child,
  table.table_responsive tbody td:last-child {
    position: relative;
    width: 100%;
    min-width: 100%;
    min-height: 35px;
    padding: 5px 15px;
    text-align: right;
    word-break: break-word;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
  }
  table.table_responsive tbody td:first-child {
    padding-top: 10px;
  }
  table.table_responsive tbody td:last-child {
    padding-bottom: 10px;
  }
  table.table_responsive tbody td:before {
    min-width: 25%;
    content: attr(title);
    text-align: left;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 1022px) {
  .calendargrid__table tbody, .basic__table tbody {
    width: 100%;
    margin: 0;
    font-size: 1.6rem;
    border-bottom: none;
  }
  .calendargrid__table tbody tr, .basic__table tbody tr {
    height: auto;
    position: relative;
  }
  .calendargrid__table tbody td, .basic__table tbody td {
    border: none;
    position: relative;
  }
}

.basic__table {
  width: 96%;
  margin: 0 auto;
  font-size: 1.6rem;
  border-bottom: 2px dotted #ccc;
}
.basic__table__head tr, .basic__table__body tr {
  height: 50px;
}
.basic__table__head {
  text-transform: uppercase;
  background-color: #b2b2b2;
  cursor: pointer;
}
.basic__table__head tr {
  border-left: 10px solid #b2b2b2;
}
.basic__table__head__container {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.basic__table__head__container:hover {
  color: #000;
}
.basic__table__head__container.nohover:hover {
  cursor: auto;
  color: #fff;
}
.basic__table__head__container__icon {
  margin-left: 5px;
  font-size: 18px;
  transition: transform 0.2s;
  transform-origin: 50% 45%;
}
.basic__table__body {
  border-bottom: 2px dotted #ccc;
}
.basic__table__body tr {
  cursor: pointer;
  color: #333;
  transition: background-color 0.2s;
  border-left: 10px solid #f1f1f1;
  background: #f1f1f1;
}
.basic__table__body tr:nth-child(odd) {
  background: #fff;
  border-left: 10px solid #fff;
}
.basic__table__body td {
  text-align: center;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
}
.basic__table__body td.outdated {
  color: #ef1c25;
}
.basic__table__body__cross.ko {
  top: 0;
  right: 0;
  position: relative;
}
.basic__table__body__cross.ko:before {
  color: #ef1c25;
  font-weight: bold;
  font-size: 1.5rem;
}
.basic__table__body__cross.ok {
  color: #427127;
}
.basic__table__body__pas, .basic__table__body__pto {
  font-weight: bold;
}

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden; /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item.center {
  z-index: 5;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

owl-carousel-o {
  display: flex;
  height: 100%;
  width: 100%;
}

.owl-theme,
.owl-stage-outer,
owl-stage > div,
.owl-stage,
.owl-item {
  height: 100%;
}

.owl-dot {
  transform: scale(1.7);
  margin: 5px;
}
.owl-dot span {
  background: transparent !important;
  margin: 3px !important;
  border: 1px solid #666;
}
.owl-dot.active span {
  border-color: #0073e6;
  background: #003366 !important;
}

.cssload-loader__container {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: block;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  margin: 0 auto;
  min-height: 100%;
}
.cssload-loader__container:before {
  position: absolute;
  content: "";
  width: 50vh;
  height: 50vh;
  max-width: 600px;
  max-height: 600px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 1022px) {
  .cssload-loader__container {
    align-items: flex-start;
    padding-top: 200px;
  }
}

.loader_abs, .login__loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loader_abs h2, .login__loading h2,
.loader_abs h3,
.login__loading h3 {
  display: block;
  margin: 20px auto;
}

.cssload-loader,
.cssload-loader > div {
  box-sizing: border-box;
}

.cssload-loader {
  position: relative;
  display: block;
  font-size: 0;
  color: #003366;
  width: 50px;
  height: 96px;
  transform: rotate(90deg);
}

.cssload-loader > div {
  position: absolute;
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
  bottom: 0;
  left: 50%;
  width: 36px;
  height: 36px;
  border-radius: 2px;
  transform: translateY(0) translateX(-50%) rotate(45deg) scale(0);
  animation: fire-diamonds 2.5s infinite linear;
}

.cssload-loader > div:nth-child(1) {
  animation-delay: -0.85s;
}

.cssload-loader > div:nth-child(2) {
  animation-delay: -1.85s;
  color: #0099ff;
}

.cssload-loader > div:nth-child(3) {
  animation-delay: -2.85s;
  color: #333333;
}

/*
 * Animation
 */
@keyframes fire-diamonds {
  0% {
    transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
  }
  50% {
    transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1);
  }
  100% {
    transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0);
  }
}
sbmn-header {
  display: block;
  z-index: 3;
  position: relative;
}

#main_header {
  background: #fff;
  padding: 0 10px;
  height: 80px;
  max-width: unset;
}
#main_header,
#main_header div,
#main_header nav,
#main_header a {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1022px) {
  #main_header {
    position: fixed;
    z-index: 6;
    top: 0;
    height: 60px;
  }
}
#main_header .main_header__logo:before {
  margin: 0 -5px;
  left: 0;
  bottom: 18px;
  transform: rotate(-270deg);
}
@media only screen and (max-width: 1022px) {
  #main_header .main_header__logo {
    min-width: calc(100% - 100px);
    margin-right: auto;
    margin-left: auto;
  }
  #main_header .main_header__logo:before {
    top: -5px;
    left: 50%;
    margin-left: -5px;
  }
}
#main_header figure {
  max-width: 300px;
  display: flex;
  align-items: center;
  margin: 0;
  padding-right: 30px;
}
@media only screen and (max-width: 1022px) {
  #main_header figure {
    max-width: 60%;
    padding: 0;
    margin: 0 auto;
    line-height: 65px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #main_header figure img {
    align-self: center;
    max-width: 220px;
  }
}
#main_header figcaption {
  color: #003366;
  font-size: 2rem;
  font-family: basicbold, Arial, sans-serif;
  margin-left: 10px;
  position: absolute;
  top: 0;
  right: 0;
}
@media only screen and (max-width: 1022px) {
  #main_header figcaption {
    font-size: 1.5rem;
    top: -25px;
  }
}
@media only screen and (max-width: 559px) {
  #main_header figcaption {
    font-size: 1rem;
  }
}
#main_header a {
  position: relative;
  align-items: center;
  color: #b2b2b2;
  text-transform: uppercase;
  padding: 0 10px;
  font-size: 1.5rem;
  text-decoration: none;
}
#main_header a:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 5px 0 5px;
  border-color: #05c7fa transparent transparent transparent;
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s linear;
  bottom: 25px;
  margin: -5px;
  left: 50%;
}
#main_header a:hover, #main_header a.active {
  color: #003366;
}
#main_header a:hover:before, #main_header a.active:before {
  opacity: 1;
}
#main_header .msgbullet {
  position: absolute;
  top: 17px;
  right: calc(50% - 9px);
  padding-top: 2px;
}
@media only screen and (max-width: 1022px) {
  #main_header .msgbullet {
    position: relative;
    right: unset;
    top: 0;
    margin-right: 5px;
  }
}
#main_header .poweroff {
  display: flex;
  align-items: center;
  font-size: 0;
  color: transparent;
  cursor: pointer;
  padding: 10px 20px;
  position: absolute;
  right: 2px;
}
#main_header .poweroff:before {
  font-size: 3rem;
  color: #999;
  transition: color 0.2s linear;
}
#main_header .poweroff:hover:before {
  color: #003366;
}
@media only screen and (max-width: 1022px) {
  #main_header .poweroff {
    position: absolute;
    right: 0;
    transform: scale(0.9);
  }
}
#main_header a.header__balance {
  font-size: 2rem;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  border: solid #0099ff;
  border-width: 0 2px 0 0;
  margin: 0 10px;
  color: #003366;
  transition: color 0.2s linear;
  position: absolute;
  right: 65px;
}
#main_header a.header__balance:before {
  display: none;
}
#main_header a.header__balance span {
  color: #003366;
  font-size: 0.5em;
}
#main_header a.header__balance:hover {
  border-top-color: transparent;
}
#main_header a.header__balance:hover span {
  color: #003366;
}
@media only screen and (max-width: 1022px) {
  #main_header a.header__balance {
    align-items: flex-start;
    border-right: 0;
    border-top: 2px solid #0099ff;
    padding: 20px 0 0 20px;
    margin: 20px 10px;
    font-size: 3rem;
    width: 93%;
    right: 0;
    position: relative;
  }
  #main_header a.header__balance:hover {
    border-top-color: #0099ff;
  }
}
@media only screen and (max-width: 1022px) {
  #main_header .desktop_menu_container {
    display: none;
  }
}
@media only screen and (min-width: 1023px) {
  #main_header .mobile_menu_container {
    display: none;
  }
}
#main_header .mobile_menu_container__burger {
  cursor: pointer;
  z-index: 10;
  position: fixed;
  left: 20px;
  display: flex;
  flex-direction: column;
  top: 15px;
}
#main_header .mobile_menu_container__burger__bar1, #main_header .mobile_menu_container__burger__bar2, #main_header .mobile_menu_container__burger__bar3 {
  width: 35px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
  transition: 0.4s;
}
#main_header .mobile_menu_container__sidenav {
  display: flex;
  flex-direction: column;
  color: white;
  background-color: black;
  height: 100vh;
  position: absolute;
  left: -320px;
  transition: 0.3s;
  width: 300px;
  opacity: 0;
  top: -15px;
}
#main_header .mobile_menu_container__sidenav__title {
  display: block;
  margin: 24px auto;
  font-size: 2rem;
}
#main_header .mobile_menu_container__sidenav__options {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 100%;
  align-items: flex-start;
}
#main_header .mobile_menu_container__sidenav__options__option {
  font-size: 2rem;
  margin: 20px;
  border: none;
  color: #fff;
}
#main_header .mobile_menu_container__sidenav__options__option:before {
  bottom: 11px;
  left: -5px;
}
#main_header .mobile_menu_container .-change .mobile_menu_container__sidenav {
  left: -20px;
  opacity: 1;
}
#main_header .mobile_menu_container .-change .mobile_menu_container__burger__bar1 {
  transform: rotate(-45deg) translate(-15px, 10px);
}
#main_header .mobile_menu_container .-change .mobile_menu_container__burger__bar2 {
  opacity: 0;
}
#main_header .mobile_menu_container .-change .mobile_menu_container__burger__bar3 {
  transform: rotate(45deg) translate(-6px, -1px);
}
@media only screen and (min-width: 1023px) {
  #main_header.sticky {
    box-shadow: 0 8px 12px -6px rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 2;
    height: 45px;
    animation: headerIn 1s forwards;
  }
  #main_header.sticky .main_header__logo {
    border-width: 2px 0 0 0;
  }
  #main_header.sticky figcaption {
    left: 209px;
    font-size: 1.5rem;
    top: 5px;
  }
  #main_header.sticky img {
    width: 75%;
  }
  #main_header.sticky nav a {
    border-width: 2px 0 0 0;
  }
  #main_header.sticky .header__balance {
    right: 40px;
  }
  #main_header.sticky .header__balance span {
    display: none;
  }
  #main_header.sticky .msgbullet {
    color: transparent;
    width: 5px;
    height: 5px;
    min-width: 5px;
    min-height: 5px;
    border-radius: 50%;
    display: block;
    right: calc(50% - 2.5px);
    top: 18px;
  }
  #main_header.sticky .poweroff {
    padding: 10px;
  }
  #main_header.sticky .poweroff:before {
    font-size: 2rem;
  }
}

#fixstickyheader {
  height: 80px;
}
@media only screen and (max-width: 1022px) {
  #fixstickyheader {
    display: none;
  }
}

sbmn-footer {
  border-top: 1px solid #0099ff;
  z-index: 0;
}

#main_footer {
  color: #fff;
  padding: 10px;
  font-size: 1.6rem;
  background: #000;
  margin-top: 6vh;
  min-width: 100%;
}

sbmn-lang-selector {
  display: flex;
  align-items: center !important;
  margin: 0 10px !important;
  font-size: 1.3rem;
}
sbmn-lang-selector .mat-select {
  opacity: 0;
}
sbmn-lang-selector mat-form-field {
  max-width: 30px;
  max-height: 30px;
  width: unset !important;
}
sbmn-lang-selector mat-form-field:hover #langTrigger {
  border-color: #fff;
  color: #fff;
}
sbmn-lang-selector mat-form-field .mat-form-field-underline {
  display: none !important;
}
sbmn-lang-selector mat-form-field .mat-form-field-wrapper {
  padding: 0;
  align-items: center !important;
}
@media only screen and (max-width: 1022px) {
  sbmn-lang-selector {
    transform: scale(0.9);
    right: 60px;
    position: absolute;
  }
}

#langTrigger {
  border-radius: 50%;
  border: 2px solid #b2b2b2;
  justify-content: center;
  display: flex;
  align-items: center !important;
  position: absolute;
  height: 30px;
  width: 30px;
  top: -12px;
  color: #b2b2b2;
  font-weight: bold;
  transition: all 0.2s linear;
  text-transform: uppercase;
}

sbmn-access {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
}
@media only screen and (max-width: 1022px) {
  sbmn-access {
    flex-direction: column;
    top: 0;
  }
}
sbmn-access > header,
sbmn-access figure,
sbmn-access > section {
  display: flex;
  justify-content: center;
  align-items: center;
}
sbmn-access > header {
  position: fixed;
  flex-direction: column;
  background-image: url("/assets/img/logos/avasa-login-bg.jpg");
  background-size: 100% 100%;
  color: #fff;
  width: 100%;
  padding: 3%;
  height: 100%;
}
@media only screen and (max-width: 1022px) {
  sbmn-access > header {
    width: 100%;
    padding: 2% 3% 3%;
    position: relative;
    min-height: 140px;
    height: auto;
  }
}
sbmn-access > header span {
  font-size: 1.6rem;
  color: #7f7f7f;
  position: relative;
  left: 20%;
}
sbmn-access figure {
  height: 98%;
  position: relative;
  left: 20%;
}
sbmn-access figure img {
  max-width: 400px;
  min-width: 200px;
  width: 80%;
}
sbmn-access > section {
  position: relative;
  width: 40%;
  left: 10%;
  height: 100%;
  background-color: #fff;
}
sbmn-access > section:after {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: block;
  background: #fff;
  opacity: 0.2;
  content: " ";
  z-index: -1;
}
@media only screen and (max-width: 1022px) {
  sbmn-access > section {
    align-items: flex-start;
    width: 100%;
    left: 0;
  }
}
sbmn-access > section.register {
  align-items: flex-start;
}
sbmn-access sbmn-lang-selector {
  position: absolute;
  right: 10px;
  top: 10px;
}
sbmn-access sbmn-lang-selector:hover #langTrigger {
  color: #003366;
  border-color: #003366;
}

sbmn-auto-login,
sbmn-admin-login {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(76deg, #005c99 25%, #333333 33%, #262626 50%, #005c99 71%);
  background-size: 100% 100%;
}

sbmn-auto-login sbmn-change-password {
  background: white;
  padding: 20px;
  border-radius: 12px;
  text-align: right;
  position: relative;
  display: block;
}
sbmn-auto-login sbmn-change-password .Berror {
  color: #ef1c25;
  display: block;
  text-align: left;
  width: 100%;
  padding-left: 20px;
  line-height: 0.8;
  bottom: 105px;
}
sbmn-auto-login sbmn-change-password button {
  margin: 30px 0 10px;
}

.auto-login {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.auto-login h2 {
  margin: 30px auto;
}
.auto-login h2._w {
  color: #fff;
}
.auto-login h2.Berror {
  font-size: 3rem;
}
.auto-login figure,
.auto-login figcaption {
  max-width: 560px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.auto-login img {
  max-width: 400px;
}
@media only screen and (max-width: 559px) {
  .auto-login img {
    max-width: 100%;
  }
}
.auto-login figcaption {
  justify-content: flex-start;
  min-height: 200px;
}
.auto-login span {
  font-size: 1.6rem;
  color: #7f7f7f;
}

#login-container {
  max-height: 430px;
  overflow: hidden;
  min-width: 500px;
  width: 45%;
  position: relative;
  background: radial-gradient(ellipse at center, #fff 0%, #fff 25%, transparent 50%);
  margin: auto;
}
@media only screen and (max-width: 1022px) {
  #login-container {
    width: 100%;
    min-width: 100%;
    max-height: 550px;
    margin-top: 20px;
  }
}
#login-container .Berror {
  position: relative;
  font-size: 1.5rem;
  bottom: 0;
  margin-bottom: 10px;
}
@media only screen and (max-width: 1022px) {
  #login-container .Berror {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 1022px) {
  #login-container re-captcha {
    display: flex;
    justify-content: center;
  }
}

#login-access,
#login-change-password,
#login-submit-password {
  position: relative;
  padding: 0 40px;
  min-height: 400px;
  font-size: 1.6rem;
  opacity: 1;
  transition: opacity 1s linear, top 0.2s linear;
}
@media only screen and (max-width: 1022px) {
  #login-access,
  #login-change-password,
  #login-submit-password {
    padding: 50px 40px;
    min-height: 550px;
  }
}
@media only screen and (max-width: 479px) {
  #login-access,
  #login-change-password,
  #login-submit-password {
    padding: 20px;
  }
}
#login-access h1,
#login-change-password h1,
#login-submit-password h1 {
  margin-bottom: 40px;
  color: #66c2ff;
}
@media only screen and (max-width: 479px) {
  #login-access h1,
  #login-change-password h1,
  #login-submit-password h1 {
    font-size: 4rem;
  }
}
#login-access h1 > strong,
#login-change-password h1 > strong,
#login-submit-password h1 > strong {
  font-weight: 300;
  color: #005c99;
}
#login-access button,
#login-change-password button,
#login-submit-password button {
  margin: 10px 0 20px 0;
  float: right;
  border-radius: 25px;
}

@media only screen and (max-width: 1022px) {
  #login-access button {
    float: none;
  }
}

#login-change-password h1.h1withbold, #login-change-password h1.h4withbold, #login-change-password h1.h3withbold, #login-change-password h1.h2withbold {
  font-size: 4rem;
  margin-bottom: 10px;
}
#login-change-password h3 {
  margin-bottom: 20px;
}
#login-change-password .Berror {
  position: relative;
  font-size: 1.5rem;
  bottom: 0;
}
@media only screen and (max-width: 1022px) {
  #login-change-password .Berror {
    display: flex;
    justify-content: center;
  }
}

.login-reset-active {
  top: -400px;
}
@media only screen and (max-width: 1022px) {
  .login-reset-active {
    top: -540px;
  }
}
.login-reset-active#login-access {
  opacity: 0;
}

.login-change-active {
  top: -800px;
}
@media only screen and (max-width: 1022px) {
  .login-change-active {
    top: -1100px;
  }
}
.login-change-active#login-access {
  opacity: 0;
}

.login-reset-inactive {
  top: 0;
}
.login-reset-inactive#login-submit-password, .login-reset-inactive#login-change-password {
  opacity: 0;
}

.login-reset-nav {
  position: relative;
  top: 27px;
}
@media only screen and (max-width: 1022px) {
  .login-reset-nav {
    display: block;
    top: 0;
    margin: 20px 0;
  }
}

@media only screen and (max-width: 1022px) {
  .login-reset-password {
    text-align: center;
  }
}

.login__loading {
  opacity: 0;
}

#login-new-account {
  position: absolute;
  color: #7f7f7f;
  padding: 10px;
  margin: 0 auto;
  font-size: 1.6rem;
  text-align: center;
  clear: both;
  bottom: 0;
  width: 100%;
  background: #fff;
}
@media only screen and (max-width: 1022px) {
  #login-new-account {
    line-height: 1.4;
  }
}
@media only screen and (max-width: 479px) {
  #login-new-account a {
    display: block;
  }
}

sbmn-register {
  min-width: 50%;
  padding: 40px 0;
  position: relative;
}
sbmn-register .registered {
  max-width: 500px;
  padding: 20px 0;
  color: #4c4c4c;
  font-size: 1.6rem;
}
sbmn-register > section {
  padding: 30px 5% 100px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0 20px 0;
}
sbmn-register fieldset {
  position: relative;
}
sbmn-register .returnlink {
  margin-top: -20px;
  color: #4c4c4c;
}
sbmn-register .Berror {
  bottom: unset;
  top: -15px;
  font-size: 1.25rem;
}
sbmn-register mat-error {
  margin-top: -2px;
  font-size: 1.25rem;
}
sbmn-register button {
  margin: 20px 0 10px 0;
  float: right;
}

.agreement_form__section {
  margin: 20px 0;
}
.agreement_form__section_extra {
  display: flex;
  justify-content: center;
  margin: 50px 0 15px;
}

.cms__basicinfo__header__title {
  border-bottom: 1px solid #ccc;
}
.cms__basicinfo__header__title.h2withbold {
  margin-bottom: 30px;
}
.cms__basicinfo__container__content br {
  display: none;
}
.cms__basicinfo__container__content > span {
  display: block;
  width: 100%;
  margin: 30px 0 10px 0;
}
.cms__basicinfo__container__content a {
  padding: 0 2px;
}
.cms__basicinfo__container__content li {
  margin-bottom: 10px;
}
.cms__basicinfo__container__content li strong:first-child {
  display: block;
  font-size: 1.2em;
}
.cms__openHTML {
  font-size: 1.6rem;
  text-align: justify;
  padding: 0 40px;
}
@media only screen and (max-width: 1022px) {
  .cms__openHTML {
    padding: 0 10px;
  }
}
.cms__openHTML.vuelos, .cms__openHTML.vuelos table, .cms__openHTML.vuelos td, .cms__openHTML.vuelos th {
  border-color: #0099ff;
}
.cms__openHTML.info, .cms__openHTML.info table, .cms__openHTML.info td, .cms__openHTML.info th {
  border-color: #999;
}
.cms__openHTML i {
  background: #000;
  border-radius: 50%;
  padding: 20px 24px;
  font-size: 4rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1022px) {
  .cms__openHTML i {
    padding: 10px;
    font-size: 3rem;
    margin: 5px;
  }
}
.cms__openHTML p {
  margin: 0 auto 20px;
}
.cms__openHTML ul {
  padding-left: 5%;
}
.cms__openHTML .upper {
  text-transform: uppercase;
}
.cms__openHTML .subs {
  text-decoration: underline;
}
.cms__openHTML__main-figure {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 40px;
}
.cms__openHTML__main-figure__img {
  max-width: 550px;
  width: 80%;
}
.cms__openHTML__header {
  width: 100%;
}
.cms__openHTML__header__title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  line-height: 1;
}
@media only screen and (max-width: 1022px) {
  .cms__openHTML__header__title {
    font-size: 3rem;
  }
}
.cms__openHTML__content {
  padding: 30px 0;
  margin: 0;
  max-width: 100%;
  min-width: 100%;
}
.cms__openHTML__content__title {
  font-size: 2.6rem;
  margin-bottom: 5px;
}
@media only screen and (max-width: 1022px) {
  .cms__openHTML__content__title {
    font-size: 1.6rem;
  }
}
.cms__openHTML__content__title.extra {
  text-transform: uppercase;
  text-decoration: underline;
}
.cms__openHTML__content__floatfigure {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  float: right;
  max-width: 50%;
  width: 50%;
  max-height: 350px;
  margin-right: 0;
}
@media only screen and (max-width: 1022px) {
  .cms__openHTML__content__floatfigure {
    max-width: 100%;
    width: 100%;
  }
}
.cms__openHTML__content__floatfigure img {
  height: 100%;
  max-height: 350px;
  width: auto;
  border-radius: 10px;
}
.cms__openHTML__content__figure {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  margin: 40px 0;
}
.cms__openHTML__content__figure img {
  height: 100%;
  max-height: 350px;
  width: auto;
  border-radius: 10px;
  margin: 1% auto;
  max-width: 650px;
}
@media only screen and (max-width: 1022px) {
  .cms__openHTML__content__figure img {
    max-width: 100%;
    max-height: unset;
    height: auto;
  }
}
.cms__openHTML__content__yellow {
  list-style-type: none;
}
.cms__openHTML__content__yellow li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 1022px) {
  .cms__openHTML__content__yellow li {
    flex-direction: column;
  }
}
.cms__openHTML__content__yellow__block {
  background: #003366;
  border-radius: 10px;
  font-size: 3rem;
  line-height: 1;
  text-align: center;
  padding: 20px;
  width: 80%;
  margin: 10px 0;
}
@media only screen and (max-width: 1022px) {
  .cms__openHTML__content__yellow__block {
    font-size: 1.8rem;
    width: 100%;
  }
}
.cms__openHTML__content__yellow__block a {
  font-size: 3rem;
  line-height: 1;
}
@media only screen and (max-width: 1022px) {
  .cms__openHTML__content__yellow__block a {
    font-size: 1.8rem;
  }
}
.cms__openHTML__content__plane-ball {
  height: 500px;
  width: 500px;
  position: relative;
  border-radius: 100%;
  margin: 0 auto 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1022px) {
  .cms__openHTML__content__plane-ball {
    margin: 30px auto;
    height: 400px;
    width: 400px;
  }
}
@media only screen and (max-width: 559px) {
  .cms__openHTML__content__plane-ball {
    height: 250px;
    width: 250px;
  }
}
.cms__openHTML__content__plane-ball .avion {
  animation: spin 8s infinite linear;
  height: 80%;
  width: 80%;
}
@media only screen and (max-width: 559px) {
  .cms__openHTML__content__plane-ball .avion {
    height: 90%;
    width: 90%;
  }
}
.cms__openHTML__content__plane-ball .avion:before {
  font-size: 3rem;
}
@media only screen and (max-width: 559px) {
  .cms__openHTML__content__plane-ball .avion:before {
    font-size: 2rem;
  }
}
.cms__openHTML__content__plane-ball__inside {
  height: 95%;
  width: 95%;
  background-color: #003366;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-weight: bold;
  font-size: 3rem;
  position: absolute;
}
@media only screen and (max-width: 559px) {
  .cms__openHTML__content__plane-ball__inside {
    font-size: 1.5rem;
  }
}
.cms__openHTML__content table {
  border-collapse: unset;
  border-spacing: 0;
  margin-bottom: 20px;
  border: solid #003366;
  border-width: 0 3px 3px 0;
  background: #fff;
}
@media only screen and (max-width: 1022px) {
  .cms__openHTML__content table {
    border-width: 3px;
    border-collapse: collapse;
  }
}
@media only screen and (max-width: 1022px) {
  .cms__openHTML__content table tr {
    border-bottom: 5px solid #003366;
  }
  .vuelos .cms__openHTML__content table tr {
    border-bottom: 5px solid #0099ff;
  }
  .info .cms__openHTML__content table tr {
    border-bottom: 5px solid #0099ff;
  }
}
.cms__openHTML__content table td,
.cms__openHTML__content table th {
  padding: 5px 10px;
  border: solid #003366;
  border-width: 3px 0 0 3px;
}
@media only screen and (max-width: 1022px) {
  .cms__openHTML__content table td,
  .cms__openHTML__content table th {
    border-width: 0;
  }
}
.cms__openHTML__content table td h2,
.cms__openHTML__content table th h2 {
  font-size: 1.7rem;
  margin: 5px 0;
  color: #7f7f7f;
}
.cms__openHTML__content table td h3,
.cms__openHTML__content table th h3 {
  font-size: 1.5rem;
  color: #191919;
}
.cms__boletin {
  text-align: center;
}
.cms__boletin img {
  max-width: 100%;
  margin: auto;
}

sbmn-flights {
  display: block;
  z-index: 1;
  min-height: 100%;
}

sbmn-flights-dashboard {
  display: block;
  position: relative;
  margin-bottom: -6vh;
}
@media only screen and ( max-width: 1023px) {
  sbmn-flights-dashboard .stopper-modal__scroll {
    width: calc(100% - 15px);
  }
}
sbmn-flights-dashboard .blurred {
  display: none;
}
sbmn-flights-dashboard .appear {
  display: unset;
}
sbmn-flights-dashboard .max-width-page, sbmn-flights-dashboard .max-width-box {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: auto;
  justify-content: center;
}
sbmn-flights-dashboard .max-width-page sbmn-flights-search-engine, sbmn-flights-dashboard .max-width-box sbmn-flights-search-engine,
sbmn-flights-dashboard .max-width-page sbmn-flights-highlight,
sbmn-flights-dashboard .max-width-box sbmn-flights-highlight,
sbmn-flights-dashboard .max-width-page sbmn-flights-offers,
sbmn-flights-dashboard .max-width-box sbmn-flights-offers,
sbmn-flights-dashboard .max-width-page sbmn-flights-messages,
sbmn-flights-dashboard .max-width-box sbmn-flights-messages {
  border-radius: 0 20px 0;
}
@media only screen and (max-width: 1022px) {
  sbmn-flights-dashboard .max-width-page sbmn-flights-search-engine, sbmn-flights-dashboard .max-width-box sbmn-flights-search-engine,
  sbmn-flights-dashboard .max-width-page sbmn-flights-highlight,
  sbmn-flights-dashboard .max-width-box sbmn-flights-highlight,
  sbmn-flights-dashboard .max-width-page sbmn-flights-offers,
  sbmn-flights-dashboard .max-width-box sbmn-flights-offers,
  sbmn-flights-dashboard .max-width-page sbmn-flights-messages,
  sbmn-flights-dashboard .max-width-box sbmn-flights-messages {
    width: 100%;
    margin: 2% auto 40px;
  }
}
sbmn-flights-dashboard .max-width-page sbmn-flights-highlight, sbmn-flights-dashboard .max-width-box sbmn-flights-highlight,
sbmn-flights-dashboard .max-width-page sbmn-flights-offers,
sbmn-flights-dashboard .max-width-box sbmn-flights-offers,
sbmn-flights-dashboard .max-width-page sbmn-flights-messages,
sbmn-flights-dashboard .max-width-box sbmn-flights-messages {
  box-shadow: 0px 0px 18px -3px #333333;
}
sbmn-flights-dashboard .max-width-page > :nth-child(1), sbmn-flights-dashboard .max-width-box > :nth-child(1) {
  order: 1;
}
sbmn-flights-dashboard .max-width-page > :nth-child(2), sbmn-flights-dashboard .max-width-box > :nth-child(2) {
  order: 2;
}
sbmn-flights-dashboard .max-width-page > :nth-child(3), sbmn-flights-dashboard .max-width-box > :nth-child(3) {
  order: 3;
}
sbmn-flights-dashboard .max-width-page > :nth-child(4), sbmn-flights-dashboard .max-width-box > :nth-child(4) {
  order: 5;
}
sbmn-flights-dashboard .max-width-page > :nth-child(5), sbmn-flights-dashboard .max-width-box > :nth-child(5) {
  order: 4;
}
@media only screen and (max-width: 1022px) {
  sbmn-flights-dashboard .max-width-page, sbmn-flights-dashboard .max-width-box {
    flex-direction: column;
  }
}
sbmn-flights-dashboard sbmn-flights-welcome {
  width: 100%;
  height: 30vh;
  min-height: 500px;
  max-height: 500px;
  top: 0;
  left: 0;
  margin-bottom: -160px;
}
sbmn-flights-dashboard sbmn-flights-welcome img {
  opacity: 0.8;
}
sbmn-flights-dashboard sbmn-flights-search-engine {
  width: 90%;
  margin: 0 10% -40px;
  top: -90px;
  max-width: 1150px;
}
sbmn-flights-dashboard sbmn-flights-search-engine:after {
  content: " ";
  width: 100%;
}
sbmn-flights-dashboard #main_search-engine {
  min-height: 287px;
}
sbmn-flights-dashboard sbmn-flights-highlight {
  display: none;
  width: 28%;
  max-width: 403.2px;
  min-height: 100%;
  overflow: hidden;
  margin: 0 1% 50px;
}
sbmn-flights-dashboard sbmn-flights-highlight.appear {
  display: flex;
}
@media only screen and (max-width: 1022px) {
  sbmn-flights-dashboard sbmn-flights-highlight {
    min-height: 0;
  }
}
sbmn-flights-dashboard sbmn-flights-offers {
  display: none;
  background: #e5e5e5;
  padding: 30px;
  flex-basis: 58%;
  max-width: 1440px;
  margin-bottom: 100px;
  flex-grow: 1;
}
sbmn-flights-dashboard sbmn-flights-messages {
  display: none;
  flex-basis: 68%;
  max-width: 979.2px;
  padding: 30px 0;
  z-index: 0;
  flex-grow: 1;
  margin: 0 1% 50px;
}

sbmn-flights-highlight {
  position: relative;
}
sbmn-flights-highlight section {
  background: rgba(0, 153, 255, 0.5);
  color: #fff;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
  font-size: 3rem;
}
@media only screen and (max-width: 1247px) {
  sbmn-flights-highlight section {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 1022px) {
  sbmn-flights-highlight section {
    width: 100%;
    font-size: 2rem;
    flex-direction: row;
    padding: 10px 20px;
    min-height: 110px;
  }
}
@media only screen and (max-width: 559px) {
  sbmn-flights-highlight section {
    font-size: 1.5rem;
  }
}
sbmn-flights-highlight button.buttonbluelr, sbmn-flights-highlight button.stopper-modal__scroll__container__button {
  margin: 10px 20px;
  padding: 10px;
}

sbmn-flights-messages {
  position: relative;
  overflow: hidden;
  font-size: 1.5rem;
  background: rgba(255, 255, 255, 0.9);
}
@media only screen and (max-width: 1022px) {
  sbmn-flights-messages {
    padding: 20px 0;
  }
}
sbmn-flights-messages section {
  padding: 0 9%;
  margin: 20px 10px 40px 40px;
  color: #005c99;
}
@media only screen and (max-width: 1022px) {
  sbmn-flights-messages section {
    height: 100%;
  }
}
sbmn-flights-messages section._vuelos {
  border-color: #0099ff;
}
sbmn-flights-messages section._avisos {
  border-color: rgba(244, 129, 38, 0.9);
}
sbmn-flights-messages section._info {
  border-color: #999;
}
sbmn-flights-messages section div {
  color: #333;
  display: inline-block;
}
sbmn-flights-messages i {
  border-bottom: 2px solid rgba(0, 92, 153, 0.5);
  position: absolute;
  left: 4%;
}
sbmn-flights-messages i.avion:before {
  color: #003366;
}
sbmn-flights-messages i.megafono:before {
  font-size: 4rem;
  color: #f48126;
}
sbmn-flights-messages i.info3:before {
  font-size: 3rem;
  color: #999;
}
sbmn-flights-messages img.icoboom {
  max-width: 50px;
  position: absolute;
  left: 4.5%;
}
sbmn-flights-messages .owl-stage-outer {
  height: calc(100% - 30px);
}
sbmn-flights-messages .owl-theme .owl-dots {
  padding-left: 10%;
  text-align: left;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
@media only screen and (max-width: 1022px) {
  sbmn-flights-messages .owl-theme .owl-dots {
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
sbmn-flights-messages .link {
  margin: 0 10px;
  text-transform: uppercase;
}
sbmn-flights-messages .link:after {
  content: " >>";
}

sbmn-flights-welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}
sbmn-flights-welcome:before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: block;
  background: url("/assets/img/logos/main_logo.svg") no-repeat center center;
  background-size: 300px auto;
  opacity: 0.3;
}
@media only screen and (max-width: 1022px) {
  sbmn-flights-welcome {
    height: 435px;
  }
}
@media only screen and (max-width: 559px) {
  sbmn-flights-welcome {
    height: 300px;
  }
}
sbmn-flights-welcome figure,
sbmn-flights-welcome figcaption {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  overflow: hidden;
}
sbmn-flights-welcome figcaption {
  flex-direction: column;
  justify-content: flex-start;
  padding: 80px 1% 8vh;
  color: #fff;
}
@media only screen and (max-width: 559px) {
  sbmn-flights-welcome figcaption {
    font-size: 2rem;
  }
}
sbmn-flights-welcome figcaption div {
  text-align: right;
  font-family: basicbold, Arial, sans-serif;
}
sbmn-flights-welcome figcaption h2 {
  font-size: 3.5vw;
  padding: 0 0 20px 0;
  margin: 0 0 20px;
  text-align: center;
}
sbmn-flights-welcome figcaption p {
  margin: 0;
  text-align: right;
  padding: 0 0 20px 0;
}
sbmn-flights-welcome figcaption a {
  color: #fff;
}
sbmn-flights-welcome img,
sbmn-flights-welcome .owl-carousel .owl-item img {
  height: auto;
  width: 100%;
  min-height: 100%;
  position: absolute;
}
sbmn-flights-welcome .owl-theme .owl-dots {
  position: absolute;
  right: 1vw;
  top: 1vh;
  max-width: 1100px;
  z-index: 1;
}
@media only screen and (max-width: 1022px) {
  sbmn-flights-welcome .owl-theme .owl-dots {
    display: flex;
    margin: 0;
    justify-content: center;
    width: 100%;
    left: unset;
  }
}

sbmn-flights-offers {
  position: relative;
  min-height: 300px;
  border-radius: 0 20px 0 20px;
  overflow: hidden;
}
sbmn-flights-offers h2 {
  margin: 10px 0;
}
sbmn-flights-offers h2.noofferslist {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 40px 30px;
  height: 100%;
  z-index: 0;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
sbmn-flights-offers figure {
  position: relative;
}

#list_offers {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media only screen and (max-width: 1022px) {
  #list_offers {
    flex-direction: column;
  }
}
#list_offers div {
  position: relative;
  text-transform: uppercase;
  width: 49%;
  margin: 5px 1% 0 0;
  background: #fff;
  padding: 15px 10px 15px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 1.6em;
  transition: all 0.1s linear;
}
#list_offers div.Bborder-blr:hover {
  background: #0099ff;
  color: #fff;
}
#list_offers div.Bborder-bl:hover {
  background: #0099ff;
  color: #fff;
}
@media only screen and (max-width: 1022px) {
  #list_offers div {
    min-width: 100%;
    padding: 25px 22px 25px 70px;
    margin: 10px 1% 0 0;
  }
}
#list_offers .desc {
  flex-grow: 2;
}
#list_offers .price {
  font-weight: bold;
  font-size: 1.3em;
  margin-left: 20px;
}
#list_offers .idavuelta,
#list_offers .soloida {
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  border: none;
  background: #0073e6;
  justify-content: center;
  align-items: center;
  margin-left: -45px;
  margin-top: 0;
}
#list_offers .idavuelta:before,
#list_offers .soloida:before {
  color: #005c99;
}
#list_offers .soloida {
  border-color: #0099ff;
}
#list_offers .soloida:before {
  color: #0099ff;
}

#fresults_ads sbmn-flights-offers:before {
  display: none;
}

sbmn-flights-search-engine {
  position: relative;
  width: 100%;
  display: block;
}
.hhide sbmn-flights-search-engine {
  background: transparent;
}

#gradient_search-engine {
  position: absolute;
  display: block;
  width: 100%;
  top: 0;
  height: 100%;
  left: 0;
}
#gradient_search-engine.Simpleborder span, #gradient_search-engine.Calendarioborder span, #gradient_search-engine.Multidestinoborder span {
  font-family: basiclight, Arial, sans-serif;
  color: #fff;
  font-size: 3em;
  top: 10px;
  right: 5px;
  display: block;
  position: absolute;
  text-align: right;
  animation: searchtype 0.2s linear forwards;
}
@media only screen and (max-width: 1022px) {
  #gradient_search-engine.Simpleborder span, #gradient_search-engine.Calendarioborder span, #gradient_search-engine.Multidestinoborder span {
    width: 100%;
    display: block;
    margin: 90px auto 0 -20px;
    max-width: unset;
    text-align: center;
  }
}
#gradient_search-engine.Simpleborder strong, #gradient_search-engine.Calendarioborder strong, #gradient_search-engine.Multidestinoborder strong {
  font-family: basicbold, Arial, sans-serif;
}

#main_search-engine {
  position: relative;
  width: 100%;
  margin: auto;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 1022px) {
  #main_search-engine {
    flex-direction: column;
  }
}

#nav_search-engine {
  display: flex;
  justify-content: stretch;
  background: transparent;
  min-height: 50px;
  z-index: 1;
}
@media only screen and (max-width: 1022px) {
  #nav_search-engine {
    flex-direction: row;
    position: absolute;
    top: -25px;
    width: 100%;
  }
}
#nav_search-engine span {
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  font-size: 0;
  min-width: 130px;
  cursor: pointer;
  transition: background 0.1s linear;
  box-shadow: inset 0px -9px 14px -14px #333333;
  background: #e5e5e5;
}
#nav_search-engine span:hover, #nav_search-engine span.selected {
  background: #fff;
}
@media only screen and (max-width: 1022px) {
  #nav_search-engine span {
    border-width: 0 2px 2px 2px;
    height: 50px;
    margin-top: 5px;
    width: 100%;
  }
}
#nav_search-engine span:before {
  font-size: 2.5rem;
  color: #005c99;
  opacity: 0.7;
  position: absolute;
  transition: transform 0.2s linear;
}
#nav_search-engine span.selected {
  overflow: hidden;
  margin-right: -2px;
  box-shadow: none;
  opacity: 1;
  background: #fff;
}
#nav_search-engine span.selected:before {
  transform: scale(1.2);
  color: #003366;
}
@media only screen and (max-width: 1022px) {
  #nav_search-engine span.selected {
    border-width: 2px 2px 0 2px;
    margin-right: 0;
    margin-top: 0;
    height: 55px;
  }
}
#nav_search-engine span.multitrayecto {
  border-color: #ccc;
  border-width: 0 1px;
  border-style: solid;
}

#searchEngineForm {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.3rem;
  overflow: hidden;
  min-height: 300px;
  box-shadow: 0px 0px 18px -3px #333333;
  border-radius: 0 20px;
}
#searchEngineForm:before {
  border-radius: 0 20px;
}
.Simple_search #searchEngineForm {
  background: #fff;
}
.Multidestino_search #searchEngineForm {
  background: #fff;
}
.Calendario_search #searchEngineForm {
  background: #fff;
}
@media only screen and (max-width: 1022px) {
  #searchEngineForm {
    flex-direction: column;
    margin-top: 30px;
    border-radius: 0;
  }
}
#searchEngineForm .mat-form-field-appearance-legacy .mat-form-field-underline {
  background: rgba(153, 153, 153, 0.5);
}
#searchEngineForm .mat-select-arrow {
  border-top-color: rgba(153, 153, 153, 0.5);
}

#destdates_search-engine {
  min-width: 75%;
  max-width: 75%;
  width: 75%;
  display: flex;
}
@media only screen and (max-width: 1022px) {
  #destdates_search-engine {
    width: 100%;
    margin: 0;
    max-width: unset;
    flex-direction: column;
  }
}
#destdates_search-engine .destdate_container {
  flex-direction: column;
  padding: 5% 0 5% 7%;
}
@media only screen and (max-width: 1022px) {
  #destdates_search-engine .destdate_container {
    padding: 2%;
  }
}
.Multidestino_search #destdates_search-engine .destdate_container {
  padding: 1% 0;
  flex-direction: row;
}
.Multidestino_search #destdates_search-engine .destdate_container:first-child {
  padding-top: 2%;
}
@media only screen and (max-width: 1022px) {
  .Multidestino_search #destdates_search-engine .destdate_container {
    flex-direction: column;
  }
}
#destdates_search-engine > section,
#destdates_search-engine .destdate_container {
  position: relative;
  display: flex;
  flex-grow: 2;
  justify-content: space-around;
}
@media only screen and (max-width: 1022px) {
  #destdates_search-engine > section,
  #destdates_search-engine .destdate_container {
    flex-direction: column;
  }
}
#destdates_search-engine .idavuelta {
  width: 23px;
  height: 23px;
  min-width: 23px;
  min-height: 23px;
  border-radius: 50%;
  display: block;
  display: flex;
  cursor: pointer;
  position: absolute;
  left: 15px;
  transform: rotate(90deg);
  margin: 0 auto;
  border: 1px solid #0099ff;
  transition: color 0.2s linear, border 0.2s linear;
}
#destdates_search-engine .idavuelta:hover {
  border-color: #003366;
}
#destdates_search-engine .idavuelta:hover:before {
  color: #003366;
}
#destdates_search-engine .idavuelta:before {
  font-size: 1.5rem;
  color: #0099ff;
}
.Multidestino_search #destdates_search-engine .idavuelta {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  display: block;
  display: flex;
  position: relative;
  transform: rotate(0);
  margin-top: 17px;
}
@media only screen and (max-width: 1022px) {
  .Multidestino_search #destdates_search-engine .idavuelta {
    transform: rotate(90deg);
    margin: 5px 0 0 -10px;
    left: 50%;
  }
}
.Multidestino_search #destdates_search-engine .idavuelta:before {
  font-size: 1.2rem;
}
@media only screen and (max-width: 1022px) {
  #destdates_search-engine .idavuelta {
    margin: 0 auto;
    left: unset;
    right: 2px;
  }
}
#destdates_search-engine mat-form-field {
  overflow: hidden;
  padding: 0 20px;
  max-height: 50px;
  font-size: 1.4rem;
}
.Multidestino_search #destdates_search-engine mat-form-field {
  padding: 0;
  margin-left: 30px;
}
@media only screen and (max-width: 1022px) {
  .Multidestino_search #destdates_search-engine mat-form-field {
    margin: 0 5%;
    width: 90%;
    max-height: 50px;
  }
}
@media only screen and (max-width: 1022px) {
  #destdates_search-engine mat-form-field {
    width: 100%;
    max-width: unset;
    max-height: 70px;
    font-size: 1.7rem;
  }
}
#destdates_search-engine mat-form-field:before {
  content: unset;
  display: none;
}
#destdates_search-engine mat-form-field.calendar:before, #destdates_search-engine .summary #simpledates_search-engine mat-form-field.day:before, .summary #simpledates_search-engine #destdates_search-engine mat-form-field.day:before {
  left: 0;
  font-size: 1.5rem;
}
.Multidestino_search #destdates_search-engine > section {
  flex-wrap: wrap;
}
.Multidestino_search #destdates_search-engine mat-form-field.calendar, .Multidestino_search #destdates_search-engine .summary #simpledates_search-engine mat-form-field.day, .summary #simpledates_search-engine .Multidestino_search #destdates_search-engine mat-form-field.day {
  max-width: 25%;
  min-width: 25%;
}
@media only screen and (max-width: 1022px) {
  .Multidestino_search #destdates_search-engine mat-form-field.calendar, .Multidestino_search #destdates_search-engine .summary #simpledates_search-engine mat-form-field.day, .summary #simpledates_search-engine .Multidestino_search #destdates_search-engine mat-form-field.day {
    max-width: 100%;
  }
}
.Multidestino_search #destdates_search-engine .addRoute {
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 0.5;
  margin: 10px 0;
  width: 100%;
  justify-content: center;
}
@media only screen and (max-width: 1022px) {
  .Multidestino_search #destdates_search-engine .addRoute {
    justify-content: center;
    margin: 20px;
    opacity: 0.8;
  }
}
.Multidestino_search #destdates_search-engine .addRoute:hover {
  opacity: 1;
}
.Multidestino_search #destdates_search-engine .addRoute:hover .Bplus, .Multidestino_search #destdates_search-engine .addRoute:hover .Bless {
  background: #005c99;
}
.Multidestino_search #destdates_search-engine .Bless {
  position: absolute;
  right: 0;
  top: 15px;
}
@media only screen and (max-width: 1022px) {
  .Multidestino_search #destdates_search-engine .destdate_container {
    margin-bottom: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #7f7f7f;
    margin-top: 15px;
  }
}

#desdate_container_multiinfo {
  display: none;
}

#simpledates_search-engine {
  display: flex;
  cursor: pointer;
  max-width: 40%;
}
@media only screen and (max-width: 1022px) {
  #simpledates_search-engine {
    flex-direction: row !important;
    margin: 0;
    min-width: 100%;
  }
}
#simpledates_search-engine .simpledates_dateview {
  min-width: 50%;
  max-width: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: color 0.2s linear;
  margin-top: -10px;
  color: #005c99;
}
#simpledates_search-engine .simpledates_dateview:hover {
  color: #0099ff;
}
@media only screen and (max-width: 1022px) {
  #simpledates_search-engine .simpledates_dateview {
    padding-bottom: 5px;
  }
}
#simpledates_search-engine mat-form-field {
  opacity: 0;
  position: absolute;
  min-width: 100%;
}
#simpledates_search-engine .simpledates_opener {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: block;
}
#simpledates_search-engine .day {
  font-size: 4vw;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  margin-bottom: 5px;
  line-height: 0.5;
}
@media only screen and (max-width: 1022px) {
  #simpledates_search-engine .day {
    font-size: 10vw;
  }
}
#simpledates_search-engine .month {
  font-size: 1.7rem;
  font-family: special, Arial, sans-serif;
}
@media only screen and (max-width: 1022px) {
  #simpledates_search-engine .month {
    font-size: 3vw;
  }
}
@media only screen and (max-width: 559px) {
  #simpledates_search-engine .month {
    font-size: 6vw;
  }
}
#simpledates_search-engine .simpledates_input {
  background: none;
  border-width: 0;
  box-shadow: none;
  color: transparent;
  font-size: 0;
}

.multidates_search-engine {
  position: relative;
  min-width: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 10px 0 20px;
}
@media only screen and (max-width: 1022px) {
  .multidates_search-engine {
    margin-top: 15px;
    padding: 0;
  }
}
.multidates_search-engine:hover .multidates_dateview {
  color: #0059b3;
}
.multidates_search-engine mat-form-field {
  position: absolute;
  cursor: pointer;
  opacity: 0;
}
.multidates_search-engine mat-form-field input {
  display: none;
}
.multidates_search-engine .multidates_dateview {
  transition: color 0.2s linear;
  text-align: center;
}
.multidates_search-engine .multidates_dateview strong {
  font-size: 1.5em;
  margin-right: 5px;
  display: block;
}

#passengers_search-engine {
  min-width: 14%;
  max-width: 14%;
  width: 14%;
  text-align: center;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 1439px) {
  #passengers_search-engine {
    min-width: 10%;
    max-width: 10%;
    width: 10%;
  }
}
#passengers_search-engine > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 120px;
}
#passengers_search-engine > div:hover .pasajero__container,
#passengers_search-engine > div:hover .pasajero {
  color: #0099ff;
  border-color: #0099ff;
}
#passengers_search-engine > div:hover .pasajero__container:before,
#passengers_search-engine > div:hover .pasajero:before {
  color: #0099ff;
}
#passengers_search-engine .pasajero,
#passengers_search-engine .personas {
  width: 50px;
  height: 50px;
  margin: 0 auto 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.2s linear;
}
#passengers_search-engine .pasajero:before,
#passengers_search-engine .personas:before {
  font-size: 3rem;
  color: #005c99;
}
#passengers_search-engine .personas {
  display: none;
}
#passengers_search-engine .personas:before {
  margin-right: 3px;
}
#passengers_search-engine .pasajero__container {
  display: flex;
  flex-direction: column;
  transition: color 0.2s linear;
  cursor: pointer;
  line-height: 1.3;
}
#passengers_search-engine .passenger_nav {
  margin-bottom: 15px;
  text-align: center;
  min-height: 35px;
}
#passengers_search-engine .passenger_nav > div {
  margin-bottom: 10px;
}
#passengers_search-engine .passenger_list {
  display: flex;
  align-items: center;
}
#passengers_search-engine mat-form-field {
  max-width: 150px;
}
#passengers_search-engine mat-checkbox {
  margin: 0 10px;
  color: #000;
}
#passengers_search-engine .addpassengers {
  color: #7f7f7f;
  cursor: pointer;
}
#passengers_search-engine .addpassengers:hover {
  color: #000;
}
@media only screen and (max-width: 1022px) {
  #passengers_search-engine {
    margin: 15px 0 !important;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
  #passengers_search-engine > div {
    flex-direction: row;
    display: block;
    width: 100%;
    min-height: unset;
  }
}

#options_search-engine {
  min-width: 90%;
  max-width: 90%;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  border: solid #4c4c4c;
  border-width: 1px 0 0;
  padding: 2% 4% 0;
}
.Multidestino_search #options_search-engine {
  min-width: 40%;
  max-width: 40%;
  width: 40%;
  padding: 1% 0 1% 4%;
}
@media only screen and (max-width: 1439px) {
  .Multidestino_search #options_search-engine {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 1022px) {
  #options_search-engine {
    margin-top: 20px !important;
    max-height: 200px;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 2% 4%;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 559px) {
  #options_search-engine {
    display: block;
  }
}
#options_search-engine fieldset {
  margin: 0 1.5% 5px 0;
  min-width: 18%;
  max-width: 18%;
  width: 18%;
  font-size: 1.4rem;
}
.Multidestino_search #options_search-engine fieldset {
  min-width: 31%;
  max-width: 31%;
  width: 31%;
  margin: 0 0 0 1.5%;
  padding-top: 5px;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1022px) {
  .Multidestino_search #options_search-engine fieldset {
    min-width: 90%;
    max-width: 90%;
    width: 90%;
  }
}
#options_search-engine fieldset:nth-of-type(1) {
  position: absolute;
  top: 47%;
  left: 56%;
}
@media only screen and (max-width: 1022px) {
  #options_search-engine fieldset:nth-of-type(1) {
    position: relative;
    top: unset;
    left: unset;
  }
}
.Multidestino_search #options_search-engine fieldset:nth-of-type(1) {
  position: relative;
  top: unset;
  left: unset;
}
@media only screen and (max-width: 1247px) {
  #options_search-engine fieldset {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 1022px) {
  #options_search-engine fieldset {
    min-height: 30px;
    min-width: 48%;
    max-width: 48%;
    width: 48%;
    font-size: 2vw;
    margin: 10px 2% 10px 0;
  }
  #options_search-engine fieldset .mat-checkbox-label {
    white-space: pre-wrap;
    line-height: 17px;
  }
  #options_search-engine fieldset .mat-checkbox-inner-container {
    margin-top: 0;
  }
}
@media only screen and (max-width: 559px) {
  #options_search-engine fieldset {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    font-size: 4.5vw;
  }
}

#moreopts_search-engine {
  display: flex;
  width: 90%;
  padding: 1% 4%;
}
.Multidestino_search #moreopts_search-engine {
  min-width: 30%;
  max-width: 30%;
  width: 30%;
  padding: 1.2% 0 0;
  border: solid #4c4c4c;
  border-width: 1px 0 0;
}
@media only screen and (max-width: 1439px) {
  .Multidestino_search #moreopts_search-engine {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    padding: 30px 0;
  }
}
@media only screen and (max-width: 1022px) {
  #moreopts_search-engine {
    flex-direction: column;
    margin-top: 20px !important;
    width: 100%;
  }
}
#moreopts_search-engine fieldset {
  min-width: 22%;
  max-width: 22%;
  width: 22%;
  margin: 0 1.5%;
}
#moreopts_search-engine fieldset:last-child {
  margin-top: 1px;
}
@media only screen and (max-width: 1022px) {
  #moreopts_search-engine fieldset {
    min-width: 90%;
    max-width: 90%;
    width: 90%;
    font-size: 1.8rem;
    margin: auto;
  }
}
.Multidestino_search #moreopts_search-engine fieldset {
  min-width: 48.5%;
  max-width: 48.5%;
  width: 48.5%;
  margin: 0 0 0 1.5%;
}
.Multidestino_search #moreopts_search-engine fieldset:last-child {
  margin-top: 1px;
}
@media only screen and (max-width: 1022px) {
  .Multidestino_search #moreopts_search-engine fieldset {
    min-width: 90%;
    max-width: 90%;
    width: 90%;
    font-size: 1.8rem;
    margin: auto;
  }
}
#moreopts_search-engine .mat-select-value-text,
#moreopts_search-engine .mat-form-field-empty {
  font-size: 1.4rem;
}
#moreopts_search-engine .airlines_list {
  position: relative;
  padding: 5px 30px 5px 0;
  text-align: right;
}
#moreopts_search-engine .airlines_list .Bless {
  position: absolute;
  right: -10px;
  top: 4px;
}

#submit_search-engine {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50px;
  right: 0;
  width: 10%;
  height: calc(100% - 50px);
  padding: 0;
  border-radius: 0 20px 0 0;
}
@media only screen and (max-width: 1022px) {
  #submit_search-engine {
    top: 0;
    width: 100%;
    font-size: 2rem;
    height: 85px;
    border-radius: 0;
    position: relative;
  }
}

/*___________________________________________________________ Results View ____________*/
.search-box #main_search-engine {
  margin-top: 0;
}
.search-box.hide #main_search-engine {
  animation: toBig 0.2s linear;
}
.search-box.hide #gradient_search-engine {
  background: transparent;
}

.summary {
  position: relative;
  background: transparent;
  z-index: 4;
  top: 0;
  height: 65px;
  max-height: 65px;
  display: block;
  width: calc(100% - 125px);
  margin: auto;
}
@media only screen and (max-width: 1247px) {
  .summary {
    width: calc(100% - 50px);
    margin-left: 0;
  }
}
.summary #main_search-engine {
  max-width: 1080px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0;
  height: auto;
  justify-content: center;
  max-height: 65px;
}
@media only screen and (max-width: 1247px) {
  .summary #main_search-engine {
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 1022px) {
  .summary #main_search-engine {
    display: none !important;
  }
}
.summary #main_search-engine .idavuelta,
.summary #main_search-engine .soloida {
  display: none;
}
.summary #nav_search-engine {
  justify-content: flex-start;
  margin: 0;
  align-items: center;
  max-height: 65px;
}
.summary #nav_search-engine span {
  display: none;
  border: 0;
  height: 55px;
  min-width: 0;
  margin-top: 5px;
}
.summary #nav_search-engine span:hover, .summary #nav_search-engine span.selected {
  display: flex;
  cursor: default;
  background: none;
}
.summary #nav_search-engine span:hover:before, .summary #nav_search-engine span.selected:before {
  color: #fff;
}
.summary #searchEngineForm {
  align-items: center;
  height: 65px;
  width: 100%;
  border: 0;
  background: none;
  min-height: 0;
  box-shadow: none;
}
.summary #searchEngineForm:before {
  border-width: 32px;
  left: -32px;
}
.summary #destdates_search-engine {
  min-width: 0;
  max-width: 100%;
  width: 80%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.summary #destdates_search-engine .destdate_container {
  flex-direction: row;
}
.summary #destdates_search-engine .simpledates_dateview {
  margin: 0;
  padding-bottom: 0;
}
.summary #destdates_search-engine .simpledates_dateview .day {
  line-height: 1;
}
.summary .Multidestino_search #destdates_search-engine {
  display: none;
}
.summary #desdate_container_multiinfo {
  padding: 0 29px 0 20px;
  width: 800px;
  max-width: 75%;
  flex-grow: 2;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.7rem;
  justify-content: flex-start;
}
@media only screen and (max-width: 1247px) {
  .summary #desdate_container_multiinfo {
    font-size: 1.7rem;
    padding: 0;
  }
}
.summary #desdate_container_multiinfo span {
  min-width: 33%;
  margin: 3px 0;
}
.summary #desdate_container_multiinfo span:before {
  color: #1a1a1a;
}
.summary #simpledates_search-engine {
  position: relative;
  top: -2px;
  margin: 0 0 0 20px;
}
@media only screen and (max-width: 1247px) {
  .summary #simpledates_search-engine {
    flex-direction: column;
  }
}
.summary #simpledates_search-engine div {
  flex-direction: row;
  max-width: 150px;
  min-width: 150px;
}
@media only screen and (max-width: 1247px) {
  .summary #simpledates_search-engine div {
    height: 23px;
  }
}
.summary #simpledates_search-engine .day {
  font-size: 2rem;
  flex-direction: row;
  margin: 0 5px;
}
.summary #simpledates_search-engine .day:before {
  font-size: 1.5rem;
  position: relative;
  left: -5px;
  top: -1px;
}
@media only screen and (max-width: 1247px) {
  .summary #simpledates_search-engine .day {
    font-size: 1.7rem;
  }
}
.summary #simpledates_search-engine .month {
  width: 200px;
}
.summary #passengers_search-engine {
  min-width: 0;
  max-width: 100%;
  width: auto;
}
.summary #passengers_search-engine > div {
  flex-direction: row;
  min-height: 0;
}
.summary #passengers_search-engine .personas {
  display: flex;
}
.summary #passengers_search-engine .pasajero,
.summary #passengers_search-engine .pasajero__container {
  display: none;
}
.summary #passengers_search-engine .Btooltip_content {
  margin-top: -85px;
}
.summary #submit_search-engine {
  padding: 10px 30px;
  margin: 0 10px 0 20px;
  border: 0;
  height: 100%;
  top: 0;
  font-weight: bold;
}
.summary #gradient_search-engine,
.summary #moreopts_search-engine,
.summary #options_search-engine {
  display: none;
}

.stopper-modal.modal_psg_manager {
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.stopper-modal.modal_psg_manager button {
  margin-top: 20px;
}
.stopper-modal.modal_psg_manager h2 {
  display: flex;
  align-items: center;
  margin: 20px 0 0;
  color: #fff;
  border-bottom: 1px solid #333;
  padding: 15px;
}
@media only screen and (max-width: 1022px) {
  .stopper-modal.modal_psg_manager h2 {
    margin-top: 70px;
  }
}
.stopper-modal.modal_psg_manager .personas {
  background: transparent;
  border: 0;
  margin: 0 10px 0 0;
  opacity: 0.4;
  font-size: 3rem;
}

.psg_manager {
  padding-top: 10px;
  display: flex;
  color: #fff;
  font-size: 1.4rem;
  text-align: center;
  text-transform: uppercase;
}
.psg_manager__error {
  padding: 5px;
  margin: 0 30px;
  border-bottom: 1px solid rgba(239, 28, 37, 0.5);
  text-align: center;
  color: #ff4500;
  opacity: 0;
}
.psg_manager__error.appear {
  padding: 5px 10px;
}
@media only screen and (max-width: 1022px) {
  .psg_manager__error {
    padding: 20px 0;
    margin: 0 auto;
  }
}
.psg_manager__container__box {
  border-bottom: 1px solid #333;
  padding: 10px 0;
}
.psg_manager__container__box__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  min-height: 22px;
}
.psg_manager__container__box__control {
  min-width: 30px;
  display: flex;
  position: relative;
}
.psg_manager__container__box__control i {
  top: 27px;
  position: relative;
}
.psg_manager__container__box__legend {
  margin: 5px 0;
  min-width: 40px;
  display: inline-block;
  text-align: center;
  font-size: 2.5rem;
}
.psg_manager__residents {
  margin: 20px auto 0 auto;
  color: #7f7f7f;
  font-family: basicregular, Arial, sans-serif;
  font-size: 1.6rem;
  max-width: 300px;
  text-align: center;
  width: 90%;
}
.psg_manager__residents p {
  text-align: left;
  font-size: 0.85em;
}

sbmn-flights-loading {
  position: fixed;
  height: 0;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8;
  opacity: 0;
  transition: opacity 0.5s linear;
  flex-direction: column;
}
@media only screen and (max-width: 1022px) {
  sbmn-flights-loading {
    justify-content: flex-start;
    overflow-y: scroll;
    width: calc(100% + 40px);
    padding-right: 20px;
  }
}
@media only screen and (orientation: portrait) {
  sbmn-flights-loading {
    width: 100%;
    padding-right: 0;
  }
}
sbmn-flights-loading:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("/assets/img/patterns/gravel.png") 0% 0%;
  content: " ";
  opacity: 0.2;
  z-index: -1;
}
sbmn-flights-loading.loading {
  top: 0;
  height: 100%;
  opacity: 1;
}

#loading-data {
  max-width: 800px;
  width: 90%;
}
#loading-data > header,
#loading-data > section {
  padding: 0 40px;
}
@media only screen and (max-width: 1022px) {
  #loading-data > header,
  #loading-data > section {
    padding: 0;
  }
}
#loading-data h1 {
  color: #000;
  font-size: 3.5rem;
  text-align: center;
  margin-bottom: 10px;
}
@media only screen and (max-width: 559px) {
  #loading-data h1 {
    font-size: 2.5rem;
  }
}
#loading-data header > div {
  text-align: center;
}
#loading-data section {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 1px solid #e5e5e5;
  padding: 20px;
}
@media only screen and (max-width: 1022px) {
  #loading-data section {
    flex-direction: column;
    border-color: #005c99;
    border-style: solid;
    border-width: 2px 0;
    padding: 12px;
  }
}
#loading-data .loading-info {
  font-size: 1.5rem;
  line-height: 1.2;
  opacity: 0;
  padding: 0 15px 10px 0;
}
@media only screen and (max-width: 559px) {
  #loading-data .loading-info {
    font-size: 1.2rem;
  }
}
#loading-data .loading-info:nth-of-type(1) {
  animation: appears 0.3s linear 0.5s forwards;
}
#loading-data .loading-info:nth-of-type(2) {
  animation: appears 0.3s linear 1.1s forwards;
}
#loading-data .loading-info:nth-of-type(n + 3) {
  animation: appears 0.3s linear 1.4s forwards;
  min-width: 180px;
}
@media only screen and (max-width: 1022px) {
  #loading-data .loading-info:nth-of-type(n + 3) {
    padding: 0;
  }
}
#loading-data .loading-info p {
  margin: 0;
}
#loading-data .loading-info > span {
  text-transform: uppercase;
  font-size: 1.5rem;
  color: #666;
}
@media only screen and (max-width: 1022px) {
  #loading-data .loading-info > span {
    font-weight: bold;
  }
}
#loading-data .Multidestino_loading {
  flex-wrap: wrap;
  background: none;
}
#loading-data .Multidestino_loading .loading-info {
  min-width: 100%;
  margin-bottom: 10px;
}
#loading-data .Multidestino_loading .loading-info:nth-of-type(n + 3) {
  padding: 0 15px 0 0;
}
#loading-data .Multidestino_loading .loading-info:last-child {
  padding-top: 10px;
  border-top: 1px solid #b2b2b2;
}
#loading-data .Multidestino_loading .loading-info:last-child p {
  display: inline;
  padding-right: 10px;
}
#loading-data .Multidestino_loading .loading-info:last-child br {
  display: none;
}
#loading-data .idavuelta,
#loading-data .soloida {
  border: none;
  position: relative;
  margin-right: 30px;
  margin-left: 0;
  opacity: 0;
  animation: appears 0.3s linear 0.8s forwards;
}
#loading-data .idavuelta:before,
#loading-data .soloida:before {
  color: #0099ff;
}
@media only screen and (max-width: 1022px) {
  #loading-data .idavuelta,
  #loading-data .soloida {
    display: none;
  }
}
#loading-data .mini {
  width: 20px;
  height: 20px;
  border: 0;
  display: inline;
  line-height: 0.9;
  padding: 0 10px;
}
#loading-data .mini:before {
  font-size: 1.6rem;
}
#loading-data figure {
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
  min-width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 1022px) {
  #loading-data figure {
    margin: 0;
  }
}
#loading-data figure img {
  width: 100%;
  max-width: 710px;
}

.ffamilies .cssload-loader__container {
  position: relative;
}
.ffamilies__scroll .h2withbold {
  text-align: center;
  margin: 0;
  color: #fff;
}
.ffamilies__scroll .h3withbold, .ffamilies__scroll .cms__basicinfo__container__content > span > strong {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1022px) {
  .ffamilies__scroll .h3withbold, .ffamilies__scroll .cms__basicinfo__container__content > span > strong {
    font-size: 1.8rem;
  }
}
.ffamilies__scroll table {
  margin: 0;
  width: 100%;
}
.ffamilies__scroll table thead {
  background: transparent;
  border-bottom: 1px solid #005c99;
  color: #666;
}
.ffamilies__scroll table thead tr:last-child {
  color: #005c99;
  border-bottom: 0;
}
.ffamilies__scroll table thead td {
  padding: 2px 5px;
}
.ffamilies__scroll table tr {
  border-bottom: 1px solid #f9b987;
  border-left: 0;
}
@media only screen and (max-width: 1022px) {
  .ffamilies__scroll table tr {
    border-bottom: 5px solid #f9b987;
  }
}
.ffamilies__scroll table tr.in {
  background: rgba(0, 89, 179, 0.2);
}
.ffamilies__scroll table tr.selected {
  background: rgba(168, 216, 140, 0.1);
}
.ffamilies__scroll table td {
  vertical-align: top;
  padding: 5px;
}
@media only screen and (max-width: 1022px) {
  .ffamilies__scroll table td:before {
    display: none;
  }
}
.ffamilies__scroll table td:last-child {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.ffamilies__scroll span.ko {
  z-index: 0;
}
.ffamilies .ffamilies__options__container__item__icon {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  display: block;
  display: inline-flex;
  font-size: 2rem;
  border-color: #0099ff;
}
.ffamilies .ffamilies__options__container__item__icon.maleta {
  border: 0;
  margin-right: 10px;
  background: transparent;
  color: #fff;
}
.ffamilies .ffamilies__options__container__item__icon.maleta:before {
  font-size: 2.5rem;
  top: -1px;
  position: absolute;
  color: rgba(0, 92, 153, 0.5);
}
.ffamilies .ffamilies__options__container__item__icon.maleta strong {
  margin-top: 3px;
  font-size: 1.3rem;
  z-index: 1;
}
.ffamilies__options__container__header {
  display: none;
}
@media only screen and (max-width: 559px) {
  .ffamilies__options__container__header {
    display: flex;
  }
}
.ffamilies__options__container__header .h3withbold, .ffamilies__options__container__header .cms__basicinfo__container__content > span > strong {
  margin-right: 10px;
}
@media only screen and (max-width: 559px) {
  .ffamilies__options__container__header .ffamilies__options__container__item__icon {
    display: flex;
  }
}
.ffamilies__options__container__item {
  min-width: 100%;
  margin-top: 15px;
}
.ffamilies__options__container__item__list {
  font-size: 1.3rem;
  text-align: left;
  border-radius: 5px;
  width: 100%;
  height: 0;
  padding: 0 10%;
  margin: 0 auto;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s linear;
  list-style: disc;
}
.ffamilies__options__container__item__list li:first-child {
  list-style: none;
}
.in .ffamilies__options__container__item__list {
  height: auto;
  max-height: 1000px;
  margin: 5px auto;
  padding: 10px 10%;
  background: rgba(0, 89, 179, 0.5);
}
.selected.in .ffamilies__options__container__item__list {
  background: #fff;
}
.ffamilies__options__container__item__title {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
}
.ffamilies__options__container__item__title > strong {
  display: inline-block;
  text-align: right;
}
.ffamilies__options__container__item__title > strong span {
  margin: 0 4px;
}
.ffamilies__options__container__item__title em {
  font-size: 0.9em;
  margin-left: 10px;
  display: none;
}
@media only screen and (max-width: 1022px) {
  .ffamilies__options__container__item__title em {
    display: inline-block;
  }
}
.ffamilies__options__container__item__price {
  font-size: 2rem;
  padding: 5px 10px 2px 10px;
  width: 130px;
  display: inline-block;
  text-align: center;
  background: #b2b2b2;
}
.selected .ffamilies__options__container__item__price {
  background-color: #003366;
}
.selected .ffamilies__options__container__item__price:hover, .selected .ffamilies__options__container__item__price:focus, .selected .ffamilies__options__container__item__price:active {
  box-shadow: none;
  cursor: auto;
}
.ffamilies__options__container__item__open.menosopciones {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  display: block;
  margin-left: 15px;
  transform: rotate(180deg);
  position: relative;
}
.ffamilies__options__container__item__open.menosopciones:before {
  color: #005c99;
  font-size: 3rem;
}
.in .ffamilies__options__container__item__open.menosopciones {
  transform: rotate(0);
}

sbmn-flights-results, sbmn-flights-calendar {
  position: relative;
  display: block;
}
sbmn-flights-results > header, sbmn-flights-calendar > header {
  position: relative;
  display: flex;
  padding: 0;
  width: 100%;
  align-items: center;
  z-index: 2;
}
sbmn-flights-results > header > .max-width-box, sbmn-flights-calendar > header > .max-width-box {
  max-width: 100%;
}
sbmn-flights-results > header.hsummary, sbmn-flights-calendar > header.hsummary {
  height: 65px;
  max-height: 65px;
  background: #ccc;
}
@media only screen and (max-width: 1022px) {
  sbmn-flights-results > header.hsummary, sbmn-flights-calendar > header.hsummary {
    margin-top: 60px;
  }
}
sbmn-flights-results > header.hhide, sbmn-flights-calendar > header.hhide {
  background: #fff;
}
sbmn-flights-results sbmn-flights-search-engine, sbmn-flights-calendar sbmn-flights-search-engine {
  max-width: 1440px;
  margin: auto;
}
@media only screen and (max-width: 1022px) {
  sbmn-flights-results sbmn-flights-search-engine, sbmn-flights-calendar sbmn-flights-search-engine {
    margin-top: 83px;
  }
}
@media only screen and (min-width: 1023px) {
  sbmn-flights-results header.sticky, sbmn-flights-calendar header.sticky {
    position: fixed;
    top: 0;
    height: 50px;
    animation: headerIn 1s forwards;
    box-shadow: 0 8px 12px -6px rgba(0, 0, 0, 0.5);
    z-index: 3;
  }
  sbmn-flights-results header.sticky.hhide, sbmn-flights-calendar header.sticky.hhide {
    height: auto;
  }
  sbmn-flights-results .sticky:not(.hhide) #searchEngineForm, sbmn-flights-calendar .sticky:not(.hhide) #searchEngineForm {
    padding-top: 0;
  }
  sbmn-flights-results .sticky:not(.hhide) #nav_search-engine .selected, sbmn-flights-calendar .sticky:not(.hhide) #nav_search-engine .selected {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    display: block;
    display: flex;
    border-width: 2px;
  }
  sbmn-flights-results .sticky:not(.hhide) #searchEngineForm, sbmn-flights-calendar .sticky:not(.hhide) #searchEngineForm,
  sbmn-flights-results .sticky:not(.hhide) #nav_search-engine,
  sbmn-flights-calendar .sticky:not(.hhide) #nav_search-engine,
  sbmn-flights-results .sticky:not(.hhide) #destdates_search-engine,
  sbmn-flights-calendar .sticky:not(.hhide) #destdates_search-engine,
  sbmn-flights-results .sticky:not(.hhide) #passengers_search-engine,
  sbmn-flights-calendar .sticky:not(.hhide) #passengers_search-engine,
  sbmn-flights-results .sticky:not(.hhide) .mat-form-field,
  sbmn-flights-calendar .sticky:not(.hhide) .mat-form-field,
  sbmn-flights-results .sticky:not(.hhide) .menosopciones,
  sbmn-flights-calendar .sticky:not(.hhide) .menosopciones {
    height: 50px;
  }
  sbmn-flights-results .sticky:not(.hhide) #passengers_search-engine .personas, sbmn-flights-calendar .sticky:not(.hhide) #passengers_search-engine .personas {
    border: 0;
  }
  sbmn-flights-results .sticky:not(.hhide) .mat-form-field-label, sbmn-flights-calendar .sticky:not(.hhide) .mat-form-field-label {
    top: 1.7em;
  }
  sbmn-flights-results #fixstickysengine, sbmn-flights-calendar #fixstickysengine {
    height: 65px;
  }
}
sbmn-flights-results i, sbmn-flights-calendar i {
  margin: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
}
@media only screen and (max-width: 1247px) {
  sbmn-flights-results i, sbmn-flights-calendar i {
    margin: 3px 6px 3px 0;
  }
}
sbmn-flights-results i.selected, sbmn-flights-calendar i.selected {
  border-color: #0099ff;
  background: rgba(0, 51, 102, 0.2);
}
sbmn-flights-results .filtrar, sbmn-flights-calendar .filtrar {
  color: #b2b2b2;
  border-bottom: 2px solid #b2b2b2;
  background: transparent;
  cursor: pointer;
  margin: 0 20px 0 0;
  font-size: 3rem;
  padding-bottom: 5px;
}
sbmn-flights-results .filtrar:hover, sbmn-flights-calendar .filtrar:hover, sbmn-flights-results .filtrar:focus, sbmn-flights-calendar .filtrar:focus {
  color: #003366;
  border-color: #003366;
}
@media only screen and (max-width: 1247px) {
  sbmn-flights-results .filtrar, sbmn-flights-calendar .filtrar {
    font-size: 2rem;
  }
}
sbmn-flights-results .menosopciones, sbmn-flights-calendar .menosopciones {
  opacity: 0.5;
  transform: rotateX(180deg);
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 15px;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s linear, opacity 0.2s linear;
}
sbmn-flights-results .menosopciones:before, sbmn-flights-calendar .menosopciones:before {
  font-size: 3rem;
  color: #2d5a96;
}
sbmn-flights-results .menosopciones:hover, sbmn-flights-calendar .menosopciones:hover, sbmn-flights-results .menosopciones:focus, sbmn-flights-calendar .menosopciones:focus {
  opacity: 1;
}
sbmn-flights-results .menosopciones.expanded, sbmn-flights-calendar .menosopciones.expanded {
  transform: rotateX(0);
}
@media only screen and (max-width: 1022px) {
  sbmn-flights-results .leftdialog, sbmn-flights-calendar .leftdialog,
  sbmn-flights-results .leftdialog.hide,
  sbmn-flights-results .leftdialog.show {
    left: 0;
    top: 60px;
    width: 100%;
    padding-right: 0;
    padding-bottom: 0;
    max-width: unset;
    min-width: unset;
  }
  sbmn-flights-results .leftdialog__list, sbmn-flights-calendar .leftdialog__list,
  sbmn-flights-results .leftdialog.hide__list,
  sbmn-flights-calendar .leftdialog.hide__list,
  sbmn-flights-results .leftdialog.show__list,
  sbmn-flights-calendar .leftdialog.show__list {
    padding-left: 5px;
  }
  sbmn-flights-results .leftdialog__list li, sbmn-flights-calendar .leftdialog__list li,
  sbmn-flights-results .leftdialog.hide__list li,
  sbmn-flights-calendar .leftdialog.hide__list li,
  sbmn-flights-results .leftdialog.show__list li,
  sbmn-flights-calendar .leftdialog.show__list li {
    text-decoration: none;
  }
}
@media only screen and (max-width: 1022px) {
  sbmn-flights-results .leftdialog.hide, sbmn-flights-calendar .leftdialog.hide {
    left: -100%;
    width: 100%;
  }
}
sbmn-flights-results sbmn-pagination, sbmn-flights-calendar sbmn-pagination {
  width: auto;
}
sbmn-flights-results .cssload-loader__container, sbmn-flights-calendar .cssload-loader__container {
  position: absolute;
  z-index: 1;
  padding-top: 10%;
}

.results_controls {
  padding: 0 1%;
  height: 100%;
  z-index: 5;
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media only screen and (max-width: 1022px) {
  .results_controls {
    align-items: flex-start;
    margin-top: 15px;
  }
  .results_controls:first-child {
    margin-top: 8px;
  }
}

#fresults {
  display: flex;
  justify-content: center;
  max-width: 100%;
  min-height: 1300px;
  overflow: unset;
}

#fresults_list {
  width: 100%;
  max-width: 1280px;
  padding: 0 3%;
}
@media only screen and (max-width: 1022px) {
  #fresults_list {
    padding: 0;
  }
}

.fresult_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fresult_header h1 {
  font-size: 4rem;
  margin: 20px 0;
  position: relative;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1022px) {
  .fresult_header {
    padding: 0;
    flex-direction: column;
  }
  .fresult_header h1 {
    text-align: center;
    margin: 15px 0 0;
  }
}

.fresult_scheme {
  margin: 0 0 40px 0;
  display: flex;
  box-shadow: -3px -3px 10px -3px rgba(0, 0, 0, 0.2);
  font-size: 1.6rem;
  min-height: 234px;
  background: rgba(241, 241, 241, 0.7);
}
@media only screen and (max-width: 1022px) {
  .fresult_scheme {
    flex-direction: column;
    box-shadow: 0px 2px 7px 5px rgba(0, 0, 0, 0.2);
  }
}

.fresult_schemedata {
  width: 100%;
  box-shadow: 5px 5px 10px -2px rgba(0, 0, 0, 0.2);
  z-index: 0;
  /* &._Calendario_.selected_scheme{
    border-color: $Bprimary-dark;
  }*/
}

.fresult_headerscheme {
  display: flex;
  padding: 5px;
  align-items: center;
  background: #f1f1f1;
  border-bottom: 1px solid #999;
}
.fresult_headerscheme i {
  transform: rotateX(0);
  width: auto;
  min-width: 98px;
  border-right: 2px solid #b2b2b2;
  padding-right: 15px;
  justify-content: left;
}
.fresult_headerscheme i.multiida {
  font-size: 0;
  color: transparent;
}
.fresult_headerscheme i:before {
  font-family: basicbold, Arial, sans-serif !important;
  font-size: 2rem;
  content: attr(title);
  text-transform: uppercase;
  color: #333;
}
.fresult_headerscheme p {
  margin: 5px;
  color: #0099ff;
  font-family: basicbold, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 1;
}
.fresult_headerscheme p span {
  color: #000;
  font-family: basicregular, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 1.4rem;
}

.fresult_route {
  position: relative;
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid #ccc;
  transition: background 0.1s linear;
  background: #fff;
  min-height: 60px;
  cursor: pointer;
  width: 100%;
}
.fresult_route:last-child {
  border-bottom: 0;
}
.fresult_route:hover, .fresult_route:focus, .fresult_route.selected {
  background: #003366;
}
.fresult_route:hover .fresults_schemeicons i, .fresult_route:focus .fresults_schemeicons i, .fresult_route.selected .fresults_schemeicons i {
  color: #999;
}
.fresult_route:hover .fresults_schemeicons i.butaca, .fresult_route:focus .fresults_schemeicons i.butaca, .fresult_route.selected .fresults_schemeicons i.butaca {
  color: #333;
}
.fresult_route:hover .fresults_schemeicons i:before, .fresult_route:focus .fresults_schemeicons i:before, .fresult_route.selected .fresults_schemeicons i:before {
  color: #999;
  border-color: #999;
}
.fresult_route:hover .fresults_schemeicons i.butaca:before, .fresult_route:focus .fresults_schemeicons i.butaca:before, .fresult_route.selected .fresults_schemeicons i.butaca:before {
  color: rgba(0, 89, 179, 0.8);
}
.fresult_route:hover .fresults_state, .fresult_route:focus .fresults_state {
  background: rgba(255, 255, 255, 0.9);
}
.fresult_route.selected .fresults_state {
  background: rgba(255, 255, 255, 0.8);
}
.fresult_route.selected .fresults_routesummary {
  color: #000;
  font-size: 2rem;
  line-height: 0.9;
}
.fresult_route.disabled {
  opacity: 0.3;
}
.fresult_route.selected.disabled {
  opacity: 0.3;
  background: #fff;
}
.fresult_route.extra:hover, .fresult_route.extra:focus, .fresult_route.extra.selected {
  background: #2d5a96;
}
.fresult_route.extra:hover .fresults_state, .fresult_route.extra:focus .fresults_state, .fresult_route.extra.selected .fresults_state {
  background: rgba(255, 255, 255, 0.9);
}
.fresult_route:before {
  position: absolute;
  right: 15px;
  top: 20px;
  color: transparent;
  font-size: 2rem;
}
@media only screen and (max-width: 1022px) {
  .fresult_route:before {
    right: 2px;
    font-size: 1.8rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
.fresult_route.selected:before {
  color: white;
}
.fresult_route figure {
  margin: 0;
  padding: 0;
  z-index: 2;
}

.fresults_selector {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.fresults_state {
  margin: 0 60px 0 0;
  padding: 0 20px;
  background: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 70px;
}
@media only screen and (max-width: 1022px) {
  .fresults_state {
    margin: 0 30px 0 0;
    padding: 0 10px;
  }
  .fresults_state figure {
    position: absolute;
    top: 5px;
  }
}
.fresults_state img {
  max-height: 50px;
}

.fresults_routetext {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;
}
@media only screen and (max-width: 1022px) {
  .fresults_routetext {
    flex-wrap: wrap;
    padding: 10px 0;
  }
}
@media only screen and (max-width: 1247px) {
  .fresults_routetext i {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    display: block;
  }
}

.fresults_routesummary {
  width: 100%;
  padding: 5px 30px;
  font-family: basicregular, Arial, sans-serif;
  color: #66c2ff;
  font-size: 2.5rem;
  line-height: 1;
  align-items: center;
}
@media only screen and (max-width: 1022px) {
  .fresults_routesummary {
    padding: 5px 15px 10px;
    flex-wrap: wrap;
    margin-left: 50px;
  }
}
.fresults_routesummary br {
  display: none;
}
.fresults_routesummary span {
  color: #000;
  font-family: basicregular, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 1.3rem;
  line-height: 1;
  display: block;
}

.fresults_stopover {
  min-width: 120px;
  padding: 0 5px 0 15px;
  text-align: center;
  font-size: 1.3rem;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 1022px) {
  .fresults_stopover {
    min-width: unset;
    padding: 0 5px;
  }
}
.fresults_stopover span:first-child {
  text-decoration: underline;
}
.fresults_stopover span.isCupo {
  background-color: #dbeaf5;
  padding: 0.15rem 1.4rem;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  text-decoration: none !important;
  max-width: fit-content;
  margin-top: 2px;
  font-size: x-small;
}
.fresults_stopover .escala {
  color: #333;
  position: relative;
  background: transparent;
  display: flex;
  flex-direction: row-reverse;
  margin: 0;
}
.fresults_stopover .escala:before {
  font-size: 3.5rem;
  color: #ccc;
}
.fresults_stopover .pt {
  font-family: basicregular, Arial, sans-serif;
  color: #2b1649;
  margin: 0 0 0 35px;
  min-width: 25px;
  display: inline-block;
}

.fresults_schemeicons {
  width: 180px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 1022px) {
  .fresults_schemeicons {
    flex-wrap: wrap;
    width: unset;
    height: auto;
    padding: 0;
  }
}
.fresults_schemeicons i {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  border-radius: 50%;
  display: block;
  display: flex;
}
.fresults_schemeicons i:before {
  color: #b2b2b2;
  font-size: 3rem;
  position: absolute;
}
.fresults_schemeicons .butaca {
  position: relative;
}
.fresults_schemeicons .butaca:before {
  color: #e5e5e5;
  font-size: 3rem;
  top: 6px;
  left: 6px;
  z-index: 0;
}
@media only screen and (max-width: 1022px) {
  .fresults_schemeicons .butaca {
    padding: 10px;
  }
  .fresults_schemeicons .butaca:before {
    top: 7px;
    left: 8px;
  }
}
.fresults_schemeicons .butaca .fresults_seats_text {
  z-index: 1;
  color: #666;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-family: basicregular, Arial, sans-serif;
}
.fresults_schemeicons .maleta {
  display: flex;
}
.fresults_schemeicons .maleta:before {
  font-size: 3.3rem;
  margin-top: -1px;
}
.fresults_schemeicons .fresults_baggagetxt {
  color: #fff;
  z-index: 1;
  font-weight: bold;
  padding-top: 3px;
  font-size: 1.4rem;
}
.fresults_schemeicons .fresults_baggagetxt small {
  font-size: 1.2rem;
  font-weight: normal;
}
@media only screen and (max-width: 1022px) {
  .fresults_schemeicons .fresults_baggagetxt {
    font-size: 1.25rem;
    text-align: center;
  }
}
.fresults_schemeicons .clase {
  color: #b2b2b2;
  font-weight: bold;
}
@media only screen and (max-width: 1022px) {
  .fresults_schemeicons .clase {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fresult_schemeprice {
  min-width: 180px;
  padding: 20px 12px;
  z-index: 0;
  background: #fff;
  box-shadow: 7px 5px 10px -2px rgba(0, 0, 0, 0.2);
  border-left: 1px solid #e5e5e5;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fresult_schemeprice.--bigscheme {
  align-items: flex-start;
}
@media only screen and (max-width: 1022px) {
  .fresult_schemeprice {
    box-shadow: none;
    width: 100%;
    padding: 0;
    margin-left: 0;
    flex-wrap: wrap;
    height: auto;
    border-top: 1px solid #b2b2b2;
  }
}
@media only screen and (max-width: 1022px) {
  .fresult_schemeprice button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 0 20px;
    padding: 25px 0;
    font-size: 2rem;
    border-radius: 0;
  }
}
@media only screen and (max-width: 559px) {
  .fresult_schemeprice button {
    margin: 0;
  }
}

.fresult_schemeprice_container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  padding: 30px 0 60px;
}
.--bigscheme .fresult_schemeprice_container {
  position: sticky;
  top: 60px;
}
@media only screen and (min-width: 1023px) {
  .--bigscheme .fresult_schemeprice_container {
    padding: 10px 0;
  }
}
@media only screen and (max-width: 1022px) {
  .fresult_schemeprice_container {
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }
  .fresult_schemeprice_container > * {
    min-width: 50%;
    text-align: center;
    padding: 0;
  }
}
@media only screen and (max-width: 559px) {
  .fresult_schemeprice_container {
    width: 100%;
  }
}
.fresult_schemeprice_container .fresults_routeinfo {
  background: transparent;
  width: auto;
  height: auto;
  margin-bottom: 10px;
}
.fresult_schemeprice_container .fresults_routeinfo:before {
  display: none;
}
.fresult_schemeprice_container .fresults_routeinfo:hover, .fresult_schemeprice_container .fresults_routeinfo:focus {
  background: transparent;
}
.fresult_schemeprice_container .fresults_routeinfo.enabled i {
  opacity: 0.6;
}
.fresult_schemeprice_container .fresults_routeinfo.enabled:hover, .fresult_schemeprice_container .fresults_routeinfo.enabled:focus {
  background: transparent;
}
.fresult_schemeprice_container .fresults_routeinfo.enabled:hover i, .fresult_schemeprice_container .fresults_routeinfo.enabled:focus i {
  transform: none;
}
@media only screen and (max-width: 1022px) {
  .fresult_schemeprice_container .fresults_routeinfo {
    align-items: center;
    margin: 0 0 10px;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
  }
}
.fresult_schemeprice_container .fresults_routeinfo i {
  margin: 0 6px;
}
.fresult_schemeprice_container .fresults_routeinfo i:hover, .fresult_schemeprice_container .fresults_routeinfo i:focus {
  opacity: 1;
}
.fresult_schemeprice_container .fresults_routeinfo i:hover:before, .fresult_schemeprice_container .fresults_routeinfo i:focus:before {
  transform: scale(1.5);
}
@media only screen and (max-width: 1247px) {
  .fresult_schemeprice_container .fresults_routeinfo i {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    display: block;
    display: flex;
  }
}
.fresult_schemeprice_container .fresults_routeinfo .personas {
  font-size: 1.6rem;
  font-weight: normal;
}

@media only screen and (max-width: 1022px) {
  .fresults_rate {
    font-size: 1.6rem;
  }
}
.fresults_rate strong span.isCupo {
  font-weight: 900;
}

.fresults_routeinfo {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  display: block;
  border-radius: 50%;
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  z-index: 3;
  position: relative;
  transition: all 0.2s linear;
}
.fresults_routeinfo:hover, .fresults_routeinfo:focus {
  background: #0059b3;
  color: #fff;
}
@media only screen and (max-width: 1022px) {
  .fresults_routeinfo {
    padding: 0;
    height: auto;
    margin: 0;
    background: none;
  }
}
.fresults_routeinfo i {
  font-size: 2.2rem;
  font-weight: bold;
  color: #ccc;
  border-color: #ccc;
  display: flex;
}
.fresults_routeinfo i:before {
  transition: transform 0.2s linear;
}
.fresults_routeinfo i.selected {
  background: transparent;
}
.fresults_routeinfo i.selected:before {
  color: #003366;
}
.fresults_routeinfo .block_routerinfo {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: block;
}
.fresults_routeinfo.enabled:hover i, .fresults_routeinfo.enabled:hover:before, .fresults_routeinfo.enabled:focus i, .fresults_routeinfo.enabled:focus:before {
  transform: scale(1.2);
}
.fresults_routeinfo.enabled:before {
  content: "+";
  font-family: basicbold, Arial, sans-serif;
  margin-right: -5px;
}
.fresults_routeinfo.enabled .block_routerinfo {
  display: none;
}
.fresults_routeinfo.enabled i {
  color: #005c99;
  cursor: pointer;
}

.fresults_price {
  font-size: 3rem;
  margin: 10px auto;
}
.fresults_price small {
  font-size: 0.8em;
}
@media only screen and (max-width: 1022px) {
  .fresults_price {
    font-size: 3.5rem;
  }
}

.fresult_footer {
  display: flex;
  justify-content: flex-end;
}

#fresults_ads {
  width: 230px;
  padding: 78px 0;
  display: flex;
  flex-direction: column;
  position: relative;
}
#fresults_ads .h2withbold {
  padding-right: 10px;
  font-size: 2.5rem;
}
#fresults_ads figure {
  margin: 10px 0;
}
@media only screen and (max-width: 1247px) {
  #fresults_ads {
    display: none;
  }
}

.fresults_filters {
  position: absolute;
  min-height: calc(100% - 65px);
  background-color: #f1f1f1;
  border-right: 1px solid #4c4c4c;
  min-width: 400px;
  width: 400px;
  transition: margin-left 0.5s;
  padding: 10px 20px 50px 20px;
  margin-left: -400px;
  z-index: 2;
  left: 0;
}
@media only screen and (max-width: 1022px) {
  .fresults_filters {
    min-width: 100%;
    width: 100%;
  }
}
.fresults_filters .ko:hover {
  color: #000;
}
.fresults_filters.hide {
  margin-left: -400px;
}
.fresults_filters.show {
  margin-left: 0;
}

.tooltipinfo {
  background-color: #fff;
  font-size: 1.5rem;
}

/*--------------- extendedinfo & extendedpriceinfo ------*/
.extendedinfo {
  padding-bottom: 50px;
  font-size: 1.5rem;
}
.extendedinfo__header {
  background: #666;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  margin-bottom: 30px;
}
@media only screen and (max-width: 1022px) {
  .extendedinfo__header {
    flex-wrap: wrap;
    padding: 10px 0;
  }
}
.extendedinfo__header__icon {
  background: #fff;
  display: flex;
  align-items: center;
  padding: 5px 15px 5px 10px;
  margin: 0 7px;
  border-radius: 30px;
  color: #666;
}
.extendedinfo__header__icon:before {
  color: #b2b2b2;
  font-size: 1.5rem;
  margin-right: 5px;
}
@media only screen and (max-width: 1022px) {
  .extendedinfo__header__icon {
    flex-wrap: wrap;
    margin: 7px;
  }
}
.extendedinfo__container {
  margin: 0 30px 0 25px;
  padding: 5px 0 20px 0;
  border-top: 2px dotted #ccc;
}
.extendedinfo__container:nth-of-type(1) {
  border: 0;
}
@media only screen and (max-width: 1022px) {
  .extendedinfo__container {
    margin: 0 20px 0 15px;
  }
}
.extendedinfo__container__buttons {
  display: flex;
  flex-direction: row;
}
.extendedinfo__container__print {
  display: flex;
  flex-direction: column;
  width: 49%;
  margin-right: 1%;
}
.extendedinfo__container__clipboard {
  display: flex;
  flex-direction: column;
  width: 49%;
  margin-right: 1%;
  margin-left: 1%;
}
.extendedinfo__container__copy {
  display: flex;
  flex-direction: column;
  width: 49%;
  margin-left: 1%;
}
.extendedinfo__container__title {
  color: #4c4c4c;
  margin: 20px 0 10px;
  font-size: 1.7rem;
}
.extendedinfo__container__title.nomargin {
  margin-top: 0;
}
.extendedinfo__container__title.withborder {
  border-bottom: 2px dotted #ccc;
  padding-bottom: 5px;
}
.extendedinfo__container__subtitle {
  font-size: 1.3rem;
  margin: 0;
  color: #7f7f7f;
  line-height: 1;
}
.extendedinfo__container__warning {
  margin: 20px auto 0;
  color: #a8d88c;
}
.extendedinfo__container__code {
  padding: 10px;
  font-size: 1.5rem;
  border-radius: 5px;
  background: #f1f1f1;
  max-height: 300px;
  overflow-y: scroll;
}
.extendedinfo__container__details {
  padding: 0 0 10px 0;
}
.extendedinfo__container__details__header {
  margin: 5px 0;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  display: flex;
  color: #000;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid #005c99;
}
@media only screen and (max-width: 559px) {
  .extendedinfo__container__details__header {
    flex-direction: column;
  }
}
.extendedinfo__container__details__header__title {
  font-size: 1.1em;
  display: flex;
  align-items: center;
}
.extendedinfo__container__details__header__title img {
  margin: 0 3px;
}
.extendedinfo__container__details__header__title span {
  margin-left: 5px;
  font-family: basicregular, Arial, sans-serif;
}
.extendedinfo__container__details__header__info {
  font-family: basicregular, Arial, sans-serif;
}
.extendedinfo__container__details__scale {
  color: #191919;
  background: #e5e5e5;
  text-align: center;
  margin: 0 0 20px 0;
  padding: 5px 0;
}
.extendedinfo__container__details__flight {
  padding: 10px 0 5px;
}
.extendedinfo__container__details__flight__detail {
  display: flex;
  width: 100%;
  padding: 3px 0;
}
@media only screen and (max-width: 559px) {
  .extendedinfo__container__details__flight__detail {
    flex-wrap: wrap;
  }
}
.extendedinfo__container__details__flight__detail > span:nth-of-type(1) {
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  color: #4c4c4c;
  font-size: 1.1em;
  width: 110px;
}
@media only screen and (max-width: 559px) {
  .extendedinfo__container__details__flight__detail > span:nth-of-type(1) {
    min-width: 100%;
  }
}
.extendedinfo__container__details__flight__detail > span:nth-of-type(2) {
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  color: #7f7f7f;
  width: 50px;
}
.extendedinfo__container__details__flight__detail > span:nth-of-type(3) {
  flex-grow: 2;
}
.extendedinfo__container__details__flight__detail > span:nth-of-type(3), .extendedinfo__container__details__flight__detail > span:nth-of-type(4) {
  font-family: basiclight, Arial, sans-serif;
  color: #191919;
}
.extendedinfo__container__details__footer {
  border-top: 1px solid #ccc;
  font-family: basicregular, Arial, sans-serif;
  color: #191919;
  padding: 5px 0 10px 0;
}
.extendedinfo__container__details__footer span {
  margin-right: 15px;
}
@media only screen and (max-width: 559px) {
  .extendedinfo__container__details__footer span {
    display: block;
  }
}
.extendedinfo__container__details__footer span:before {
  margin-right: 5px;
  color: #7f7f7f;
  position: relative;
  top: 3px;
  font-size: 1.8rem;
}
.extendedinfo__container__details__footer span.bono {
  text-transform: capitalize;
}
.extendedinfo__container__details__footer strong {
  color: #7f7f7f;
}
.extendedinfo__container__icons {
  text-align: center;
}
.extendedinfo__container__table.table_responsive {
  width: 100%;
  text-align: center;
  border: 1px solid #0099ff;
  border-collapse: unset;
  border-radius: 5px;
}
@media only screen and (max-width: 639px) {
  .extendedinfo__container__table.table_responsive {
    border-color: #ccc;
  }
}
.extendedinfo__container__table.table_responsive thead {
  background: #0099ff;
  color: #fff;
  text-transform: uppercase;
  padding: 15px;
}
.extendedinfo__container__table.table_responsive tbody {
  padding: 15px;
}
.extendedinfo__container__table.table_responsive tbody tr:nth-last-of-type(even) {
  background: #f1f1f1;
}
.extendedinfo__container__table.table_responsive td,
.extendedinfo__container__table.table_responsive th {
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  font-size: 1.3rem;
  font-family: basicregular, Arial, sans-serif;
}
.extendedinfo__container__table.table_responsive td {
  font-size: 1.4rem;
  font-weight: bold;
  color: #666;
}
.extendedinfo__container__table.table_responsive td:last-child {
  color: #000;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
}
.extendedinfo__container__table-total {
  padding: 15px;
  text-transform: uppercase;
  text-align: right;
  font-family: basicregular, Arial, sans-serif;
}
.extendedinfo__container__list {
  font-size: 1.3rem;
  padding: 0;
  list-style-position: inside;
  margin: 5px 0 10px;
}
.extendedinfo__container__list li {
  margin-bottom: 5px;
  text-transform: none;
  color: #4c4c4c;
}
.extendedinfo__container__loading {
  margin: 50px 40%;
}

#fcalendar {
  display: flex;
  background-color: #f1f1f1;
}

#fcalendar_list {
  width: 100%;
  padding: 0 3%;
}
#fcalendar_list h1 {
  font-size: 4rem;
  margin: 25px 0 0;
  display: flex;
  align-items: center;
}

#fcalendar_ads {
  width: 230px;
}
@media only screen and (max-width: 1022px) {
  #fcalendar_ads {
    display: none;
  }
}

.calendargrid {
  padding-bottom: 80px;
  padding-right: 20px;
  position: relative;
}
@media only screen and (max-width: 1022px) {
  .calendargrid {
    padding-right: 0;
  }
}
.calendargrid__titulo1, .calendargrid__titulo2 {
  font-family: basiclight, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 2rem;
  text-align: left;
  color: #666;
}
@media only screen and (max-width: 1022px) {
  .calendargrid__titulo1, .calendargrid__titulo2 {
    display: none;
  }
}
.calendargrid__titulo1 {
  border-bottom: 0;
}
.calendargrid__titulo2 {
  position: absolute;
  right: -70px;
  transform: rotate(90deg);
  top: calc(50% - 20px);
}
@media only screen and (max-width: 1022px) {
  .calendargrid__titulo2 {
    right: -60px;
  }
}
.calendargrid__type1, .calendargrid__type2, .calendargrid__type3, .calendargrid__type4, .calendargrid__type5 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}
.calendargrid__type1:before, .calendargrid__type2:before, .calendargrid__type3:before, .calendargrid__type4:before, .calendargrid__type5:before {
  content: "";
  height: 100%;
  width: 8px;
  position: absolute;
  left: 0;
  top: 0;
}
.calendargrid__type1:before {
  background-color: #003366;
}
.calendargrid__type1 .calendargrid__price-maleta-container {
  color: black;
  background-color: rgba(0, 51, 102, 0.4);
}
.calendargrid__type1 .calendargrid__price-maleta-container:hover {
  background-color: rgba(0, 51, 102, 0.9);
}
.calendargrid__type2:before {
  background-color: #6ebd41;
}
.calendargrid__type2 .calendargrid__price-maleta-container:hover {
  background-color: #6ebd41;
  color: #fff;
}
.calendargrid__type2 .calendargrid__price-maleta-container:hover .calendargrid__price-maleta-container__maleta__icon:before {
  color: #fff;
}
.calendargrid__type2 .calendargrid__price-maleta-container:hover .calendargrid__price-maleta-container__maleta__number {
  color: #fff;
}
.calendargrid__type3:before {
  background-color: #0099ff;
}
.calendargrid__type3 .calendargrid__price-maleta-container:hover {
  background-color: #0099ff;
  color: #fff;
}
.calendargrid__type3 .calendargrid__price-maleta-container:hover .calendargrid__price-maleta-container__maleta__icon:before {
  color: #fff;
}
.calendargrid__type3 .calendargrid__price-maleta-container:hover .calendargrid__price-maleta-container__maleta__number {
  color: #fff;
}
.calendargrid__type4:before {
  background-color: #f9b987;
}
.calendargrid__type4 .calendargrid__price-maleta-container:hover {
  background-color: #f9b987;
  color: #fff;
}
.calendargrid__type4 .calendargrid__price-maleta-container:hover .calendargrid__price-maleta-container__maleta__icon:before {
  color: #fff;
}
.calendargrid__type4 .calendargrid__price-maleta-container:hover .calendargrid__price-maleta-container__maleta__number {
  color: #fff;
}
.calendargrid__type5:hover {
  background-color: #7f7f7f;
  color: #fff;
}
.calendargrid__price-maleta-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  line-height: 24px;
  padding: 6px;
  min-height: 60px;
  flex-grow: 2;
  transition: background-color 0.2s linear;
}
@media only screen and (max-width: 1022px) {
  .calendargrid__price-maleta-container {
    align-items: flex-end;
  }
}
.calendargrid__price-maleta-container__maleta {
  display: flex;
  align-items: center;
}
.calendargrid__price-maleta-container__maleta__to {
  background-color: #b2b2b2;
  border-radius: 50%;
  color: white;
  padding: 0px 5px;
  font-size: 10px;
  margin-right: 1em;
}
.calendargrid__price-maleta-container__maleta__icon {
  border: none;
  position: relative;
  background-color: transparent;
  margin: 0;
  height: auto;
  min-height: unset;
}
.calendargrid__price-maleta-container__maleta__icon:before {
  transition: color 0.2s linear;
  font-size: 20px;
  color: #b2b2b2;
}
.calendargrid__price-maleta-container__maleta__number {
  font-size: 0.8em;
  color: #b2b2b2;
  margin-left: 5px;
  position: relative;
  top: 2px;
  padding-top: 4px;
}
.calendargrid__table {
  table-layout: fixed;
  width: 100%;
  text-align: center;
  border-spacing: 10px;
}
.calendargrid__table.table_responsive {
  overflow: visible;
}
@media only screen and (min-width: 1023px) {
  .calendargrid__table.table_responsive {
    border-spacing: 10px;
    border-collapse: unset;
    width: 100%;
  }
}
.calendargrid__table tr td {
  border: 1px solid #7f7f7f;
  height: auto;
  border-radius: 3px;
  font-size: 1.7rem;
  font-weight: bold;
  position: relative;
  color: #666;
  background-color: #fff;
  cursor: pointer;
  padding: 0;
}
.calendargrid__table tr td:before {
  font-family: basiclight, Arial, sans-serif;
  font-size: 1.4rem;
}
.calendargrid__table tr td:last-child {
  border: none;
  background-color: transparent;
}
@media only screen and (max-width: 1022px) {
  .calendargrid__table tr td,
  .calendargrid__table td:first-child {
    width: 100%;
    padding: 0 !important;
    border-radius: 0;
  }
  .calendargrid__table tr td:before,
  .calendargrid__table td:first-child:before {
    padding: 0 20px;
  }
  .calendargrid__table tr td:last-child {
    min-height: 20px;
    background-color: #000;
  }
  .calendargrid__table tr td:last-child span {
    display: none;
  }
}
.calendargrid__table__title-horiz, .calendargrid__table__title-vert {
  font-size: 1.7rem;
}
@media only screen and (max-width: 1022px) {
  .calendargrid__table__title-horiz, .calendargrid__table__title-vert {
    display: none;
  }
}
.calendargrid__table__title-horiz__number, .calendargrid__table__title-vert__number {
  font-weight: normal;
  margin-right: 5px;
  color: #4c4c4c;
}
.calendargrid__table__title-horiz__month, .calendargrid__table__title-vert__month {
  font-weight: bold;
  text-transform: uppercase;
  color: #4c4c4c;
  font-size: 0.9em;
}
.calendargrid__table__title-vert {
  text-align: left;
  padding-left: 15px;
}
.calendargrid__table__title-horiz {
  padding-bottom: 15px;
}
.calendargrid__legend {
  border-bottom: 2px dashed #e5e5e5;
  padding-top: 20px;
  text-align: center;
  padding-bottom: 5px;
}
.calendargrid__legend__first, .calendargrid__legend__second, .calendargrid__legend__third, .calendargrid__legend__fourth, .calendargrid__legend__all {
  position: relative;
  cursor: pointer;
  color: #4c4c4c;
  font-size: 1.7rem;
  margin: 0 15px 10px;
  border-radius: 3px;
  padding: 3px 30px 5px 10px;
  border-bottom: 3px solid transparent;
  display: inline-block;
}
.calendargrid__legend__first:before, .calendargrid__legend__second:before, .calendargrid__legend__third:before, .calendargrid__legend__fourth:before, .calendargrid__legend__all:before {
  position: absolute;
  right: 5px;
  font-size: 1.2rem;
  transition: opacity 0.2s linear;
  opacity: 0;
}
.calendargrid__legend__first:hover, .calendargrid__legend__first.okselected, .calendargrid__legend__second:hover, .calendargrid__legend__second.okselected, .calendargrid__legend__third:hover, .calendargrid__legend__third.okselected, .calendargrid__legend__fourth:hover, .calendargrid__legend__fourth.okselected, .calendargrid__legend__all:hover, .calendargrid__legend__all.okselected {
  color: #000;
}
.calendargrid__legend__first:hover:before, .calendargrid__legend__first.okselected:before, .calendargrid__legend__second:hover:before, .calendargrid__legend__second.okselected:before, .calendargrid__legend__third:hover:before, .calendargrid__legend__third.okselected:before, .calendargrid__legend__fourth:hover:before, .calendargrid__legend__fourth.okselected:before, .calendargrid__legend__all:hover:before, .calendargrid__legend__all.okselected:before {
  opacity: 1;
}
@media only screen and (max-width: 1247px) {
  .calendargrid__legend__first, .calendargrid__legend__second, .calendargrid__legend__third, .calendargrid__legend__fourth, .calendargrid__legend__all {
    font-size: 1.6rem;
    margin: 0 10px 10px;
  }
}
.calendargrid__legend__first {
  border-bottom-color: #003366;
}
.calendargrid__legend__first:before {
  color: #003366;
}
.calendargrid__legend__second {
  border-bottom-color: #6ebd41;
}
.calendargrid__legend__second:before {
  color: #6ebd41;
}
.calendargrid__legend__third {
  border-bottom-color: #0099ff;
}
.calendargrid__legend__third:before {
  color: #0099ff;
}
.calendargrid__legend__fourth {
  border-bottom-color: #f9b987;
}
.calendargrid__legend__fourth:before {
  color: #f9b987;
}
.calendargrid__legend__all:hover, .calendargrid__legend__all.okselected {
  border-bottom-color: #000;
}
.calendargrid__legend__all:hover:before, .calendargrid__legend__all.okselected:before {
  color: #000;
}

.-calendarDay-other,
.ND {
  opacity: 0.35;
  transition: none;
}
.-calendarDay-other:hover,
.-calendarDay-other .calendargrid__price-maleta-container:hover,
.ND:hover,
.ND .calendargrid__price-maleta-container:hover {
  opacity: 1;
}

.ND:before {
  display: none;
}
.ND:hover {
  opacity: 0.35;
  background: transparent;
}
.ND:hover .calendargrid__price-maleta-container {
  background: #fff;
  color: #666;
}
.ND .calendargrid__price-maleta-container {
  cursor: default;
}

/*_____________________________________________ DETAIL LAYER ____*/
.csummary {
  display: block;
  position: absolute;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 7px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 0;
  z-index: 3;
  width: 85%;
  max-width: 850px;
  overflow: hidden;
  /*&[class*='c0'],
  &[class*='c1'],
  &[class*='c2'],
  &[class*='c3']{
    left:0;
    right:auto;
  }
  &[class*='c4'],
  &[class*='c5'],
  &[class*='c6'],
  &[class*='c7']{
    left:auto;
    right:0;
  }
  &[class*='r0'],
  &[class*='r1'],
  &[class*='r2'],
  &[class*='r3']{
    top:80px;
    bottom:auto;
  }
  &[class*='r4'],
  &[class*='r5'],
  &[class*='r6'],
  &[class*='r7']{
    top:auto;
    bottom:80px;
  }*/
}
@media only screen and (max-width: 1247px) {
  .csummary {
    max-width: 700px;
  }
}
@media only screen and (max-width: 1022px) {
  .csummary {
    width: 100%;
  }
}
.csummary__content {
  display: flex;
}
@media only screen and (max-width: 1022px) {
  .csummary__content {
    flex-direction: column;
  }
}
.csummary__content__ida-vuelta {
  width: 50%;
  padding: 15px;
  font-size: 1.4rem;
}
.csummary__content__ida-vuelta.solo-ida {
  width: 100%;
}
@media only screen and (max-width: 1022px) {
  .csummary__content__ida-vuelta {
    width: 100%;
  }
}
.csummary__content__ida-vuelta:nth-of-type(2) {
  border-left: 1px solid #ccc;
}
.csummary__content__ida-vuelta:nth-of-type(2) .csummary__content__ida-vuelta__block__icon:before {
  transform: rotate(180deg);
}
.csummary__content__ida-vuelta__icon {
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  color: #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
}
.csummary__content__ida-vuelta__icon .ida, .csummary__content__ida-vuelta__icon .vuelta {
  display: flex;
  align-items: center;
}
.csummary__content__ida-vuelta__icon .ida:before, .csummary__content__ida-vuelta__icon .vuelta:before {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  margin-right: 10px;
  background: #ccc;
}
.csummary__content__ida-vuelta__icon .icon1:before {
  background: #003366;
  color: #000;
}
.csummary__content__ida-vuelta__icon .icon2:before {
  background: #6ebd41;
  color: #fff;
}
.csummary__content__ida-vuelta__icon .icon3:before {
  background: #a8d88c;
  color: #fff;
}
.csummary__content__ida-vuelta__icon .icon4:before {
  background: #005c99;
  color: #fff;
}
.csummary__content__ida-vuelta__icon__duration {
  background: #e5e5e5;
  color: #333;
  padding: 4px 10px;
  border-radius: 50px;
  font-size: 0.9em;
  min-width: 91px;
  text-align: center;
}
.csummary__content__ida-vuelta__jump {
  margin: 10px 0;
  background-color: #f1f1f1;
  padding: 4px 10px;
  border-radius: 3px;
  text-align: center;
}
@media only screen and (max-width: 1022px) {
  .csummary__content__ida-vuelta__jump {
    margin-bottom: 15px;
    padding: 4px 5px;
  }
}
.csummary__content__ida-vuelta__block {
  display: flex;
  justify-content: space-between;
  text-align: left;
  color: #666;
  flex-wrap: wrap;
}
.csummary__content__ida-vuelta__block__left {
  flex-grow: 2;
  width: calc(100% - 91px);
}
.csummary__content__ida-vuelta__block__left__airline {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 1.2rem;
}
.csummary__content__ida-vuelta__block__left__airline img {
  position: relative;
  top: 5px;
  margin: 0 5px;
}
.csummary__content__ida-vuelta__block__left__flight {
  display: flex;
  justify-content: space-between;
}
.csummary__content__ida-vuelta__block__left__flight strong {
  padding-right: 5px;
}
.csummary__content__ida-vuelta__block__right {
  text-align: right;
}
.csummary__content__ida-vuelta__block__right__duration {
  background: #e5e5e5;
  color: #666;
  padding: 4px 10px;
  border-radius: 50px;
  font-size: 0.9em;
  min-width: 91px;
  text-align: center;
}
.csummary__content__ida-vuelta__block__icons, .csummary__content__ida-vuelta__icons {
  display: flex;
  margin: 6px 0 0;
}
.csummary__content__ida-vuelta__block__icons__item, .csummary__content__ida-vuelta__icons__item {
  border: 0;
  width: auto;
  height: auto;
  margin-right: 10px;
  min-height: 0;
  font-size: 1.2rem;
}
.csummary__content__ida-vuelta__block__icons__item:before, .csummary__content__ida-vuelta__icons__item:before {
  font-size: 1.2rem;
  margin-right: 2px;
}
.csummary__content__ida-vuelta__block__icons__item.maleta:before, .csummary__content__ida-vuelta__icons__item.maleta:before {
  margin: -5px 5px 0 0;
}
.csummary[class*=r0] {
  bottom: auto;
  top: 165px;
}
.csummary[class*=r1] {
  bottom: auto;
  top: 240px;
}
.csummary[class*=r2] {
  bottom: auto;
  top: 315px;
}
.csummary[class*=r3] {
  bottom: 394px;
  top: auto;
}
.csummary[class*=r4] {
  bottom: 318px;
  top: auto;
}
.csummary[class*=r5] {
  bottom: 241px;
  top: auto;
}
.csummary[class*=r6] {
  bottom: 165px;
  top: auto;
}
.csummary[class*=c0] {
  left: 0;
  right: auto;
}
.csummary[class*=c1] {
  left: 12%;
  right: auto;
}
.csummary[class*=c2] {
  left: 20%;
  right: auto;
}
.csummary[class*=c3] {
  left: 25%;
  right: auto;
}
.csummary[class*=c4] {
  left: auto;
  right: 18%;
}
.csummary[class*=c5] {
  left: auto;
  right: 12%;
}
.csummary[class*=c6] {
  left: auto;
  right: 6%;
}

sbmn-flights-filters {
  padding-bottom: 50px;
}
sbmn-flights-filters h2 {
  top: 17px;
  font-family: basiclight, Arial, sans-serif;
  font-size: 3rem;
  font-weight: lighter;
  margin: 0;
  padding-bottom: 50px;
  display: block;
  position: relative;
}
sbmn-flights-filters > div {
  display: flex;
  flex-wrap: wrap;
}
sbmn-flights-filters section {
  width: 100%;
  margin-bottom: 15px;
  min-width: 280px;
  padding: 0 3%;
  border-bottom: 1px dotted #7f7f7f;
}
sbmn-flights-filters section h3 {
  font-family: basicregular, Arial, sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  width: 100%;
  color: #000;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline;
}
sbmn-flights-filters section h3:before {
  font-size: 2.5rem;
  color: #999;
  margin-right: 5px;
  top: 4px;
  position: relative;
}
sbmn-flights-filters section span {
  width: auto;
  text-align: center;
  display: inline-block;
  font-family: basicregular, Arial, sans-serif;
  color: #666;
  font-size: 1.5rem;
}
sbmn-flights-filters .filters_checks {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 12px;
}
sbmn-flights-filters .filters_checks mat-checkbox {
  width: 50%;
  min-width: fit-content;
  font-size: 1.7rem;
  margin: 10px 0 0;
}
sbmn-flights-filters .airlines .mat-checkbox-inner-container {
  margin-right: 4px;
}
sbmn-flights-filters .airlines .mat-checkbox-label {
  letter-spacing: -0.1rem;
}
sbmn-flights-filters .airlines img {
  position: relative;
  top: 4px;
  left: -1px;
}
sbmn-flights-filters mat-slider {
  width: 100%;
  padding: 4px 8px 8px;
  margin-top: 10px;
}
sbmn-flights-filters .deptime_filter {
  position: relative;
  max-width: calc(100% - 20px);
  width: calc(100% - 20px);
}
sbmn-flights-filters .deptime_filter span {
  margin-top: 10px;
  text-align: left;
  width: 100%;
}
sbmn-flights-filters .deptime_filter mat-slider {
  margin-top: 0;
}
sbmn-flights-filters .deptime_filter mat-slider:nth-of-type(2) {
  position: absolute;
  left: 10px;
}

sbmn-flights-summary {
  background: #fff;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  display: block;
  font-size: 1.5rem;
  position: relative;
}
sbmn-flights-summary i {
  width: 75px;
  height: 75px;
  min-width: 75px;
  min-height: 75px;
  border-radius: 50%;
  display: block;
  margin: 3px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 2px solid #ccc;
}
sbmn-flights-summary .menosopciones {
  transform: rotate(180deg);
  display: inline-block;
}
sbmn-flights-summary .menosopciones.expanded {
  transform: rotate(0);
}
@media only screen and (min-width: 1023px) {
  sbmn-flights-summary .sticky:not(.expanded) {
    background: #fff;
    position: fixed;
    top: 0;
    animation: headerIn 1s forwards;
    box-shadow: 0 8px 12px -6px rgba(0, 0, 0, 0.5);
    z-index: 3;
  }
  sbmn-flights-summary .sticky:not(.expanded) .fsummary__top {
    height: 50px;
  }
  sbmn-flights-summary .sticky:not(.expanded) .fsummary__top__ida-vuelta__icon {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    display: block;
    display: flex;
    font-size: 20px;
  }
  sbmn-flights-summary .sticky:not(.expanded) .fsummary__top__ida-vuelta__flights {
    flex-direction: row;
    flex-wrap: wrap;
  }
  sbmn-flights-summary .sticky:not(.expanded) .fsummary__top__ida-vuelta__flights > div {
    margin-right: 10px;
    font-size: 1.4rem;
  }
  sbmn-flights-summary .sticky:not(.expanded) .fsummary__top__ida-vuelta__flights__published {
    display: none;
  }
  sbmn-flights-summary .sticky:not(.expanded) .fsummary__top__ida-vuelta__flights__duration {
    background: transparent;
    padding: 0;
  }
  sbmn-flights-summary .sticky:not(.expanded) .fsummary__top__ida-vuelta__flights__dates {
    margin-bottom: 0;
  }
  sbmn-flights-summary .sticky:not(.expanded) .fsummary__top__passenger {
    font-size: 1.3rem;
  }
  sbmn-flights-summary .sticky:not(.expanded) .fsummary__top__passenger__num-name {
    line-height: 14px;
    margin-bottom: 0;
  }
  sbmn-flights-summary .sticky:not(.expanded) .fsummary__top__passenger__num-name:before {
    font-size: 1.4rem;
    margin-top: -3px;
  }
  sbmn-flights-summary .sticky:not(.expanded) .fsummary__top__passenger__price {
    line-height: 22px;
  }
  sbmn-flights-summary #fixstickysummary {
    display: block;
    height: 120px;
  }
}

.fsummary__container {
  width: 100%;
}
.fsummary__container.expanded {
  max-height: 3000px;
}
.fsummary__top-section {
  margin: auto;
  max-width: 850px;
}
.fsummary__top {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px 0;
  height: 120px;
  margin: auto;
  max-width: 850px;
}
@media only screen and (max-width: 1022px) {
  .fsummary__top {
    flex-direction: row;
    height: 230px;
    padding-top: 0;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 559px) {
  .fsummary__top {
    height: 250px;
  }
}
.fsummary__top__ida-vuelta {
  display: flex;
  align-items: center;
  width: 40%;
}
@media only screen and (max-width: 1022px) {
  .fsummary__top__ida-vuelta {
    flex: 1 1 135px;
    width: 50%;
    padding-right: 0px;
    justify-content: center;
  }
}
.fsummary__top__ida-vuelta__icon {
  font-size: 40px;
  color: #b2b2b2;
}
@media only screen and (max-width: 559px) {
  .fsummary__top__ida-vuelta__icon {
    margin: 0 5px;
    min-width: 40px;
    font-size: 23px;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 479px) {
  .fsummary__top__ida-vuelta__icon {
    display: none;
  }
}
.fsummary__top__ida-vuelta .-vuelta {
  transform: rotate(180deg);
}
.fsummary__top__ida-vuelta__flights {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 559px) {
  .fsummary__top__ida-vuelta__flights {
    padding: 25px 0 5px 0;
    text-align: center;
    align-items: center;
    position: relative;
  }
}
.fsummary__top__ida-vuelta__flights__published {
  background-color: #003366;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  padding: 1px 5px;
  font-size: 0.7em;
  display: inline;
  align-self: flex-end;
  margin-bottom: 5px;
}
@media only screen and (max-width: 559px) {
  .fsummary__top__ida-vuelta__flights__published {
    align-self: center;
    top: 5px;
    position: absolute;
  }
}
@media only screen and (max-width: 559px) {
  .fsummary__top__ida-vuelta__flights__from-to, .fsummary__top__ida-vuelta__flights__dates {
    text-align: center;
  }
}
@media only screen and (max-width: 559px) {
  .fsummary__top__ida-vuelta__flights__from-to {
    font-size: 0.9em;
  }
}
.fsummary__top__ida-vuelta__flights__dates {
  font-size: 1.6em;
  color: #0099ff;
  margin-bottom: 6px;
}
@media only screen and (max-width: 559px) {
  .fsummary__top__ida-vuelta__flights__dates {
    font-size: 1.7rem;
  }
}
.fsummary__top__ida-vuelta__flights__duration {
  background-color: #e5e5e5;
  padding: 4px 8px;
  border-radius: 50px;
  font-size: 0.9em;
  max-width: 180px;
  text-align: center;
}
.fsummary__top__ida-vuelta__flights__duration strong {
  margin-right: 10px;
}
.fsummary__top__ida-vuelta__flights__duration:before {
  padding-right: 5px;
  color: #7f7f7f;
}
.fsummary__top__passenger {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  border-left: 1px dashed #b2b2b2;
  padding-left: 30px;
  line-height: 30px;
}
@media only screen and (max-width: 1022px) {
  .fsummary__top__passenger {
    height: auto;
    border: none;
    width: 100%;
    border-top: 1px dashed #b2b2b2;
    display: flex;
    flex-direction: row;
    padding: 10px 20px 0;
    justify-content: space-between;
  }
}
.fsummary__top__passenger__num-name {
  color: #b2b2b2;
  display: inherit;
  line-height: 22px;
  margin-bottom: 5px;
}
.fsummary__top__passenger__num-name:before {
  padding-right: 5px;
  font-size: 2rem;
}
@media only screen and (max-width: 1022px) {
  .fsummary__top__passenger__num-name {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
}
.fsummary__top__passenger__price {
  font-size: 1.4em;
  color: #000;
  font-weight: bold;
}
.fsummary__top__toggle {
  display: block;
  width: 10%;
}
.fsummary__content-section {
  height: 0;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.35s ease-in-out, opacity 0.3s linear;
}
.fsummary__content-section.expanded {
  display: block;
  height: auto;
  opacity: 1;
  max-height: 3000px;
}
.fsummary__content {
  border-top: 1px dashed #b2b2b2;
  padding: 20px 20px 40px 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin: auto;
  max-width: 850px;
  font-family: basicregular, Arial, sans-serif;
}
@media only screen and (max-width: 1022px) {
  .fsummary__content {
    flex-direction: column;
    padding-left: 30px;
  }
}
.fsummary__content__left {
  width: 60%;
  margin-right: 7%;
}
@media only screen and (max-width: 1022px) {
  .fsummary__content__left {
    margin-right: 0;
    width: 100%;
    padding-right: 20px;
  }
}
.fsummary__content__left__ida-vuelta {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid rgba(0, 92, 153, 0.4);
}
.fsummary__content__left__ida-vuelta:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
}
.fsummary__content__left__ida-vuelta:nth-child(2) .fsummary__content__left__container__block__left__date:before {
  transform: rotate(180deg);
}
.fsummary__content__left__container {
  display: flex;
  flex-direction: column;
}
.fsummary__content__left__container__jump {
  margin: 10px 0;
  background-color: #f1f1f1;
  padding: 4px 10px;
  border-radius: 3px;
  text-align: center;
}
@media only screen and (max-width: 1022px) {
  .fsummary__content__left__container__jump {
    margin-bottom: 15px;
    padding: 4px 5px;
  }
}
.fsummary__content__left__container__block {
  line-height: 25px;
  padding-bottom: 5px;
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
@media only screen and (max-width: 1022px) {
  .fsummary__content__left__container__block {
    flex-direction: column;
    align-items: flex-start;
  }
}
.fsummary__content__left__container__block__left__date {
  align-items: center;
  display: flex;
  color: #0099ff;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  position: relative;
}
.fsummary__content__left__container__block__left__date:before {
  font-size: 30px;
  color: #005c99;
  position: absolute;
  left: -35px;
}
@media only screen and (max-width: 1022px) {
  .fsummary__content__left__container__block__left__date:before {
    font-size: 20px;
    position: relative;
    left: 0;
    margin-right: 10px;
  }
}
.fsummary__content__left__container__block__left__airline {
  display: flex;
  font-size: 1.3rem;
  color: #000;
  line-height: 1;
  margin-bottom: 10px;
  font-family: basicregular, Arial, sans-serif;
  flex-direction: column;
  align-items: flex-start;
}
.fsummary__content__left__container__block__left__airline img {
  margin: 0 3px;
  position: relative;
  top: 2px;
}
.fsummary__content__left__container__block__right {
  min-width: 110px;
}
@media only screen and (max-width: 1022px) {
  .fsummary__content__left__container__block__right {
    margin: 10px 0 5px;
  }
}
.fsummary__content__left__container__block__right__duration {
  background-color: #e5e5e5;
  padding: 4px 10px;
  border-radius: 50px;
  font-size: 0.9em;
  max-width: 170px;
  text-align: center;
}
.fsummary__content__left__container__block__right__duration:before {
  padding-right: 5px;
  color: #7f7f7f;
}
@media only screen and (max-width: 559px) {
  .fsummary__content__left__container__block__right__duration {
    padding: 1px 10px;
  }
}
.fsummary__content__left__container__block__icons, .fsummary__content__left__container__icons {
  justify-content: flex-start;
  display: flex;
  padding: 0 0 5px 0;
}
.fsummary__content__left__container__block__icons i, .fsummary__content__left__container__icons i {
  border: 0;
  min-width: 0;
  min-height: 0;
  width: auto;
  height: auto;
}
.fsummary__content__left__container__block__icons__item, .fsummary__content__left__container__icons__item {
  margin: 0 20px 0 0;
  color: #666;
  font-size: 1.5rem;
  font-family: basicregular, Arial, sans-serif;
}
.fsummary__content__left__container__block__icons__item:before, .fsummary__content__left__container__icons__item:before {
  margin-right: 5px;
  color: #b2b2b2;
}
.fsummary__content__left__container__block__icons__item__text, .fsummary__content__left__container__icons__item__text {
  color: #4c4c4c;
  margin-left: 5px;
  font-family: basicbold, Arial, sans-serif;
  text-transform: capitalize;
}
@media only screen and (max-width: 559px) {
  .fsummary__content__left__container__block__icons {
    flex-direction: column;
    align-items: flex-start;
    line-height: 1.3;
  }
}
.fsummary__content__left__container__icons {
  margin-top: 5px;
  padding-top: 10px;
  border-top: 1px solid #e5e5e5;
}
.fsummary__content__right {
  width: 33%;
  line-height: 1.4;
  font-size: 1.35rem;
}
@media only screen and (max-width: 1022px) {
  .fsummary__content__right {
    width: 100%;
    padding-right: 10px;
    margin-top: 40px;
    font-size: 1.6rem;
  }
}
.fsummary__content__right__container__item strong {
  color: #0099ff;
  font-family: basicregular, Arial, sans-serif;
}
.fsummary__content__right__container__item__title {
  margin: 5px 0;
  font-size: 1.4rem;
  line-height: 1;
}
.fsummary__content__right__container__item__subtitle {
  font-size: 1.3rem;
  color: #7f7f7f;
  line-height: 1;
  margin: 0;
}
.fsummary__content__right__container__item__list {
  padding-left: 16px;
  margin: 5px 0 10px;
  line-height: 1;
}
.fsummary__content__right__container__pasajero {
  display: flex;
  color: #0099ff;
  font-size: 1.5rem;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
}
.fsummary__content__right__container__pasajero span {
  width: 33%;
}
.fsummary__content__right__container__pasajero span:last-child {
  text-align: right;
}
.fsummary__content__right__container__tarifa, .fsummary__content__right__container__tasa, .fsummary__content__right__container__fee, .fsummary__content__right__container__baggage, .fsummary__content__right__container__subtotal, .fsummary__content__right__container__insurance {
  display: flex;
  justify-content: space-between;
}
.fsummary__content__right__container__tarifa span, .fsummary__content__right__container__tasa span, .fsummary__content__right__container__fee span, .fsummary__content__right__container__baggage span, .fsummary__content__right__container__subtotal span, .fsummary__content__right__container__insurance span {
  width: 33%;
  text-align: right;
}
.fsummary__content__right__container__tarifa span:first-child, .fsummary__content__right__container__tasa span:first-child, .fsummary__content__right__container__fee span:first-child, .fsummary__content__right__container__baggage span:first-child, .fsummary__content__right__container__subtotal span:first-child, .fsummary__content__right__container__insurance span:first-child {
  text-align: left;
}
.fsummary__content__right__container__subtotal {
  border-top: 1px dotted #7f7f7f;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;
}
.fsummary__content__right__container__subtotal strong {
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  color: #005c99;
}
.fsummary__content__right__total {
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid;
  padding-top: 20px;
  margin-top: 20px;
  line-height: 2.5rem;
}
.fsummary__content__right__total span {
  font-size: 2.7rem;
  font-family: basicregular, Arial, sans-serif;
  font-weight: bold;
  color: #333333;
}
.fsummary__toggle-container {
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: -20px;
}
.fsummary__toggle-container__label {
  cursor: pointer;
  position: absolute;
  bottom: -30px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 0 0 5px 5px;
  color: #7f7f7f;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
}
.fsummary__toggle-container__label:hover {
  color: #000;
}

._Multidestino #fsummary_summary {
  height: auto;
}
._Multidestino .fsummary__top {
  display: flex;
  flex-wrap: wrap;
}
._Multidestino .fsummary__top__ida-vuelta {
  width: auto;
  padding: 0 1%;
  margin-bottom: 10px;
  border-left: 1px solid #ccc;
}
._Multidestino .fsummary__top__ida-vuelta:first-child {
  border: 0;
}
._Multidestino .fsummary__top__ida-vuelta__icon {
  display: none;
}
@media only screen and (max-width: 559px) {
  ._Multidestino .fsummary__top__ida-vuelta__flights__duration {
    padding: 2px 8px;
  }
}
._Multidestino .fsummary__top__passenger {
  border-top: 1px dashed #b2b2b2;
  border-left: 0;
  min-width: 100%;
  flex-direction: row;
  justify-content: space-between;
  line-height: 1;
  padding: 10px 10px 0;
  max-height: 30px;
}
@media only screen and (max-width: 1022px) {
  ._Multidestino .clock:before {
    display: none;
  }
}
._Multidestino ._size2 .fsummary__top__ida-vuelta {
  width: 50%;
}
._Multidestino ._size3 .fsummary__top__ida-vuelta {
  width: 33%;
}
._Multidestino ._size4 .fsummary__top__ida-vuelta {
  width: 25%;
}
._Multidestino ._size5 .fsummary__top__ida-vuelta {
  width: 20%;
}
._Multidestino ._size6 .fsummary__top__ida-vuelta {
  width: 16.5%;
}
._Multidestino .fsummary__content__left__ida-vuelta:nth-child(2) .fsummary__content__left__container__block__left__date:before {
  transform: rotate(0);
}

sbmn-flights-passengers {
  background: #f1f1f1;
  display: block;
  min-height: 100%;
  padding-bottom: 40px;
}

.passengers {
  position: relative;
  margin: 60px auto;
  max-width: 1023px;
}
@media only screen and (max-width: 1022px) {
  .passengers {
    left: 0;
    padding: 20px 0;
    margin-bottom: 40px;
    margin-top: 10px;
  }
}
.passengers__loading {
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.passengers__loading__backlink.max-width-page, .passengers__loading__backlink.max-width-box {
  display: block;
  max-width: 970px;
  margin: 40px auto;
  text-align: right;
}
.passengers .mat-form-field {
  width: auto;
  margin: 5px 10px;
  flex-grow: 2;
}
.passengers .mat-form-field:nth-of-type(1) {
  margin-left: 0;
}
.passengers .mat-form-field:last-of-type {
  margin-right: 0;
}
.passengers .mat-form-field.mini {
  flex-grow: 1;
  min-width: 65px;
  max-width: 65px;
}
.passengers .mat-form-field.checks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0;
}
.passengers .mat-form-field.checks mat-checkbox {
  margin-bottom: 5px;
  color: #005c99;
}
.passengers .mat-form-field.total {
  margin-left: 0;
  min-width: 100%;
  margin-bottom: 10px;
}
.passengers .mat-form-field.dates {
  max-width: 23%;
}
.passengers .mat-form-field .mat-checkbox-label {
  line-height: 1;
}
@media only screen and (max-width: 1022px) {
  .passengers .mat-form-field {
    width: 100%;
    flex-grow: unset;
    display: block;
    margin: 0;
  }
  .passengers .mat-form-field.mini {
    max-width: unset;
    flex-grow: unset;
    width: 20%;
    margin-right: 1%;
  }
  .passengers .mat-form-field.semi {
    width: 77%;
    flex-grow: 2;
  }
  .passengers .mat-form-field.dates {
    max-width: 100%;
  }
}
@media only screen and (max-width: 559px) {
  .passengers .mat-form-field.mini {
    width: 100%;
  }
}
.passengers .mat-form-field .passengers__section__passenger__data__info {
  padding-bottom: 0;
}
.passengers .unavailable {
  color: transparent !important;
  animation: heightOut 0.3s linear forwards;
}
.passengers .alert_active {
  color: #ff4500;
}
.passengers__specialinfo {
  padding-bottom: 20px;
  line-height: 1.4;
}
@media only screen and (max-width: 1022px) {
  .passengers__specialinfo {
    padding: 0 0 20px 20px;
  }
}
.passengers__specialinfo h2 {
  color: #ff4500;
}
.passengers__section, .mtamain__container__section {
  position: relative;
  margin-bottom: 60px;
}
.passengers__section:before, .mtamain__container__section:before {
  position: absolute;
  width: 32px;
  color: #003366;
  font-size: 3rem;
  left: 5px;
  top: -5px;
  text-align: center;
  padding: 0;
}
.passengers__section.maleta:before, .maleta.mtamain__container__section:before {
  font-size: 2.2rem;
}
.passengers__section .out, .mtamain__container__section .out {
  animation: fadeOutHeight 0.6s linear forwards;
}
.passengers__section .in, .mtamain__container__section .in {
  animation: fadeOutHeight 0.6s linear backwards reverse;
}
.passengers__section__title, .mtamain__container__section__title, .passengers__section__title.h2withbold {
  font-family: special, Arial, sans-serif;
  font-size: 3.5rem;
  color: #262626;
  margin-bottom: 30px;
  margin-left: 50px;
  align-items: center;
  line-height: 0.7;
}
.passengers__section__title__note, .passengers__section__title.h2withbold__note, .h2withbold__note.mtamain__container__section__title {
  font-size: 0.5em;
  color: rgba(89, 46, 151, 0.8);
  max-width: 90%;
}
.passengers__section__title__note:before, .passengers__section__title.h2withbold__note:before, .h2withbold__note.mtamain__container__section__title:before {
  content: " | ";
  margin: 0 10px;
  font-style: normal;
}
@media only screen and (max-width: 1022px) {
  .passengers__section__title__note, .passengers__section__title.h2withbold__note, .h2withbold__note.mtamain__container__section__title {
    display: block;
    padding-top: 10px;
  }
  .passengers__section__title__note:before, .passengers__section__title.h2withbold__note:before, .h2withbold__note.mtamain__container__section__title:before {
    display: none;
  }
}
@media only screen and (max-width: 1022px) {
  .passengers__section__title, .mtamain__container__section__title, .passengers__section__title.h2withbold {
    width: 100%;
  }
}
.passengers__section__passenger {
  position: relative;
  margin: 0 1% 30px;
  background: rgba(255, 255, 255, 0.7);
  padding: 20px 0;
  border-radius: 0 20px 0;
}
@media only screen and (max-width: 1022px) {
  .passengers__section__passenger {
    overflow: hidden;
  }
}
.passengers__section__passenger__title {
  background: #0099ff;
  color: #fff;
  margin: 0 0 15px 2.5%;
  padding: 5px 50px 5px 5px;
  display: inline-block;
}
@media only screen and (max-width: 1022px) {
  .passengers__section__passenger__title {
    position: relative;
    top: -20px;
    margin-left: 0;
    margin-bottom: -20px;
    padding: 15px 50px 10px 2.5%;
    width: 100%;
  }
}
.passengers__section__passenger__title__ok {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #a8d88c;
  font-size: 1.4rem;
  border-radius: 50%;
  border: 2px solid #a8d88c;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.passengers__section__passenger__travelwith {
  margin-left: 10px;
  color: #005c99;
}
@media only screen and (max-width: 1022px) {
  .passengers__section__passenger__travelwith {
    display: block;
    margin-bottom: 20px;
  }
}
.passengers__section__passenger__data {
  display: flex;
  flex-wrap: wrap;
  padding: 0 2.5%;
  overflow: hidden;
}
@media only screen and (max-width: 1022px) {
  .passengers__section__passenger__data {
    padding: 0 5%;
  }
}
.passengers__section__passenger__data__title {
  width: 100%;
  font-family: basicbold, Arial, sans-serif;
  font-size: 2rem;
  padding: 5px 0 2px;
  border-bottom: 1px solid #ccc;
  color: rgba(89, 46, 151, 0.8);
  margin: 0 0 13px 0;
}
.passengers__section__passenger__data__title.-psg-luggage {
  color: #0099ff;
}
.passengers__section__passenger__data__title__note {
  font-family: basicregular, Arial, sans-serif;
  font-size: 0.7em;
  color: rgba(89, 46, 151, 0.8);
}
.passengers__section__passenger__data__title__note:before {
  content: " | ";
  margin: 0 10px;
  font-style: normal;
  font-weight: bold;
}
.passengers__section__passenger__data__info {
  font-size: 1.3rem;
  color: #666;
  margin: 0;
  padding-bottom: 20px;
  transition: color 0.5s linear;
}
@media only screen and (min-width: 1023px) {
  .passengers__section__passenger__data.TsaDocs mat-form-field {
    max-width: 15%;
  }
}
.passengers__section__passenger__baggage {
  position: relative;
  width: 45%;
  margin: 5px 2.5% 5px 0;
}
.passengers__section__passenger__baggage:nth-of-type(2) {
  margin: 5px 2.5% 5px 5%;
}
@media only screen and (max-width: 1022px) {
  .passengers__section__passenger__baggage {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-right: 25px;
  }
  .passengers__section__passenger__baggage:nth-of-type(2) {
    margin: 0;
  }
}
.passengers__section__passenger__baggage .mat-form-field {
  width: 100%;
  margin: 0;
}
.passengers__section__passenger__baggage .ko {
  top: 20px;
  right: -22px;
  font-size: 1.5rem;
}
@media only screen and (max-width: 1022px) {
  .passengers__section__passenger__baggage .ko {
    right: 0;
  }
}
.passengers__section__passenger__baggage .ko:hover {
  color: #ef1c25;
}
.passengers__section__cabin-luggage {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 2.5%;
}
@media only screen and (max-width: 1022px) {
  .passengers__section__cabin-luggage__info {
    width: 100%;
  }
}
.passengers__section__cabin-luggage__info__title {
  margin: 0 0 5px;
  color: #0099ff;
  font-size: 2rem;
}
.passengers__section__cabin-luggage__info__title span {
  color: #a8d88c;
  font-size: 1.6rem;
}
.passengers__section__cabin-luggage .buttonwhite {
  padding: 7px 20px;
  margin-left: 20px;
}
@media only screen and (max-width: 1022px) {
  .passengers__section__cabin-luggage .buttonwhite {
    margin: 20px 0;
    width: 100%;
    padding: 15px 20px;
  }
}
.passengers__section.seguro mat-checkbox, .seguro.mtamain__container__section mat-checkbox, .passengers__section.money mat-checkbox, .money.mtamain__container__section mat-checkbox {
  margin-left: 2.5%;
}
.passengers__section__insurance, .passengers__section__cards {
  overflow: hidden;
  margin-left: 2.5%;
}
.passengers__section__insurance > div, .passengers__section__cards > div {
  color: #005c99;
  margin: 20px 0 10px;
}
.passengers__section__insurance .mat-form-field, .passengers__section__cards .mat-form-field {
  width: 45%;
  margin: 0 2.5%;
}
.passengers__section__insurance strong.Berror, .passengers__section__cards strong.Berror {
  position: relative;
  bottom: auto;
  padding: 0 1% 0 0;
  font-size: 1.5rem;
}
.passengers__section__termslink {
  color: #666;
  font-size: 1.4rem;
  padding-top: 10px;
  display: inline-block;
}
.passengers__section__shoppingterms {
  line-height: 1.4;
  font-size: 1.4rem;
  color: #0099ff;
  text-decoration: underline;
  cursor: pointer;
}
.passengers__section__shoppingterms:hover {
  color: #592e97;
  text-decoration: none;
}
.passengers__section__usainfo {
  line-height: 1.4;
  font-size: 1.4rem;
  padding: 0 2.5% 0 0;
}
.passengers__section__last {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (max-width: 1022px) {
  .passengers__section__last {
    padding: 0 15px;
    flex-direction: column;
  }
}
.passengers__section__last__button {
  padding: 15px 60px;
  margin: 10px;
}
.passengers__section__last__link {
  display: block;
  float: right;
  text-align: right;
  margin: -20px 20px -20px 0;
}
@media only screen and (max-width: 1022px) {
  .passengers__section__last__link {
    margin: 20px 20px 20px 0;
  }
}

sbmn-flights-passengers-cabin-luggage .cssload-loader__container {
  background: transparent;
}
sbmn-flights-passengers-cabin-luggage .h2withbold {
  color: #fff;
}
sbmn-flights-passengers-cabin-luggage .maleta {
  opacity: 0.5;
  margin-right: 20px;
}

.cabinFR__container {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 1022px) {
  .cabinFR__container {
    flex-direction: column;
    align-items: center;
  }
}
.cabinFR__container__option {
  position: relative;
  background: #fff;
  flex-basis: 90%;
  padding: 10px 2%;
  margin: 2% 2% 70px;
  cursor: pointer;
  max-width: 350px;
}
.cabinFR__container__option.selected h3, .cabinFR__container__option:hover h3, .cabinFR__container__option:focus h3 {
  color: #005c99;
}
.cabinFR__container__option.selected ul,
.cabinFR__container__option.selected em, .cabinFR__container__option:hover ul,
.cabinFR__container__option:hover em, .cabinFR__container__option:focus ul,
.cabinFR__container__option:focus em {
  color: #000;
}
.cabinFR__container__option.selected em, .cabinFR__container__option:hover em, .cabinFR__container__option:focus em {
  color: #4c4c4c;
}
.cabinFR__container__option.selected i, .cabinFR__container__option:hover i, .cabinFR__container__option:focus i {
  color: #005c99;
}
.cabinFR__container__option.selected strong, .cabinFR__container__option:hover strong, .cabinFR__container__option:focus strong {
  background: #f9b987;
}
.cabinFR__container__option.selected {
  transform: scale(1.03);
}
.cabinFR__container__option.selected strong {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  display: block;
  display: flex;
  background: #a8d88c;
  color: transparent;
  font-size: 0;
}
.cabinFR__container__option.selected strong:before {
  color: #fff;
  font-size: 2rem;
}
.cabinFR__container__option:nth-of-type(2) .maleta {
  padding-left: 25%;
}
.cabinFR__container__option:nth-of-type(2) .trolley {
  position: absolute;
  top: 80px;
  left: 25%;
}
.cabinFR__container__option i {
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  transition: color 0.1s linear;
}
.cabinFR__container__option .maleta:before {
  font-size: 6rem;
}
.cabinFR__container__option .trolley:before {
  font-size: 10rem;
}
.cabinFR__container__option__selected {
  font-size: 2rem;
  color: #a8d88c;
  display: block;
  margin: -40px auto 20px;
  cursor: auto;
}
.cabinFR__container__option__title {
  font-size: 2rem;
  margin-top: 0;
  font-family: basiclight, Arial, sans-serif;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  transition: color 0.1s linear;
}
.cabinFR__container__option__description {
  position: relative;
  background: #fff;
  flex-basis: 90%;
  padding: 1% 5%;
  margin: 20px 2%;
  font-size: 1.5rem;
  text-align: left;
  color: #7f7f7f;
  transition: color 0.1s linear;
}
.cabinFR__container__option__description__item {
  padding: 5px 0;
}
.cabinFR__container__option__comment {
  font-size: 1.2rem;
  text-align: justify;
  display: block;
  color: #b2b2b2;
  transition: color 0.1s linear;
}
@media only screen and (max-width: 1022px) {
  .cabinFR__container__option__comment {
    padding: 20px 20px 10%;
  }
}
.cabinFR__container__option__price {
  width: 90px;
  height: 90px;
  min-width: 90px;
  min-height: 90px;
  border-radius: 50%;
  display: block;
  background: #999;
  font-size: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: basicbold, Arial, sans-serif;
  letter-spacing: -0.05em;
  color: #fff;
  top: 65px;
  right: 10px;
  position: absolute;
  transition: background 0.1s linear;
}

sbmn-flights-voucher {
  display: block;
  padding: 0 0 40px 0;
}
sbmn-flights-voucher strong {
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
}
sbmn-flights-voucher .brand_header .h1withbold, sbmn-flights-voucher .brand_header .h4withbold, sbmn-flights-voucher .brand_header .h3withbold, sbmn-flights-voucher .brand_header .cms__basicinfo__container__content > span > strong, sbmn-flights-voucher .brand_header .h2withbold {
  color: #666;
}
@media only screen and (max-width: 1022px) {
  sbmn-flights-voucher {
    padding: 0;
  }
}

.voucher {
  margin: 10px auto 40px auto;
  max-width: 1023px;
  min-width: 1023px;
  border: 1px solid #7f7f7f;
  background: #fff;
  border-radius: 0 20px 0;
  font-family: special, Arial, sans-serif;
}
.voucher__header {
  padding: 0;
  border-bottom: 1px solid #000;
}
.voucher__header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}
.voucher__header__container__contimg {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
}
.voucher__header__container__contimg img {
  max-width: 550px;
  max-height: 100px;
  margin: 10px 25px;
}
.voucher__header__container__contimg .h1withbold, .voucher__header__container__contimg .h4withbold, .voucher__header__container__contimg .h3withbold, .voucher__header__container__contimg .cms__basicinfo__container__content > span > strong, .voucher__header__container__contimg .h2withbold {
  line-height: 1;
  padding: 20px;
  text-align: center;
  font-size: 3rem;
  background: #b2b2b2;
  width: 100%;
  min-height: 120px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.voucher__header__container__state-loc {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 17px 20px;
}
.voucher__header__container__state-loc__state {
  background-color: #0099ff;
  font-size: 1.5rem;
  padding: 5px 15px;
  border-radius: 6px;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  line-height: 20px;
  text-align: center;
  width: auto;
  color: #fff;
}
.INI .voucher__header__container__state-loc__state {
  background-color: #ff4500;
}
.voucher__header__container__state-loc__state__text {
  text-transform: uppercase;
  display: block;
}
.voucher__header__container__state-loc__loc {
  color: #0099ff;
  margin-bottom: 0;
  margin-top: 20px;
  text-align: right;
  font-size: 1.5em;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
}
.voucher__header__container__state-loc__subloc {
  color: #0099ff;
  text-align: right;
  margin-top: 2px;
  font-size: 1.3em;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
}
.voucher__header__container__state-loc .localizador:before {
  margin-right: 5px;
  font-size: 12px;
}
.voucher__header__container__warning {
  margin: 20px 0 0;
  font-size: 1.5rem;
  line-height: 1.2;
  color: orange;
  padding-right: 0;
}
.voucher__container {
  padding: 0 40px 50px;
}
.voucher__container__limit {
  color: #ef1c25;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.4rem;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  margin-top: 15px;
}
.voucher__container__passenger {
  margin: 20px 0;
  display: flex;
  position: relative;
}
.voucher__container__passenger__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.voucher__container__passenger__container__title {
  font-size: 2.1em;
  color: #000;
  margin-top: 0;
}
.voucher__container__passenger__container__group {
  display: flex;
  flex-wrap: wrap;
}
.voucher__container__passenger__container__group__title {
  font-size: 1.6rem;
  margin-top: 0;
  margin-bottom: 20px;
}
.voucher__container__passenger__container__group__info {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 20px 10px 0;
  align-items: center;
  min-width: 30%;
  margin: 0 1.5%;
  display: flex;
}
.voucher__container__passenger__container__group__info:before {
  color: #7f7f7f;
  font-size: 40px;
  padding-top: 5px;
}
.voucher__container__passenger__container__group__info__container {
  line-height: 1.5;
  margin-left: 11px;
  display: flex;
  flex-direction: column;
  font-size: 1.5em;
}
.voucher__container__passenger__container__group__info__container__name {
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  line-height: 1;
}
.voucher__container__passenger__container__group__info__container__year, .voucher__container__passenger__container__group__info__container__state {
  color: #7f7f7f;
}
.voucher__container__flights {
  padding-bottom: 10px;
  position: relative;
}
.voucher__container__flights__container__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.voucher__container__flights__container__info__title {
  font-size: 2.1em;
  color: #000;
  margin: 0;
}
.voucher__container__flights__container__info__company {
  margin: 0;
  color: #7f7f7f;
  display: flex;
  align-items: center;
  font-size: 1.5em;
}
.voucher__container__flights__container__info__company__name {
  margin-right: 15px;
}
.voucher__container__flights__container__content:nth-child(odd) .avion:before {
  transform: rotate(225deg);
}
.voucher__container__flights__container__content__info {
  justify-content: space-between;
  background-color: #0099ff;
  padding: 10px 15px;
  display: flex;
  align-items: center;
}
.voucher__container__flights__container__content__info__left {
  line-height: 20px;
  text-transform: uppercase;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  color: #fff;
}
.voucher__container__flights__container__content__info__left__title {
  margin: 0;
}
.voucher__container__flights__container__content__info__left__date {
  font-size: 1.6rem;
}
.voucher__container__flights__container__content__info__right__duration {
  font-size: 1.5em;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 100px;
  color: #4c4c4c;
  display: flex;
  align-items: center;
}
.voucher__container__flights__container__content__info__right__duration:before {
  font-size: 20px;
  margin-right: 10px;
}
.voucher__container__flights__container__content__flight__info {
  width: 95%;
  margin: 10px;
  display: flex;
  font-size: 1.6rem;
}
.voucher__container__flights__container__content__flight__info__left {
  width: 70%;
  display: flex;
}
.voucher__container__flights__container__content__flight__info__left:before {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  display: block;
  color: #b2b2b2;
  font-size: 30px;
  border: 1px solid #b2b2b2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  position: absolute;
  transform: rotate(45deg);
}
.voucher__container__flights__container__content__flight__info__left__text {
  width: 100%;
  padding-left: 70px;
  padding-right: 20px;
}
.voucher__container__flights__container__content__flight__info__left__text__title {
  margin: 10px 0 3px;
  font-size: 2rem;
  color: #b2b2b2;
}
.voucher__container__flights__container__content__flight__info__left__text__dates {
  color: #333;
  margin-bottom: 5px;
}
.voucher__container__flights__container__content__flight__info__left__text__from-to {
  margin: 10px 0;
  line-height: 21px;
}
.voucher__container__flights__container__content__flight__info__right {
  width: 30%;
  margin-top: 10px;
  line-height: 1.3;
  font-size: 1.5rem;
  padding-left: 3%;
}
.voucher__container__flights__container__content__flight__stops {
  position: relative;
  font-size: 1.6rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px 20px;
}
.voucher__container__flights__container__content__flight__stops:before, .voucher__container__flights__container__content__flight__stops:after {
  content: "";
  display: inline-block;
  width: 25%;
  height: 1px;
  border-top: 2px dotted #b2b2b2;
  top: 7px;
  left: 0;
}
.voucher__container__flights__container__content__flight__stops__container {
  display: flex;
  background-color: white;
  z-index: 2;
  padding: 0 30px;
}
.voucher__container__flights__container__content__flight__stops__container__logo:before {
  border: 1px solid black;
  border-radius: 100%;
  padding: 4px;
  background-color: #003366;
  margin-right: 10px;
}
.voucher__container__flights__container__content__flight__stops__container__hours {
  margin-left: 5px;
  min-width: 70px;
}
.voucher__container__fares {
  margin: 40px 0 50px;
  height: auto;
  display: flex;
  position: relative;
}
.voucher__container__fares__container {
  width: 100%;
}
.voucher__container__fares__container__title {
  font-size: 2.1em;
  color: #000;
  margin-top: 0;
}
.voucher__container__fares__container__table {
  font-size: 1.5em;
  width: 100%;
  text-align: center;
  line-height: 25px;
  table-layout: fixed;
}
.voucher__container__fares__container__table__head {
  text-transform: uppercase;
  color: #7f7f7f;
}
.voucher__container__fares__container__table__head th {
  font-weight: normal;
}
.voucher__container__fares__container__table__body {
  line-height: 30px;
}
.voucher__container__fares__container__table__body td {
  border-top: 1px solid #ccc;
}
.voucher__container__fares__container__table__body__passenger {
  text-align: left;
  line-height: 1.1;
}
.voucher__container__fares__container__table__body__tot-cell {
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
}
.voucher__container__fares__container__table__body__total td {
  text-align: right !important;
}
.voucher__container__fares__container__table__body__total__text {
  padding-right: 11px;
  padding-top: 11px;
}
.voucher__container__fares__container__table__body__total__text__price {
  color: #005c99;
  padding: 5px 15px;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  margin-left: 10px;
  font-size: 3rem;
}
.voucher__container__fares__container__table__body__extra td.extra {
  text-align: right;
  font-size: 1.3rem;
  font-weight: normal;
  color: #333;
  padding-right: 5%;
}
.voucher__container__fares__container__table__body__extra td.extra strong {
  color: #000;
  font-size: 1.5rem;
}
.voucher__container__reco, .voucher__container__important, .voucher__container__info {
  margin: 30px 0;
}
.voucher__container__reco__title, .voucher__container__important__title, .voucher__container__info__title {
  border-bottom: 1px dashed #999;
  padding-bottom: 2px;
}
.voucher__container__reco__text, .voucher__container__important__text, .voucher__container__info__text {
  font-size: 1.5em;
  color: #7f7f7f;
  line-height: 22px;
}
.voucher__container__reco__text ul, .voucher__container__important__text ul, .voucher__container__info__text ul {
  padding-left: 15px;
}

.voucher__nav {
  margin: 10px auto 0;
  max-width: 1023px;
  display: flex;
  justify-content: flex-end;
}
.voucher__nav .pdf {
  height: 60px;
}
.voucher__nav .insurance_icon {
  position: relative;
  top: 5px;
  justify-content: center;
}
@media only screen and (max-width: 1022px) {
  .voucher__nav .insurance_icon {
    top: 0;
  }
}

.voucher__sendBondForm {
  display: flex;
  max-width: 560px;
}
.voucher__sendBondForm mat-form-field {
  margin-right: 1rem;
}
.voucher__sendBondForm mat-form-field mat-hint {
  color: #0099ff !important;
  font-size: large !important;
}
.voucher__sendBondForm button {
  padding: 0px 10px;
  font-size: xx-large;
  background-color: transparent !important;
  color: #0099ff;
  transition: none !important;
  border-width: 0px !important;
}
.voucher__sendBondForm button[disabled] {
  color: #7f7f7f;
}

.responsive .voucher {
  min-width: 0;
}
@media only screen and (max-width: 1022px) {
  .responsive .voucher {
    margin-top: 0;
    margin-bottom: 0;
    border: none;
  }
  .responsive .voucher__header__container {
    margin: 0;
    flex-direction: column;
    align-items: center;
  }
  .responsive .voucher__header__container__contimg {
    justify-content: center;
  }
  .responsive .voucher__header__container__image {
    max-width: 90%;
  }
  .responsive .voucher__header__container__state-loc {
    align-items: center;
  }
  .responsive .voucher__header__container__state-loc__state {
    font-size: 1.3rem;
    max-width: 95%;
  }
  .responsive .voucher__container {
    padding: 10px 10px 10px 0;
  }
  .responsive .voucher__container__limit__text {
    line-height: 1;
  }
}
@media only screen and (max-width: 1022px) and (max-width: 1022px) {
  .responsive .voucher__container__limit__text {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 1022px) {
  .responsive .voucher__container__passenger {
    margin: 20px 0 35px;
  }
  .responsive .voucher__container__passenger:before {
    width: 10px;
  }
  .responsive .voucher__container__passenger__container {
    margin-left: 20px;
    padding: 10px 0px;
    min-width: calc(100% - 20px);
  }
  .responsive .voucher__container__passenger__container__title {
    font-size: 1.8em;
  }
  .responsive .voucher__container__passenger__container__group__info {
    min-width: 100%;
    margin-bottom: 15px;
  }
  .responsive .voucher__container__passenger__container__group__info:last-child {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 1022px) {
  .responsive .voucher__container__flights:before {
    width: 10px;
  }
  .responsive .voucher__container__flights__container {
    padding: 10px 0px;
    margin-left: 20px;
    margin-bottom: -30px;
  }
  .responsive .voucher__container__flights__container__info {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  .responsive .voucher__container__flights__container__info__title {
    font-size: 1.8em;
  }
  .responsive .voucher__container__flights__container__info__company {
    width: 100%;
    justify-content: space-between;
  }
  .responsive .voucher__container__flights__container__info__company__logo {
    width: 38px;
  }
  .responsive .voucher__container__flights__container__content__info {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
  }
  .responsive .voucher__container__flights__container__content__info__left {
    margin-bottom: 15px;
    font-size: 0.9em;
  }
  .responsive .voucher__container__flights__container__content__info__left__title {
    margin-bottom: 5px;
  }
  .responsive .voucher__container__flights__container__content__flight__info {
    width: 100%;
    flex-direction: column;
    margin-left: 0;
    margin-bottom: 0;
  }
  .responsive .voucher__container__flights__container__content__flight__info__left, .responsive .voucher__container__flights__container__content__flight__info__right {
    width: 100%;
  }
  .responsive .voucher__container__flights__container__content__flight__info__left__text, .responsive .voucher__container__flights__container__content__flight__info__right__text {
    padding-left: 55px;
  }
  .responsive .voucher__container__flights__container__content__flight__info__right {
    padding-left: 55px;
    margin-top: -5px;
  }
  .responsive .voucher__container__flights__container__content__flight__info__right div {
    margin-bottom: 5px;
  }
  .responsive .voucher__container__flights__container__content__flight__info__left:before {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    font-size: 20px;
  }
  .responsive .voucher__container__flights__container__content__flight__info__left__text__title {
    margin: 10px 0 10px;
  }
  .responsive .voucher__container__flights__container__content__flight__info__left__text__from-to div:first-child {
    margin-bottom: 5px;
  }
  .responsive .voucher__container__flights__container__content__flight__stops {
    margin: 0;
  }
  .responsive .voucher__container__flights__container__content__flight__stops:before, .responsive .voucher__container__flights__container__content__flight__stops:after {
    display: none;
  }
  .responsive .voucher__container__flights__container__content__flight__stops__container {
    align-items: center;
    justify-content: center;
    border-top: 1px dotted #b2b2b2;
    border-bottom: 1px dotted #b2b2b2;
    padding: 20px 0;
    margin: 0 25px 20px;
    width: 90%;
  }
  .responsive .voucher__container__flights__container__content__flight__stops__container__logo {
    flex-basis: 4%;
    height: 17px;
  }
  .responsive .voucher__container__flights__container__content__flight__stops__container span {
    flex-basis: 60%;
  }
}
@media only screen and (max-width: 1022px) {
  .responsive .voucher__container__fares:before {
    width: 10px;
  }
  .responsive .voucher__container__fares__container {
    margin-left: 20px;
    padding: 10px 0;
  }
  .responsive .voucher__container__fares__container__title {
    font-size: 1.8em;
  }
  .responsive .voucher__container__fares__container__table__body tr:nth-of-type(even) {
    background: #f1f1f1;
  }
  .responsive .voucher__container__fares__container__table__body tr:last-child {
    background: transparent;
  }
  .responsive .voucher__container__fares__container__table__body tr td:last-child {
    border: 0;
  }
  .responsive .voucher__container__fares__container__table__body td {
    border-top: 0;
  }
}
@media only screen and (max-width: 1022px) {
  .responsive .voucher__container__reco, .responsive .voucher__container__important, .responsive .voucher__container__info {
    padding-left: 20px;
  }
  .responsive .voucher__container__reco__title, .responsive .voucher__container__important__title, .responsive .voucher__container__info__title {
    font-size: 1.8rem;
  }
  .responsive .voucher__container__reco__text, .responsive .voucher__container__important__text, .responsive .voucher__container__info__text {
    font-size: 1.6rem;
  }
}

sbmn-my-bookings .table_responsive {
  border-collapse: unset;
  border-spacing: 0 2px;
}
sbmn-my-bookings .table_responsive th.mth {
  width: 90px;
  position: relative;
}
sbmn-my-bookings .table_responsive .split {
  border: 0;
  border-radius: 50%;
  display: flex;
  width: 3rem;
  height: 3rem;
  justify-content: center;
  align-items: center;
  background: #fff;
}
sbmn-my-bookings .table_responsive .split:before {
  font-size: 2.5rem;
  margin-left: 1px;
  color: rgba(0, 92, 153, 0.5);
}
sbmn-my-bookings .table_responsive tr:hover .split:before {
  color: #005c99;
}
@media only screen and (min-width: 1023px) {
  sbmn-my-bookings .table_responsive .msgbullet,
  sbmn-my-bookings .table_responsive .split {
    margin: auto;
  }
}
sbmn-my-bookings .table_responsive .ok {
  color: #a8d88c;
}

.mybookings__header__icon {
  position: absolute;
  margin: 12px 0 0 22px;
  padding-left: 60px;
  left: 2px;
  cursor: pointer;
  color: black;
  text-transform: uppercase;
  font-size: 1.4rem;
  width: 100px;
}
.mybookings__header__icon:before {
  left: 0;
  position: absolute;
  font-size: 2rem;
  padding: 8px;
  border: 2px solid black;
  border-radius: 50%;
}
@media only screen and (max-width: 1022px) {
  .mybookings__header__icon:before {
    font-size: 1.2rem;
  }
}
.mybookings__header__icon:hover {
  color: #003366;
}
.mybookings__header__icon:hover:before {
  border: 2px solid #003366;
}

#mybookings {
  display: flex;
  position: relative;
  min-height: 75vh;
}
#mybookings > div {
  margin: 0 auto;
}

.mybookings__container {
  width: 100%;
  position: relative;
  min-height: 400px;
}
.mybookings__container__nobookings {
  text-align: center;
  width: 100%;
  font-size: 2.5rem;
  color: #ff4500;
}

.mybookings__filters, .mymessages__filters, .mtausers__filters, .mtabalance__filters {
  opacity: 0;
  transition: margin-left 0.4s linear;
  background: #f1f1f1;
  padding: 80px 30px 0 30px;
  position: absolute;
  min-width: 340px;
  margin-left: -340px;
  z-index: 1;
  box-shadow: 19px 5px 13px -16px rgba(0, 0, 0, 0.7);
  height: 100%;
}
@media only screen and (max-width: 1022px) {
  .mybookings__filters, .mymessages__filters, .mtausers__filters, .mtabalance__filters {
    min-width: 100%;
    margin-left: -100%;
  }
}
.mybookings__filters.show, .show.mymessages__filters, .show.mtausers__filters, .show.mtabalance__filters {
  opacity: 1;
  margin-left: 0;
}

.mybookings__legend {
  text-align: center;
  padding: 20px 10px 25px;
  width: 96%;
  margin: 0 auto;
}
@media only screen and (max-width: 1022px) {
  .mybookings__legend {
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px 15px 25px;
  }
}
.mybookings__legend strong {
  height: 30px;
  width: 2px;
  background-color: #4c4c4c;
  opacity: 0.5;
  border-radius: 100px;
}
.mybookings__legend span {
  position: relative;
  cursor: pointer;
  color: #4c4c4c;
  font-size: 1.5rem;
  margin: 5px 10px 5px 5px;
  display: inline-block;
  padding: 3px 10px 3px 10px;
  line-height: 1.2;
  border-bottom: 3px solid;
  transition: all 0.2s linear;
  min-width: 115px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.6;
}
@media only screen and (max-width: 1022px) {
  .mybookings__legend span {
    min-width: 45%;
    margin: 10px 10px 10px 5px;
  }
}
.mybookings__legend span:before {
  position: absolute;
  right: 1px;
  bottom: 7px;
  font-size: 1rem;
  transition: opacity 0.2s linear;
  opacity: 0;
  color: #fff;
}
.mybookings__legend span:hover, .mybookings__legend span.okselected {
  padding-left: 2px;
  padding-right: 18px;
  opacity: 1;
}
.mybookings__legend span:hover:before, .mybookings__legend span.okselected:before {
  opacity: 1;
}
@media only screen and (max-width: 1022px) {
  .mybookings__legend strong {
    display: none;
  }
}

tr.is_tr.__INI .document:before {
  background: #ff4500;
}
@media only screen and (max-width: 1022px) {
  tr.is_tr.__INI {
    border-left: 10px solid #ff4500 !important;
  }
}
tr.is_tr.__INI:hover {
  background-color: rgba(255, 69, 0, 0.2);
}
tr.is_tr.__RES .document:before {
  background: #7f7f7f;
}
@media only screen and (max-width: 1022px) {
  tr.is_tr.__RES {
    border-left: 10px solid #7f7f7f !important;
  }
}
tr.is_tr.__RES:hover {
  background-color: rgba(127, 127, 127, 0.2);
}
tr.is_tr.__RLE .document:before {
  background: #66c2ff;
}
@media only screen and (max-width: 1022px) {
  tr.is_tr.__RLE {
    border-left: 10px solid #66c2ff !important;
  }
}
tr.is_tr.__RLE:hover {
  background-color: rgba(102, 194, 255, 0.2);
}
tr.is_tr.__BEM .document:before {
  background: #6ebd41;
}
@media only screen and (max-width: 1022px) {
  tr.is_tr.__BEM {
    border-left: 10px solid #6ebd41;
  }
}
tr.is_tr.__BEM:hover {
  background-color: rgba(110, 189, 65, 0.2);
}
tr.is_tr.__BCN .document:before {
  background: #9a0b11;
}
@media only screen and (max-width: 1022px) {
  tr.is_tr.__BCN {
    border-left: 10px solid #9a0b11;
  }
}
tr.is_tr.__BCN:hover {
  background-color: rgba(154, 11, 17, 0.2);
}
tr.is_tr.__CAN .document:before {
  background: #ef1c25;
}
@media only screen and (max-width: 1022px) {
  tr.is_tr.__CAN {
    border-left: 10px solid #ef1c25;
  }
}
tr.is_tr.__CAN:hover {
  background-color: rgba(239, 28, 37, 0.2);
}
tr.is_tr.__LCP .document:before {
  background: #003366;
}
@media only screen and (max-width: 1022px) {
  tr.is_tr.__LCP {
    border-left: 10px solid #003366;
  }
}
tr.is_tr.__LCP:hover {
  background-color: rgba(0, 51, 102, 0.2);
}
tr.is_tr.__LCE .document:before {
  background: #427127;
}
@media only screen and (max-width: 1022px) {
  tr.is_tr.__LCE {
    border-left: 10px solid #427127;
  }
}
tr.is_tr.__LCE:hover {
  background-color: rgba(66, 113, 39, 0.2);
}
tr.is_tr.__LCC .document:before {
  background: #f67b81;
}
@media only screen and (max-width: 1022px) {
  tr.is_tr.__LCC {
    border-left: 10px solid #f67b81;
  }
}
tr.is_tr.__LCC:hover {
  background-color: rgba(246, 123, 129, 0.2);
}

.is_tr .document {
  position: relative;
  padding-left: 12px;
  border-right: 1px solid transparent;
}
.is_tr .document span {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  font-size: 1.5rem;
  background: #fff;
  font-weight: bold;
}
@media only screen and (max-width: 1022px) {
  .is_tr .document span.copied {
    width: 100%;
    margin: 0;
  }
}
.is_tr .document:before {
  color: transparent;
  background: #4c4c4c;
  font-size: 2.8rem;
  transition: width 0.2s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 10px);
  left: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.is_tr .document:hover {
  line-height: 1rem;
}
.is_tr .document:hover .copied {
  opacity: 1;
}
@media only screen and (max-width: 1022px) {
  .is_tr .document:hover .copied {
    opacity: 0.6;
  }
}
.is_tr .document:hover:before {
  color: #fff;
  width: 25px;
}
.is_tr .document.noloc:hover {
  cursor: default;
}
.is_tr .document.noloc:hover:before {
  color: transparent;
}
@media only screen and (max-width: 1022px) {
  .is_tr .document {
    line-height: 1;
    min-height: 50px;
    border-bottom: 1px solid #7f7f7f !important;
    font-size: 2rem;
    font-weight: bold;
  }
  .is_tr .document:before {
    left: auto;
    right: 10px;
    justify-content: flex-end;
    background: transparent !important;
  }
  .is_tr .document:hover {
    line-height: 1;
  }
  .is_tr .document:hover:before {
    content: "\e90b";
    background: transparent !important;
    color: #4c4c4c;
  }
}

span.ok:hover:before, span.okselected:before {
  opacity: 1;
}
span.cod-estado__RES {
  border-color: #7f7f7f;
}
span.cod-estado__RES.ok:hover:before, span.cod-estado__RES.okselected:before {
  color: #7f7f7f;
}
span.cod-estado__RLE {
  border-color: #66c2ff;
}
span.cod-estado__RLE.ok:hover:before, span.cod-estado__RLE.okselected:before {
  color: #66c2ff;
}
span.cod-estado__BEM {
  border-color: #6ebd41;
}
span.cod-estado__BEM.ok:hover:before, span.cod-estado__BEM.okselected:before {
  color: #6ebd41;
}
span.cod-estado__BCN {
  border-color: #9a0b11;
}
span.cod-estado__BCN.ok:hover:before, span.cod-estado__BCN.okselected:before {
  color: #9a0b11;
}
span.cod-estado__CAN {
  border-color: #ef1c25;
}
span.cod-estado__CAN.ok:hover:before, span.cod-estado__CAN.okselected:before {
  color: #ef1c25;
}
span.cod-estado__LCP {
  border-color: #0099ff;
}
span.cod-estado__LCP.ok:hover:before, span.cod-estado__LCP.okselected:before {
  color: #0099ff;
}
span.cod-estado__LCE {
  border-color: #427127;
}
span.cod-estado__LCE.ok:hover:before, span.cod-estado__LCE.okselected:before {
  color: #427127;
}
span.cod-estado__LCC {
  border-color: #f67b81;
}
span.cod-estado__LCC.ok:hover:before, span.cod-estado__LCC.okselected:before {
  color: #f67b81;
}
span.cod-estado__msg {
  border-color: #f9b987;
}
span.cod-estado__msg.ok:hover:before, span.cod-estado__msg.okselected:before {
  color: #f9b987;
}
span.cod-estado__ {
  border-color: #4c4c4c;
}
span.cod-estado__.ok:hover:before, span.cod-estado__.okselected:before {
  color: #4c4c4c;
}

sbmn-my-bookings-manager + #mybookings {
  max-height: calc(100vh - 315px);
  overflow: hidden;
  min-height: 0;
}
sbmn-my-bookings-manager .stopper-modal__scroll__container {
  text-align: left;
  color: #000;
  top: 0;
}
sbmn-my-bookings-manager .stopper-modal__scroll__container__title {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  flex-wrap: wrap;
  font-size: 3rem;
}
sbmn-my-bookings-manager .stopper-modal__scroll__container__title span {
  width: 100%;
  text-align: center;
}
sbmn-my-bookings-manager .stopper-modal__scroll__container__title em {
  color: #0059b3;
  font-size: 2rem;
  display: block;
}
sbmn-my-bookings-manager .stopper-modal__scroll__container__title em.cancelled {
  color: #ff4500;
}
sbmn-my-bookings-manager .stopper-modal__scroll__container__title em.booked {
  color: #6ebd41;
}
sbmn-my-bookings-manager .stopper-modal__scroll__container__title em.book-error {
  color: #f67b81;
  font-weight: bold;
  font-size: 1.5rem;
}
sbmn-my-bookings-manager .stopper-modal__scroll__container__title .split {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
}
sbmn-my-bookings-manager .stopper-modal__scroll__container__title .split:before {
  font-size: 3rem;
}
sbmn-my-bookings-manager .stopper-modal__scroll__container__content {
  margin-top: 20px;
  display: flex;
}
sbmn-my-bookings-manager .stopper-modal__scroll__container__content.white_modal {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 300px;
  background: #f1f1f1;
}
sbmn-my-bookings-manager .stopper-modal__scroll__container__content > div {
  position: relative;
  width: 100%;
  padding: 5% 0;
  justify-content: center;
  display: flex;
}
sbmn-my-bookings-manager .loader_abs, sbmn-my-bookings-manager .login__loading {
  background: rgba(241, 241, 241, 0.7);
}
sbmn-my-bookings-manager .cssload-loader__container {
  position: relative;
  background: transparent;
}
sbmn-my-bookings-manager sbmn-new-search {
  margin: 10px 10px 0 0;
}
@media only screen and (max-width: 1022px) {
  sbmn-my-bookings-manager sbmn-flights-voucher {
    max-width: 100%;
  }
}
sbmn-my-bookings-manager sbmn-flights-voucher .brand_header {
  background: transparent;
  border-top: 1px solid #0099ff;
  position: absolute;
  width: 100%;
  text-align: left;
}
sbmn-my-bookings-manager sbmn-flights-voucher:after {
  display: none;
}
sbmn-my-bookings-manager .bmanager__nav {
  margin: 20px auto 0 auto;
  max-width: 1023px;
  display: flex;
  justify-content: space-between;
  min-height: 80px;
}
@media only screen and (max-width: 1022px) {
  sbmn-my-bookings-manager .bmanager__nav {
    flex-direction: column;
    min-height: 0;
    max-width: 100%;
  }
}
sbmn-my-bookings-manager .bmanager__nav__section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 2;
}
sbmn-my-bookings-manager .bmanager__nav__section:last-child {
  justify-content: flex-end;
}
@media only screen and (max-width: 1022px) {
  sbmn-my-bookings-manager .bmanager__nav__section {
    justify-content: center;
    flex-wrap: wrap;
  }
}
sbmn-my-bookings-manager .bmanager__nav__section__item {
  position: relative;
  cursor: pointer;
}
sbmn-my-bookings-manager .bmanager__nav__section__item.warning:before {
  position: absolute;
  top: 2px;
  right: 2px;
  color: #ff4500;
}
sbmn-my-bookings-manager .bmanager__nav__section__item .refund:before {
  color: #99d179;
}
sbmn-my-bookings-manager .bmanager__nav__section__item .info3:before,
sbmn-my-bookings-manager .bmanager__nav__section__item .agency_data:before,
sbmn-my-bookings-manager .bmanager__nav__section__item .msg-flights:before,
sbmn-my-bookings-manager .bmanager__nav__section__item .billete:before,
sbmn-my-bookings-manager .bmanager__nav__section__item .agency_data:before,
sbmn-my-bookings-manager .bmanager__nav__section__item .refund:before {
  font-size: 3rem;
}
sbmn-my-bookings-manager .bmanager__cancel {
  text-align: center;
}
sbmn-my-bookings-manager .bmanager__cancel__title {
  color: #0099ff;
}
sbmn-my-bookings-manager .bmanager__cancel__nobutton {
  margin: 5px 10px 20px auto;
}
sbmn-my-bookings-manager .bmanager__cancel__button {
  margin: 5px auto 20px 10px;
}

sbmn-my-bookings-status {
  display: block;
  min-width: 100%;
}
sbmn-my-bookings-status .drop-container {
  margin: 0;
}
sbmn-my-bookings-status .Berror {
  position: relative;
  bottom: 0;
  font-size: 1.7rem;
  margin: 0 0 20px;
}
sbmn-my-bookings-status sbmn-info {
  padding-top: 5%;
  padding-bottom: 5%;
}

.bstatus {
  display: flex;
}
@media only screen and (max-width: 1022px) {
  .bstatus {
    flex-direction: column;
  }
}
.bstatus__form {
  min-width: 54%;
  max-width: 54%;
}
@media only screen and (max-width: 1022px) {
  .bstatus__form {
    max-width: 100%;
  }
}
.bstatus__form__field {
  margin: 0 0 30px;
}
.bstatus__form__field__label {
  padding: 0 0 10px;
  display: inline-block;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  color: #0099ff;
}
.bstatus__form__field__textarea {
  width: 100%;
  height: 100px;
}
.bstatus__form__field:last-child {
  text-align: right;
}
.bstatus__form__price-container {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
}
.bstatus__form__price-container h3 {
  margin-bottom: 10px;
}
.bstatus__form__price-container__updated {
  display: inline-block;
  margin-bottom: 20px;
  font-size: 1.6rem;
}
.bstatus__form__price-container__updated span {
  display: block;
  font-size: 1.5rem;
  color: #ff4500;
}
.bstatus__form__price-container__button {
  margin: 5px 5px 5px 0;
  padding: 10px;
}
.bstatus__info {
  width: 40%;
  padding-left: 5%;
  margin-left: 5%;
  border-left: 1px solid #ccc;
}
@media only screen and (max-width: 1022px) {
  .bstatus__info {
    width: 100%;
    padding-left: 0;
    margin-left: 0;
    border-left: 0;
  }
}
.bstatus__info__container {
  color: #7f7f7f;
}
.bstatus__info__container.highlighted {
  color: #9a0b11;
}
.bstatus__info__container__title, .bstatus__info__container__desc {
  transition: color 0.2s linear;
}
.bstatus__revert {
  text-align: center;
  margin: auto;
}
.bstatus__revert__title {
  color: #0099ff;
}
.bstatus__revert__button {
  margin: 5px auto 20px;
}
.bstatus__tpv {
  font-size: 1.8rem;
  text-align: center;
}
.bstatus__tpv:before {
  color: #0099ff;
  font-size: 3rem;
  margin: 10px auto;
  display: block;
}
.bstatus__tpv__button {
  display: block;
  margin: 20px auto;
}
.bstatus__shoppingterms {
  line-height: 1.4;
  font-size: 1.4rem;
  color: #0099ff;
  text-decoration: underline;
  cursor: pointer;
}
.bstatus__shoppingterms:hover {
  color: #592e97;
  text-decoration: none;
}

sbmn-my-bookings-tickets {
  min-width: 100%;
  display: block;
}
sbmn-my-bookings-tickets .basic__table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}
sbmn-my-bookings-tickets .basic__table__head {
  text-align: center;
}
sbmn-my-bookings-tickets .basic__table__head th:first-child {
  width: 40%;
}
sbmn-my-bookings-tickets .basic__table__head th:last-child {
  padding-right: 10px;
}
sbmn-my-bookings-tickets .basic__table__body tr,
sbmn-my-bookings-tickets .basic__table__body tr:nth-child(n) {
  cursor: auto;
  border-left: 10px solid #6ebd41;
}
sbmn-my-bookings-tickets .basic__table__body tr.inactive,
sbmn-my-bookings-tickets .basic__table__body tr:nth-child(n).inactive {
  border-left: 10px solid #9a0b11;
}
sbmn-my-bookings-tickets .basic__table__body td:last-child {
  padding-right: 10px;
}
sbmn-my-bookings-tickets .basic__table__body td:last-child:before {
  display: none;
}

.bmanager__tickets {
  position: relative;
}
.bmanager__tickets__cancel {
  text-align: center;
}
.bmanager__tickets__cancel__title {
  color: #0099ff;
}
.bmanager__tickets__cancel__nobutton {
  margin: 5px 10px 20px auto;
}
.bmanager__tickets__cancel__button {
  margin: 5px auto 20px 10px;
}

sbmn-my-bookings-info {
  display: block;
  width: 100%;
}
sbmn-my-bookings-info .bmanager__info__container__route {
  border-width: 0 0 0 5px;
}

.bmanager__info__container {
  display: flex;
  align-items: flex-start;
  margin: 20px 0 30px 0;
  padding-bottom: 30px;
  border-bottom: 1px solid #ccc;
}
.bmanager__info__container:nth-of-type(even) .ida:before, .bmanager__info__container:nth-of-type(even) .vuelta:before {
  transform: rotate(180deg);
}
.bmanager__info__container:last-child {
  margin-bottom: 0;
}
@media only screen and (max-width: 1022px) {
  .bmanager__info__container {
    flex-direction: column;
  }
}
.bmanager__info__container__title {
  text-align: center;
  font-family: basicregular, Arial, sans-serif;
  min-width: 80px;
}
.bmanager__info__container__title:before {
  font-size: 40px;
  color: #b2b2b2;
  border: 1px solid #b2b2b2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 0 auto 10px;
}
.bmanager__info__container__route {
  flex-grow: 2;
  margin-left: 20px;
  padding-left: 20px;
}
@media only screen and (max-width: 1022px) {
  .bmanager__info__container__route {
    margin-left: 0;
    padding-right: 20px;
    min-width: 100%;
  }
}
.bmanager__info__container__route__flight {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}
.bmanager__info__container__route__flight__stop {
  background-color: #f1f1f1;
  padding: 3px 10px;
  border-radius: 3px;
  text-align: center;
  margin: 11px 0;
  min-width: 100%;
}
.bmanager__info__container__route__flight__info {
  flex-grow: 2;
}
.bmanager__info__container__route__flight__info > div {
  margin: 5px 0;
}
.bmanager__info__container__route__flight__info__header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #0099ff;
  color: #0099ff;
  font-family: basicregular, Arial, sans-serif;
}
@media only screen and (max-width: 1022px) {
  .bmanager__info__container__route__flight__info__header {
    flex-direction: column;
  }
}
.bmanager__info__container__route__flight__info__date {
  font-family: basicregular, Arial, sans-serif;
  color: #005c99;
}
.bmanager__info__container__route__flight__info__desc {
  font-size: 1.7rem;
}
.bmanager__info__container__route__flight__info__desc strong {
  margin-right: 10px;
}
.bmanager__info__container__route__flight__extrainfo {
  margin: 0 0 0 20px;
  display: flex;
  justify-content: flex-end;
  text-align: center;
  flex-direction: column;
}
@media only screen and (max-width: 1022px) {
  .bmanager__info__container__route__flight__extrainfo {
    margin: 0;
    width: 100%;
  }
}
.bmanager__info__container__route__flight__extrainfo__fare {
  background-color: #003366;
  text-transform: uppercase;
  color: #000;
  padding: 1px 5px;
  font-size: 0.7em;
  border-radius: 10px;
}
@media only screen and (max-width: 1022px) {
  .bmanager__info__container__route__flight__extrainfo__fare {
    padding: 7px 5px;
  }
}
.bmanager__info__container__route__flight__extrainfo__duration {
  background-color: #e5e5e5;
  padding: 8px;
  border-radius: 50px;
  font-size: 0.9em;
  margin: 10px 0;
}
.bmanager__info__container__route__flight__extrainfo__duration strong {
  margin-right: 10px;
}
.bmanager__info__container__route__flight__extrainfo__duration:before {
  padding-right: 5px;
  color: #7f7f7f;
}

sbmn-my-bookings-passengers {
  display: block;
  min-width: 105%;
}
sbmn-my-bookings-passengers .h3withbold, sbmn-my-bookings-passengers .cms__basicinfo__container__content > span > strong {
  font-size: 2rem;
  padding: 0 30px 15px 2.5%;
  margin: 0;
  background: transparent;
  max-width: calc(100% - 75px);
}
@media only screen and (max-width: 1022px) {
  sbmn-my-bookings-passengers .h3withbold, sbmn-my-bookings-passengers .cms__basicinfo__container__content > span > strong {
    top: 0;
    padding: 0 5% 15px;
    line-height: 1;
  }
}
sbmn-my-bookings-passengers .h4withbold {
  margin: 0 2.5% 20px;
}
sbmn-my-bookings-passengers div.bpassengers__container {
  border-bottom: 1px solid #e5e5e5;
}
sbmn-my-bookings-passengers span.bmanager__passengers__edit {
  top: 0;
  right: 10px;
  flex-direction: row-reverse;
  cursor: pointer;
  min-width: 100%;
  height: 55px;
}
sbmn-my-bookings-passengers span.bmanager__passengers__edit:hover i {
  border-color: #666;
}
sbmn-my-bookings-passengers span.bmanager__passengers__edit i {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  display: block;
  display: flex;
  margin: 0 5px;
}
sbmn-my-bookings-passengers span.bmanager__passengers__edit i:before {
  font-size: 1.4rem;
}
sbmn-my-bookings-passengers span.bmanager__passengers__edit.warning:before {
  position: absolute;
  top: 6px;
  right: -2px;
  color: #ff4500;
  font-size: 1.6rem;
}
sbmn-my-bookings-passengers .xtra {
  max-height: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.25s linear;
}
sbmn-my-bookings-passengers .extradata {
  max-height: 1500px;
  height: auto;
  overflow: hidden;
  width: 100%;
  opacity: 1;
  transition: all 0.25s linear;
}
sbmn-my-bookings-passengers .passengers {
  margin: 0 auto;
}
sbmn-my-bookings-passengers .passengers__title {
  padding-left: 0;
}
sbmn-my-bookings-passengers .passengers__section__passenger {
  position: relative;
  border: 1px solid #ccc;
  background: rgba(255, 255, 255, 0.4);
  padding-bottom: 0;
  overflow: hidden;
}
sbmn-my-bookings-passengers .passengers__section__passenger__data {
  position: relative;
  align-items: center;
  background: #fff;
}
sbmn-my-bookings-passengers .passengers__section__passenger__data .TsaDocs {
  display: flex;
  width: 100%;
}
sbmn-my-bookings-passengers .passengers__section__passenger__data__title {
  line-height: 1.5rem;
}
sbmn-my-bookings-passengers .buttonwhite {
  padding: 5px 20px;
  margin-left: 20px;
}
sbmn-my-bookings-passengers .loyalty {
  padding-bottom: 20px;
  justify-content: center;
}
sbmn-my-bookings-passengers .loyalty .mat-form-field:last-of-type {
  margin-right: 10px;
}

.bmanager__passengers__container__travelwith {
  display: block;
  font-size: 0.7em;
  color: #4c4c4c;
}
.bmanager__passengers__container__extra {
  margin-top: 5px;
}
.bmanager__passengers__container__extra__title {
  color: #ff4500;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
}
.bmanager__passengers__container__extra__resident {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #4c4c4c;
  padding: 0 20px 20px 0;
}
.bmanager__passengers__container__extra__resident .ok {
  color: #a8d88c;
  font-size: 1.3rem;
}
.bmanager__passengers__container__extra__resident .delete {
  color: #ef1c25;
}
.bmanager__passengers__container__extra__buttoninfo, .bmanager__passengers__container__extra__button {
  margin: 0 auto 20px auto;
}
.bmanager__passengers__container__extra__buttoninfo {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 1.4rem;
}

sbmn-my-bookings-fares {
  display: block;
  min-width: 100%;
}
sbmn-my-bookings-fares .limitdate {
  color: #f48126;
}
sbmn-my-bookings-fares .price {
  color: #0099ff;
  letter-spacing: -0.5px;
}
sbmn-my-bookings-fares .basic__table {
  width: 100%;
  border-width: 0 0 1px 0;
  margin-bottom: 25px;
}
sbmn-my-bookings-fares .basic__table.bmanager__fares__main-table th:nth-of-type(4), sbmn-my-bookings-fares .basic__table.bmanager__fares__main-table th:nth-of-type(3) {
  width: 25%;
}
sbmn-my-bookings-fares .basic__table__head tr {
  border: 0;
  cursor: auto;
}
sbmn-my-bookings-fares .basic__table__head tr th {
  cursor: auto;
  text-align: center;
}
sbmn-my-bookings-fares .basic__table__head tr th:last-child {
  padding-right: 0;
}
sbmn-my-bookings-fares .basic__table__body tr {
  cursor: auto;
}
sbmn-my-bookings-fares .basic__table__body tr td:last-child {
  padding-right: 0;
}
sbmn-my-bookings-fares .basic__table__body tr td:last-child:before {
  content: "";
}
sbmn-my-bookings-fares .basic__table__body .delete {
  color: #ef1c25;
}
sbmn-my-bookings-fares .basic__table__body .ok {
  color: #6ebd41;
}

.bmanager__fares__section {
  border-bottom: 1px solid #0099ff;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.bmanager__fares__section:nth-of-type(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 1022px) {
  .bmanager__fares__section {
    flex-direction: column;
  }
}
.bmanager__fares__section__item {
  text-align: right;
  padding: 3px 0;
  font-size: 1.6rem;
  line-height: 1;
}
.bmanager__fares__section__item strong {
  font-size: 1.2em;
}
.bmanager__fares__section__container {
  border-bottom: 1px solid #b2b2b2;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
.bmanager__fares__section__container__title {
  margin: 5px 0 5px;
}
.bmanager__fares__section__container__title.pasajero:before {
  margin-right: 10px;
}
.bmanager__fares__section__container__subtitle {
  margin: 10px 0 5px;
  font-size: 1.8rem;
  font-family: basicregular, Arial, sans-serif;
  font-weight: lighter;
}
.bmanager__fares__section__code {
  padding: 10px;
  font-size: 1.5rem;
  border-radius: 5px;
  background: #f1f1f1;
  max-height: 300px;
  overflow-y: scroll;
}

sbmn-my-bookings-baggage {
  display: block;
  min-width: 100%;
}
sbmn-my-bookings-baggage .h3withbold, sbmn-my-bookings-baggage .cms__basicinfo__container__content > span > strong {
  font-size: 2rem;
}
sbmn-my-bookings-baggage .h4withbold {
  margin-bottom: 20px;
}
sbmn-my-bookings-baggage .price {
  color: #0099ff;
  font-size: 1.8rem;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  letter-spacing: -0.5px;
}
sbmn-my-bookings-baggage .bmanager__baggage__nav__item {
  position: relative;
  min-width: 80px;
  top: auto;
  right: auto;
  cursor: default;
}
sbmn-my-bookings-baggage .bmanager__baggage__nav__item:hover {
  color: #7f7f7f;
}
sbmn-my-bookings-baggage .bmanager__baggage__nav__item .h2withbold {
  margin-bottom: 0;
}
sbmn-my-bookings-baggage .bmanager__baggage__nav__item i.m_icon:hover {
  border: 2px solid #0099ff;
  background: #fff;
  cursor: default;
}
sbmn-my-bookings-baggage .bmanager__baggage__nav__item i.m_icon:hover:before {
  color: #0099ff;
  transform: scale(1);
}
sbmn-my-bookings-baggage .bmanager__baggage__nav__item i.active:hover {
  border: 2px solid #fff;
  background: #0099ff;
}
sbmn-my-bookings-baggage .bmanager__baggage__nav__item i.active:hover:before {
  color: #fff;
  transform: scale(1.1);
}
sbmn-my-bookings-baggage .bmanager__baggage__nav__item i:before {
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  font-style: normal;
}
sbmn-my-bookings-baggage .disabled .basic__table thead th,
sbmn-my-bookings-baggage .disabled .basic__table tbody tr {
  cursor: default;
}
sbmn-my-bookings-baggage .basic__table {
  width: 100%;
  border-width: 0 0 1px 0;
  margin-bottom: 15px;
}
sbmn-my-bookings-baggage .basic__table__head tr {
  border: 0;
}
sbmn-my-bookings-baggage .basic__table__head tr th {
  text-align: center;
}
sbmn-my-bookings-baggage .basic__table__head tr th:last-child {
  padding-right: 0;
}
sbmn-my-bookings-baggage .basic__table__body {
  background: #fff;
  border-bottom: 0;
}
sbmn-my-bookings-baggage .basic__table__body tr {
  border: 0;
}
sbmn-my-bookings-baggage .basic__table__body tr td:last-child {
  padding-right: 0;
}
sbmn-my-bookings-baggage .basic__table__body tr td:last-child:before {
  content: "";
}

.bmanager__baggage__nav {
  display: flex;
  justify-content: center;
  min-height: 50px;
  position: relative;
}
.bmanager__baggage__nav__item .one:before {
  content: "1";
}
.bmanager__baggage__nav__item .two:before {
  content: "2";
}
.bmanager__baggage__nav__item .three:before {
  content: "3";
}
.bmanager__baggage__nav__item .four:before {
  content: "4";
}
.bmanager__baggage__steps {
  border-bottom: 1px solid #0099ff;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.bmanager__baggage__steps__title.h3withbold, .cms__basicinfo__container__content > span > strong.bmanager__baggage__steps__title {
  margin-top: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.bmanager__baggage__steps__error {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bmanager__baggage__steps__container {
  border-bottom: 1px solid #b2b2b2;
  padding: 10px;
  margin: 0 auto 30px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 10px;
  transition: background 0.2s linear;
}
.bmanager__baggage__steps__container:hover, .bmanager__baggage__steps__container:focus, .bmanager__baggage__steps__container:active {
  background: rgba(0, 89, 179, 0.6);
}
.bmanager__baggage__steps__container:hover .h3withbold, .bmanager__baggage__steps__container:hover .cms__basicinfo__container__content > span > strong, .bmanager__baggage__steps__container:focus .h3withbold, .bmanager__baggage__steps__container:focus .cms__basicinfo__container__content > span > strong, .bmanager__baggage__steps__container:active .h3withbold, .bmanager__baggage__steps__container:active .cms__basicinfo__container__content > span > strong {
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
}
.disabled .bmanager__baggage__steps__container {
  cursor: default;
}
.disabled .bmanager__baggage__steps__container:hover, .disabled .bmanager__baggage__steps__container:focus, .disabled .bmanager__baggage__steps__container:active {
  background: transparent;
}
.disabled .bmanager__baggage__steps__container:hover .h3withbold, .disabled .bmanager__baggage__steps__container:hover .cms__basicinfo__container__content > span > strong, .disabled .bmanager__baggage__steps__container:focus .h3withbold, .disabled .bmanager__baggage__steps__container:focus .cms__basicinfo__container__content > span > strong, .disabled .bmanager__baggage__steps__container:active .h3withbold, .disabled .bmanager__baggage__steps__container:active .cms__basicinfo__container__content > span > strong {
  font-family: basicregular, Arial, sans-serif;
}
.bmanager__baggage__steps__container__title {
  margin: 5px 0 5px;
  min-width: 50%;
}
.bmanager__baggage__steps__container__title:before {
  background: white;
  border-radius: 50%;
  padding: 3px;
  margin-right: 15px;
}
.bmanager__baggage__steps__container__total {
  text-align: right;
}
.bmanager__baggage__steps__container__total.large {
  min-width: 100%;
}
.bmanager__baggage__steps__container__total.large .price {
  font-size: 2.1rem;
  color: #0099ff;
}
.bmanager__baggage__steps__container__step3, .bmanager__baggage__steps__container__step4 {
  width: 100%;
  margin: 20px auto;
}
.bmanager__baggage__steps__container__step3 {
  text-align: center;
}
.bmanager__baggage__steps__container__step3__notice {
  width: 100%;
  font-size: 1.5rem;
  text-align: center;
}
.bmanager__baggage__steps__container__step3__button {
  margin: 20px auto;
}
.bmanager__baggage__steps__container__step4 .cssload-loader {
  margin: 20px auto;
}

sbmn-my-bookings-tariffs {
  display: block;
  min-width: 100%;
}
sbmn-my-bookings-tariffs .h3withbold, sbmn-my-bookings-tariffs .cms__basicinfo__container__content > span > strong {
  font-size: 2rem;
}
sbmn-my-bookings-tariffs .h4withbold {
  margin-bottom: 20px;
}
sbmn-my-bookings-tariffs .price {
  color: #0099ff;
  font-size: 1.8rem;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  letter-spacing: -0.5px;
}
sbmn-my-bookings-tariffs .bmanager__tariffs__nav__item {
  position: relative;
  min-width: 80px;
  top: auto;
  right: auto;
  cursor: default;
}
sbmn-my-bookings-tariffs .bmanager__tariffs__nav__item:hover {
  color: #7f7f7f;
}
sbmn-my-bookings-tariffs .bmanager__tariffs__nav__item .h2withbold {
  margin-bottom: 0;
}
sbmn-my-bookings-tariffs .bmanager__tariffs__nav__item i.m_icon:hover {
  border: 2px solid #0099ff;
  background: #fff;
  cursor: default;
}
sbmn-my-bookings-tariffs .bmanager__tariffs__nav__item i.m_icon:hover:before {
  color: #0099ff;
  transform: scale(1);
}
sbmn-my-bookings-tariffs .bmanager__tariffs__nav__item i.active:hover {
  border: 2px solid #fff;
  background: #0099ff;
}
sbmn-my-bookings-tariffs .bmanager__tariffs__nav__item i.active:hover:before {
  color: #fff;
  transform: scale(1.1);
}
sbmn-my-bookings-tariffs .bmanager__tariffs__nav__item i:before {
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  font-style: normal;
}
sbmn-my-bookings-tariffs i {
  margin: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
}
@media only screen and (max-width: 1247px) {
  sbmn-my-bookings-tariffs i {
    margin: 3px 6px 3px 0;
  }
}
sbmn-my-bookings-tariffs i.selected {
  border-color: #0099ff;
  background: rgba(0, 51, 102, 0.2);
}
sbmn-my-bookings-tariffs .menosopciones {
  opacity: 0.5;
  transform: rotateX(180deg);
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 15px;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s linear, opacity 0.2s linear;
}
sbmn-my-bookings-tariffs .menosopciones:before {
  font-size: 3rem;
  color: #2d5a96;
}
sbmn-my-bookings-tariffs .menosopciones:hover, sbmn-my-bookings-tariffs .menosopciones:focus {
  opacity: 1;
}
sbmn-my-bookings-tariffs .menosopciones.expanded {
  transform: rotateX(0);
}
sbmn-my-bookings-tariffs .ffamilies__scroll .h2withbold {
  text-align: center;
  margin: 0;
  color: #fff;
}
sbmn-my-bookings-tariffs .ffamilies__scroll .h3withbold, sbmn-my-bookings-tariffs .ffamilies__scroll .cms__basicinfo__container__content > span > strong {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1022px) {
  sbmn-my-bookings-tariffs .ffamilies__scroll .h3withbold, sbmn-my-bookings-tariffs .ffamilies__scroll .cms__basicinfo__container__content > span > strong {
    font-size: 1.8rem;
  }
}
sbmn-my-bookings-tariffs .ffamilies__scroll table {
  background-color: #fff;
  margin: 0;
  width: 100%;
  border-collapse: collapse;
}
sbmn-my-bookings-tariffs .ffamilies__scroll table thead {
  background: transparent;
  border-bottom: 1px solid #005c99;
  color: #666;
}
sbmn-my-bookings-tariffs .ffamilies__scroll table thead tr:last-child {
  color: #005c99;
  border-bottom: 0;
}
sbmn-my-bookings-tariffs .ffamilies__scroll table thead td {
  padding: 2px 5px;
}
sbmn-my-bookings-tariffs .ffamilies__scroll table tr {
  border-bottom: 1px solid #f9b987;
  border-left: 0;
}
@media only screen and (max-width: 1022px) {
  sbmn-my-bookings-tariffs .ffamilies__scroll table tr {
    border-bottom: 5px solid #f9b987;
  }
}
sbmn-my-bookings-tariffs .ffamilies__scroll table tr.in {
  background: rgba(0, 89, 179, 0.2);
}
sbmn-my-bookings-tariffs .ffamilies__scroll table tr.selected {
  background: rgba(168, 216, 140, 0.1);
}
sbmn-my-bookings-tariffs .ffamilies__scroll table td {
  vertical-align: top;
  padding: 5px;
}
@media only screen and (max-width: 1022px) {
  sbmn-my-bookings-tariffs .ffamilies__scroll table td:before {
    display: none;
  }
}
sbmn-my-bookings-tariffs .ffamilies__scroll table td:last-child {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
sbmn-my-bookings-tariffs .ffamilies__scroll span.ko {
  z-index: 0;
}
sbmn-my-bookings-tariffs .ffamilies__options__container__item__icon {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  display: block;
  display: inline-flex;
  font-size: 2rem;
  border-color: #0099ff;
}
sbmn-my-bookings-tariffs .ffamilies__options__container__item__icon.maleta {
  border: 0;
  margin-right: 10px;
  background: transparent;
  color: #fff;
}
sbmn-my-bookings-tariffs .ffamilies__options__container__item__icon.maleta:before {
  font-size: 2.5rem;
  top: -1px;
  position: absolute;
  color: rgba(0, 92, 153, 0.5);
}
sbmn-my-bookings-tariffs .ffamilies__options__container__item__icon.maleta strong {
  margin-top: 3px;
  font-size: 1.3rem;
  z-index: 1;
}
sbmn-my-bookings-tariffs .ffamilies__options__container__header {
  display: none;
}
@media only screen and (max-width: 559px) {
  sbmn-my-bookings-tariffs .ffamilies__options__container__header {
    display: flex;
  }
}
sbmn-my-bookings-tariffs .ffamilies__options__container__header .h3withbold, sbmn-my-bookings-tariffs .ffamilies__options__container__header .cms__basicinfo__container__content > span > strong {
  margin-right: 10px;
}
@media only screen and (max-width: 559px) {
  sbmn-my-bookings-tariffs .ffamilies__options__container__header .ffamilies__options__container__item__icon {
    display: flex;
  }
}
sbmn-my-bookings-tariffs .ffamilies__options__container__item {
  min-width: 100%;
  margin-top: 15px;
}
sbmn-my-bookings-tariffs .ffamilies__options__container__item__list {
  font-size: 1.3rem;
  text-align: left;
  border-radius: 5px;
  width: 100%;
  height: 0;
  padding: 0 10%;
  margin: 0 auto;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s linear;
  list-style: disc;
}
sbmn-my-bookings-tariffs .ffamilies__options__container__item__list li:first-child {
  list-style: none;
}
.in sbmn-my-bookings-tariffs .ffamilies__options__container__item__list {
  height: auto;
  max-height: 1000px;
  margin: 5px auto;
  padding: 10px 10%;
  background: rgba(0, 89, 179, 0.5);
}
.selected.in sbmn-my-bookings-tariffs .ffamilies__options__container__item__list {
  background: #fff;
}
sbmn-my-bookings-tariffs .ffamilies__options__container__item__title {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
}
sbmn-my-bookings-tariffs .ffamilies__options__container__item__title > strong {
  display: inline-block;
  text-align: right;
}
sbmn-my-bookings-tariffs .ffamilies__options__container__item__title > strong span {
  margin: 0 4px;
}
sbmn-my-bookings-tariffs .ffamilies__options__container__item__title em {
  font-size: 0.9em;
  margin-left: 10px;
  display: none;
}
@media only screen and (max-width: 1022px) {
  sbmn-my-bookings-tariffs .ffamilies__options__container__item__title em {
    display: inline-block;
  }
}
sbmn-my-bookings-tariffs .ffamilies__options__container__item__price {
  font-size: 2rem;
  padding: 5px 10px 2px 10px;
  width: 130px;
  display: inline-block;
  text-align: center;
  background: #b2b2b2;
}
.selected sbmn-my-bookings-tariffs .ffamilies__options__container__item__price {
  background-color: #003366;
}
.selected sbmn-my-bookings-tariffs .ffamilies__options__container__item__price:hover, .selected sbmn-my-bookings-tariffs .ffamilies__options__container__item__price:focus, .selected sbmn-my-bookings-tariffs .ffamilies__options__container__item__price:active {
  box-shadow: none;
  cursor: auto;
}
sbmn-my-bookings-tariffs .ffamilies__options__container__item__open.menosopciones {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  display: block;
  margin-left: 15px;
  transform: rotate(180deg);
  position: relative;
}
sbmn-my-bookings-tariffs .ffamilies__options__container__item__open.menosopciones:before {
  color: #005c99;
  font-size: 3rem;
}
.in sbmn-my-bookings-tariffs .ffamilies__options__container__item__open.menosopciones {
  transform: rotate(0);
}

.bmanager__tariffs__nav {
  display: flex;
  justify-content: center;
  min-height: 50px;
  position: relative;
}
.bmanager__tariffs__nav__item .one:before {
  content: "1";
}
.bmanager__tariffs__nav__item .two:before {
  content: "2";
}
.bmanager__tariffs__nav__item .three:before {
  content: "3";
}
.bmanager__tariffs__nav__item .four:before {
  content: "4";
}
.bmanager__tariffs__steps {
  border-bottom: 1px solid #0099ff;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.bmanager__tariffs__steps__title.h3withbold, .cms__basicinfo__container__content > span > strong.bmanager__tariffs__steps__title {
  margin-top: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.bmanager__tariffs__steps__error {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bmanager__tariffs__steps__container {
  border-bottom: 1px solid #b2b2b2;
  padding: 10px;
  margin: 0 auto 30px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 10px;
  transition: background 0.2s linear;
}
.bmanager__tariffs__steps__container:hover, .bmanager__tariffs__steps__container:focus, .bmanager__tariffs__steps__container:active {
  background: rgba(0, 89, 179, 0.6);
}
.bmanager__tariffs__steps__container:hover .h3withbold, .bmanager__tariffs__steps__container:hover .cms__basicinfo__container__content > span > strong, .bmanager__tariffs__steps__container:focus .h3withbold, .bmanager__tariffs__steps__container:focus .cms__basicinfo__container__content > span > strong, .bmanager__tariffs__steps__container:active .h3withbold, .bmanager__tariffs__steps__container:active .cms__basicinfo__container__content > span > strong {
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
}
.disabled .bmanager__tariffs__steps__container {
  cursor: default;
}
.disabled .bmanager__tariffs__steps__container:hover, .disabled .bmanager__tariffs__steps__container:focus, .disabled .bmanager__tariffs__steps__container:active {
  background: transparent;
}
.disabled .bmanager__tariffs__steps__container:hover .h3withbold, .disabled .bmanager__tariffs__steps__container:hover .cms__basicinfo__container__content > span > strong, .disabled .bmanager__tariffs__steps__container:focus .h3withbold, .disabled .bmanager__tariffs__steps__container:focus .cms__basicinfo__container__content > span > strong, .disabled .bmanager__tariffs__steps__container:active .h3withbold, .disabled .bmanager__tariffs__steps__container:active .cms__basicinfo__container__content > span > strong {
  font-family: basicregular, Arial, sans-serif;
}
.bmanager__tariffs__steps__container__title {
  margin: 5px 0 5px;
  min-width: 50%;
}
.bmanager__tariffs__steps__container__title:before {
  background: white;
  border-radius: 50%;
  padding: 3px;
  margin-right: 15px;
}
.bmanager__tariffs__steps__container__total {
  text-align: right;
}
.bmanager__tariffs__steps__container__total.large {
  min-width: 100%;
}
.bmanager__tariffs__steps__container__total.large .price {
  font-size: 2.1rem;
  color: #0099ff;
}
.bmanager__tariffs__steps__container__step3, .bmanager__tariffs__steps__container__step4 {
  width: 100%;
  margin: 20px auto;
}
.bmanager__tariffs__steps__container__step3 {
  text-align: center;
}
.bmanager__tariffs__steps__container__step3__notice {
  width: 100%;
  font-size: 1.5rem;
  text-align: center;
}
.bmanager__tariffs__steps__container__step3__button {
  margin: 20px auto;
}
.bmanager__tariffs__steps__container__step4 .cssload-loader {
  margin: 20px auto;
}

@media only screen and (max-width: 1022px) {
  sbmn-my-bookings-messages {
    min-width: 100%;
  }
}
sbmn-my-bookings-messages .msg-flights:before {
  right: -3%;
}
sbmn-my-bookings-messages .basic__table {
  width: 100%;
}
sbmn-my-bookings-messages .basic__table-paginator {
  width: 100%;
}
sbmn-my-bookings-messages .basic__table__head tr {
  border: 0;
}
sbmn-my-bookings-messages .basic__table__head th {
  text-align: center;
}
sbmn-my-bookings-messages .basic__table__head th:first-child {
  width: 40%;
}
sbmn-my-bookings-messages .basic__table__body {
  background: #fff;
}
sbmn-my-bookings-messages .basic__table__body tr {
  border: 0;
  cursor: default;
}
sbmn-my-bookings-messages .basic__table__body tr td:last-child:before {
  display: inline-block;
}
sbmn-my-bookings-messages .basic__table__body tr:hover, sbmn-my-bookings-messages .basic__table__body tr:focus, sbmn-my-bookings-messages .basic__table__body tr.active {
  cursor: pointer;
  background: rgba(0, 153, 255, 0.2);
  border-left: 10px solid #0099ff;
  color: #000;
}
sbmn-my-bookings-messages .basic__table__body td:last-child:before {
  display: none;
}
sbmn-my-bookings-messages .basic__table__body td:first-child {
  width: 30%;
}
sbmn-my-bookings-messages .Bplus, sbmn-my-bookings-messages .Bless {
  margin: 0 0 15px 15px;
}

.bmanager__msg__header__title {
  display: inline-block;
}
.bmanager__msg__section.msgmanager {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.bmanager__msg__section.msgmanager .ko {
  position: absolute;
  right: -3.5%;
  left: unset;
  top: 10px;
  color: #000;
  font-size: 2rem;
  opacity: 0.3;
  width: auto;
  height: auto;
}
.bmanager__msg__section.msgmanager .ko:hover {
  opacity: 0.7;
}
.bmanager__msg__section.msgmanager .ko:hover:before {
  color: #000;
}
.bmanager__msg__section .msgmanager__header:before {
  display: none;
}
.bmanager__msg__hide {
  height: 0;
  overflow: hidden;
}

@media only screen and (max-width: 1022px) {
  sbmn-my-bookings-historic {
    min-width: 100%;
  }
}
sbmn-my-bookings-historic .msg-flights:before {
  right: -3%;
}
sbmn-my-bookings-historic .basic__table {
  width: 100%;
}
sbmn-my-bookings-historic .basic__table-paginator {
  width: 100%;
}
sbmn-my-bookings-historic .basic__table__head tr {
  border: 0;
}
sbmn-my-bookings-historic .basic__table__head th {
  text-align: center;
}
sbmn-my-bookings-historic .basic__table__head th:first-child {
  width: 40%;
}
sbmn-my-bookings-historic .basic__table__body {
  background: #fff;
}
sbmn-my-bookings-historic .basic__table__body tr {
  border: 0;
  cursor: default;
}
sbmn-my-bookings-historic .basic__table__body tr td:last-child:before {
  display: inline-block;
}
sbmn-my-bookings-historic .basic__table__body tr:hover, sbmn-my-bookings-historic .basic__table__body tr:focus, sbmn-my-bookings-historic .basic__table__body tr.active {
  cursor: pointer;
  background: rgba(0, 153, 255, 0.2);
  border-left: 10px solid #0099ff;
  color: #000;
}
sbmn-my-bookings-historic .basic__table__body td:last-child:before {
  display: none;
}
sbmn-my-bookings-historic .basic__table__body td:first-child {
  width: 30%;
}
sbmn-my-bookings-historic .Bplus, sbmn-my-bookings-historic .Bless {
  margin: 0 0 15px 15px;
}

.bmanager__msg__header__title {
  display: inline-block;
}
.bmanager__msg__section.msgmanager {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.bmanager__msg__section.msgmanager .ko {
  position: absolute;
  right: -3.5%;
  left: unset;
  top: 10px;
  color: #000;
  font-size: 2rem;
  opacity: 0.3;
  width: auto;
  height: auto;
}
.bmanager__msg__section.msgmanager .ko:hover {
  opacity: 0.7;
}
.bmanager__msg__section.msgmanager .ko:hover:before {
  color: #000;
}
.bmanager__msg__section .msgmanager__header:before {
  display: none;
}
.bmanager__msg__hide {
  height: 0;
  overflow: hidden;
}

.bmanager__split {
  position: relative;
}
.bmanager__split__header {
  border-top: 1px solid #0099ff;
}
.bmanager__split__header .h1withbold, .bmanager__split__header .h4withbold, .bmanager__split__header .h3withbold, .bmanager__split__header .cms__basicinfo__container__content > span > strong, .bmanager__split__header .h2withbold {
  margin: 0;
  font-size: 3.5rem;
  padding: 15px 0 5px;
  color: #fff;
  text-align: center;
}
.bmanager__split__container {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 100px;
}
.bmanager__split__container .h3withbold, .bmanager__split__container .cms__basicinfo__container__content > span > strong {
  color: #0099ff;
  text-align: center;
  padding: 10px 5%;
  margin-bottom: 0;
}
.bmanager__split__container .h3withbold strong, .bmanager__split__container .cms__basicinfo__container__content > span > strong strong {
  color: #ccc;
}
@media only screen and (max-width: 1022px) {
  .bmanager__split__container .h3withbold, .bmanager__split__container .cms__basicinfo__container__content > span > strong {
    font-size: 2rem;
  }
}
.bmanager__split__container__booking {
  min-width: 50%;
  padding-bottom: 30px;
}
@media only screen and (max-width: 1022px) {
  .bmanager__split__container__booking {
    max-width: 49%;
    padding: 5px 5px 30px;
  }
}
.bmanager__split__container__booking:nth-of-type(2) {
  border-left: 5px dashed #666;
}
@media only screen and (max-width: 1022px) {
  .bmanager__split__container__booking:nth-of-type(2) {
    border-left: 2px dashed #666;
  }
}
.bmanager__split__container__booking__psg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid #4c4c4c;
  margin: 5px 5%;
  cursor: pointer;
  transition: border 0.2s linear;
}
@media only screen and (max-width: 1022px) {
  .bmanager__split__container__booking__psg {
    margin: 5px 0;
  }
}
.bmanager__split__container__booking__psg:hover {
  border-bottom: 1px solid #a8d88c;
}
.bmanager__split__container__booking__psg:hover .h4withbold {
  color: #fff;
}
.bmanager__split__container__booking__psg:hover .menosopciones,
.bmanager__split__container__booking__psg:hover .personas,
.bmanager__split__container__booking__psg:hover .pasajero,
.bmanager__split__container__booking__psg:hover .pasajerobuscador {
  opacity: 1;
}
.bmanager__split__container__booking__psg.disabled .menosopciones {
  opacity: 0;
}
.bmanager__split__container__booking__psg.disabled:hover {
  border-bottom: 1px solid #4c4c4c;
  cursor: auto;
}
.bmanager__split__container__booking__psg.disabled:hover .h4withbold {
  color: #666;
}
.bmanager__split__container__booking__psg.disabled:hover .personas,
.bmanager__split__container__booking__psg.disabled:hover .pasajero,
.bmanager__split__container__booking__psg.disabled:hover .pasajerobuscador {
  opacity: 0.5;
}
.bmanager__split__container__booking__psg.disabled:hover .menosopciones {
  opacity: 0;
}
.bmanager__split__container__booking__psg__name {
  display: flex;
  align-items: center;
}
.bmanager__split__container__booking__psg .personas,
.bmanager__split__container__booking__psg .pasajero,
.bmanager__split__container__booking__psg .pasajerobuscador {
  font-size: 3rem;
  color: #0099ff;
  opacity: 0.5;
}
@media only screen and (max-width: 1022px) {
  .bmanager__split__container__booking__psg .personas,
  .bmanager__split__container__booking__psg .pasajero,
  .bmanager__split__container__booking__psg .pasajerobuscador {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 559px) {
  .bmanager__split__container__booking__psg .personas,
  .bmanager__split__container__booking__psg .pasajero,
  .bmanager__split__container__booking__psg .pasajerobuscador {
    display: none;
  }
}
.bmanager__split__container__booking__psg .menosopciones {
  transform: rotate(90deg);
  color: #fff;
  transition: opacity 0.2s linear;
  font-size: 4rem;
  opacity: 0.2;
}
.bmanager__split__container__booking__psg .menosopciones.new {
  transform: rotate(-90deg);
}
@media only screen and (max-width: 1022px) {
  .bmanager__split__container__booking__psg .menosopciones {
    font-size: 3rem;
  }
}
.bmanager__split__container__booking__psg .h4withbold {
  display: flex;
  flex-direction: column;
  color: #666;
  margin: 0 10px;
  transition: color 0.2s linear;
}
@media only screen and (max-width: 1022px) {
  .bmanager__split__container__booking__psg .h4withbold {
    font-size: 1.5rem;
    margin: 0;
  }
}
.bmanager__split__container__button {
  width: 100%;
  padding: 20px;
  text-align: center;
}
.bmanager__split .loader_abs .h3withbold, .bmanager__split .login__loading .h3withbold, .bmanager__split .loader_abs .cms__basicinfo__container__content > span > strong, .bmanager__split .login__loading .cms__basicinfo__container__content > span > strong {
  margin: 20px auto;
  color: #666;
}

sbmn-my-bookings-refund {
  width: 100%;
}
sbmn-my-bookings-refund h2 {
  padding-right: 50px;
}
sbmn-my-bookings-refund .ok {
  position: absolute;
  top: 20px;
  right: -3%;
  border: 4px solid #a8d88c;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
sbmn-my-bookings-refund .ok:before {
  font-size: 2rem;
  color: #a8d88c;
}

sbmn-pagination {
  width: 100%;
}
sbmn-pagination .pagination {
  display: flex;
  justify-content: center;
  margin: 20px;
  font-size: 1.6rem;
}
sbmn-pagination .pagination__container {
  display: flex;
}
sbmn-pagination .pagination__container.hide {
  visibility: hidden;
}
sbmn-pagination .pagination__container > * {
  margin: 0 5px;
}
sbmn-pagination .pagination__container__arrow {
  transition: background 0.2s linear;
  color: #fff;
  cursor: pointer;
  background: #ccc;
  padding: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}
sbmn-pagination .pagination__container__arrow:hover {
  background: #0099ff;
}
sbmn-pagination .pagination__info {
  display: inline-block;
  width: 150px;
  text-align: center;
  line-height: 2;
}
sbmn-pagination .pagination .-left {
  transform: rotate(180deg);
}

sbmn-my-travel-agency {
  display: block;
  padding-bottom: 0px;
  min-height: 100%;
}
sbmn-my-travel-agency sbmn-info {
  display: block;
  position: relative;
  margin: 20px auto;
  max-width: 1023px;
}
@media only screen and (max-width: 1022px) {
  sbmn-my-travel-agency sbmn-info {
    left: 0;
    padding: 20px 20px 20px 0;
    margin-bottom: 40px;
    margin-top: 10px;
  }
}

#mta__data {
  min-height: 90vh;
}
#mta__data.max-width-page, #mta__data.max-width-box {
  max-width: unset;
}

.mta__header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
}
.mta__header h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90px;
  margin: 0;
  font-size: 4.2rem;
}
.mta__header h1 strong {
  padding: 0 20px;
}
@media only screen and (max-width: 1022px) {
  .mta__header h1 {
    padding: 20px 30px;
    font-size: 3.5rem;
    flex-direction: column;
    justify-content: center;
  }
  .mta__header h1 strong {
    display: block;
  }
}
.mta__header .Bplus, .mta__header .Bless {
  margin-left: 20px;
}
.mta__header__filter-button {
  position: absolute;
  cursor: pointer;
  color: #005c99;
  text-transform: uppercase;
  font-size: 1.4rem;
  height: 40px;
  width: 40px;
  margin: 0 20px 0 10px;
  left: 30px;
}
.mta__header__filter-button:before {
  line-height: 2;
  font-size: 2rem;
  padding: 8px;
  border: 2px solid #005c99;
  border-radius: 50%;
}
.mta__header__filter-button:hover, .mta__header__filter-button:focus {
  color: #003366;
}
.mta__header__filter-button:hover:before, .mta__header__filter-button:focus:before {
  border: 2px solid #003366;
}
@media only screen and (max-width: 1022px) {
  .mta__header__filter-button {
    position: relative;
    height: 30px;
    width: 30px;
    left: unset;
  }
  .mta__header__filter-button:before {
    line-height: 1.7;
    font-size: 1.8rem;
    padding: 5px;
  }
}
.mta__menu {
  border-bottom: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 5px;
  position: relative;
}
.mta__menu-container {
  display: flex;
  justify-content: center;
}
.mta__menu__link {
  text-decoration: none;
  text-align: center;
  font-size: 1.1rem;
  text-transform: uppercase;
  margin: 0 10px;
}
.mta__menu__link.active, .mta__menu__link:hover i, .mta__menu__link.active i {
  color: #2d5a96;
  border-color: #2d5a96;
}
.mta__menu__link.active:before, .mta__menu__link:hover i:before, .mta__menu__link.active i:before {
  transform: scale(1.2);
}
.mta__menu i {
  color: #b2b2b2;
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px auto;
  transition: all 0.2s linear;
}
@media only screen and (max-width: 1022px) {
  .mta__menu i {
    font-size: 2rem;
    display: flex;
  }
}
.mta__submenu {
  display: flex;
  justify-content: center;
  border-bottom: 1px dashed #b2b2b2;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1022px) {
  .mta__submenu {
    color: transparent;
    flex-direction: column;
  }
}
.mta__submenu__link {
  margin: 0 10px;
  cursor: pointer;
}
@media only screen and (max-width: 1022px) {
  .mta__submenu__link {
    padding: 0 0 12px;
    border-bottom: 1px solid #b2b2b2;
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0;
    text-decoration: none;
  }
  .mta__submenu__link:last-child {
    border: 0;
  }
}
.mta__submenu__link:hover, .mta__submenu__link:focus, .mta__submenu__link.selected {
  color: #0099ff;
  font-weight: bold;
}

sbmn-mta-main {
  min-height: 400px;
  display: block;
}

.mtamain {
  max-width: 950px;
  margin: 10px auto 0;
  padding-bottom: 50px;
  overflow: hidden;
}
@media only screen and (max-width: 1022px) {
  .mtamain {
    padding: 20px 0 40px;
    width: 100%;
    margin-bottom: 0;
  }
}
.mtamain__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
  position: relative;
  max-width: 600px;
  cursor: pointer;
}
.mtamain__logo:hover img {
  opacity: 0.2;
}
.mtamain__logo:hover figcaption {
  opacity: 1;
  color: #0099ff;
}
.mtamain__logo figcaption {
  font-size: 2rem;
  flex-direction: column;
  right: 0;
  top: 0;
}
.mtamain__logo__figcaption, .mtamain__logo__nologo {
  opacity: 0;
  transition: opacity 0.7s linear;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  right: 0;
}
.mtamain__logo__figcaption:before, .mtamain__logo__nologo:before {
  color: #2d5a96;
  font-size: 2.5rem;
}
.mtamain__logo__nologo {
  opacity: 1;
  min-height: 100px;
  color: #666;
  flex-direction: column;
}
.mtamain__logo__nologo:before {
  transition: color 0.2s linear;
  font-size: 6rem;
}
.mtamain__logo__nologo:hover:before {
  color: #003366;
}
.mtamain__logo img {
  max-width: 100%;
  max-height: 125px;
}
.mtamain__logo__uploader {
  width: 90%;
  margin: 5px auto;
  color: #fff;
  top: 0;
  left: 0;
  z-index: 6;
}
.mtamain__container {
  display: flex;
  transition: left 0.2s linear;
  position: relative;
  align-items: flex-start;
}
.mtamain__container.main {
  left: 0;
}
.mtamain__container.main fieldset {
  padding: 5px 0;
}
.mtamain__container.fee {
  left: -100%;
}
.mtamain__container.more {
  left: -200%;
}
.mtamain__container__section {
  min-width: 100%;
  padding: 0 10px;
}
@media only screen and (max-width: 1022px) {
  .mtamain__container__section.cash h2 {
    padding-left: 25px;
  }
  .mtamain__container__section.cash tr {
    padding: 5px 10px;
    border-bottom: 1px solid #b2b2b2;
  }
  .mtamain__container__section.cash tr:nth-child(odd) {
    background: #fff;
  }
  .mtamain__container__section.cash td,
  .mtamain__container__section.cash td:first-child,
  .mtamain__container__section.cash td:last-child {
    padding-left: 0;
    padding-right: 0;
  }
  .mtamain__container__section.cash mat-form-field {
    max-height: 21px;
    margin-right: 0;
  }
  .mtamain__container__section.cash mat-form-field input {
    text-align: right;
  }
  .mtamain__container__section.cash .mat-form-field-infix {
    border-top: 0;
  }
}
.mtamain__container__section:before {
  color: #003366;
  width: 40px;
}
.mtamain__container__section mat-form-field,
.mtamain__container__section mat-checkbox {
  width: 30%;
  margin: 0 3% 0 0;
}
.mtamain__container__section mat-form-field.large,
.mtamain__container__section mat-checkbox.large {
  width: 90%;
}
@media only screen and (max-width: 1022px) {
  .mtamain__container__section mat-form-field,
  .mtamain__container__section mat-checkbox {
    width: 95%;
    display: block;
    margin-bottom: 10px;
  }
}
.mtamain__container__section__title {
  margin: 0 0 30px 40px;
}
.mtamain__container__section__title__saved {
  font-size: 0.8em;
  opacity: 0;
  margin-left: 20px;
  color: #0099ff;
  line-height: 1em;
  transition: opacity 0.7s linear;
}
.mtamain__container__section__title__saved.in {
  opacity: 1;
}
.mtamain__container__section__table {
  width: 100%;
  margin: auto;
  text-align: center;
  border-collapse: separate;
  border: 0;
}
@media only screen and (max-width: 1022px) {
  .mtamain__container__section__table__agency-fee {
    padding-top: 16px;
    padding-bottom: 0;
  }
  .mtamain__container__section__table__agency-fee mat-form-field {
    min-width: 80px;
    width: 100px;
  }
}
.mtamain__container__section__table td {
  position: relative;
  border-bottom: 1px solid #7f7f7f;
}
.mtamain__container__section__table td:last-child {
  font-family: basicbold, Arial, sans-serif;
}
.mtamain__container__section__table td .Berror {
  bottom: 5px;
  display: inline-block;
  width: 100%;
  text-align: center;
}
.mtamain__container__section__table input {
  text-align: center;
}
.mtamain__container__section__container {
  width: 100%;
  margin: 0 auto 20px;
  display: flex;
  flex-wrap: wrap;
}
.mtamain__container__section__container__title {
  margin: 5px auto 10px;
  width: 100%;
}
.mtamain__container__section__container__fieldlarge {
  width: 100%;
}
.mtamain__container__section__container__field {
  padding-right: 3%;
}
.mtamain__container__section__container__field__half {
  flex-basis: 50%;
}
.mtamain__container__section__container__field:nth-of-type(1) {
  width: 60%;
}
@media only screen and (max-width: 1022px) {
  .mtamain__container__section__container__field:nth-of-type(1) {
    width: 97%;
  }
}
.mtamain__container__section__container__field:nth-of-type(2) {
  width: 39%;
}
@media only screen and (max-width: 1022px) {
  .mtamain__container__section__container__field:nth-of-type(2) {
    width: 97%;
  }
}
.mtamain__container__section__container__field.valigned {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.mtamain__container__section__container__field__freetext {
  width: 100%;
  height: 100px;
  margin: 10px auto;
}
.mtamain__container__section__container__field__note {
  margin-bottom: 35px;
}
.mtamain__container__section__container__field mat-form-field {
  width: 100%;
}
.mtamain__container__section__container__field mat-checkbox {
  width: 100%;
  margin-top: 20px;
}
.mtamain__container__section__container__field mat-radio-group {
  margin: 10px;
  display: flex;
  flex-direction: column;
}
.mtamain__container__section__container__field mat-radio-button {
  margin: 5px 0;
}
.mtamain__container__section__container__field mat-slide-toggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after, .mtamain__container__section__container__field mat-slide-toggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after, .mtamain__container__section__container__field mat-slide-toggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after {
  background: #6c98db;
}
.mtamain__container__section__container__field mat-slide-toggle.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after, .mtamain__container__section__container__field mat-slide-toggle.mat-mdc-slide-toggle .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after, .mtamain__container__section__container__field mat-slide-toggle.mat-mdc-slide-toggle .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after {
  background: #b2b2b2;
}

sbmn-mta-balance h1 {
  text-align: center;
  font-size: 4.5rem;
  padding: 20px 0;
}
sbmn-mta-balance .basic__table-paginator {
  width: 100%;
  max-width: 1440px;
  margin: 10px auto;
}
sbmn-mta-balance .basic__table__head tr {
  border: 0;
}
sbmn-mta-balance .basic__table__head th:nth-of-type(4) {
  width: 25%;
}
sbmn-mta-balance .basic__table__body tr {
  cursor: auto;
}
sbmn-mta-balance .basic__table__body tr td:last-child:before {
  content: "";
}

.mtabalance__filters-container {
  display: flex;
  min-height: 400px;
}
.mtabalance__filters {
  margin-top: -90px;
  padding: 80px 40px 50px 30px;
  background-color: #fff;
}
.mtabalance__filters h2 {
  font-family: basiclight, Arial, sans-serif;
  font-size: 3rem;
  font-weight: lighter;
  position: absolute;
  top: 17px;
  margin: 0;
}
.mtabalance__filters .ko {
  font-size: 1.8rem;
}

.exportxls {
  position: absolute;
  right: 30px;
}

sbmn-mta-balance-manager h4, sbmn-mta-cards-manager h4 {
  color: #333;
  font-family: basicregular, Arial, sans-serif;
}
sbmn-mta-balance-manager .mbalancem__shoppingterms, sbmn-mta-cards-manager .mbalancem__shoppingterms {
  color: #0099ff;
  text-decoration: underline;
  cursor: pointer;
}
sbmn-mta-balance-manager .mbalancem__shoppingterms:hover, sbmn-mta-cards-manager .mbalancem__shoppingterms:hover {
  color: #592e97;
  text-decoration: none;
}
sbmn-mta-balance-manager .mbalancem__file sbmn-file-uploader, sbmn-mta-cards-manager .mbalancem__file sbmn-file-uploader {
  padding: 0 0 10px;
  margin-bottom: 15px;
  display: block;
  border-bottom: 1px solid #ccc;
}
sbmn-mta-balance-manager .mbalancem__file__title, sbmn-mta-cards-manager .mbalancem__file__title {
  color: #0099ff;
}
sbmn-mta-balance-manager .mbalancem__file__title small, sbmn-mta-cards-manager .mbalancem__file__title small {
  font-family: basiclight, Arial, sans-serif;
  font-size: 0.8em;
  color: #666;
}
sbmn-mta-balance-manager .mbalancem__file__action, sbmn-mta-cards-manager .mbalancem__file__action {
  text-align: center;
}

sbmn-mta-users h1 {
  text-align: center;
  font-size: 4.5rem;
  padding: 20px 0;
}
sbmn-mta-users .basic__table-paginator {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  min-height: 650px;
}
sbmn-mta-users .basic__table__head tr {
  border: 0;
}
sbmn-mta-users .basic__table__body tr:hover, sbmn-mta-users .basic__table__body tr:focus, sbmn-mta-users .basic__table__body tr.active {
  background: rgba(0, 153, 255, 0.2);
  border-left: 10px solid #0099ff;
  color: #000;
}

.mtausers__filters-container {
  display: flex;
  min-height: 400px;
}
.mtausers__filters {
  margin-top: -90px;
  padding: 80px 40px 50px 30px;
  background-color: #fff;
}
.mtausers__filters h2 {
  font-family: basiclight, Arial, sans-serif;
  font-size: 3rem;
  font-weight: lighter;
  position: absolute;
  top: 17px;
  margin: 0;
}
.mtausers__filters .ko {
  font-size: 1.8rem;
}

sbmn-mta-users-manager form {
  display: flex;
  flex-wrap: wrap;
}
sbmn-mta-users-manager fieldset {
  width: 49%;
  padding: 5px 2%;
}
sbmn-mta-users-manager fieldset.large {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
sbmn-mta-users-manager fieldset.tpadding {
  padding-top: 20px;
}
@media only screen and (max-width: 1022px) {
  sbmn-mta-users-manager fieldset {
    width: 100%;
  }
}

.musersm__roles {
  display: flex;
  max-width: 960px;
  flex-wrap: wrap;
  height: 0;
  max-height: 0;
  transition: opacity 0.3s linear, max-height 1s linear;
  overflow: hidden;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #0099ff;
  opacity: 0;
}
.musersm__roles.allowopts {
  transition: opacity 0.3s linear, max-height 1s linear;
  max-height: 1000px;
  height: auto;
  opacity: 1;
}
.musersm__roles__group {
  min-width: 33%;
  width: 33%;
  padding: 0 3%;
}
.musersm__roles__group fieldset {
  width: 100%;
  margin: 0 2% 5px;
  padding: 5px 2% 0;
}
.musersm__roles__group mat-checkbox {
  width: 100%;
  line-height: 2;
  display: block;
}
.musersm__roles__group__title {
  margin: 20px 0 0;
  border-bottom: 1px solid #ccc;
}
.musersm__roles__group__role {
  font-size: 1.5rem;
  color: #005c99;
  font-family: basicregular, Arial, sans-serif;
}
.musersm__roles__group__subrole {
  font-size: 1.3rem;
  padding-left: 20px;
  width: 100%;
  color: #7f7f7f;
}

sbmn-mta-cards {
  display: block;
  width: 100%;
  min-height: 500px;
  padding-bottom: 100px;
}
sbmn-mta-cards table.table_responsive thead th {
  cursor: default;
}
sbmn-mta-cards .basic__table-paginator {
  width: 100%;
}
sbmn-mta-cards .basic__table__head tr {
  border: 0;
}
sbmn-mta-cards .basic__table__body {
  background: #fff;
}
sbmn-mta-cards .basic__table__body tr {
  cursor: default;
}
sbmn-mta-cards .basic__table__body tr:hover, sbmn-mta-cards .basic__table__body tr:focus {
  background: rgba(246, 123, 129, 0.2);
  border-left: 10px solid #f67b81;
  color: #000;
}
sbmn-mta-cards .basic__table__body tr.manage:hover, sbmn-mta-cards .basic__table__body tr.manage:focus {
  background: rgba(168, 216, 140, 0.2);
  border-left: 10px solid #a8d88c;
}
sbmn-mta-cards .basic__table__body tr.default {
  background: rgba(0, 89, 179, 0.2);
  border-left: 10px solid #0059b3;
}
sbmn-mta-cards .basic__table__body tr.default td {
  font-weight: bold;
  font-size: 1.1em;
}
sbmn-mta-cards .basic__table__body tr.default td:last-child span {
  margin-top: 11px;
}
sbmn-mta-cards .basic__table__body tr.default td img {
  max-width: 52px;
}
@media only screen and (max-width: 1022px) {
  sbmn-mta-cards .basic__table__body tr.default td {
    line-height: 30px;
  }
  sbmn-mta-cards .basic__table__body tr.default td:last-child span {
    margin-top: 5px;
  }
}
sbmn-mta-cards .basic__table__body tr td {
  height: 40px;
  position: relative;
  line-height: 1;
}
sbmn-mta-cards .basic__table__body tr td:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px 0 0;
}
sbmn-mta-cards .basic__table__body tr td:last-child:before {
  content: "";
  display: none;
}
@media only screen and (max-width: 1022px) {
  sbmn-mta-cards .basic__table__body tr td:last-child {
    justify-content: flex-end;
  }
  sbmn-mta-cards .basic__table__body tr td:last-child span {
    margin-left: 20px;
    height: auto;
    width: auto;
  }
  sbmn-mta-cards .basic__table__body tr td:last-child span:nth-of-type(1) {
    order: 1;
  }
  sbmn-mta-cards .basic__table__body tr td:last-child span:nth-of-type(2) {
    order: 3;
  }
  sbmn-mta-cards .basic__table__body tr td:last-child span:nth-of-type(3) {
    order: 2;
  }
  sbmn-mta-cards .basic__table__body tr td:last-child span:nth-of-type(4) {
    order: 4;
  }
}
sbmn-mta-cards .basic__table__body__card {
  max-width: 40px;
  display: flex;
  margin: auto;
}
@media only screen and (max-width: 1022px) {
  sbmn-mta-cards .basic__table__body__card {
    margin: 0;
  }
}
sbmn-mta-cards .basic__table__body__legend {
  word-break: normal;
  display: inline-block;
  overflow: hidden;
  font-size: 1.1rem;
  color: #ccc;
  transition: all 0.2s linear;
  width: 0;
  min-width: 0;
  height: 0;
}
sbmn-mta-cards .basic__table__body__legend._show {
  width: auto;
  height: auto;
  color: #000;
}
sbmn-mta-cards .basic__table__body__action {
  margin-top: 0;
  margin-left: 5px;
  cursor: pointer;
}
@media only screen and (max-width: 1022px) {
  sbmn-mta-cards .basic__table__body__action {
    margin-top: 5px;
  }
}
sbmn-mta-cards .basic__table__body__action i {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  display: block;
  border: 2px solid #b2b2b2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b2b2b2;
  transition: all 0.2s linear;
}
sbmn-mta-cards .basic__table__body__action i:hover, sbmn-mta-cards .basic__table__body__action i:focus {
  color: #fff;
  background: #a8d88c;
  border: 2px solid #a8d88c;
}
sbmn-mta-cards .basic__table__body__action i.delete:hover, sbmn-mta-cards .basic__table__body__action i.delete:focus {
  color: #fff;
  background: #f67b81;
  border: 2px solid #f67b81;
}
sbmn-mta-cards .basic__table__body__cross.ok:hover {
  color: #a8d88c;
}
sbmn-mta-cards .basic__table__body__cross.ko:hover, sbmn-mta-cards .basic__table__body__cross.ko:focus {
  cursor: auto;
  color: #9a0b11;
}
sbmn-mta-cards .basic__table__body__cross.webcam {
  font-size: 2rem;
  color: #003366;
}
sbmn-mta-cards .basic__table__body__cross.webcam:hover, sbmn-mta-cards .basic__table__body__cross.webcam:focus {
  cursor: pointer;
  color: #ff4500;
}

.mtacards__container {
  position: relative;
  min-height: 400px;
  max-width: 1440px;
  margin: auto;
}
.mtacards__container__saved {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: block;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.8);
  animation: blink 2s linear forwards;
}
.mtacards__container__saved h3 {
  margin-top: 5%;
  color: #0099ff;
}

.cards__modal {
  text-align: center;
}
.cards__modal .stopper-modal__scroll__container__title {
  color: #fff;
  font-size: 4rem;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
}
.cards__modal .stopper-modal__scroll__container__title:before {
  display: block;
  margin: 0 auto 20px;
}
.cards__modal .stopper-modal__scroll__container__content {
  font-size: 1.7rem;
  text-align: center;
  color: #fff;
  margin: 20px auto;
}

sbmn-mta-cards-manager fieldset.center {
  margin-top: 30px;
}

#card__number {
  background-image: url("/assets/img/logos/cardtypes.png");
  background-position: left bottom;
  background-repeat: no-repeat;
  transition: background-position 0.3s linear;
  padding-left: 60px;
  height: 37px;
  margin-bottom: 35px;
}
#card__number.VISA {
  background-position: 0 1px;
}
#card__number.MASTERCARD {
  background-position: 0 -34px;
}
#card__number.AMEX {
  background-position: 0 -72px;
}
#card__number.DISCOVER {
  background-position: 0 -108px;
}
#card__number.DINERS, #card__number.DINERSBLANCHE {
  background-position: 0 -144px;
}
#card__number.JCB {
  background-position: 0 -179px;
}
#card__number.VISAELECTRON {
  background-position: 0 -216px;
}

.mtainfo {
  min-height: 400px;
  max-width: 950px;
  margin: 0 auto;
  width: 100%;
}

.newsletters {
  padding-bottom: 50px;
}
.newsletters .filters-form section {
  width: 96%;
  margin: auto;
}
@media only screen and (max-width: 1022px) {
  .newsletters .filters-form section {
    flex-direction: column;
  }
}
.newsletters__container {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 559px) {
  .newsletters__container {
    justify-content: center;
  }
}
.newsletters__container .h3withbold, .newsletters__container .cms__basicinfo__container__content > span > strong {
  width: 100%;
  text-align: center;
}
.newsletters__container__new {
  margin: 1.5%;
  border-radius: 10px;
  padding: 10px 1.5%;
  max-width: 30%;
  min-width: 30%;
  min-height: 100px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  background: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background 0.2s linear, color 0.2s linear;
}
.newsletters__container__new:hover {
  background: #0099ff;
}
.newsletters__container__new:hover h2 {
  color: #fff;
}
@media only screen and (max-width: 1022px) {
  .newsletters__container__new {
    max-width: 48%;
    min-width: 48%;
    margin: 1.5% 1%;
  }
}
@media only screen and (max-width: 559px) {
  .newsletters__container__new {
    max-width: 98%;
    min-width: 98%;
    margin: 1.5% 0.5%;
  }
}
.newsletters__container__new figure {
  margin: 0 10px 0 0;
  padding: 0 10px 0 0;
  border-right: 1px solid #656574;
}
.newsletters__container__new img {
  max-height: 70px;
}
.newsletters__container__new h2 {
  font-size: 1.5rem;
  line-height: 1;
  font-family: basiclight, Arial, sans-serif;
}
.newsletters__container__new h2 strong {
  padding-top: 5px;
  font-size: 0.8em;
  display: block;
}
.newsletters .stopper-modal h2 {
  width: 90%;
  margin: auto;
}
.newsletters sbmn-cms-templates img {
  max-width: 80%;
}
@media only screen and (max-width: 1022px) {
  .newsletters sbmn-cms-templates img {
    max-width: 100%;
  }
}
.newsletters .cms__openHTML__content {
  padding-top: 0;
  text-align: center;
}

.tutorials {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1022px) {
  .tutorials {
    justify-content: center;
  }
}
.tutorials figure {
  padding: 10px 1.5%;
  margin: 0;
  max-width: 33%;
}
@media only screen and (max-width: 1022px) {
  .tutorials figure {
    max-width: 50%;
  }
}
@media only screen and (max-width: 559px) {
  .tutorials figure {
    max-width: 100%;
  }
}
.tutorials figure:hover {
  cursor: pointer;
}
.tutorials figure:hover img {
  filter: grayscale(0);
}
.tutorials figure:hover .h2withbold,
.tutorials figure:hover p {
  color: #000;
}
.tutorials figure .h2withbold {
  font-size: 2rem;
  color: #666;
}
@media only screen and (max-width: 1022px) {
  .tutorials figure .h2withbold {
    font-size: 2.5rem;
    text-align: center;
  }
}
.tutorials img {
  max-width: 100%;
  filter: grayscale(0.7);
  transition: filter 0.2s linear;
}
.tutorials figcaption {
  margin: 10px auto;
  color: #666;
}

.mta-contact .cms__openHTML__content {
  padding: 10px 0 30px 0;
}
.mta-contact .cms__openHTML__content i {
  display: flex;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  padding: 0;
  font-size: 2.5rem;
}
.mta-contact .cms__openHTML__content i img {
  width: 3rem;
}
.mta-contact .cms__openHTML__content__plane-ball {
  width: 450px;
  height: 450px;
}
.mta-contact .cms__openHTML__content__yellow__block {
  font-size: 2.5rem;
  text-align: left;
  width: 100%;
}
.mta-contact .cms__openHTML__content__yellow__block a {
  font-size: 2.2rem;
}

.mymessages {
  min-height: 800px;
}
.mymessages.max-width-page, .mymessages.max-width-box {
  max-width: unset;
}
.mymessages .loader_abs, .mymessages .login__loading {
  height: 50%;
}
.mymessages__header__icon {
  position: absolute;
  margin: 12px 0 0 22px;
  padding-left: 60px;
  left: 2px;
  cursor: pointer;
  color: black;
  text-transform: uppercase;
  font-size: 1.4rem;
  width: 100px;
}
.mymessages__header__icon:before {
  left: 0;
  position: absolute;
  font-size: 2rem;
  padding: 8px;
  border: 2px solid black;
  border-radius: 50%;
}
.mymessages__header__icon:hover {
  color: #003366;
}
.mymessages__header__icon:hover:before {
  border: 2px solid #003366;
}
.mymessages__header .Bplus, .mymessages__header .Bless {
  margin-left: 20px;
}
.mymessages__container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
.mymessages__container__legend {
  padding: 5px 20px 5px;
  text-align: center;
  margin: 0 auto 20px;
  width: 96%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.mymessages__container__legend:first-child {
  margin: 20px auto 0;
}
.mymessages__container__legend strong {
  height: 30px;
  width: 2px;
  background-color: #666;
  opacity: 0.5;
  border-radius: 100px;
  margin-right: 15px;
}
.mymessages__container__legend span {
  position: relative;
  cursor: pointer;
  color: #4c4c4c;
  font-size: 1.5rem;
  margin: 5px 20px 5px 5px;
  display: inline-block;
  padding: 3px 10px 3px 10px;
  line-height: 1.2;
  border-bottom: 3px solid;
  transition: all 0.2s linear;
  min-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.6;
}
.mymessages__container__legend span:before {
  position: absolute;
  right: 7px;
  bottom: 5px;
  font-size: 1.2rem;
  transition: opacity 0.2s linear;
  opacity: 0;
  color: #fff;
}
.mymessages__container__legend span:hover, .mymessages__container__legend span.okselected {
  padding-right: 30px;
  opacity: 1;
}
.mymessages__container__legend span:hover:before, .mymessages__container__legend span.okselected:before {
  opacity: 1;
}
.mymessages__container__legend .code__all {
  border-color: #4c4c4c;
}
.mymessages__container__legend .code__all:before {
  color: #4c4c4c;
}
.mymessages__container__legend .code__flights {
  border-color: #005c99;
}
.mymessages__container__legend .code__flights:before {
  color: #005c99;
}
.mymessages__container__legend .code__general {
  border-color: #8b5dce;
}
.mymessages__container__legend .code__general:before {
  color: #8b5dce;
}
.mymessages__container__legend .code__warnings {
  border-color: #f48126;
}
.mymessages__container__legend .code__warnings:before {
  color: #f48126;
}
.mymessages__container__legend .code__unread {
  background-color: #b2b2b2;
  color: white;
  border-radius: 3px;
  border: 3px solid #b2b2b2;
  transition: all 0.2s linear;
}
.mymessages__container__legend .code__unread:before {
  color: #fff;
  bottom: 7px !important;
}
.mymessages__container__legend .code__unread.okselected {
  color: #000;
  background: #b5c57e;
  border-color: #b5c57e;
}
.mymessages__container__nomsgs {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  height: 200px;
  width: 100%;
  text-align: center;
  line-height: 25px;
}
.mymessages__container__group {
  padding: 0 20px;
  margin: 0 auto 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.mymessages__container__group__msg {
  position: relative;
  display: block;
  flex-grow: 1;
  flex-basis: 18%;
  margin: 10px;
  border-radius: 0 3px 3px 0;
  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.1);
  padding: 15px 35px 15px 15px;
  font-size: 1.4rem;
  line-height: 1.3;
  flex-wrap: wrap;
  min-width: 230px;
  max-width: 390px;
  cursor: pointer;
  background: #fff;
}
.mymessages__container__group__msg strong {
  text-transform: uppercase;
}
.mymessages__container__group__msg.general {
  border-left: 10px #427127 solid;
}
.mymessages__container__group__msg.general.read {
  border-left: 3px rgba(66, 113, 39, 0.2) solid;
}
.mymessages__container__group__msg.general i {
  color: #427127;
}
.mymessages__container__group__msg.flights {
  border-left: 10px #005c99 solid;
}
.mymessages__container__group__msg.flights.read {
  border-left: 3px rgba(0, 92, 153, 0.2) solid;
}
.mymessages__container__group__msg.flights i {
  color: #005c99;
}
.mymessages__container__group__msg.warnings {
  border-left: 10px #f48126 solid;
}
.mymessages__container__group__msg.warnings.read {
  border-left: 3px rgba(244, 129, 38, 0.2) solid;
}
.mymessages__container__group__msg.warnings i {
  color: #f48126;
}
.mymessages__container__group__msg > div {
  opacity: 1;
}
.mymessages__container__group__msg.read > div {
  opacity: 0.8;
}
.mymessages__container__group__msg.ok {
  top: 0;
  right: 0;
  color: inherit;
  cursor: pointer;
  transition: none;
}
.mymessages__container__group__msg.ok:hover {
  transform: none;
  color: inherit;
}
.mymessages__container__group__msg.ok:before {
  display: flex;
  justify-content: space-around;
  position: absolute;
  font-size: 1rem;
  right: -4px;
  top: 50px;
  transform: translateX(-50%) translateY(-50%);
  border: 2px solid #427127;
  border-radius: 100%;
  padding: 0px;
  color: #427127;
  height: 20px;
  width: 20px;
  align-items: center;
}
.mymessages__container__group__msg i {
  position: absolute;
  right: 7px;
  top: 7px;
  font-size: 3rem;
  opacity: 0.85;
}
.mymessages__container__group__msg .msg-v-id {
  font-size: 1.5em;
  color: #427127;
}
.mymessages__container__group__msg:hover {
  z-index: 1;
}
@media only screen and (min-width: 769px) {
  .mymessages__container__group__msg:hover {
    animation: MSG-TOUCH 0.3s;
    animation-fill-mode: forwards;
  }
}
@media only screen and (max-width: 1247px) {
  .mymessages__container__group {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 1022px) {
  .mymessages__container__group {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 768px) {
  .mymessages__container__group {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 1022px) {
  .mymessages__header__icon:before {
    font-size: 1.2rem;
  }
  .mymessages__container__nomsgs {
    height: 100px;
    font-size: 1.7rem;
  }
  .mymessages__container__legend {
    width: 96%;
    justify-content: center;
  }
  .mymessages__container__legend strong {
    display: none;
  }
  .mymessages__container__legend span {
    min-width: 30%;
    margin: 10px 10px 10px 5px;
  }
  .mymessages__container__group {
    padding: 0;
  }
  .mymessages__container__group__msg {
    max-width: unset;
  }
}
@media only screen and (max-width: 639px) {
  .mymessages__container__group {
    grid-template-columns: repeat(1, 1fr);
  }
  .mymessages__container__legend span {
    min-width: 100%;
    margin: 5px 0;
    padding: 20px 0;
  }
}

#mymessages {
  display: flex;
  position: relative;
}

sbmn-my-messages-manager + #mymessages {
  position: fixed;
  height: 100%;
}
sbmn-my-messages-manager span.msgmanager__header__nav__item:hover,
sbmn-my-messages-manager span.msgmanager__header__nav__item:focus {
  color: #000;
}

.msgmanager {
  padding-top: 10px;
}
.msgmanager__header {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
}
.msgmanager__header.reverse {
  flex-direction: row;
}
.msgmanager__header.msg-general:before, .msgmanager__header.msg-refunds:before, .msgmanager__header.msg-admin:before {
  color: #427127;
}
.msgmanager__header.msg-flights:before {
  color: #005c99;
}
.msgmanager__header.msg-warnings:before, .msgmanager__header.msg-web:before {
  color: #f48126;
}
.msgmanager__header:before {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 4rem;
}
@media only screen and (max-width: 1022px) {
  .msgmanager__header:before {
    top: 5px;
    right: 5px;
    font-size: 3rem;
  }
}
.msgmanager__header__title__date {
  display: block;
  font-size: 0.7em;
  color: #666;
}
.msgmanager__header__nav {
  display: flex;
}
.msgmanager__header__nav__item {
  max-width: 200px;
  min-width: 90px;
  margin-bottom: 20px;
  padding-left: 10px;
}
.msgmanager__header__nav__item i.m_icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  display: block;
  display: flex;
  margin-top: 0;
}
.msgmanager__header__nav__item i.conversacion:before {
  font-size: 3rem;
}
.msgmanager__header__desc {
  min-width: 100%;
  padding: 10px 0;
  margin: 10px 0 0 0;
  border: solid #ccc;
  border-width: 1px 0;
  font-size: 1.8rem;
  font-family: basiclight, Arial, sans-serif;
}
.msgmanager__header__desc.reserveData {
  display: flex;
  border-top: 0;
  margin-top: 0;
}
@media only screen and (max-width: 639px) {
  .msgmanager__header__desc.reserveData {
    display: block;
  }
}
.msgmanager__header__desc.reserveData div {
  flex: 1;
}
@media only screen and (max-width: 639px) {
  .msgmanager__header__desc.reserveData div {
    margin-bottom: 5px;
  }
  .msgmanager__header__desc.reserveData div:last-child {
    margin-bottom: 0;
  }
}
.msgmanager__header__desc.reserveData div strong {
  margin: 0 3% 0 0;
}
.msgmanager__new, .msgmanager__file {
  opacity: 0;
  height: 0;
  max-height: 0;
  overflow: hidden;
}
.msgmanager__new.in, .msgmanager__file.in {
  animation: fadeOutHeight 0.6s linear forwards reverse;
}
.msgmanager__new__title, .msgmanager__file__title {
  margin: 10px 0 5px;
}
.msgmanager__new__input, .msgmanager__file__input {
  width: 100%;
  height: 150px;
}
.msgmanager__new__button, .msgmanager__file__button {
  margin: 10px auto;
  display: block;
}
.msgmanager__file__title {
  margin: 20px 0 0;
}
.msgmanager__chat__title {
  margin-bottom: 5px;
}
.msgmanager__chat__list {
  padding: 10px;
  background: #fff;
  min-height: 200px;
  max-height: 400px;
  overflow-y: scroll;
  border: 1px solid #ccc;
}
.msgmanager__chat__list__item {
  padding: 3px 0;
  border-bottom: 1px solid #ccc;
}
.msgmanager__chat__list__item:last-child {
  border: 0;
}
.msgmanager__data fieldset.reserveData {
  display: flex;
}
@media only screen and (max-width: 1022px) {
  .msgmanager__data fieldset.reserveData {
    display: block;
  }
}
.msgmanager__data fieldset.reserveData mat-form-field {
  margin: 0 3% 0 0;
}
.msgmanager__data fieldset.reserveData mat-form-field:last-child {
  margin: 0;
}

sbmn-new-search i.m_icon {
  border-width: 0;
}
sbmn-new-search .menuicons, sbmn-new-search .menuinnericons {
  margin-bottom: 10px;
}
sbmn-new-search .menuicons i.m_icon, sbmn-new-search .menuinnericons i.m_icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  display: block;
  display: flex;
  margin-right: 10px;
  border-width: 2px;
}
sbmn-new-search .m_iconHide {
  position: absolute;
  font-size: 0;
  color: transparent;
  background: transparent;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
sbmn-new-search .m_iconHide i.m_icon {
  display: none;
}

sbmn-tpv, sbmn-inespay {
  display: block;
  min-width: 100%;
}
sbmn-tpv iframe, sbmn-inespay iframe {
  width: 100%;
  min-height: 750px;
  border: 0;
}
sbmn-tpv form > img, sbmn-inespay form > img {
  visibility: hidden;
}

.tpv__response {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: block;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  font-size: 2rem;
}
.tpv__response__header {
  width: 100%;
  margin: 3vh auto;
  text-align: center;
}
.tpv__response__header img {
  max-height: 70px;
}
.tpv__response__loading {
  align-items: flex-start;
  padding-top: 3%;
}
.tpv__response__container {
  margin-top: 6%;
  max-width: 600px;
  text-align: center;
}
.tpv__response__container__title.h2withbold {
  margin: 25px auto;
}
.tpv__response__container__text {
  margin: 30px;
}
.tpv__response__container__text:before {
  font-size: 3rem;
  margin: 20px auto;
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  border-radius: 50%;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #a8d88c;
  color: #a8d88c;
}
.tpv__response__container__text.delete:before {
  color: #f67b81;
  border: 3px solid #f67b81;
}
.tpv__response__container__text__p {
  font-size: 0.8em;
}

/*html, body {
  width: 100%;
  height: 100%;
}

body {
  background: white;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: black;
}*/
/*
.hero {
  background: transparent;
}

{
  width: 100%;
  height: auto;
  display: inline-block;
  border-radius: 10px;
  background: white;
}
.upload-box-header {
  width: 100%;
  height: 50px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.upload-box-header .control-circles {
  padding-left: 15px;
  height: 14px;
}
.upload-box-header .control-circles .circle {
  display: inline-block;
  margin: 0 4px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.upload-box-header .control-circles .circle.is-red {
  background: red;
}
.upload-box-header .header-title h2 + unselectable {
  font-size: 18px;
  font-weight: 400;
  color: grey;
}
.upload-box-header .gh-icon {
  padding-right: 15px;
}
.upload-box-header .gh-icon i {
  font-size: 24px;
  color: blue;
  margin-right: 5px;
  margin-left: 36px;
  cursor: pointer;
}*/
.upload-item,
.drop-container {
  width: 100%;
  margin: 20px auto;
}

.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: inset 0 0 22px -8px rgba(0, 0, 0, 0.6);
  background-color: #fff;
}
.drop-container p {
  font-size: 16px;
  font-weight: 400;
  color: darkgrey;
}

.upload-button {
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  padding: 0 2px;
  color: #0099ff;
}
.upload-button:hover {
  color: #000;
}

.upload-button input {
  display: none;
}

.upload-item {
  display: flex;
  align-items: center;
}

.upload-item-content {
  width: 100%;
}

.upload-item-content .filename {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-item-content .filename-left,
.upload-item-content .filename-right {
  display: flex;
  align-items: center;
  position: relative;
}

.upload-item-content .filename-right {
  color: #ff4500;
  opacity: 0.5;
  font-size: 15px;
  cursor: pointer;
}
.upload-item-content .filename-right:hover {
  opacity: 1;
}

.upload-item-content i.ko:hover {
  opacity: 1;
  color: #005c99;
}

.upload-item-content span {
  color: #0099ff;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
}

.upload-item-content .progress-content {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.progress-content .progress {
  display: block;
  position: relative;
  width: 100%;
  height: 12px;
  border-radius: 10px;
}

.progress-content .progress .bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  background: linear-gradient(90deg, #0099ff 0%, #a8d88c 100%);
}

.progress-content .progress .bar.is-done {
  background: #6ebd41;
}

.progress-text-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0 0;
}

.progress-text-content .progress-text {
  font-size: 13px;
  font-weight: 600;
  color: #0099ff;
  transition: color 200ms ease-out;
}

.progress-text-content .progress-text.is-done {
  color: #a8d88c;
}

.progress-text-content .speed-and-eta-text {
  font-size: 13px;
  font-weight: 600;
  color: #000;
}

.help-text {
  width: 100%;
  display: inline-block;
  padding: 10px 30px;
  font-size: 12px;
  color: #0099ff;
  box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.6);
}

.help-text span {
  display: block;
  text-align: center;
  margin: 5px 0;
}

.help-text span img {
  display: block;
  width: 30px;
  margin: 0 auto 5px auto;
}

.manage_upload .Berror {
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  position: relative;
  padding: 0 0 20px 0;
  display: block;
  font-size: 1.6rem;
}

.manage_upload__items {
  font-family: basicregular, Arial, sans-serif;
  color: #a8d88c;
  margin: 10px 0 0;
}

.manage_upload__button {
  margin: 10px auto;
}

sbmn-html2pdf .stopper-modal__scroll__container {
  color: #fff;
  font-size: 3rem;
}
sbmn-html2pdf .cssload-loader {
  margin: 0 0 170px 0;
}
sbmn-html2pdf .pdf_icon {
  position: relative;
  top: 5px;
}
@media only screen and (max-width: 1022px) {
  sbmn-html2pdf .pdf_icon {
    top: 0;
  }
}

sbmn-my-bookings-filters h2,
sbmn-my-messages-filters h2,
sbmn-mta-balance-filters h2,
sbmn-mta-users-filters h2 {
  font-family: basiclight, Arial, sans-serif;
  font-size: 3rem;
  font-weight: lighter;
  position: absolute;
  top: 17px;
  margin: 0;
}

.filters-form {
  font-size: 1.4rem;
}
.filters-form section {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.filters-form .ok,
.filters-form .avion,
.filters-form .billete,
.filters-form .calendar,
.filters-form .summary #simpledates_search-engine .day,
.summary #simpledates_search-engine .filters-form .day,
.filters-form .pasajero,
.filters-form .msg-general,
.filters-form .msg-admin {
  font-size: 20px;
  color: #999;
  margin-right: 10px;
}
.filters-form .ok {
  font-size: 15px;
}
.filters-form .calendar, .filters-form .summary #simpledates_search-engine .day, .summary #simpledates_search-engine .filters-form .day,
.filters-form .pasajero {
  transform: none;
}
.filters-form .Bplus, .filters-form .Bless {
  transition: all 0.2s linear;
  transform: rotate(45deg);
}
.filters-form .mat-form-field mat-icon {
  position: relative;
  float: right;
  top: -3px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
}
.filters-form .mat-form-field .mat-date-range-input {
  width: 85%;
  display: inline-block;
}

sbmn-youtube .cssload-loader__container {
  position: relative;
  background: transparent;
}

.youtube__video {
  margin: 5%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.youtube__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.light-theme .primary-text, .light-theme {
  color: #000;
  font-family: basicregular, Helvetica, sans-serif;
}

.light-theme .primary-background {
  background-color: #fff;
}
.light-theme .secondary-background {
  background-color: #f1f1f1;
}
.light-theme .primary-outline-color {
  border-color: #999;
}
.light-theme .secondary-text {
  color: #005c99;
  font-family: Arial, Helvetica, sans-serif;
}
.light-theme .friends-search-bar {
  background-color: #fff;
}
.light-theme .unread-messages-counter-container,
.light-theme .ng-chat-people-action,
.light-theme .ng-chat-people-action > i {
  color: #005c99;
  background-color: #003366;
}
.light-theme .load-history-action {
  background-color: #e5e5e5;
}
.light-theme .chat-window-input {
  background-color: #fff;
}
.light-theme .sent-chat-message-container,
.light-theme .file-message-container {
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

sbmn-chat {
  position: fixed;
  z-index: 8;
  right: -100%;
  bottom: 0;
  font-size: 12px;
  text-align: left;
  width: 50%;
  max-width: 350px;
  opacity: 0;
}
@media only screen and ( max-width: 560px) {
  sbmn-chat {
    max-width: 100%;
    width: 100%;
    height: calc(100vh - 60px);
  }
}
sbmn-chat ul {
  padding: 0;
  margin: 0;
}
sbmn-chat li {
  list-style: none;
  position: relative;
}
sbmn-chat.appear {
  right: 0;
}
sbmn-chat .ng-chat-title {
  font-family: basicbold, Arial, sans-serif;
  color: #fff;
  font-size: 1.9rem;
  width: 100%;
  max-height: 45px;
  height: 45px;
  background: #333;
  border-bottom: 6px solid #ccc;
  padding: 0 50px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
}
sbmn-chat .ng-chat-title.ng-chat-title-admin {
  background: #592e97;
}
sbmn-chat .ng-chat-title .ko {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: right;
  padding: 6px;
}
sbmn-chat .ng-chat-title .ko:hover {
  transform: scale(1.1) translateX(-4%);
}
sbmn-chat .ng-chat-title .ng-chat-unread-messages-count {
  position: relative;
  top: -3px;
  left: 10px;
}
sbmn-chat .notWorkingHours {
  font-size: 1.6rem;
  font-family: basiclight, Arial, sans-serif;
  padding: 20px;
}
sbmn-chat .notWorkingHours img {
  margin: 30px auto;
  width: 150px;
  opacity: 0.3;
  display: block;
}
sbmn-chat .friends-finder {
  padding: 20px 20px 0;
}
sbmn-chat .friends-search-bar {
  background-color: #fff;
  border-width: 0 0 3px 0;
  border-style: solid;
  border-color: #0099ff;
  font-size: 2rem;
  padding: 5px 0;
  width: 100%;
  margin-bottom: 10px;
}
sbmn-chat .friends-search-button {
  transition: opacity 0.2s linear;
  cursor: pointer;
  opacity: 0.4;
  right: 8px;
  position: absolute;
  top: 19px;
}
sbmn-chat .friends-search-button:hover {
  opacity: 1;
}
sbmn-chat .friends-mailed-button {
  right: 37px;
}
sbmn-chat .chat-users-list {
  font-family: basiclight, Arial, sans-serif;
  font-size: 1.8rem;
}
sbmn-chat .ng-chat-friends-list-container {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  position: relative;
  transition: border 0.2s linear;
  cursor: pointer;
  border-bottom: 2px solid #b2b2b2;
}
sbmn-chat .ng-chat-friends-list-container:hover {
  border-color: rgba(139, 93, 206, 0.5);
}
sbmn-chat .ng-chat-friends-list-container:hover .tooltip {
  opacity: 1;
}
sbmn-chat .ng-chat-friends-list-container.cantTake {
  cursor: not-allowed;
  border-color: #f1f1f1;
  user-select: none;
}
sbmn-chat .ng-chat-friends-list-container.cantTake > * {
  opacity: 0.6;
}
sbmn-chat .ng-chat-friends-list-container.cantTake.mine {
  cursor: pointer;
  border-color: #a8d88c;
}
sbmn-chat .ng-chat-friends-list-container.cantTake.mine > * {
  opacity: 1;
}
sbmn-chat .ng-chat-friends-list-container.cantTake .friends-mailed-button {
  display: none;
}
sbmn-chat .ng-chat-friends-list-container .tooltip {
  width: calc(100% - 75px);
  opacity: 0;
  transition: opacity 0.2s linear;
  height: 100%;
  background-color: #fff;
  text-align: left;
  padding: 10px 10px 10px 5px;
  position: absolute;
  z-index: 0;
  right: 0;
  font-size: 0.75em;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}
sbmn-chat .chat-user-avatar {
  margin: 5px 20px 5px 0;
  border-radius: 50%;
}
sbmn-chat .chat-user-avatar.is-admin {
  opacity: 0.5;
}
sbmn-chat .chat-user-info {
  text-transform: uppercase;
  font-family: basicregular, Arial, sans-serif;
  font-size: 1.5rem;
  display: inline-block;
  max-width: calc(100% - 150px);
}
sbmn-chat .chat-user-info em {
  text-transform: none;
  font-size: 0.8em;
  display: block;
  color: #999;
}
sbmn-chat .ng-chat-participant-status {
  position: absolute;
  left: 50px;
  top: 10px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #666;
}
sbmn-chat .ng-chat-participant-status.online {
  background: #a8d88c;
}
sbmn-chat .ng-chat-participant-status.busy {
  background: #ff4500;
}
sbmn-chat .ng-chat-participant-status.forbidden {
  border-radius: 0%;
  background-color: white;
  width: 20px;
  height: 20px;
  background-image: url("/assets/img/icons/forbidden.svg");
}
sbmn-chat .startConv {
  min-width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}
sbmn-chat .ng-chat-unread-messages-count {
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  width: 20px;
  height: 20px;
  line-height: 1;
  font-family: basicbold, Arial, sans-serif;
  position: absolute;
  top: 40px;
  left: 47px;
}
sbmn-chat .ng-chat-messages {
  overflow-y: auto;
  height: calc(100% - 116px);
}
sbmn-chat .ng-chat-message {
  display: flex;
  margin: 10px;
  font-size: 1.5rem;
  justify-content: flex-end;
  letter-spacing: 0.03em;
}
sbmn-chat .ng-chat-message.ng-chat-message-received {
  justify-content: flex-start;
}
sbmn-chat .ng-chat-message .no-user-icon {
  width: 50px;
  display: inline-block;
}
sbmn-chat .ng-chat-message .chat-user-avatar {
  margin: 0 10px 0 0;
}
sbmn-chat .received-chat-message-container,
sbmn-chat .sent-chat-message-container {
  width: 100%;
  max-width: 65%;
  font-size: 1.4rem;
  padding: 5px 10px 20px;
  border: 2px solid #4c4c4c;
  display: flex;
  justify-items: center;
  text-align: left;
  position: relative;
  word-break: break-word;
  line-height: 1.2;
}
sbmn-chat .sent-chat-message-container .message-sent-date {
  color: #4c4c4c;
}
sbmn-chat .message-sent-date {
  font-size: 1rem;
  position: absolute;
  bottom: 2px;
  right: 2px;
  color: #ccc;
}
sbmn-chat .ng-chat-footer {
  position: absolute;
  bottom: -1px;
  width: 100%;
  left: 0;
}
sbmn-chat .ng-chat-footer textarea {
  font-size: 1.5rem;
  font-family: basiclight, Arial, sans-serif;
  min-height: 30px;
  width: 100%;
  line-height: 1.5;
  border-bottom: 1px solid #ccc;
  background-color: #4c4c4c;
  border-radius: 0;
}

#ng-chat {
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
}

#ng-chat-people,
.ng-chat-window {
  position: relative;
  max-width: 33%;
  min-width: 300px;
  height: 500px;
  max-height: 100vh;
  min-height: 40vh;
  margin-right: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  border-left: solid #003366 8px;
  background: #fff;
  overflow-y: hidden;
}
@media only screen and ( max-width: 560px) {
  #ng-chat-people,
  .ng-chat-window {
    margin: auto;
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
  }
}
#ng-chat-people.ng-chat-window-collapsed,
.ng-chat-window.ng-chat-window-collapsed {
  display: none;
}

.ng-chat-notadmin {
  position: absolute;
  right: 0;
}

.ng-chat-history {
  position: relative;
}
.ng-chat-history .Bplus, .ng-chat-history .Bless {
  position: absolute;
  right: 0;
  top: 16px;
  transform: rotate(45deg);
}

.chat-hide {
  display: none;
  position: fixed;
  top: -30000px;
  z-index: -1;
}

sbmn-videoconf {
  z-index: 1000;
  position: absolute;
}
sbmn-admin-tools sbmn-videoconf .stopper-modal {
  max-width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
}
sbmn-admin-tools sbmn-videoconf .stopper-modal .stopper-modal__scroll {
  overflow-y: hidden;
}
sbmn-admin-tools sbmn-videoconf .stopper-modal .ko {
  display: unset;
  left: unset !important;
  top: 20px;
  right: 20px;
}
sbmn-videoconf .stopper-modal__scroll__container {
  max-width: 1300px;
  position: relative;
}
sbmn-videoconf .videoconf__title {
  margin: 0 auto 40px;
  max-width: 600px;
}
@media only screen and (max-width: 1022px) {
  sbmn-videoconf .videoconf__title.h2withbold {
    font-size: 3rem;
  }
}
sbmn-videoconf .videoconf__conversation {
  border: 3px solid #ccc;
  border-radius: 30px;
  font-size: 1.6rem;
  line-height: 1.2;
  color: #b2b2b2;
  font-family: basicregular, Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64vh;
  width: 113.7777777778vh;
  max-width: 100%;
  min-height: 300px;
  position: relative;
  overflow: hidden;
  margin: auto;
  padding: 20px;
}
@media only screen and (max-width: 1022px) {
  sbmn-videoconf .videoconf__conversation {
    max-height: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 559px) {
  sbmn-videoconf .videoconf__conversation {
    width: 85vw;
  }
}
sbmn-videoconf .videoconf__conversation strong {
  color: #fff;
  display: inline;
}
sbmn-videoconf .videoconf__conversation__content {
  max-width: 70%;
}
@media only screen and (max-width: 1022px) {
  sbmn-videoconf .videoconf__conversation__content {
    max-width: 90%;
  }
}
sbmn-videoconf .videoconf__conversation__content .play:before {
  margin: 30px;
  font-size: 12rem;
  color: #fff;
  display: block;
  transition: color 0.2s linear;
  cursor: pointer;
}
@media only screen and (max-width: 559px) {
  sbmn-videoconf .videoconf__conversation__content .play:before {
    font-size: 6rem;
  }
}
sbmn-videoconf .videoconf__conversation__content .play:hover:before {
  color: #005c99;
}
sbmn-videoconf .videoconf__conversation__video {
  position: absolute;
  width: 100%;
  height: 100%;
}
sbmn-videoconf .videoconf__conversation__icon {
  position: relative;
}
sbmn-videoconf .videoconf__conversation__icon:before {
  display: block;
  font-size: 20rem;
  color: #4c4c4c;
  margin-bottom: 30px;
}
sbmn-videoconf .videoconf__conversation__icon figcaption {
  margin-top: 30px;
}
sbmn-videoconf .videoconf__conversation__forbidden {
  width: 40px;
  height: 40px;
  background-image: url("/assets/img/icons/forbidden.svg");
  background-size: cover;
  position: absolute;
  margin-left: 50px;
  left: 50%;
  top: 0;
}

.videoConfUser {
  font-size: 1.2em;
  display: block;
  padding-top: 5px;
}

#protected_adminvideoconf {
  position: fixed;
  color: #fff;
  background-color: #ff4500;
  padding: 10px 20px;
  left: 0;
  top: 0;
  font-size: 2rem;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: color 0.2s linear;
  z-index: 8;
}
#protected_adminvideoconf:hover {
  color: #592e97;
}
#protected_adminvideoconf:hover:before {
  color: #fff;
}
#protected_adminvideoconf:before {
  transition: color 0.2s linear;
  font-size: 51px;
  color: #592e97;
  margin: -10px 10px;
}

sbmn-admin-tools sbmn-chat {
  width: 50%;
  max-width: 500px;
  height: calc(100vh - 50px);
}
sbmn-admin-tools .chat-users-list {
  user-select: none;
}
sbmn-admin-tools .ng-chat-friends-list-container {
  flex-wrap: wrap;
}
sbmn-admin-tools .startConvWith {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -10px 0 10px;
  padding: 0 10px;
}
sbmn-admin-tools .startConvWith span {
  min-width: 45%;
  font-size: 1.4rem;
}
sbmn-admin-tools .startConvWith .mat-form-field {
  display: block;
  min-width: 45%;
}
sbmn-admin-tools .startConvWith .mat-form-field-infix {
  border-top: 0;
  padding-top: 0;
}
sbmn-admin-tools .startConvWith .mat-form-field-wrapper {
  margin-bottom: -1em;
}
sbmn-admin-tools .ng-chat-department {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1;
  font-family: basicbold, Arial, sans-serif;
}
sbmn-admin-tools .ng-chat-department:before {
  width: 5px;
  height: 100%;
  background: #a8d88c;
  content: "";
  animation: blinkbullet 2s infinite;
  position: absolute;
  left: 0;
  top: 0;
}
sbmn-admin-tools #chaticon {
  cursor: pointer;
}
sbmn-admin-tools .incomingChat {
  animation: pulseChat 0.5s alternate infinite;
}
sbmn-admin-tools #ng-chat-people,
sbmn-admin-tools .ng-chat-window {
  margin: 0;
  max-width: 100%;
  min-width: 100%;
  height: calc(100vh - 50px);
  max-height: 100vh;
  position: absolute;
  right: 0;
}
sbmn-admin-tools .ng-chat-title-admin .ko {
  display: none;
}
sbmn-admin-tools .stopper-modal {
  top: 50px;
  max-width: calc(100% - 500px);
  height: calc(100vh - 50px);
  width: 100%;
}
@media only screen and (max-width: 1022px) {
  sbmn-admin-tools .stopper-modal {
    max-width: 50%;
  }
}
sbmn-admin-tools .stopper-modal__scroll__container {
  top: 0;
}
sbmn-admin-tools .stopper-modal ul {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
sbmn-admin-tools .stopper-modal li {
  width: 100%;
  font-weight: bold;
  font-size: 2rem;
  margin-right: 10px;
  color: #999;
  transition: color 0.2s linear;
  min-width: 122px;
  padding: 5px;
}
sbmn-admin-tools .stopper-modal li strong {
  color: #000;
  font-family: basicbold, Arial, sans-serif;
}
sbmn-admin-tools .stopper-modal li small {
  cursor: pointer;
  font-family: basiclight, Arial, sans-serif;
  letter-spacing: 0.07em;
}
sbmn-admin-tools .stopper-modal li small:hover {
  color: #0099ff;
}
sbmn-admin-tools .stopper-modal .ko {
  display: none;
}

.atools__header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000;
}
.atools__header__figure {
  position: relative;
  margin: 0 10px;
  height: 100%;
  display: flex;
  font-size: 2rem;
  color: #0099ff;
  align-items: center;
  max-width: 50%;
}
.atools__header__logo {
  max-height: 90%;
  margin-right: 1.5vw;
  max-width: 50%;
}
.atools__header__nav {
  max-height: 100%;
  display: flex;
  align-items: center;
}
.atools__header__nav img {
  max-height: 40px;
  margin: 0 10px;
}

* {
  box-sizing: border-box;
  outline: none !important;
}

html {
  font-size: 75%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html,
body {
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  line-height: 1;
  font-family: special, Arial, sans-serif;
}

body:before, body:after {
  top: 0;
  width: 100%;
  content: "";
  height: 100%;
  position: fixed;
  z-index: -1;
}
body:before {
  background-image: url("/assets/img/logos/B_logo.svg");
  background-position: top center;
  background-size: 200% auto;
  opacity: 0.1;
}
body:after {
  background-color: #fff;
  opacity: 0.5;
}

.max-width-page, .max-width-box {
  max-width: 1440px;
  margin: auto;
  min-width: 0;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.max-width-box {
  overflow: visible;
}

/*--------------------------------------LINKS -----------_*/
a,
.returnlink,
.login-reset-nav,
.link {
  color: #0099ff;
  text-decoration: underline;
  font-family: "Raleway", sans-serif;
  cursor: pointer;
  font-size: 1.6rem;
}
a:hover,
.returnlink:hover,
.login-reset-nav:hover,
.link:hover {
  color: black;
  text-decoration: none;
}

/*------------------------------------ MODALS & DIALOGS --------*/
.Btooltip {
  z-index: 7;
  position: absolute;
}

.Btooltip_back, .backcover,
.Btooltip_back:before,
.backcover:before {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  display: block;
}

.Btooltip_back, .backcover {
  background: rgba(0, 0, 0, 0.93);
}

.Btooltip_back:before, .backcover:before {
  content: " ";
  opacity: 0.8;
}

.Btooltip_content {
  position: absolute;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}
.Btooltip_content .ko {
  top: -30px;
  right: -30px;
}

.appear {
  opacity: 0;
  animation: appears 0.2s linear forwards;
}

.dialog, .leftdialog {
  position: fixed;
  z-index: 5;
  background: white;
  width: 45%;
  height: 100%;
  padding: 65px 0 90px 0;
  top: 0;
  font-size: 1.6rem;
  overflow: hidden;
  max-width: 810px;
}
.dialog.show + *, .show.leftdialog + * {
  position: fixed;
  width: 100%;
}
.dialog .dialog__header, .leftdialog .dialog__header {
  background: #0099ff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
}
.dialog .dialog__header__title, .leftdialog .dialog__header__title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  margin-right: 20px;
  color: #fff;
  padding: 5px 20px;
  font-size: 2rem;
}
.dialog .dialog__content, .leftdialog .dialog__content {
  width: 200%;
  overflow-y: scroll;
  position: relative;
  height: 100%;
  border: solid #ccc;
  border-width: 0 0 1px 0;
  padding: 0 0 50px;
  background: #fff;
}
.dialog .dialog__content > *, .leftdialog .dialog__content > * {
  display: block;
  max-width: calc(50% + 10px);
}
.dialog .ko, .leftdialog .ko {
  color: #fff;
  font-size: 2rem;
}

.leftdialog {
  transition: left 0.5s, opacity 0.5s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  left: -46%;
  opacity: 0;
}
.leftdialog.show {
  left: 0;
  opacity: 1;
  min-width: 600px;
}
.leftdialog.hide {
  opacity: 0;
  left: -46%;
}

.backcover {
  z-index: 5;
}

.stopper-modal {
  position: fixed;
  width: calc(100% + 30px);
  height: 100%;
  background: rgba(0, 0, 0, 0.93);
  top: 0;
  left: 0;
  z-index: 8;
  padding: 0 !important;
}
.stopper-modal ~ * {
  position: fixed;
}
.stopper-modal__scroll {
  position: relative;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  padding: 0 0 60px 0;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 1022px) {
  .stopper-modal__scroll {
    width: calc(100% - 30px);
  }
}
.stopper-modal__scroll.expand-modal {
  width: 100%;
  padding-right: 15px;
}
@media only screen and (max-width: 1022px) {
  .stopper-modal__scroll.expand-modal .ko {
    left: calc(98% - 5px);
  }
}
.stopper-modal__scroll.expand-modal__container {
  width: 85%;
}
.stopper-modal__scroll__container {
  color: #005c99;
  text-align: center;
  width: 86%;
  margin: auto;
  max-width: 960px;
  padding: 0 0 5%;
  z-index: 1;
  position: absolute;
  top: 60px;
}
.stopper-modal__scroll__container__title.h2withbold {
  font-size: 4rem;
  color: #fff;
}
.stopper-modal__scroll__container__title.warning {
  font-size: 4rem;
  color: #f9b987;
}
.stopper-modal__scroll__container__content {
  font-size: 1.5rem;
  line-height: 1.2;
  text-align: left;
  margin: 40px 0;
  color: #fff;
  position: relative;
  overflow: hidden;
  transition: min-height 0.2s linear;
  word-break: break-word;
}
.stopper-modal__scroll__container__content.reduced {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.stopper-modal__scroll__container__content.reduced > div {
  border-bottom: 1px solid #4c4c4c;
  padding: 10px 0;
}
.Berror .stopper-modal__scroll__container__content {
  text-align: center;
  color: #f9b987;
  font-size: 1.7rem;
}
.stopper-modal__scroll__container__content.justify {
  text-align: justify;
  padding: 0 10%;
}
.stopper-modal__scroll__container__content br {
  line-height: 30px;
}
.stopper-modal__scroll__container__content__br br {
  line-height: 1;
}
.stopper-modal__scroll__container__content.white_modal {
  background: #fff;
  color: #000;
  padding: 5%;
  border-radius: 0 25px 0 25px;
  max-width: 100%;
}
.stopper-modal__scroll__container__content .cssload-loader__container {
  transition: height 0.5s linear;
  min-height: 340px;
}
.stopper-modal__scroll .ko {
  z-index: -1;
}

.__ko, .stopper-modal__scroll .ko {
  position: fixed;
  color: #b2b2b2;
  width: 100px;
  height: 100px;
  top: 0;
  left: calc(50% + 520px);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1439px) {
  .__ko, .stopper-modal__scroll .ko {
    left: 94%;
    width: auto;
    height: 40px;
  }
}
@media only screen and (max-width: 1022px) {
  .__ko, .stopper-modal__scroll .ko {
    left: calc(98% - 30px);
  }
}
.__ko:hover, .stopper-modal__scroll .ko:hover {
  color: #fff;
  transform: none;
}
.__ko:hover:before, .stopper-modal__scroll .ko:hover:before {
  transform: scale(1.1);
  color: #0059b3;
}
@media only screen and (max-width: 639px) {
  .__ko:before, .stopper-modal__scroll .ko:before {
    font-size: 1.55rem;
  }
}

/*--------------------------------------ICONS -----------_*/
.Bplus, .Bless {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  display: block;
  color: #0099ff;
  display: inline-flex;
  margin: 0 5px;
  cursor: pointer;
  font-size: 1.8rem;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 0;
}
.Bplus:hover, .Bless:hover {
  color: #fff;
  background: #005c99;
}
.Bplus.mini, .mini.Bless {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border-radius: 50%;
  display: block;
  font-size: 1.7rem;
}
.Bplus.special, .special.Bless {
  min-width: 40px;
  min-height: 40px;
  font-size: 2.3rem;
  transition: background 0.2s linear;
  background: transparent;
  color: #0099ff;
  border: 1px solid #0099ff;
}
.Bplus.special:before, .special.Bless:before {
  opacity: 0.5;
  transition: opacity 0.2s linear;
}
.Bplus.special:hover, .special.Bless:hover {
  color: #005c99;
  border-color: #005c99;
}
.Bplus.special:hover:before, .special.Bless:hover:before {
  opacity: 1;
}
.Bplus.special.reverse, .special.reverse.Bless {
  background: rgba(0, 153, 255, 0.5);
  color: #fff;
}
.Bplus.special.reverse:hover, .special.reverse.Bless:hover {
  background: #0099ff;
  color: #fff;
}

.Bless:before {
  letter-spacing: 0;
  font-size: 1.5rem;
  color: #f48126;
}

.ko {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #7f7f7f;
  font-size: 2.5rem;
  cursor: pointer;
  transition: all 0.2s linear;
}
.ko:hover {
  color: #fff;
  transform: scale(1.1);
}

#navutils {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 8;
}
#navutils i {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  display: block;
  transition: opacity 0.2s linear;
  opacity: 0.6;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  background: #005c99;
  margin: 5px 0 0;
}
#navutils i:before {
  opacity: 0.4;
  color: #fff;
}
#navutils i.hcontrast {
  opacity: 0.8;
}
#navutils i.hcontrast:before {
  opacity: 0.7;
}
#navutils i:hover {
  opacity: 1;
}
#navutils i:hover:before {
  opacity: 1;
}
#navutils #chaticon {
  background: #fff;
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  margin-left: 3px;
  margin-bottom: 15px;
}
#navutils #chaticon img {
  min-width: 40px;
  max-width: 40px;
  z-index: 1;
}
#navutils #chaticon .unseen {
  position: absolute;
  left: 36px;
  top: 0;
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
}
#navutils .end {
  transform: rotate(-90deg);
}
#navutils .ayuda {
  font-size: 4.2rem;
  background: transparent;
}
#navutils .ayuda:before {
  color: #005c99;
}

.soloida,
.idavuelta, .menuicons i, .menuinnericons i, sbmn-flights-messages i {
  width: 50px;
  height: 50px;
  margin: 5px;
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  font-size: 0;
}
.soloida:before,
.idavuelta:before, .menuicons i:before, .menuinnericons i:before, sbmn-flights-messages i:before {
  font-size: 3rem;
  color: #fff;
  position: absolute;
}

.menuicons, .menuinnericons {
  color: #0589fa;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  max-width: 75px;
  align-items: center;
  text-align: center;
  line-height: 1;
}
.menuicons:hover, .menuinnericons:hover, .menuicons:focus, .menuinnericons:focus, .menuicons.active, .active.menuinnericons {
  color: #0099ff;
}
.menuicons:hover i.m_icon:before, .menuinnericons:hover i.m_icon:before, .menuicons:focus i.m_icon:before, .menuinnericons:focus i.m_icon:before, .menuicons.active i.m_icon:before, .active.menuinnericons i.m_icon:before {
  color: #0099ff;
}
.menuicons:hover i.cancelar:before, .menuinnericons:hover i.cancelar:before, .menuicons:focus i.cancelar:before, .menuinnericons:focus i.cancelar:before, .menuicons.active i.cancelar:before, .active.menuinnericons i.cancelar:before {
  color: #9a0b11;
}
.menuicons i.cancelar, .menuinnericons i.cancelar {
  color: #f67b81;
}
.menuicons i.cancelar:before, .menuinnericons i.cancelar:before {
  color: #f67b81;
}
.menuicons i.cancelar:hover:before, .menuinnericons i.cancelar:hover:before, .menuicons i.cancelar:focus:before, .menuinnericons i.cancelar:focus:before, .menuicons i.cancelar.active:before, .menuinnericons i.cancelar.active:before {
  color: #9a0b11;
}
.menuicons.disabled, .disabled.menuinnericons {
  color: #7f7f7f;
}
.menuicons.disabled:hover, .disabled.menuinnericons:hover {
  color: #7f7f7f;
}
.menuicons.disabled i.m_icon, .disabled.menuinnericons i.m_icon,
.menuicons.disabled i.m_icon:hover,
.menuicons.disabled i.m_icon:focus,
.menuicons.disabled i.m_icon.active {
  cursor: default;
  opacity: 0.7;
  border: 0;
}
.menuicons.disabled i.m_icon:before, .disabled.menuinnericons i.m_icon:before,
.menuicons.disabled i.m_icon:hover:before,
.menuicons.disabled i.m_icon:focus:before,
.menuicons.disabled i.m_icon.active:before {
  transform: scale(1);
  color: #7f7f7f;
}

i.m_icon {
  cursor: pointer;
  transition: background 0.2s linear;
  height: 35px;
}
i.m_icon:before {
  color: #0589fa;
  transition: color 0.2s linear;
}
i.m_icon:hover, i.m_icon:focus, i.m_icon.active {
  color: #0099ff;
}
i.m_icon:hover:before, i.m_icon:focus:before, i.m_icon.active:before {
  color: #0099ff;
  transform: scale(1.1);
}
i.m_icon.active.selected {
  color: #fff;
}

.menuinnericons {
  position: absolute;
  top: -35px;
  right: -35px;
  font-size: 1.3rem;
}
.menuinnericons:hover, .menuinnericons:focus, .menuinnericons.active {
  color: #000;
}
.menuinnericons i {
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
}

.soloida,
.idavuelta {
  position: absolute;
  border: 2px solid #fff;
}

span.soloida {
  transition: margin 0.1s linear;
  margin: 1rem 0 0 7.5rem;
}

.-icon-hide {
  color: #fff;
}
.-icon-hide .menosopciones {
  display: none;
}
th:hover .-icon-hide .menosopciones {
  position: absolute;
  right: -20px;
}

.menosopciones.arrow-rotate {
  transform: rotate(180deg);
  transform-origin: 50% 47%;
}

.msgbullet {
  background: #005c99;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border-radius: 50%;
  display: block;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  color: #fff;
  animation: pulsebullet 1s alternate infinite;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*--------------------------------------BOX -----------_*/
#main_header figure img {
  width: 100%;
}

.Bborder-b, sbmn-flights-offers:before, #searchEngineForm:before, #loading-data:before, .Bborder-y, sbmn-flights-highlight section:before, sbmn-flights-messages:before, sbmn-mta-balance-manager:before, sbmn-mta-users-manager:before, sbmn-mta-cards-manager:before, sbmn-my-messages .white_modal:before, .drop-container:before {
  border-left: 10px solid;
}

sbmn-mta-balance-manager .Bborder-y, sbmn-mta-balance-manager sbmn-flights-highlight section:before, sbmn-flights-highlight sbmn-mta-balance-manager section:before, sbmn-mta-balance-manager sbmn-flights-messages:before, sbmn-mta-balance-manager sbmn-mta-balance-manager:before, sbmn-mta-balance-manager sbmn-mta-users-manager:before, sbmn-mta-cards-manager .Bborder-y, sbmn-mta-cards-manager sbmn-flights-highlight section:before, sbmn-flights-highlight sbmn-mta-cards-manager section:before, sbmn-mta-cards-manager sbmn-flights-messages:before, sbmn-mta-cards-manager sbmn-mta-balance-manager:before, sbmn-mta-balance-manager sbmn-mta-cards-manager:before, sbmn-mta-cards-manager sbmn-mta-cards-manager:before, sbmn-mta-cards-manager sbmn-mta-users-manager:before, sbmn-mta-balance-manager sbmn-my-messages .white_modal:before, sbmn-my-messages sbmn-mta-balance-manager .white_modal:before, sbmn-mta-cards-manager sbmn-my-messages .white_modal:before, sbmn-my-messages sbmn-mta-cards-manager .white_modal:before, sbmn-mta-balance-manager .drop-container:before, sbmn-mta-cards-manager .drop-container:before {
  position: relative;
  bottom: unset;
  left: unset;
  width: unset;
  height: unset;
  border: 0;
}

.Bborder-y, sbmn-flights-highlight section:before, sbmn-flights-messages:before, sbmn-mta-balance-manager:before, sbmn-mta-users-manager:before, sbmn-mta-cards-manager:before, sbmn-my-messages .white_modal:before, .drop-container:before {
  border-color: #05c7fa;
}

.Bborder-b, sbmn-flights-offers:before, #searchEngineForm:before, #loading-data:before {
  border-left: 30px solid #003366;
  border-bottom: 30px solid #003366;
}

.Bbacky {
  background-color: #003366;
}

.Bbackblr,
.Simple {
  background-color: #003366;
}

.Bbackbl,
.Calendario {
  background-color: rgba(0, 115, 230, 0.4);
}

.Bbackb,
.Multidestino {
  background-color: black;
}

.Bbackbd {
  background-color: #000;
}

.returnlink {
  position: absolute;
  top: 30px;
  left: 30px;
  text-transform: uppercase;
}

.brand_header {
  text-align: center;
  background-color: transparent;
}
.brand_header.Multidestino {
  background: black;
}
.brand_header.Multidestino .h1withbold, .brand_header.Multidestino .h4withbold, .brand_header.Multidestino .h3withbold, .brand_header.Multidestino .cms__basicinfo__container__content > span > strong, .brand_header.Multidestino .h2withbold {
  color: #0073e6;
}
.brand_header.Calendario {
  background-color: rgba(0, 89, 179, 0.2);
}
.brand_header .h1withbold, .brand_header .h4withbold, .brand_header .h3withbold, .brand_header .cms__basicinfo__container__content > span > strong, .brand_header .h2withbold {
  margin: 0;
  font-size: 4.5rem;
  padding: 15px 0;
  color: #0099ff;
}
@media only screen and (max-width: 1022px) {
  .brand_header .h1withbold, .brand_header .h4withbold, .brand_header .h3withbold, .brand_header .cms__basicinfo__container__content > span > strong, .brand_header .h2withbold {
    font-size: 2.5rem;
  }
}

.orange-pill {
  background: #f9b987;
  padding: 5px 18px;
  border-radius: 10px;
  display: inline-block;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  color: #000;
  font-size: 1.1em;
}

/*--------------------------------------TEXT -----------_*/
.logotext {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  padding: 0 10px;
  color: #000;
}
.logotext span,
.logotext i {
  background: #003366;
  padding: 1px 10px;
  border: 2px solid #000;
}
.logotext span:nth-of-type(1) {
  margin: 10px 0 10px 10px;
  border-width: 2px 0 2px 2px;
}
.logotext span:nth-of-type(2) {
  margin: 10px 10px 10px 0;
  border-width: 2px 2px 2px 0;
}
.logotext i {
  padding: 9px 10px 5px;
  top: 2px;
  position: relative;
}
.logotext i:before {
  transform: rotate(-45deg);
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}

.h1withbold, .h4withbold, .h3withbold, .cms__basicinfo__container__content > span > strong, .h2withbold {
  font-size: 5rem;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  margin: 0 0 10px 0;
  color: #005c99;
}
.h1withbold strong, .h4withbold strong, .h3withbold strong, .cms__basicinfo__container__content > span > strong strong, .h2withbold strong {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}

.h2withbold {
  font-size: 3.5rem;
  color: #000;
}

.h3withbold, .cms__basicinfo__container__content > span > strong {
  font-family: "Raleway", sans-serif;
  font-size: 2.5rem;
  color: #005c99;
}

.h4withbold {
  font-size: 1.8rem;
  font-family: "Raleway", sans-serif;
  color: #0099ff;
}
.h4withbold .Berror {
  position: relative;
  bottom: 0;
  display: block;
  padding: 5px 0;
  font-size: 0.8em;
}

.Bwarning {
  color: #f48126;
}

.Berror {
  color: #ef1c25;
  padding: 20px;
  display: inline-block;
}

#loading-data header > div {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  max-width: 700px;
}

sbmn-flights-messages section div, sbmn-flights-offers div {
  font-family: "Raleway", sans-serif;
  font-size: 1.6rem;
  max-width: 700px;
}

.tstop {
  font-family: "Raleway", sans-serif;
  color: #2b1649;
  padding: 2px 0;
  line-height: 1;
  font-size: 0.9em;
}

/*

888888b.   8888888b.         d8888 888b    888 8888888b.        .d8888b.   .d8888b.   .d8888b.
888  "88b  888   Y88b       d88888 8888b   888 888  "Y88b      d88P  Y88b d88P  Y88b d88P  Y88b
888  .88P  888    888      d88P888 88888b  888 888    888      888    888 Y88b.      Y88b.
8888888K.  888   d88P     d88P 888 888Y88b 888 888    888      888         "Y888b.    "Y888b.
888  "Y88b 8888888P"     d88P  888 888 Y88b888 888    888      888            "Y88b.     "Y88b.
888    888 888 T88b     d88P   888 888  Y88888 888    888      888    888       "888       "888
888   d88P 888  T88b   d8888888888 888   Y8888 888  .d88P      Y88b  d88P Y88b  d88P Y88b  d88P
8888888P"  888   T88b d88P     888 888    Y888 8888888P"        "Y8888P"   "Y8888P"   "Y8888P"

*/
.mtamain__container__section:before,
sbmn-flights-messages i.avion:before,
.mta__menu__link.active,
.mta__menu__link:hover i,
.mta__menu__link.active i,
sbmn-mta-cards .basic__table__body__cross.webcam {
  color: #0589fa;
}

#list_offers .idavuelta,
#list_offers .soloida {
  background-color: #0589fa;
}
#list_offers .idavuelta:before,
#list_offers .soloida:before {
  color: #fff;
}

#main_header {
  background: #fff;
}
#main_header figure img {
  width: auto;
  max-height: 55px;
}
#main_header.sticky .main_header__logo {
  max-width: 300px;
}
#main_header.sticky .main_header__logo img {
  max-width: 180px;
}
#main_header.sticky .main_header__logo figcaption {
  left: 150px;
}

sbmn-flights-highlight section {
  background: rgba(5, 137, 250, 0.5);
}

#destdates_search-engine .idavuelta {
  border: 1px solid #0589fa;
}
#destdates_search-engine .idavuelta:before {
  color: #0589fa;
}
#destdates_search-engine .simpledates_dateview:hover {
  color: #0589fa;
}

#nav_search-engine span.selected:before {
  color: #0589fa;
}

#passengers_search-engine > div:hover .pasajero__container,
#passengers_search-engine > div:hover .pasajero:before {
  color: #0589fa;
  border-color: #0589fa;
}

.fresult_headerscheme p {
  filter: grayscale(0.5);
}

.mymessages__container__legend .code__general {
  border-color: #005c99;
}

.mymessages__container__group__msg.general {
  border-left-color: #005c99;
}

.mymessages__container__group__msg.general.read {
  border-left-color: rgba(0, 92, 153, 0.2);
}

.mymessages__container__group__msg.general i {
  color: #0589fa;
}

.mymessages__container__legend .code__general:before,
.mymessages__container__group__msg .msg-v-id {
  color: #005c99;
}

.mybookings__header__icon {
  border-color: #0589fa;
}
.mybookings__header__icon:before {
  color: #0589fa;
}
.mybookings__header__icon:before:hover {
  color: #003366;
}

.cms__openHTML__main-figure__img {
  max-width: 366px;
}

.cms__openHTML__content table,
.cms__openHTML__content table td,
.cms__openHTML__content table th {
  border-color: #0589fa;
}

.voucher__header__container__state-loc__state,
.voucher__container__flights__container__content__info,
.voucher__container__flights__container__content__flight__stops__container__logo:before {
  background-color: #0589fa;
}

sbmn-my-bookings-manager .menuicons, sbmn-my-bookings-manager .menuinnericons,
sbmn-my-bookings-manager .menuicons:hover,
sbmn-my-bookings-manager .menuicons:hover i.m_icon,
sbmn-my-bookings-manager .menuinnericons:hover i.m_icon,
sbmn-my-bookings-manager i.m_icon.active {
  color: #003366;
}
sbmn-my-bookings-manager .menuicons:before, sbmn-my-bookings-manager .menuinnericons:before,
sbmn-my-bookings-manager .menuicons:hover:before,
sbmn-my-bookings-manager .menuicons:hover i.m_icon:before,
sbmn-my-bookings-manager .menuinnericons:hover i.m_icon:before,
sbmn-my-bookings-manager i.m_icon.active:before {
  color: #003366;
}

sbmn-my-bookings-import h2 {
  margin-bottom: 3rem !important;
}

sbmn-my-bookings-import div.stopper-modal__scroll__container > div {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1.25rem;
  text-align: left;
  position: relative;
  overflow: hidden;
  border-radius: 0 25px 0 25px;
}

sbmn-my-bookings-import div.stopper-modal__scroll__container > div > div:nth-child(1) {
  flex: 1 1 0;
  margin-right: 0.75rem;
  border: 0px solid transparent;
  border-radius: 0.75rem;
  overflow: hidden;
  color: #333;
}
sbmn-my-bookings-import div.stopper-modal__scroll__container > div > div:nth-child(1) p:first-child {
  font-size: large;
  background-color: #66c2ff;
  margin: 0;
  padding: 1rem 1.25rem;
  border-bottom: 1px solid #0099ff;
}
sbmn-my-bookings-import div.stopper-modal__scroll__container > div > div:nth-child(1) div:nth-child(2) {
  font-size: medium;
  background-color: #f1f1f1;
  margin: 0;
  padding: 1rem 1.25rem;
  line-height: normal;
}
sbmn-my-bookings-import div.stopper-modal__scroll__container > div > div:nth-child(1) div:nth-child(2) > * {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

sbmn-my-bookings-import div.stopper-modal__scroll__container > div > div:nth-child(2) {
  flex: 1 1 0;
  margin-left: 0.75rem;
}

sbmn-my-bookings-import div.stopper-modal__scroll__container > div > div:nth-child(2) > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
sbmn-my-bookings-import div.stopper-modal__scroll__container > div > div:nth-child(2) > div form {
  flex: 1 1 auto;
  align-content: center;
}
sbmn-my-bookings-import div.stopper-modal__scroll__container > div > div:nth-child(2) > div button {
  align-self: flex-end;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  background-color: #0099ff;
  color: #fff;
}

sbmn-my-bookings > header.mybookings__header div.mybookings__header-container {
  display: flex;
  justify-content: space-between;
  padding: 0px 1.5rem;
}
sbmn-my-bookings > header.mybookings__header h1 {
  text-align: center;
}
sbmn-my-bookings > header.mybookings__header div.mybookings__header-container > * {
  flex: 1 1 0;
}
sbmn-my-bookings > header.mybookings__header div:last-child {
  align-content: center;
}
sbmn-my-bookings > header.mybookings__header div:last-child button {
  background-color: #0099ff;
  border: 2px solid #0099ff;
  float: right;
  color: #fff;
}

.psg_manager__residents {
  font-size: 1.3rem;
}
.psg_manager__residents .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.54);
}

html {
  font-size: 0.68rem !important;
  font-family: "Raleway", sans-serif !important;
  line-height: 1.2 !important;
}

body {
  font-family: "Raleway", sans-serif !important;
  line-height: 1.2 !important;
  background-color: #f3f6f8 !important;
}
body::after {
  background-color: #f3f6f8 !important;
}
body::before {
  background-image: none !important;
}

/* General */
h1,
h2,
h3,
h4,
h5 {
  font-family: "Raleway", sans-serif !important;
  font-weight: 300 !important;
}

.h1withbold,
.h2withbold,
.h3withbold, .cms__basicinfo__container__content > span > strong,
.h4withbold {
  color: #0099ff !important;
}
.h1withbold strong, .cms__basicinfo__container__content > span > strong strong,
.h2withbold strong,
.h3withbold strong,
.h4withbold strong {
  font-weight: 300 !important;
  color: #003366;
}

/* Home */
/* Estructura*/
sbmn-flights-dashboard .max-width-page > :first-child, sbmn-flights-dashboard .max-width-box > :first-child {
  order: 1;
  display: inline-block;
}
sbmn-flights-dashboard .max-width-page > :nth-child(2), sbmn-flights-dashboard .max-width-box > :nth-child(2) {
  order: 2;
  top: 0;
  margin: 0 0 50px;
}
sbmn-flights-dashboard .max-width-page > :nth-child(3), sbmn-flights-dashboard .max-width-box > :nth-child(3) {
  order: 3 !important;
  display: inline-block;
  width: 100%;
  max-width: initial;
  margin: 0;
  padding: 0;
  border-radius: 0;
}
sbmn-flights-dashboard .max-width-page > :nth-child(5), sbmn-flights-dashboard .max-width-box > :nth-child(5) {
  order: 5 !important;
}
sbmn-flights-dashboard sbmn-flights-search-engine {
  max-width: none !important;
}
sbmn-flights-dashboard sbmn-flights-welcome {
  max-height: 550px !important;
  min-height: 550px !important;
}
sbmn-flights-dashboard sbmn-flights-welcome::before {
  content: none !important;
}
sbmn-flights-dashboard sbmn-flights-welcome figure,
sbmn-flights-dashboard sbmn-flights-welcome figcaption {
  position: relative;
}
sbmn-flights-dashboard sbmn-flights-welcome figcaption h2 {
  font-size: 2.5vw;
  display: none;
}
sbmn-flights-dashboard sbmn-flights-welcome .owl-theme .owl-dots {
  position: absolute;
  right: 0;
  left: 0;
  max-width: none;
  z-index: 1;
  top: 0;
}
sbmn-flights-dashboard sbmn-flights-welcome .owl-dot span {
  border-color: rgba(255, 255, 255, 0.5) !important;
}
sbmn-flights-dashboard sbmn-flights-welcome .owl-dot.active span {
  border-color: rgba(255, 255, 255, 0.5) !important;
  background: rgba(255, 255, 255, 0.5) !important;
}

sbmn-flights-highlight section {
  width: 100%;
  height: auto;
  padding: 0;
  flex-direction: unset !important;
  background: none !important;
}
sbmn-flights-highlight button.buttonbluelr, sbmn-flights-highlight button.stopper-modal__scroll__container__button {
  margin: 0 0 0 10px;
}

sbmn-flights-offers {
  width: 66%;
  max-width: 950px !important;
  display: inline-block;
}

sbmn-flights-messages {
  width: 33%;
  max-width: 464px !important;
  flex-basis: auto !important;
}
sbmn-flights-messages owl-carousel-o {
  height: auto;
}
sbmn-flights-messages .owl-theme .owl-dots {
  bottom: -40px !important;
}

/* Estilos */
#main_header {
  box-shadow: 1px 0 5px 0 rgb(128, 138, 153) !important;
}
#main_header a {
  text-transform: none !important;
  color: #003366 !important;
}
#main_header a:hover, #main_header a.active {
  color: #0099ff !important;
}
#main_header a::before {
  display: none;
}
#main_header a.header__balance {
  margin-right: 0 !important;
  font: 700 2.3rem "Raleway", sans-serif !important;
  color: #0099ff !important;
  border: 0 !important;
}
#main_header a.header__balance span {
  font: 500 1.3rem "Raleway", sans-serif !important;
  text-transform: lowercase;
}
#main_header .poweroff {
  top: 0;
  bottom: 0;
  right: 0;
  background: #003366;
  transition: all 0.05s;
}
#main_header .poweroff:hover {
  background: #0099ff;
}
#main_header .poweroff::before {
  font-size: 2rem !important;
  color: #fff !important;
}
#main_header .mobile_menu_container__burger__bar1,
#main_header .mobile_menu_container__burger__bar2,
#main_header .mobile_menu_container__burger__bar3 {
  background-color: #0099ff !important;
}

#main_footer {
  background: #003e73 !important;
}

sbmn-flights-highlight {
  box-shadow: 1px 0 5px 0 rgb(128, 138, 153) !important;
  z-index: 1;
  padding: 10px;
  background: #0099ff;
  background: linear-gradient(135deg, #0099ff 0%, #00ccff 100%);
}
sbmn-flights-highlight section > div {
  font: 500 1.55rem "Raleway", sans-serif !important;
}
sbmn-flights-highlight section > .buttonbluelr, sbmn-flights-highlight section > .stopper-modal__scroll__container__button {
  padding: 10px 20px !important;
  font: 500 1.55rem "Raleway", sans-serif !important;
  text-transform: none;
  border: 0;
  border-radius: 20px;
  background: #003366;
}
sbmn-flights-highlight section > .buttonbluelr:hover, sbmn-flights-highlight section > .stopper-modal__scroll__container__button:hover {
  border: 0;
}

sbmn-flights-messages,
sbmn-flights-offers {
  box-shadow: none !important;
  background: none !important;
}

sbmn-flights-offers #list_offers div {
  margin-top: 1px;
  text-transform: none !important;
  box-shadow: 0 0 5px 3px rgba(173, 204, 224, 0.47);
  border-radius: 2px;
  background: #fff;
}
sbmn-flights-offers #list_offers div:hover {
  color: #fff;
}
sbmn-flights-offers #list_offers div:hover .desc,
sbmn-flights-offers #list_offers div:hover .price {
  color: #fff;
}
sbmn-flights-offers #list_offers div:hover .idavuelta::before,
sbmn-flights-offers #list_offers div:hover .soloida::before {
  color: rgba(255, 255, 255, 0.4);
}
sbmn-flights-offers #list_offers .desc {
  color: #003366;
}
sbmn-flights-offers #list_offers .price {
  position: relative;
  top: -3px;
  font-size: 1.4em;
  font-weight: 300;
  color: #0099ff;
}
sbmn-flights-offers #list_offers .idavuelta,
sbmn-flights-offers #list_offers .soloida {
  left: 5px;
  top: 13px;
  margin: 0;
  background: none;
}
sbmn-flights-offers #list_offers .idavuelta::before,
sbmn-flights-offers #list_offers .soloida::before {
  font-size: 19px;
  color: #abbec9;
}

sbmn-flights-messages section {
  position: relative;
  margin: 0 0 10px !important;
  padding: 5px 65px 25px 25px !important;
  font-size: 0.8em;
  transition: all 0.1;
}

sbmn-flights-messages section i.ng-star-inserted,
sbmn-flights-messages section img.ng-star-inserted {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  left: auto;
  margin-top: -25px;
  border: 0;
}

sbmn-flights-messages .link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-indent: -9999px;
  margin: 0 !important;
  background: none;
  transition: 0.15s all;
}

sbmn-flights-messages section div {
  font-size: 1.3rem !important;
  line-height: 1.9rem;
}

sbmn-flights-messages section._boomerang div {
  color: #f3f6f8;
}

sbmn-flights-messages section._boomerang {
  background: #003055;
}

sbmn-flights-messages section._boomerang h2 {
  color: #f3f6f8;
}

sbmn-flights-messages section._avisos {
  background: #f3ebde;
}

sbmn-flights-messages section._avisos h2 {
  color: #736348;
}

sbmn-flights-messages section._vuelos {
  background: #dee8f3;
}

sbmn-flights-messages section._vuelos h2 {
  color: #1e3b5b;
}

sbmn-flights-messages section._info {
  background: #e3f5ff;
}

sbmn-flights-messages section._info h2 {
  color: #1e3b5b;
}

sbmn-flights-messages i.megafono::before {
  color: #ddd1bd !important;
}

sbmn-flights-messages i.avion::before {
  color: #bdcddf !important;
}

sbmn-flights-messages i.info3::before {
  color: #bed6e5 !important;
}

sbmn-flights-messages section:hover {
  background: #0099ff !important;
}

sbmn-flights-messages section:hover h2,
sbmn-flights-messages section:hover div {
  color: #f3f6f8 !important;
}

/* Searcher */
sbmn-flights-search-engine {
  margin-bottom: 50px;
}

#main_search-engine {
  text-transform: none !important;
}

#nav_search-engine {
  margin: 0 auto !important;
}

#nav_search-engine span {
  min-width: 75px !important;
  min-height: 75px;
  margin: 0 5px !important;
  opacity: 0.8;
  border-radius: 4px 4px 0 0;
  border: 0 !important;
  background: #0099ff !important;
}

#nav_search-engine span::before {
  opacity: 1 !important;
  color: #f3f6f8 !important;
}

#nav_search-engine span.selected {
  opacity: 1;
  background: #fff !important;
}

#nav_search-engine span.selected::before {
  color: #0099ff !important;
}

#nav_search-engine span:hover {
  opacity: 1;
}

#searchEngineForm {
  min-height: 215px !important;
  padding: 20px;
  font-family: "Raleway", sans-serif !important;
  box-shadow: 0 0 73px 3px rgba(0, 153, 255, 0.52) !important;
}

#searchEngineForm #passengers_search-engine .pasajero::before,
#searchEngineForm #passengers_search-engine .personas::before {
  color: #96b8d3;
}

#searchEngineForm #passengers_search-engine span {
  color: #0099ff;
}

#searchEngineForm #passengers_search-engine:hover .pasajero::before,
#searchEngineForm #passengers_search-engine:hover .personas::before,
#searchEngineForm #passengers_search-engine span {
  color: #003366;
}

#destdates_search-engine {
  flex-direction: column;
}

#searchEngineForm #destdates_search-engine > section {
  width: 100%;
  min-width: 100%;
  justify-content: flex-start;
}

.Multidestino_search #destdates_search-engine mat-form-field {
  margin: 0 !important;
  padding: 0 20px !important;
}

#searchEngineForm #destdates_search-engine .destdate_container {
  padding: 15px 0 0 0;
}

#simpledates_search-engine .simpledates_dateview {
  padding: 0 25px;
  flex-direction: row !important;
  justify-content: left !important;
}

#searchEngineForm #destdates_search-engine .simpledates_dateview .day,
#searchEngineForm #destdates_search-engine .simpledates_dateview .month,
#searchEngineForm .multidates_search-engine .multidates_dateview {
  font-family: "Raleway", sans-serif !important;
  font-weight: 300 !important;
  color: #0099ff;
}

#searchEngineForm #destdates_search-engine .simpledates_dateview:hover .day,
#searchEngineForm #destdates_search-engine .simpledates_dateview:hover .month,
#searchEngineForm .multidates_search-engine:hover .multidates_dateview {
  color: #003366;
}

#searchEngineForm #destdates_search-engine .simpledates_dateview .day {
  font-size: 4rem;
  padding-right: 8px;
}

#searchEngineForm #options_search-engine {
  max-height: none !important;
  background: #003055;
}

#searchEngineForm #options_search-engine label {
  color: #f3f6f8;
}

#searchEngineForm #moreopts_search-engine {
  padding: 25px 0 0;
}

.Multidestino_search #searchEngineForm #moreopts_search-engine {
  min-width: 60%;
  max-width: 60%;
  width: 60%;
}

.mat-checkbox,
.mat-select,
.mat-input-element {
  font-family: "Raleway", sans-serif !important;
}

.Multidestino_search #destdates_search-engine .addRoute {
  opacity: 1 !important;
  font-family: "Raleway", sans-serif !important;
  font-weight: 500 !important;
  color: #0099ff;
}

.Multidestino_search #destdates_search-engine .addRoute:hover {
  color: #003366;
}

#searchEngineForm #submit_search-engine {
  border: 0;
  background: linear-gradient(135deg, #0099ff 0%, #00ccff 100%);
}

sbmn-flights-psg-manager .stopper-modal.modal_psg_manager h2 {
  color: #003366 !important;
}

.stopper-modal.modal_psg_manager .psg_manager__container__box__title,
.stopper-modal.modal_psg_manager .psg_manager__container__box__legend {
  color: #003366;
}

.stopper-modal.modal_psg_manager .buttontransparent, .stopper-modal.modal_psg_manager .buttontransparentb {
  min-width: 120px;
  padding: 10px 20px;
  font: 500 1.55rem "Raleway", sans-serif !important;
  text-transform: none;
  border: 0;
  border-radius: 20px;
  background: #fff;
}

.stopper-modal.modal_psg_manager .buttontransparent:hover, .stopper-modal.modal_psg_manager .buttontransparentb:hover {
  color: #fff;
  background: #003366;
}

/* Listado */
sbmn-flights-results header.sticky,
sbmn-flights-calendar header.sticky {
  height: 60px !important;
}

.summary #nav_search-engine span {
  min-width: 40px !important;
  color: rgba(255, 255, 255, 0.8) !important;
  background: none !important;
}

.summary #nav_search-engine span::before {
  transform: scale(0.8) !important;
  color: rgba(255, 255, 255, 0.54) !important;
}

.summary #searchEngineForm {
  align-items: center;
  min-height: 60px !important;
  padding: 0;
  border: 0;
}

.summary #searchEngineForm #destdates_search-engine,
.summary #searchEngineForm #destdates_search-engine > section {
  width: auto;
  max-width: none;
  min-width: auto;
  flex-direction: row;
}

.summary #submit_search-engine {
  position: relative;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  width: 80px;
  height: auto !important;
  margin: 0 !important;
  padding: 10px 20px !important;
  font: 500 1.55rem "Raleway", sans-serif !important;
  text-transform: none;
  border: 0;
  border-radius: 20px;
  background: #003366 !important;
}

.summary #searchEngineForm #destdates_search-engine .simpledates_dateview .day,
.summary #searchEngineForm #destdates_search-engine .simpledates_dateview .month,
.summary #searchEngineForm .multidates_search-engine .multidates_dateview,
.summary #searchEngineForm #passengers_search-engine .personas,
.summary #searchEngineForm #passengers_search-engine .pasajero::before,
.summary #searchEngineForm #passengers_search-engine .personas::before,
.summary .mat-input-element,
.summary #desdate_container_multiinfo,
.summary #desdate_container_multiinfo span::before,
.summary .mat-form-field .mat-form-field-label {
  color: #fff;
}

.summary #desdate_container_multiinfo {
  font-weight: 600;
}

.summary .destdate_container .mat-input-element {
  color: #000;
}

.summary #searchEngineForm #destdates_search-engine .simpledates_dateview:hover .day,
.summary #searchEngineForm #destdates_search-engine .simpledates_dateview:hover .month,
.summary #searchEngineForm .multidates_search-engine:hover .multidates_dateview,
.summary #searchEngineForm #passengers_search-engine .personas:hover,
.summary #searchEngineForm #passengers_search-engine .personas:hover::before {
  color: #003366;
}

.summary #searchEngineForm #passengers_search-engine .personas {
  font-size: 1.8em;
}

@media (min-width: 996px) and (max-width: 1200px) {
  .summary #searchEngineForm #destdates_search-engine > section {
    flex-direction: column;
  }
  .summary #destdates_search-engine .simpledates_dateview {
    padding: 0;
  }
  .summary #simpledates_search-engine .day {
    font-size: 1.4em !important;
  }
  .summary #simpledates_search-engine .day::before {
    display: none;
  }
}
sbmn-flights-results > header.hsummary,
sbmn-flights-calendar > header.hsummary {
  box-shadow: 1px 0 5px 0 rgb(128, 138, 153) !important;
  background: #0099ff !important;
  background: linear-gradient(135deg, #0099ff 0%, #00ccff 100%) !important;
}

sbmn-flights-results .menosopciones,
sbmn-flights-calendar .menosopciones {
  color: #0099ff;
  border-radius: 50%;
  background: #dbeaf5;
}

sbmn-flights-search-engine.search-box #searchEngineForm #destdates_search-engine .destdate_container {
  padding-top: 15px;
}

.fresult_header {
  margin: 20px 0;
}

.fresult_header h1.h1withbold, .fresult_header h1.h2withbold, .fresult_header h1.h3withbold, .fresult_header h1.h4withbold {
  color: #003366 !important;
}

sbmn-flights-results .fresult_header .filtrar,
sbmn-flights-calendar .fresult_header .filtrar,
.mybookings__header__icon.filtrar {
  width: 38px;
  height: 38px;
  padding: 0;
  font-size: 0.5em;
  color: #fff;
  border: 0;
  border-radius: 19px;
  transition: 0.15s all;
  background: #0099ff;
}

.mybookings__header__icon::before {
  color: #fff !important;
  border: 0 !important;
}

sbmn-flights-results .fresult_header .filtrar:hover,
sbmn-flights-calendar .fresult_header .filtrar:hover,
.mybookings__header__icon.filtrar:hover {
  color: #fff;
  background: #003366;
}

sbmn-pagination .pagination__info {
  color: #003366;
}

sbmn-pagination .pagination__info strong {
  font-weight: 600;
}

sbmn-pagination .pagination .pagination__container__arrow {
  opacity: 1;
  width: 38px;
  height: 38px;
  color: #0099ff;
  background: #dbeaf5;
}

sbmn-pagination .pagination .pagination__container__arrow:hover {
  color: #fff;
  background: #003366;
}

section.fresults_filters,
#main_header .mobile_menu_container__sidenav,
.mybookings__filters,
.mymessages__filters,
.mtausers__filters,
.mtabalance__filters {
  box-shadow: 1px 0 5px 0 rgb(128, 138, 153);
  border: 0;
  background: #fff !important;
}

#fresults_list .fresult_scheme {
  border-radius: 2px;
  box-shadow: 0 0 5px 3px rgba(173, 204, 224, 0.47);
  background: #fff;
}

#fresults_list .fresult_schemedata,
#fresults_list .fresult_schemeprice {
  box-shadow: none;
}

.fresult_schemedata .fresult_headerscheme {
  min-height: 46px;
  padding-top: 3px;
  border: 0;
  background: #003366;
}

.fresult_schemedata .fresult_headerscheme i {
  min-width: 26px;
  margin-left: 5px;
  padding: 0;
  border: 0;
}

.fresult_schemedata .fresult_headerscheme i::before {
  font-family: "icons" !important;
  font-size: 1.45em;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #abbec9;
}

.fresult_schemedata .fresult_headerscheme i.ida::before, .fresult_schemedata .fresult_headerscheme i.vuelta::before {
  content: "\e902" !important;
}

.fresult_schemedata .fresult_headerscheme i.vuelta::before {
  content: "\e902" !important;
  transform: rotate(180deg);
}

.fresult_schemedata .fresult_headerscheme p {
  position: relative;
  font: 700 1.4rem "Raleway", sans-serif !important;
  color: #fff;
}

.fresult_schemedata .fresult_headerscheme p span {
  position: absolute;
  top: -1px;
  right: -100px;
  font: 300 1.4rem "Raleway", sans-serif !important;
  color: #fff;
}

.fresult_schemedata .fresult_route {
  border-color: #d9e2e8;
}

.fresult_schemedata .fresult_route .fresults_routesummary {
  padding-top: 2px;
  font-family: "Raleway", sans-serif;
  font-size: 1.2em;
  font-weight: 300;
  letter-spacing: -0.005em;
}

.fresult_schemedata .fresult_route .fresults_routesummary span {
  opacity: 0.82;
  padding-top: 5px;
  font: 400 1.1rem "Raleway", sans-serif !important;
  text-transform: none;
}

.fresult_schemedata .fresult_route .fresults_stopover {
  font-family: "Raleway", sans-serif;
  color: #003366;
}

.fresult_schemedata .fresult_route .fresults_routeinfo,
.fresult_schemedata .fresults_schemeicons .fresults_class {
  width: 38px;
  min-width: 38px;
  height: 38px;
  min-height: 38px;
}

.fresult_schemedata .fresult_route .fresults_routeinfo::before {
  content: "";
}

.fresult_schemedata .fresult_route .fresults_routeinfo i {
  margin: 0 0 0 4px;
  font-size: 1.7rem;
}

.fresult_schemedata .fresults_schemeicons .fresults_class {
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 3px;
}

.fresult_schemedata .fresults_schemeicons .butaca .fresults_seats_text,
.fresult_schemedata .fresults_schemeicons .fresults_baggagetxt,
.fresult_schemedata .fresults_schemeicons .fresults_class {
  top: -2px;
  font-family: "Raleway", sans-serif;
  font-size: 1em;
  font-weight: 500;
}

/*Colors*/
/* - Defecto */
.fresult_schemedata .fresult_route .fresults_routesummary {
  color: #0099ff;
}

.fresult_schemedata .fresult_route .fresults_routesummary span {
  color: #003366;
}

.fresult_schemedata .fresults_schemeicons .butaca::before,
.fresult_schemedata .fresults_schemeicons .maleta::before {
  color: #dbeaf5;
}

.fresult_schemedata .fresult_route .fresults_routeinfo,
.fresult_schemedata .fresults_schemeicons .fresults_class {
  background: #dbeaf5;
}

.fresult_schemedata .fresult_route .fresults_routeinfo i {
  color: #0099ff;
}

.fresult_schemedata .fresults_schemeicons .butaca .fresults_seats_text,
.fresult_schemedata .fresults_schemeicons .fresults_baggagetxt,
.fresult_schemedata .fresults_schemeicons .fresults_class {
  color: #003366;
}

/* - Hover */
.fresult_route:hover .fresults_routesummary {
  color: #003366 !important;
}

.fresult_route:hover .fresults_routesummary span {
  color: #003366;
}

.fresult_schemedata .fresult_route:hover {
  background: #c4d5e2;
}

.fresult_route:hover .fresults_state {
  background: #c4d5e2 !important;
}

.fresult_route:hover .fresults_schemeicons .butaca::before,
.fresult_route:hover .fresults_schemeicons .maleta::before {
  color: #dbeaf5 !important;
}

.fresult_route:hover .fresults_routeinfo,
.fresult_route:hover .fresults_schemeicons .fresults_class {
  background: #dbeaf5;
}

.fresult_schemedata .fresult_route:hover .fresults_routeinfo i {
  color: #003366;
}

.fresult_route:hover .fresults_schemeicons .butaca .fresults_seats_text,
.fresult_route:hover .fresults_schemeicons .fresults_baggagetxt,
.fresult_route:hover .fresults_schemeicons .fresults_class {
  color: #003366;
}

/* - Activo */
.fresult_route.selected .fresults_routesummary {
  color: #354b39 !important;
}

.fresult_route.selected .fresults_routesummary span {
  color: #003366;
}

.fresult_schemedata .fresult_route.selected {
  background: #ade1b7;
}

.fresult_schemedata .fresult_route.selected::before {
  top: 25px;
  right: 17px;
  color: #3f7348;
}

.fresult_route.selected .fresults_state {
  border: 1px #ade1b7 solid;
  background: #def3e2 !important;
}

.fresult_route.selected .fresults_schemeicons .butaca::before,
.fresult_route.selected .fresults_schemeicons .maleta::before {
  color: #c4dfc9 !important;
}

.fresult_route.selected .fresults_routeinfo,
.fresult_route.selected .fresults_schemeicons .fresults_class {
  background: #c4dfc9;
}

.fresult_schemedata .fresult_route.selected .fresults_routeinfo i {
  color: #5a8a63;
}

.fresult_route.selected .fresults_schemeicons .butaca .fresults_seats_text,
.fresult_route.selected .fresults_schemeicons .fresults_baggagetxt,
.fresult_route.selected .fresults_schemeicons .fresults_class {
  color: #003366;
}

#fresults_list .fresult_schemeprice {
  color: #003366;
  background: #dbeaf5;
}

.fresult_schemeprice .fresults_rate {
  font-size: 0.85em;
}

.fresult_schemeprice .fresults_rate strong {
  font-weight: 600;
}

.fresult_schemeprice .fresults_price {
  margin-top: 0;
  font-weight: 300;
}

.fresult_schemeprice .fresults_routeinfo {
  margin-bottom: 4px;
}

.fresult_schemeprice .fresults_routeinfo .info3 {
  display: inline-block;
  width: 28px;
  height: 28px;
  padding: 5px 0 0 1px;
  font-size: 1em;
}

.fresult_schemeprice .fresults_routeinfo .personas {
  display: inline-block;
  width: 40px;
  height: 28px;
  padding: 5px 0 0 1px;
  font-size: 1em;
}

.fresult_schemeprice .fresults_routeinfo .cash {
  display: inline-block;
  width: 28px;
  height: 28px;
  padding: 5px 0 0 1px;
  font-size: 1em;
}

.fresult_schemeprice .fresults_routeinfo.enabled .info3 {
  opacity: 1;
  color: #0099ff;
  border-radius: 14px;
  background: #fff;
  transition: all 0.08s;
}

.fresult_schemeprice .fresults_routeinfo.enabled .info3:hover {
  color: #fff;
  background: #0099ff;
}

.fresult_schemeprice .fresults_routeinfo.enabled .personas {
  opacity: 1;
  color: #0099ff;
  border-radius: 14px;
  background: #fff;
  transition: all 0.08s;
}

.fresult_schemeprice .fresults_routeinfo.enabled .personas:hover {
  color: #fff;
  background: #0099ff;
}

.fresult_schemeprice .fresults_routeinfo.enabled .cash {
  opacity: 1;
  color: #0099ff;
  border-radius: 14px;
  background: #fff;
  transition: all 0.08s;
}

.fresult_schemeprice .fresults_routeinfo.enabled .cash:hover {
  color: #fff;
  background: #0099ff;
}

.fresult_schemeprice .buttonyellow {
  width: 100%;
  padding: 12px 18px;
  font: 600 1.24rem "Raleway", sans-serif !important;
  letter-spacing: 0.01em;
  border-radius: 23px;
}

/*Colors*/
.fresult_schemeprice .buttonyellow {
  box-shadow: 0 1px 5px 3px rgb(173, 204, 224);
}

.fresult_schemeprice [disabled].buttonyellow {
  color: #6e8799;
  box-shadow: none;
  background: #c4d5e2;
}

/*Paginas*/
.stopper-modal {
  background: rgba(219, 234, 245, 0.95) !important;
}

.basic__table {
  box-shadow: 0 0 3px 0 rgba(128, 138, 153, 0.3);
  border-spacing: 0 !important;
  border-bottom: 7px solid #fff !important;
}

.basic__table .basic__table__head tr,
.basic__table .basic__table__body tr {
  height: 56px;
}

.basic__table .basic__table__head {
  background: #eaf1f5;
}

.basic__table__head__container {
  text-transform: none;
}

.basic__table .basic__table__head .-icon-hide,
.basic__table .basic__table__head .-icon-hide:hover {
  text-transform: none;
  color: #003366;
}

.basic__table .basic__table__body tr {
  font-size: 0.8em;
  color: #003366 !important;
  border-left: 0;
  background: #f3f6f8;
}

.basic__table__body tr:nth-child(2n+1) {
  background: #fff;
}

.is_tr .document::before {
  width: 6px !important;
  border-radius: 2px !important;
  box-shadow: 0 0 3px 0 rgba(128, 138, 153, 0.3);
}

.cms__openHTML__content__yellow__block,
.cms__openHTML__content__plane-ball__inside {
  font-size: 1.6rem !important;
  color: #fff;
}

.mta-contact .cms__openHTML__content__yellow__block a {
  font-size: inherit !important;
}

.mybookings__header-container.max-width-page, .mybookings__header-container.max-width-box {
  margin-bottom: 20px;
  margin-top: 20px;
  padding-left: 80px;
  text-align: left;
}

.mybookings__header-container .mybookings__header__icon {
  top: 4px;
}

.mybookings__legend {
  margin-bottom: 15px !important;
  padding: 10px !important;
  text-align: left !important;
  background: #dbeaf5;
}

.mybookings__legend > span {
  opacity: 1 !important;
  min-width: 0 !important;
  margin: 3px !important;
  padding: 8px 6px !important;
  font-size: 1.25em !important;
  border-bottom: 0 !important;
  border-left-style: solid;
  border-left-width: 3px;
  border-radius: 3px;
  box-shadow: 0 0 3px 0 rgba(128, 138, 153, 0.3);
  background: #fff;
}

.mybookings__legend > span:hover {
  border-color: #003366 !important;
  color: #fff !important;
  background: #003366 !important;
}

.mybookings__legend > span.okselected {
  padding-right: 22px !important;
  color: #fff;
  border-color: #0099ff !important;
  background: #0099ff !important;
}

.mybookings__legend span:hover::before {
  display: none !important;
}

.mybookings__legend span::before {
  top: 11px !important;
  right: 5px !important;
  color: #fff !important;
}

.mybookings__legend strong {
  position: relative;
  top: -4px;
  width: 0 !important;
  border-right: #7a9ab2 3px dotted;
  margin: 0 10px;
  display: inline-block;
  opacity: 1 !important;
  border-radius: 0 !important;
  background: none !important;
}

.mybookings__container__nobookings.Berror {
  display: block;
  width: 96%;
  margin: 10px auto;
  font-size: 1.4em;
  border-radius: 2px;
  border: 1px #e5d1d2 solid;
  background: #f3dedf !important;
}

sbmn-flights-passengers {
  background: #f3f6f8 !important;
}

.fsummary__top__ida-vuelta__flights__published {
  color: #fff !important;
}

.cms__openHTML__header__title {
  text-align: left !important;
  text-transform: none !important;
}

sbmn-access > header {
  width: 50% !important;
  right: 0 !important;
}

sbmn-access > header figure {
  height: auto;
  left: auto;
  position: default;
  background: url("/assets/img/logos/main_logo_blanco.png") no-repeat 50% 50%;
  background-size: 200px 67px;
}

sbmn-access > header figure img {
  visibility: hidden;
}

sbmn-access > header span {
  position: relative !important;
  left: auto !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

sbmn-access > section {
  width: 50% !important;
  left: 0 !important;
}

#login-access h1,
#login-change-password h1,
#login-submit-password h1 {
  margin-bottom: 20px !important;
}

#login-access h1 strong,
#login-change-password h1 strong,
#login-submit-password h1 strong {
  display: block !important;
}

#login-new-account {
  font-size: 1.3rem !important;
}

#login-new-account a {
  display: block;
  margin-top: 3px;
  font-size: 1.3rem !important;
}

.bmanager__info__container__route__flight__extrainfo__fare {
  color: #fff !important;
}

.passengers__section__passenger__data__title,
.passengers__section__passenger__data__title__note {
  color: rgba(0, 153, 255, 0.8) !important;
}

#fresults_ads figure img {
  width: 100%;
  height: auto;
}

@media (min-width: 1022px) {
  #searchEngineForm {
    border-radius: 3px 60px 3px 3px !important;
    border-left: 2px #0099ff solid;
  }
  #searchEngineForm #destdates_search-engine {
    min-width: 60%;
    max-width: 60%;
    width: 60%;
    flex-direction: column;
  }
  #searchEngineForm #destdates_search-engine .destdate_container {
    flex-direction: row;
  }
  #searchEngineForm #destdates_search-engine .idavuelta {
    position: absolute;
    left: 50%;
    top: 40px;
    right: auto;
    margin-left: -11px;
    transform: none;
    border: 0;
  }
  .Multidestino_search #searchEngineForm #destdates_search-engine .idavuelta {
    margin: 0;
    top: 35px;
    left: 38%;
  }
  #searchEngineForm #passengers_search-engine {
    min-width: 10%;
    max-width: 10%;
    width: 10%;
  }
  #searchEngineForm #options_search-engine {
    min-width: 28%;
    max-width: 28%;
    width: 28%;
    padding: 25px;
    position: absolute;
    top: 75px;
    bottom: 0;
    right: 0;
    border-radius: 0 60px 0 0;
  }
  #searchEngineForm #options_search-engine fieldset {
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
  }
  #searchEngineForm #options_search-engine fieldset .mat-checkbox-frame {
    border-color: #0099ff !important;
  }
  #searchEngineForm #options_search-engine .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
  #searchEngineForm #options_search-engine .mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: #0099ff;
  }
  .Simple_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1),
  .Calendario_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) {
    top: 36%;
    left: -180% !important;
    width: 100px !important;
    min-width: 100px !important;
    max-width: 100px !important;
    padding: 17px 0;
  }
  .Simple_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) label,
  .Calendario_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) label {
    color: #003366 !important;
  }
  .Simple_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) label:hover,
  .Calendario_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) label:hover {
    color: #0099ff !important;
  }
  .Simple_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) label:hover .mat-checkbox-frame,
  .Calendario_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) label:hover .mat-checkbox-frame {
    border-color: #0099ff !important;
  }
  .Simple_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) .mat-checkbox-frame,
  .Calendario_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) .mat-checkbox-frame {
    border-color: #003366 !important;
  }
  #searchEngineForm #moreopts_search-engine {
    width: 70%;
    flex-direction: row;
  }
  #searchEngineForm #submit_search-engine {
    top: 75px;
    height: calc(100% - 75px);
  }
  sbmn-flights-messages {
    margin-top: 100px !important;
  }
}
#simpledates_search-engine .simpledates_dateview:last-child {
  left: 0 !important;
}

@media (max-width: 1022px) {
  sbmn-access > header {
    width: 100% !important;
  }
  sbmn-access > section {
    width: 100% !important;
  }
  sbmn-flights-dashboard .max-width-page > :nth-child(3), sbmn-flights-dashboard .max-width-box > :nth-child(3),
  .mybookings__header-container.max-width-page,
  .mybookings__header-container.max-width-box,
  .brand_header {
    margin-top: 60px;
  }
  .mybookings__header__icon::before {
    font-size: 2rem !important;
  }
  .fresult_schemedata .fresult_route.selected::before {
    right: 5px !important;
  }
}
.leftdialog {
  padding-bottom: 20px;
}
.leftdialog .extendedinfo__container__div {
  position: absolute;
  top: 180px;
  bottom: 25px;
  display: block;
}
.leftdialog .extendedinfo__container__div .extendedinfo__container__code {
  max-height: 100%;
  max-width: 760px;
}

.Berror .stopper-modal__scroll__container__content {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 15px;
}

.calendargrid {
  padding-right: 55px;
}

.voucher__header__container__state-loc__state,
.voucher__container__flights__container__content__info,
.voucher__container__flights__container__content__flight__stops__container__logo::before {
  background-color: #036 !important;
  color: #fff !important;
}

#gradient_search-engine.Simpleborder span, #gradient_search-engine.Calendarioborder span, #gradient_search-engine.Multidestinoborder span {
  top: 80px;
  left: 40px;
  right: auto;
  z-index: 1;
  color: #09f;
  height: 40px;
}

.Simple_search #searchEngineForm {
  padding-top: 40px;
}
.Multidestino_search #searchEngineForm {
  padding-top: 40px;
}
.Calendario_search #searchEngineForm {
  padding-top: 40px;
}

.summary #searchEngineForm {
  padding: 0;
}

.agreement_form .agreement_sms {
  display: none;
}

sbmn-flights-dashboard sbmn-flights-offers h2, sbmn-flights-dashboard sbmn-flights-offers #list_offers {
  display: none;
}
sbmn-flights-dashboard sbmn-flights-offers .banner_home_footer {
  display: flex;
  justify-content: space-between;
  margin: 60px 0 20px 0;
  flex-wrap: wrap;
}
sbmn-flights-dashboard sbmn-flights-offers .banner_home_footer div {
  width: 50%;
  padding: 10px;
  height: 464px;
  overflow: hidden;
}

.bmanager__nav__section__item.baggage {
  display: none;
}

.mtamain__logo__uploader {
  color: #0099ff !important;
}

sbmn-flights-results, sbmn-flights-calendar {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas: "header header" "sidebar main";
}

sbmn-flights-results > header, sbmn-flights-calendar > header {
  grid-area: header;
}

sbmn-flights-results > section.fresults_filters, sbmn-flights-calendar > section.fresults_filters {
  grid-area: sidebar;
  position: relative;
  width: 280px;
  min-width: auto;
  box-shadow: none;
  background-color: transparent !important;
}

sbmn-flights-filters section {
  min-width: auto;
}

sbmn-flights-results > section#fresults, sbmn-flights-calendar > section#fresults {
  grid-area: main;
}

sbmn-flights-results > #fcalendar, sbmn-flights-calendar > #fcalendar {
  grid-area: main;
}

sbmn-flights-filters section .clock + span {
  display: block;
  margin-top: 2px;
  margin-left: 22px;
  text-align: left;
}

#main_header .mobile_menu_container__sidenav {
  max-width: 220px;
}