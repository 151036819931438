sbmn-my-bookings-tickets {
  min-width: 100%;
  display: block;

  .basic__table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;

    &__head {
      text-align: center;

      th:first-child {
        width: 40%;
      }

      th:last-child {
        padding-right: 10px;
      }
    }

    &__body {
      tr,
      tr:nth-child(n) {
        cursor: auto;
        border-left: 10px solid $Bgreen;

        &.inactive {
          border-left: 10px solid $Berror-dark;
        }
      }

      td:last-child {
        padding-right: 10px;

        &:before {
          display: none;
        }
      }
    }
  }
}

.bmanager__tickets {
  position: relative;

  &__cancel {
    text-align: center;

    &__title {
      color: $Bprimary-light;
    }

    &__nobutton {
      margin: 5px 10px 20px auto;
    }

    &__button {
      margin: 5px auto 20px 10px;
    }
  }
}
