$breakpoints: (
  'phone': 400px,
  'phone-wide': 480px,
  'phablet': 560px,
  'tablet-small': 640px,
  //"tablet":       769px,
  //"tablet-wide":  1024px,
  'tablet-med': 769px,
  'tablet': 1023px,
  'desktop': 1248px,
  'desktop-wide': 1440px,
  'cinemascope': 1905px,
);

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}
