sbmn-videoconf {
  z-index: 1000;
  position: absolute;
  sbmn-admin-tools & .stopper-modal {
    max-width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    .stopper-modal__scroll {
      overflow-y: hidden;
    }
    .ko {
      display: unset;
      left: unset !important;
      top: 20px;
      right: 20px;
    }
  }
  .stopper-modal__scroll__container {
    max-width: 1300px;
    position: relative;
  }
  .videoconf {
    &__title {
      margin: 0 auto 40px;
      max-width: 600px;

      &.h2withbold {
        @include mq('tablet', 'max') {
          font-size: 3rem;
        }
      }
    }

    &__conversation {
      border: 3px solid $Bgrey2;
      border-radius: 30px;
      font-size: 1.6rem;
      line-height: 1.2;
      color: $Bgrey3;
      font-family: basicregular, Arial, sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 64vh;
      width: calc(((64vh) / 9) * 16);
      max-width: 100%;
      min-height: 300px;
      position: relative;
      overflow: hidden;
      margin: auto;
      padding: 20px;
      @include mq('tablet', 'max') {
        max-height: 100%;
        width: 100%;
      }
      @include mq('phablet', 'max') {
        width: 85vw;
      }

      strong {
        color: $Bwhite;
        display: inline;
      }

      &__content {
        max-width: 70%;
        @include mq('tablet', 'max') {
          max-width: 90%;
        }

        .play {
          &:before {
            margin: 30px;
            font-size: 12rem;
            color: $Bwhite;
            display: block;
            transition: color 0.2s linear;
            cursor: pointer;
            @include mq('phablet', 'max') {
              font-size: 6rem;
            }
          }

          &:hover:before {
            color: $Bprimary;
          }
        }
      }

      &__video {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      &__icon {
        position: relative;

        &:before {
          display: block;
          font-size: 20rem;
          color: $Bgrey7;
          margin-bottom: 30px;
        }

        figcaption {
          margin-top: 30px;
        }
      }

      &__forbidden {
        width: 40px;
        height: 40px;
        background-image: url('/assets/img/icons/forbidden.svg');
        background-size: cover;
        position: absolute;
        margin-left: 50px;
        left: 50%;
        top: 0;
      }
    }
  }
}

.videoConfUser {
  font-size: 1.2em;
  display: block;
  padding-top: 5px;
}

#protected_adminvideoconf {
  position: fixed;
  color: $Bwhite;
  background-color: $Borange-red;
  padding: 10px 20px;
  left: 0;
  top: 0;
  font-size: 2rem;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: color 0.2s linear;
  z-index: z(top);

  &:hover {
    color: $Bviolet;

    &:before {
      color: $Bwhite;
    }
  }

  &:before {
    transition: color 0.2s linear;
    font-size: 51px;
    color: $Bviolet;
    margin: -10px 10px;
  }
}
