sbmn-admin-tools {
  sbmn-chat {
    width: 50%;
    max-width: 500px;
    height: calc(100vh - 50px);
  }
  .chat-users-list {
    user-select: none;
  }
  .ng-chat-friends-list-container {
    flex-wrap: wrap;
  }

  .startConvWith {
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: -10px 0 10px;
    padding: 0 10px;
    span {
      min-width: 45%;
      font-size: 1.4rem;
    }
    .mat-form-field {
      display: block;
      min-width: 45%;
    }
    .mat-form-field-infix {
      border-top: 0;
      padding-top: 0;
    }
    .mat-form-field-wrapper {
      margin-bottom: -1em;
    }
  }

  .ng-chat-department {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    line-height: 1;
    font-family: basicbold, Arial, sans-serif;
    &:before {
      width: 5px;
      height: 100%;
      background: $Bgreen-light;
      content: '';
      animation: blinkbullet 2s infinite;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  #chaticon {
    cursor: pointer;
  }
  .incomingChat {
    animation: pulseChat 0.5s alternate infinite;
  }

  #ng-chat-people,
  .ng-chat-window {
    margin: 0;
    max-width: 100%;
    min-width: 100%;
    height: calc(100vh - 50px);
    max-height: 100vh;
    position: absolute;
    right: 0;
  }

  .ng-chat-title-admin .ko {
    display: none;
  }

  .stopper-modal {
    top: 50px;
    max-width: calc(100% - 500px);
    height: calc(100vh - 50px);
    width: 100%;
    @include mq('tablet', 'max') {
      max-width: 50%;
    }
    &__scroll {
      &__container {
        top: 0;
      }
    }
    ul {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    li {
      width: 100%;
      font-weight: bold;
      font-size: 2rem;
      margin-right: 10px;
      color: $Bgrey4;
      transition: color 0.2s linear;
      min-width: 122px;
      padding: 5px;

      strong {
        color: $Bblack;
        font-family: basicbold, Arial, sans-serif;
      }
      small {
        cursor: pointer;
        font-family: basiclight, Arial, sans-serif;
        letter-spacing: 0.07em;
      }
      small:hover {
        color: $Bblue;
      }
    }
    .ko {
      display: none;
    }
  }
}

.atools {
  &__header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $Bblack;
    &__figure {
      position: relative;
      margin: 0 10px;
      height: 100%;
      display: flex;
      font-size: 2rem;
      color: $Byellow;
      align-items: center;
      max-width: 50%;
    }
    &__logo {
      max-height: 90%;
      margin-right: 1.5vw;
      max-width: 50%;
    }
    &__nav {
      max-height: 100%;
      display: flex;
      align-items: center;
      img {
        max-height: 40px;
        margin: 0 10px;
      }
    }
  }
}
