sbmn-flights-offers {
  position: relative;
  min-height: 300px;
  border-radius: 0 20px 0 20px;
  overflow: hidden;
  &:before {
    @extend .Bborder-b;
  }

  h2 {
    margin: 10px 0;
    &.noofferslist {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 40px 30px;
      height: 100%;
      z-index: 0;
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  div {
    @extend %Btext_reg;
  }

  figure {
    position: relative;
  }
}

#list_offers {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include mq('tablet', 'max') {
    flex-direction: column;
  }

  div {
    position: relative;
    text-transform: uppercase;
    width: 49%;
    margin: 5px 1% 0 0;
    background: $Bwhite;
    padding: 15px 10px 15px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 1.6em;
    transition: all 0.1s linear;

    &.Bborder-blr:hover {
      background: $Bprimary-lighter;
      color: $Bwhite;
    }

    &.Bborder-bl:hover {
      background: $Bprimary-light;
      color: $Bwhite;
    }

    @include mq('tablet', 'max') {
      min-width: 100%;
      padding: 25px 22px 25px 70px;
      margin: 10px 1% 0 0;
    }
  }

  .desc {
    flex-grow: 2;
  }

  .price {
    font-weight: bold;
    font-size: 1.3em;
    margin-left: 20px;
  }

  .idavuelta,
  .soloida {
    width: 30px;
    height: 30px;
    display: flex;
    position: absolute;
    border: none;
    background: $Bsecondary-lighter;
    justify-content: center;
    align-items: center;
    margin-left: -45px;
    margin-top: 0;

    &:before {
      color: $Bprimary;
    }
  }

  .soloida {
    border-color: $Bprimary-light;

    &:before {
      color: $Bprimary-light;
    }
  }
}

#fresults_ads sbmn-flights-offers:before {
  display: none;
}
