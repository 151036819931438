%text-definition {
  color: $Bprimary-dark;
  font-family: basicregular, Helvetica, sans-serif;
}

.light-theme {
  @extend %text-definition;

  .primary-background {
    background-color: $Bwhite;
  }

  .secondary-background {
    background-color: $Bgrey05;
  }

  .primary-outline-color {
    border-color: $Bgrey4;
  }

  .primary-text {
    @extend %text-definition;
  }

  .secondary-text {
    color: $Bprimary;
    font-family: Arial, Helvetica, sans-serif;
  }

  .friends-search-bar {
    background-color: $Bwhite;
  }

  .unread-messages-counter-container,
  .ng-chat-people-action,
  .ng-chat-people-action > i {
    color: $Bprimary;
    background-color: $Bsecondary;
  }

  .load-history-action {
    background-color: $Bgrey1;
  }

  .chat-window-input {
    background-color: $Bwhite;
  }

  .sent-chat-message-container,
  .file-message-container {
    background-color: $Bgrey1;
    border-color: $Bgrey1;
  }
}

//---------------------------------------- COMPONENT

sbmn-chat {
  position: fixed;
  z-index: z(top);
  right: -100%;
  bottom: 0;
  font-size: 12px;
  text-align: left;
  width: 50%;
  max-width: 350px;
  opacity: 0;
  @include mq('phablet', ' max') {
    max-width: 100%;
    width: 100%;
    height: calc(100vh - 60px);
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
    position: relative;
  }

  &.appear {
    right: 0;
  }

  .ng-chat-title {
    font-family: basicbold, Arial, sans-serif;
    color: $Bwhite;
    font-size: 1.9rem;
    width: 100%;
    max-height: 45px;
    height: 45px;
    background: $Bgrey8;
    border-bottom: 6px solid $Bgrey2;
    padding: 0 50px 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;

    &.ng-chat-title-admin {
      background: $Bviolet;
    }

    .ko {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      text-align: right;
      padding: 6px;

      &:hover {
        transform: scale(1.1) translateX(-4%);
      }
    }

    .ng-chat-unread-messages-count {
      position: relative;
      top: -3px;
      left: 10px;
    }
  }

  .notWorkingHours {
    font-size: 1.6rem;
    font-family: basiclight, Arial, sans-serif;
    padding: 20px;

    img {
      margin: 30px auto;
      width: 150px;
      opacity: 0.3;
      display: block;
    }
  }

  .friends-finder {
    padding: 20px 20px 0;
  }

  .friends-search-bar {
    background-color: $Bwhite;
    border-width: 0 0 3px 0;
    border-style: solid;
    border-color: $Bprimary-light;
    font-size: 2rem;
    padding: 5px 0;
    width: 100%;
    margin-bottom: 10px;
  }

  .friends-search-button {
    transition: opacity 0.2s linear;
    cursor: pointer;
    opacity: 0.4;
    right: 8px;
    position: absolute;
    top: 19px;

    &:hover {
      opacity: 1;
    }
  }

  .friends-mailed-button {
    right: 37px;
  }

  .chat-users-list {
    font-family: basiclight, Arial, sans-serif;
    font-size: 1.8rem;
  }

  .ng-chat-friends-list-container {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    position: relative;
    transition: border 0.2s linear;
    cursor: pointer;
    border-bottom: 2px solid $Bgrey3;

    &:hover {
      border-color: rgba($Bviolet-light, 0.5);

      .tooltip {
        opacity: 1;
      }
    }
    &.cantTake {
      cursor: not-allowed;
      border-color: $Bgrey05;
      user-select: none;
      > * {
        opacity: 0.6;
      }
      &.mine {
        cursor: pointer;
        border-color: $Bgreen-light;
        > * {
          opacity: 1;
        }
      }
      .friends-mailed-button {
        display: none;
      }
    }

    .tooltip {
      width: calc(100% - 75px);
      opacity: 0;
      transition: opacity 0.2s linear;
      height: 100%;
      background-color: $Bwhite;
      text-align: left;
      padding: 10px 10px 10px 5px;
      position: absolute;
      z-index: 0;
      right: 0;
      font-size: 0.75em;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
    }
  }

  .chat-user-avatar {
    margin: 5px 20px 5px 0;
    border-radius: 50%;

    &.is-admin {
      opacity: 0.5;
    }
  }

  .chat-user-info {
    text-transform: uppercase;
    font-family: basicregular, Arial, sans-serif;
    font-size: 1.5rem;
    display: inline-block;
    max-width: calc(100% - 150px);

    em {
      text-transform: none;
      font-size: 0.8em;
      display: block;
      color: $Bgrey4;
    }
  }

  .ng-chat-participant-status {
    position: absolute;
    left: 50px;
    top: 10px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: $Bgrey6;

    &.online {
      background: $Bgreen-light;
    }

    &.busy {
      background: $Borange-red;
    }

    &.forbidden {
      border-radius: 0%;
      background-color: white;
      width: 20px;
      height: 20px;
      background-image: url('/assets/img/icons/forbidden.svg');
    }
  }

  .startConv {
    min-width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 10px;
  }

  .ng-chat-unread-messages-count {
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    width: 20px;
    height: 20px;
    line-height: 1;
    font-family: basicbold, Arial, sans-serif;
    position: absolute;
    top: 40px;
    left: 47px;
  }

  .ng-chat-messages {
    overflow-y: auto;
    height: calc(100% - 116px);
  }

  .ng-chat-message {
    display: flex;
    margin: 10px;
    font-size: 1.5rem;
    justify-content: flex-end;
    letter-spacing: 0.03em;

    &.ng-chat-message-received {
      justify-content: flex-start;
    }

    .no-user-icon {
      width: 50px;
      display: inline-block;
    }

    .chat-user-avatar {
      margin: 0 10px 0 0;
    }
  }

  .received-chat-message-container,
  .sent-chat-message-container {
    width: 100%;
    max-width: 65%;
    font-size: 1.4rem;
    padding: 5px 10px 20px;
    border: 2px solid $Bgrey7;
    display: flex;
    justify-items: center;
    text-align: left;
    position: relative;
    word-break: break-word;
    line-height: 1.2;
  }

  .sent-chat-message-container {
    .message-sent-date {
      color: $Bgrey7;
    }
  }

  .message-sent-date {
    font-size: 1rem;
    position: absolute;
    bottom: 2px;
    right: 2px;
    color: $Bgrey2;
  }

  .ng-chat-footer {
    position: absolute;
    bottom: -1px;
    width: 100%;
    left: 0;

    textarea {
      font-size: 1.5rem;
      font-family: basiclight, Arial, sans-serif;
      min-height: 30px;
      width: 100%;
      line-height: 1.5;
      border-bottom: 1px solid $Bgrey2;
      background-color: $Bgrey7;
      border-radius: 0;
    }
  }
}

#ng-chat {
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
}

#ng-chat-people,
.ng-chat-window {
  position: relative;
  max-width: 33%;
  min-width: 300px;
  height: 500px;
  max-height: 100vh;
  min-height: 40vh;
  margin-right: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  border-left: solid $Bsecondary 8px;
  background: $Bwhite;
  overflow-y: hidden;
  @include mq('phablet', ' max') {
    margin: auto;
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
  }

  &.ng-chat-window-collapsed {
    display: none;
  }
}

.ng-chat-notadmin {
  position: absolute;
  right: 0;
}

.ng-chat-history {
  position: relative;

  .Bplus {
    position: absolute;
    right: 0;
    top: 16px;
    transform: rotate(45deg);
  }
}

.chat-hide {
  display: none;
  position: fixed;
  top: -30000px;
  z-index: z(bottom);
}
