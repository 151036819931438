.ffamilies {
  .cssload-loader__container {
    position: relative;
  }

  &__scroll {
    .h2withbold {
      text-align: center;
      margin: 0;
      color: $Bwhite;
    }

    .h3withbold {
      display: flex;
      align-items: center;
      @include mq('tablet', 'max') {
        font-size: 1.8rem;
      }
    }

    table {
      margin: 0;
      width: 100%;

      thead {
        background: transparent;
        border-bottom: 1px solid $Bprimary;
        color: $Bgrey6;

        tr:last-child {
          color: $Bprimary;
          border-bottom: 0;
        }

        td {
          padding: 2px 5px;
        }
      }

      tr {
        border-bottom: 1px solid $Borange-light;
        border-left: 0;
        @include mq('tablet', 'max') {
          border-bottom: 5px solid $Borange-light;
        }

        &.in {
          background: rgba($Bsecondary-light, 0.2);
        }

        &.selected {
          background: rgba($Bgreen-light, 0.1);
        }
      }

      td {
        vertical-align: top;
        padding: 5px;
        @include mq('tablet', 'max') {
          &:before {
            display: none;
          }
        }

        &:last-child {
          justify-content: flex-end;
          display: flex;
          align-items: center;
        }
      }
    }

    span.ko {
      z-index: z(floor);
    }
  }

  .ffamilies__options__container__item__icon {
    @include circle(25px);
    display: inline-flex;
    font-size: 2rem;
    border-color: $Bprimary-light;

    &.maleta {
      border: 0;
      margin-right: 10px;
      background: $Btransparent;
      color: $Bwhite;

      &:before {
        font-size: 2.5rem;
        top: -1px;
        position: absolute;
        color: rgba($Bprimary, 0.5);
      }

      strong {
        margin-top: 3px;
        font-size: 1.3rem;
        z-index: z(level1);
      }
    }
  }

  &__options {
    &__container {
      &__header {
        display: none;
        @include mq('phablet', 'max') {
          display: flex;
        }

        .h3withbold {
          margin-right: 10px;
        }

        & .ffamilies__options__container__item__icon {
          @include mq('phablet', 'max') {
            display: flex;
          }
        }
      }

      &__item {
        min-width: 100%;
        margin-top: 15px;

        &__list {
          font-size: 1.3rem;
          text-align: left;
          border-radius: 5px;
          width: 100%;
          height: 0;
          padding: 0 10%;
          margin: 0 auto;
          max-height: 0;
          overflow: hidden;
          transition: all 0.3s linear;
          list-style: disc;

          li:first-child {
            list-style: none;
          }
        }

        .in &__list {
          height: auto;
          max-height: 1000px;
          margin: 5px auto;
          padding: 10px 10%;
          background: rgba($Bsecondary-light, 0.5);
        }

        .selected.in &__list {
          background: $Bwhite;
        }

        &__title {
          position: relative;
          display: flex;
          align-items: center;
          text-align: left;

          > strong {
            display: inline-block;
            text-align: right;

            span {
              margin: 0 4px;
            }
          }

          em {
            font-size: 0.9em;
            margin-left: 10px;
            display: none;
            @include mq('tablet', 'max') {
              display: inline-block;
            }
          }
        }

        &__price {
          font-size: 2rem;
          padding: 5px 10px 2px 10px;
          width: 130px;
          display: inline-block;
          text-align: center;
          background: $Bgrey3;

          .selected & {
            background-color: $Bsecondary;

            &:hover,
            &:focus,
            &:active {
              box-shadow: none;
              cursor: auto;
            }
          }
        }

        &__open.menosopciones {
          @include circle(30px);
          margin-left: 15px;
          transform: rotate(180deg);
          position: relative;

          &:before {
            color: $Bprimary;
            font-size: 3rem;
          }
        }

        .in &__open.menosopciones {
          transform: rotate(0);
        }
      }
    }
  }
}
