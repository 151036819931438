sbmn-my-bookings-status {
  display: block;
  min-width: 100%;

  .drop-container {
    margin: 0;
  }

  .Berror {
    position: relative;
    bottom: 0;
    font-size: 1.7rem;
    margin: 0 0 20px;
  }

  sbmn-info {
    padding-top: 5%;
    padding-bottom: 5%;
  }
}

.bstatus {
  display: flex;
  @include mq('tablet', 'max') {
    flex-direction: column;
  }

  &__form {
    min-width: 54%;
    max-width: 54%;
    @include mq('tablet', 'max') {
      max-width: 100%;
    }

    &__field {
      margin: 0 0 30px;

      &__label {
        padding: 0 0 10px;
        display: inline-block;
        font-family: basicbold, Arial, sans-serif;
        font-weight: lighter;
        color: $Bprimary-lighter;
      }

      &__textarea {
        width: 100%;
        height: 100px;
      }

      &:last-child {
        text-align: right;
      }
    }

    &__price-container {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $Bgrey2;

      h3 {
        margin-bottom: 10px;
      }

      &__updated {
        display: inline-block;
        margin-bottom: 20px;
        font-size: 1.6rem;

        span {
          display: block;
          font-size: 1.5rem;
          color: $Borange-red;
        }
      }

      &__button {
        margin: 5px 5px 5px 0;
        padding: 10px;
      }
    }
  }

  &__info {
    width: 40%;
    padding-left: 5%;
    margin-left: 5%;
    border-left: 1px solid $Bgrey2;
    @include mq('tablet', 'max') {
      width: 100%;
      padding-left: 0;
      margin-left: 0;
      border-left: 0;
    }

    &__container {
      color: $Bgrey5;

      &.highlighted {
        color: $Berror-dark;
      }

      &__title,
      &__desc {
        transition: color 0.2s linear;
      }
    }
  }

  &__revert {
    text-align: center;
    margin: auto;

    &__title {
      color: $Bprimary-light;
    }

    &__button {
      margin: 5px auto 20px;
    }
  }

  &__tpv {
    font-size: 1.8rem;
    text-align: center;

    &:before {
      color: $Bprimary-light;
      font-size: 3rem;
      margin: 10px auto;
      display: block;
    }

    &__button {
      display: block;
      margin: 20px auto;
    }
  }

  &__shoppingterms {
    line-height: 1.4;
    font-size: 1.4rem;
    color: $Bblue;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: $Bviolet;
      text-decoration: none;
    }
  }
}
