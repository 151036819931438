$Btransparent: transparent;

/* Colores primarios y secundarios */

$Bprimary-dark: #000;
$Bprimary-dark-gradient1: lighten($Bprimary-dark, 10%);
$Bprimary-dark-gradient2: lighten($Bprimary-dark, 15%);
$Bprimary-dark-gradient3: lighten($Bprimary-dark, 20%);
$Bprimary: darken(#0099ff, 20%);
$Bprimary-light: #0099ff;
$Bprimary-lighter: saturate(#0099ff, 1);
$Bsecondary: #003366;
$Bsecondary-light: lighten($Bsecondary, 15%);
$Bsecondary-lighter: lighten($Bsecondary, 25%);
$Bsecondary-dark: darken($Bsecondary, 25%);
//$Bthird: #ffb639;
$Bthird: #2d5a96;
$Bthird-light: lighten($Bthird, 25%);
$Bthird-lighter: #b5c57e;
$Bfourth: #05c7fa;
$Bfifth: #0589fa;

/* Otros colores  */

$Bblue: #0099ff;
$Bblue-light: lighten($Bblue, 20%);
$Bblue-lighter: lighten($Bblue, 20%);
$Byellow: #0099ff;
$Byellow-light: lighten($Byellow, 20%);
$Byellow-lighter: lighten($Byellow, 20%);
$Berror: #ef1c25;
$Berror-dark: darken($Berror, 20%);
$Berror-light: lighten($Berror, 20%);
$Bprimary-link: #656574;
$Bviolet: #592e97;
$Bviolet-dark: darken($Bviolet, 20%);
$Bviolet-light: lighten($Bviolet, 20%);
$Borange: #f48126;
$Borange-light: lighten($Borange, 20%);
$Borange-red: #ff4500;
$Bgreen: #6ebd41;
$Bgreen-dark: darken($Bgreen, 20%);
$Bgreen-light: lighten($Bgreen, 20%);

/* Grises  */

$Bblack: #000;
$Bgrey9: #191919;
$Bgrey8: #333;
$Bgrey7: #4c4c4c;
$Bgrey6: #666;
$Bgrey5: #7f7f7f;
$Bgrey4: #999;
$Bgrey3: #b2b2b2;
$Bgrey2: #ccc;
$Bgrey1: #e5e5e5;
$Bgrey05: #f1f1f1;
$Bwhite: #fff;
