sbmn-register {
  min-width: 50%;
  padding: 40px 0;
  position: relative;
  .registered {
    max-width: 500px;
    padding: 20px 0;
    color: $Bgrey7;
    font-size: 1.6rem;
  }

  > section {
    padding: 30px 5% 100px;
    background: rgba($Bwhite, 0.5);
    border-radius: 0 20px 0;
    &:before {
      @extend .Bborder-blr;
    }
  }

  fieldset {
    position: relative;
  }

  .returnlink {
    margin-top: -20px;
    color: $Bgrey7;
  }

  .Berror {
    bottom: unset;
    top: -15px;
    font-size: 1.25rem;
  }

  mat-error {
    margin-top: -2px;
    font-size: 1.25rem;
  }

  button {
    margin: 20px 0 10px 0;
    float: right;
  }
}
