sbmn-mta-balance {
  h1 {
    text-align: center;
    font-size: 4.5rem;
    padding: 20px 0;
  }

  .basic__table {
    &-paginator {
      width: 100%;
      max-width: 1440px;
      margin: 10px auto;
    }

    &__head {
      tr {
        border: 0;
      }

      th:nth-of-type(4) {
        width: 25%;
      }
    }

    &__body {
      tr {
        cursor: auto;

        td:last-child {
          &:before {
            content: '';
          }
        }
      }
    }
  }
}

.mtabalance {
  &__filters-container {
    display: flex;
    min-height: 400px;
  }

  &__filters {
    @extend .mybookings__filters;
    margin-top: -90px;
    padding: 80px 40px 50px 30px;
    background-color: $Bwhite;

    h2 {
      font-family: basiclight, Arial, sans-serif;
      font-size: 3rem;
      font-weight: lighter;
      position: absolute;
      top: 17px;
      margin: 0;
    }

    .ko {
      font-size: 1.8rem;
    }
  }
}

.exportxls {
  position: absolute;
  right: 30px;
}
