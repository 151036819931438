sbmn-pagination {
  width: 100%;

  .pagination {
    display: flex;
    justify-content: center;
    margin: 20px;
    font-size: 1.6rem;

    &__container {
      display: flex;

      &.hide {
        visibility: hidden;
      }

      > * {
        margin: 0 5px;
      }

      &__arrow {
        transition: background 0.2s linear;
        color: $Bwhite;
        cursor: pointer;
        background: $Bgrey2;
        padding: 5px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;

        &:hover {
          background: $Bprimary-lighter;
        }
      }
    }

    &__info {
      display: inline-block;
      width: 150px;
      text-align: center;
      line-height: 2;
    }

    .-left {
      transform: rotate(180deg);
    }
  }
}
