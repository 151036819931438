sbmn-flights-summary {
  background: $Bwhite;
  box-shadow: 5px 5px 10px rgba($Bblack, 0.2);
  display: block;
  font-size: 1.5rem;
  position: relative;

  i {
    @include circle(75px);
    margin: 3px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $Bwhite;
    border: 2px solid $Bgrey2;
  }

  .menosopciones {
    transform: rotate(180deg);
    display: inline-block;

    &.expanded {
      transform: rotate(0);
    }
  }

  @include mq('tablet', 'min') {
    //------------------header FIX
    .sticky:not(.expanded) {
      background: $Bwhite;
      position: fixed;
      top: 0;
      animation: headerIn 1s forwards;
      box-shadow: 0 8px 12px -6px rgba($Bblack, 0.5);
      z-index: z(level3);

      .fsummary__top {
        height: 50px;

        &__ida-vuelta {
          &__icon {
            @include circle(30px);
            display: flex;
            font-size: 20px;
          }

          &__flights {
            flex-direction: row;
            flex-wrap: wrap;

            > div {
              margin-right: 10px;
              font-size: 1.4rem;
            }

            &__published {
              display: none;
            }

            &__duration {
              background: $Btransparent;
              padding: 0;
            }

            &__dates {
              margin-bottom: 0;
            }
          }
        }

        &__passenger {
          font-size: 1.3rem;
          &__num-name {
            &:before {
              font-size: 1.4rem;
              margin-top: -3px;
            }

            line-height: 14px;
            margin-bottom: 0;
          }
          &__price {
            line-height: 22px;
          }
        }
      }
    }
    #fixstickysummary {
      display: block;
      height: 120px;
    }
  }
}

.fsummary {
  &__container {
    width: 100%;

    &.expanded {
      max-height: 3000px;
    }
  }

  &__top-section {
    margin: auto;
    max-width: 850px;
  }

  &__top {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 0;
    height: 120px;
    margin: auto;
    max-width: 850px;
    @include mq('tablet', 'max') {
      flex-direction: row;
      height: 230px;
      padding-top: 0;
      flex-wrap: wrap;
    }
    @include mq('phablet', 'max') {
      height: 250px;
    }

    &__ida-vuelta {
      display: flex;
      align-items: center;
      width: 40%;
      @include mq('tablet', 'max') {
        flex: 1 1 135px;
        width: 50%;
        padding-right: 0px;
        justify-content: center;
      }

      &__icon {
        font-size: 40px;
        color: $Bgrey3;
        @include mq('phablet', 'max') {
          margin: 0 5px;
          min-width: 40px;
          font-size: 23px;
          @include circle(40px);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        @include mq('phone-wide', 'max') {
          display: none;
        }
      }

      .-vuelta {
        transform: rotate(180deg);
      }

      &__flights {
        display: flex;
        flex-direction: column;
        @include mq('phablet', 'max') {
          padding: 25px 0 5px 0;
          text-align: center;
          align-items: center;
          position: relative;
        }

        &__published {
          background-color: $Bsecondary;
          text-align: center;
          text-transform: uppercase;
          color: $Bblack;
          padding: 1px 5px;
          font-size: 0.7em;
          display: inline;
          align-self: flex-end;
          margin-bottom: 5px;
          @include mq('phablet', 'max') {
            align-self: center;
            top: 5px;
            position: absolute;
          }
        }

        &__from-to,
        &__dates {
          @include mq('phablet', 'max') {
            text-align: center;
          }
        }

        &__from-to {
          @include mq('phablet', 'max') {
            font-size: 0.9em;
          }
        }

        &__dates {
          font-size: 1.6em;
          color: $Bprimary-light;
          margin-bottom: 6px;
          @include mq('phablet', 'max') {
            font-size: 1.7rem;
          }
        }

        &__duration {
          background-color: $Bgrey1;
          padding: 4px 8px;
          border-radius: 50px;
          font-size: 0.9em;
          max-width: 180px;
          text-align: center;

          strong {
            margin-right: 10px;
          }

          &:before {
            padding-right: 5px;
            color: $Bgrey5;
          }
        }
      }
    }

    &__passenger {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      border-left: 1px dashed $Bgrey3;
      padding-left: 30px;
      line-height: 30px;
      @include mq('tablet', 'max') {
        height: auto;
        border: none;
        width: 100%;
        border-top: 1px dashed $Bgrey3;
        display: flex;
        flex-direction: row;
        padding: 10px 20px 0;
        justify-content: space-between;
      }

      &__num-name {
        color: $Bgrey3;
        display: inherit;
        line-height: 22px;
        margin-bottom: 5px;

        &:before {
          padding-right: 5px;
          font-size: 2rem;
        }

        @include mq('tablet', 'max') {
          margin-bottom: 0;
          display: flex;
          align-items: center;
        }
      }

      &__price {
        font-size: 1.4em;
        color: $Bprimary-dark;
        font-weight: bold;
      }
    }

    &__toggle {
      display: block;
      width: 10%;
    }
  }

  &__content-section {
    height: 0;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition:
      max-height 0.35s ease-in-out,
      opacity 0.3s linear;

    &.expanded {
      display: block;
      height: auto;
      opacity: 1;
      max-height: 3000px;
    }
  }

  &__content {
    border-top: 1px dashed $Bgrey3;
    padding: 20px 20px 40px 50px;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    margin: auto;
    max-width: 850px;
    font-family: basicregular, Arial, sans-serif;
    @include mq('tablet', 'max') {
      flex-direction: column;
      padding-left: 30px;
    }

    &__left {
      width: 60%;
      margin-right: 7%;
      @include mq('tablet', 'max') {
        margin-right: 0;
        width: 100%;
        padding-right: 20px;
      }

      &__ida-vuelta {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid rgba($Bprimary, 0.4);

        &:first-child {
          margin-top: 0;
          padding-top: 0;
          border-top: 0;
        }

        &:nth-child(2) {
          .fsummary__content {
            &__left {
              &__container {
                &__block {
                  &__left {
                    &__date {
                      &:before {
                        transform: rotate(180deg);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &__container {
        display: flex;
        flex-direction: column;

        &__jump {
          margin: 10px 0;
          background-color: $Bgrey05;
          padding: 4px 10px;
          border-radius: 3px;
          text-align: center;
          @include mq('tablet', 'max') {
            margin-bottom: 15px;
            padding: 4px 5px;
          }
        }

        &__block {
          line-height: 25px;
          padding-bottom: 5px;
          justify-content: space-between;
          width: 100%;
          display: flex;
          align-items: flex-start;
          @include mq('tablet', 'max') {
            flex-direction: column;
            align-items: flex-start;
          }

          &__left {
            &__date {
              align-items: center;
              display: flex;
              color: $Bprimary-light;
              font-family: basicbold, Arial, sans-serif;
              font-weight: lighter;
              position: relative;

              &:before {
                font-size: 30px;
                color: $Bprimary;
                position: absolute;
                left: -35px;
                @include mq('tablet', 'max') {
                  font-size: 20px;
                  position: relative;
                  left: 0;
                  margin-right: 10px;
                }
              }
            }

            &__airline {
              display: flex;
              font-size: 1.3rem;
              color: $Bprimary-dark;
              line-height: 1;
              margin-bottom: 10px;
              font-family: basicregular, Arial, sans-serif;
              flex-direction: column;
              align-items: flex-start;

              img {
                margin: 0 3px;
                position: relative;
                top: 2px;
              }
            }
          }

          &__right {
            min-width: 110px;
            @include mq('tablet', 'max') {
              margin: 10px 0 5px;
            }

            &__duration {
              background-color: $Bgrey1;
              padding: 4px 10px;
              border-radius: 50px;
              font-size: 0.9em;
              max-width: 170px;
              text-align: center;

              &:before {
                padding-right: 5px;
                color: $Bgrey5;
              }

              @include mq('phablet', 'max') {
                padding: 1px 10px;
              }
            }
          }
        }

        &__block__icons,
        &__icons {
          justify-content: flex-start;
          display: flex;
          padding: 0 0 5px 0;

          i {
            border: 0;
            min-width: 0;
            min-height: 0;
            width: auto;
            height: auto;
          }

          &__item {
            margin: 0 20px 0 0;
            color: $Bgrey6;

            &:before {
              margin-right: 5px;
              color: $Bgrey3;
            }

            font-size: 1.5rem;
            font-family: basicregular, Arial, sans-serif;

            &__text {
              color: $Bgrey7;
              margin-left: 5px;
              font-family: basicbold, Arial, sans-serif;
              text-transform: capitalize;
            }
          }
        }

        &__block__icons {
          @include mq('phablet', 'max') {
            flex-direction: column;
            align-items: flex-start;
            line-height: 1.3;
          }
        }

        &__icons {
          margin-top: 5px;
          padding-top: 10px;
          border-top: 1px solid $Bgrey1;
        }
      }
    }

    &__right {
      width: 33%;
      line-height: 1.4;
      font-size: 1.35rem;
      @include mq('tablet', 'max') {
        width: 100%;
        padding-right: 10px;
        margin-top: 40px;
        font-size: 1.6rem;
      }

      &__container {
        &__item {
          strong {
            color: $Bprimary-lighter;
            font-family: basicregular, Arial, sans-serif;
          }

          &__title {
            margin: 5px 0;
            font-size: 1.4rem;
            line-height: 1;
          }

          &__subtitle {
            font-size: 1.3rem;
            color: $Bgrey5;
            line-height: 1;
            margin: 0;
          }

          &__list {
            padding-left: 16px;
            margin: 5px 0 10px;
            line-height: 1;
          }
        }

        &__pasajero {
          display: flex;
          color: $Bprimary-light;
          font-size: 1.5rem;
          font-family: basicbold, Arial, sans-serif;
          font-weight: lighter;

          span {
            width: 33%;

            &:last-child {
              text-align: right;
            }
          }
        }

        &__tarifa,
        &__tasa,
        &__fee,
        &__baggage,
        &__subtotal,
        &__insurance {
          display: flex;
          justify-content: space-between;

          span {
            width: 33%;
            text-align: right;

            &:first-child {
              text-align: left;
            }
          }
        }

        &__subtotal {
          border-top: 1px dotted $Bgrey5;
          text-transform: uppercase;
          margin-top: 5px;
          margin-bottom: 5px;

          strong {
            font-family: basicbold, Arial, sans-serif;
            font-weight: lighter;
            color: $Bprimary;
          }
        }
      }

      &__total {
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid;
        padding-top: 20px;
        margin-top: 20px;
        line-height: 2.5rem;

        span {
          font-size: 2.7rem;
          font-family: basicregular, Arial, sans-serif;
          font-weight: bold;
          color: $Bprimary-dark-gradient3;
        }
      }
    }
  }

  &__toggle-container {
    display: flex;
    justify-content: center;
    width: 100%;
    bottom: -20px;

    &__label {
      cursor: pointer;
      position: absolute;
      bottom: -30px;
      background-color: white;
      padding: 5px 10px;
      border-radius: 0 0 5px 5px;
      color: $Bgrey5;
      box-shadow: 0px 4px 5px rgba($Bblack, 0.2);

      &:hover {
        color: $Bblack;
      }
    }
  }
}

._Multidestino {
  .fsummary__container {
  }

  #fsummary_summary {
    height: auto;
  }
  .fsummary__top {
    display: flex;
    flex-wrap: wrap;

    &__ida-vuelta {
      width: auto;
      padding: 0 1%;
      margin-bottom: 10px;
      border-left: 1px solid $Bgrey2;

      &:first-child {
        border: 0;
      }

      &__icon {
        display: none;
      }

      &__flights__duration {
        @include mq('phablet', 'max') {
          padding: 2px 8px;
        }
      }
    }

    &__passenger {
      border-top: 1px dashed $Bgrey3;
      border-left: 0;
      min-width: 100%;
      flex-direction: row;
      justify-content: space-between;
      line-height: 1;
      padding: 10px 10px 0;
      max-height: 30px;
    }
  }

  .clock:before {
    @include mq('tablet', 'max') {
      display: none;
    }
  }

  ._size2 .fsummary__top__ida-vuelta {
    width: 50%;
  }

  ._size3 .fsummary__top__ida-vuelta {
    width: 33%;
  }

  ._size4 .fsummary__top__ida-vuelta {
    width: 25%;
  }

  ._size5 .fsummary__top__ida-vuelta {
    width: 20%;
  }

  ._size6 .fsummary__top__ida-vuelta {
    width: 16.5%;
  }

  .fsummary__content__left__ida-vuelta:nth-child(2)
    .fsummary__content__left__container__block__left__date:before {
    transform: rotate(0);
  }
}
