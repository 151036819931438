.bmanager__split {
  position: relative;

  &__header {
    border-top: 1px solid $Bprimary-lighter;

    .h1withbold {
      margin: 0;
      font-size: 3.5rem;
      padding: 15px 0 5px;
      color: $Bwhite;
      text-align: center;
    }
  }

  &__container {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 100px;

    .h3withbold {
      color: $Bprimary-lighter;
      text-align: center;
      padding: 10px 5%;
      margin-bottom: 0;

      strong {
        color: $Bgrey2;
      }

      @include mq('tablet', 'max') {
        font-size: 2rem;
      }
    }

    &__booking {
      min-width: 50%;
      padding-bottom: 30px;
      @include mq('tablet', 'max') {
        max-width: 49%;
        padding: 5px 5px 30px;
      }

      &:nth-of-type(2) {
        border-left: 5px dashed $Bgrey6;
        @include mq('tablet', 'max') {
          border-left: 2px dashed $Bgrey6;
        }
      }

      &__psg {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        border-bottom: 1px solid $Bgrey7;
        margin: 5px 5%;
        cursor: pointer;
        transition: border 0.2s linear;
        @include mq('tablet', 'max') {
          margin: 5px 0;
        }

        &:hover {
          border-bottom: 1px solid $Bgreen-light;

          .h4withbold {
            color: $Bwhite;
          }

          .menosopciones,
          .personas,
          .pasajero,
          .pasajerobuscador {
            opacity: 1;
          }
        }

        &.disabled {
          .menosopciones {
            opacity: 0;
          }

          &:hover {
            border-bottom: 1px solid $Bgrey7;
            cursor: auto;

            .h4withbold {
              color: $Bgrey6;
            }

            .personas,
            .pasajero,
            .pasajerobuscador {
              opacity: 0.5;
            }

            .menosopciones {
              opacity: 0;
            }
          }
        }

        &__name {
          display: flex;
          align-items: center;
        }

        .personas,
        .pasajero,
        .pasajerobuscador {
          font-size: 3rem;
          color: $Bprimary-lighter;
          opacity: 0.5;
          @include mq('tablet', 'max') {
            font-size: 2rem;
          }
          @include mq('phablet', 'max') {
            display: none;
          }
        }

        .menosopciones {
          transform: rotate(90deg);
          color: $Bwhite;
          transition: opacity 0.2s linear;
          font-size: 4rem;
          opacity: 0.2;

          &.new {
            transform: rotate(-90deg);
          }

          @include mq('tablet', 'max') {
            font-size: 3rem;
          }
        }

        .h4withbold {
          display: flex;
          flex-direction: column;
          color: $Bgrey6;
          margin: 0 10px;
          transition: color 0.2s linear;
          @include mq('tablet', 'max') {
            font-size: 1.5rem;
            margin: 0;
          }
        }
      }
    }

    &__button {
      width: 100%;
      padding: 20px;
      text-align: center;
    }
  }

  .loader_abs .h3withbold {
    margin: 20px auto;
    color: $Bgrey6;
  }
}
