@import '../../../../../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '../../../../../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

owl-carousel-o {
  display: flex;
  height: 100%;
  width: 100%;
}

.owl-theme,
.owl-stage-outer,
owl-stage > div,
.owl-stage,
.owl-item {
  height: 100%;
}

.owl-dot {
  transform: scale(1.7);
  margin: 5px;

  span {
    background: transparent !important;
    margin: 3px !important;
    border: 1px solid $Bgrey6;
  }

  &.active span {
    border-color: $Bsecondary-lighter;
    background: $Bsecondary !important;
  }
}
