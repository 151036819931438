@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin circle($size) {
  width: $size;
  height: $size;
  min-width: $size;
  min-height: $size;
  border-radius: 50%;
  display: block;
}

@mixin triangle($color, $size, $sizeh) {
  width: 0;
  height: 0;
  border-style: solid;
  @if $sizeh {
  } @else {
    $sizeh: $size;
  }
  border-width: $sizeh $size 0 $size;
  border-color: $color transparent transparent transparent;
}

@mixin cover($position: absolute) {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: $position;
  display: block;
}

@mixin w($size, $min: null, $max: null) {
  @if $min {
    min-width: $min;
  } @else {
    min-width: $size;
  }
  @if $max {
    max-width: $max;
  } @else {
    max-width: $size;
  }
  width: $size;
}
