sbmn-my-bookings-tariffs {
  display: block;
  min-width: 100%;

  .h3withbold {
    font-size: 2rem;
  }

  .h4withbold {
    margin-bottom: 20px;
  }

  .price {
    color: $Bprimary-lighter;
    font-size: 1.8rem;
    font-family: basicbold, Arial, sans-serif;
    font-weight: lighter;
    letter-spacing: -0.5px;
  }

  .bmanager__tariffs__nav__item {
    position: relative;
    min-width: 80px;
    top: auto;
    right: auto;
    cursor: default;

    &:hover {
      color: $Bgrey5;
    }

    .h2withbold {
      margin-bottom: 0;
    }

    i.m_icon:hover {
      border: 2px solid $Bprimary-light;
      background: $Bwhite;
      cursor: default;

      &:before {
        color: $Bprimary-light;
        transform: scale(1);
      }
    }

    i.active:hover {
      border: 2px solid $Bwhite;
      background: $Bprimary-light;

      &:before {
        color: $Bwhite;
        transform: scale(1.1);
      }
    }

    i:before {
      font-family: basicbold, Arial, sans-serif;
      font-weight: lighter;
      font-style: normal;
    }
  }

  i {
    //@include circle(40px);
    margin: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: $Bwhite;
    //border: 1px solid $Bgrey3;
    font-style: normal;
    @include mq('desktop', 'max') {
      //@include circle(30px);
      //display: flex;
      margin: 3px 6px 3px 0;
    }

    &.selected {
      border-color: $Bprimary-lighter;
      background: rgba($Bsecondary, 0.2);
    }
  }

  .menosopciones {
    &:before {
      font-size: 3rem;
      color: $Bthird;
    }

    &:hover,
    &:focus {
      opacity: 1;
    }

    opacity: 0.5;
    transform: rotateX(180deg);
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 15px;
    align-items: center;
    cursor: pointer;
    transition:
      transform 0.2s linear,
      opacity 0.2s linear;

    &.expanded {
      transform: rotateX(0);
    }
  }

  .ffamilies__scroll {
    .h2withbold {
      text-align: center;
      margin: 0;
      color: $Bwhite;
    }

    .h3withbold {
      display: flex;
      align-items: center;
      @include mq('tablet', 'max') {
        font-size: 1.8rem;
      }
    }

    table {
      background-color: $Bwhite;
      margin: 0;
      width: 100%;
      border-collapse: collapse;

      thead {
        background: transparent;
        border-bottom: 1px solid $Bprimary;
        color: $Bgrey6;

        tr:last-child {
          color: $Bprimary;
          border-bottom: 0;
        }

        td {
          padding: 2px 5px;
        }
      }

      tr {
        border-bottom: 1px solid $Borange-light;
        border-left: 0;
        @include mq('tablet', 'max') {
          border-bottom: 5px solid $Borange-light;
        }

        &.in {
          background: rgba($Bsecondary-light, 0.2);
        }

        &.selected {
          background: rgba($Bgreen-light, 0.1);
        }
      }

      td {
        vertical-align: top;
        padding: 5px;
        @include mq('tablet', 'max') {
          &:before {
            display: none;
          }
        }

        &:last-child {
          justify-content: flex-end;
          display: flex;
          align-items: center;
        }
      }
    }

    span.ko {
      z-index: z(floor);
    }
  }

  .ffamilies__options__container__item__icon {
    @include circle(25px);
    display: inline-flex;
    font-size: 2rem;
    border-color: $Bprimary-light;

    &.maleta {
      border: 0;
      margin-right: 10px;
      background: $Btransparent;
      color: $Bwhite;

      &:before {
        font-size: 2.5rem;
        top: -1px;
        position: absolute;
        color: rgba($Bprimary, 0.5);
      }

      strong {
        margin-top: 3px;
        font-size: 1.3rem;
        z-index: z(level1);
      }
    }
  }

  .ffamilies__options {
    &__container {
      &__header {
        display: none;
        @include mq('phablet', 'max') {
          display: flex;
        }

        .h3withbold {
          margin-right: 10px;
        }

        & .ffamilies__options__container__item__icon {
          @include mq('phablet', 'max') {
            display: flex;
          }
        }
      }

      &__item {
        min-width: 100%;
        margin-top: 15px;

        &__list {
          font-size: 1.3rem;
          text-align: left;
          border-radius: 5px;
          width: 100%;
          height: 0;
          padding: 0 10%;
          margin: 0 auto;
          max-height: 0;
          overflow: hidden;
          transition: all 0.3s linear;
          list-style: disc;

          li:first-child {
            list-style: none;
          }
        }

        .in &__list {
          height: auto;
          max-height: 1000px;
          margin: 5px auto;
          padding: 10px 10%;
          background: rgba($Bsecondary-light, 0.5);
        }

        .selected.in &__list {
          background: $Bwhite;
        }

        &__title {
          position: relative;
          display: flex;
          align-items: center;
          text-align: left;

          > strong {
            display: inline-block;
            text-align: right;

            span {
              margin: 0 4px;
            }
          }

          em {
            font-size: 0.9em;
            margin-left: 10px;
            display: none;
            @include mq('tablet', 'max') {
              display: inline-block;
            }
          }
        }

        &__price {
          font-size: 2rem;
          padding: 5px 10px 2px 10px;
          width: 130px;
          display: inline-block;
          text-align: center;
          background: $Bgrey3;

          .selected & {
            background-color: $Bsecondary;

            &:hover,
            &:focus,
            &:active {
              box-shadow: none;
              cursor: auto;
            }
          }
        }

        &__open.menosopciones {
          @include circle(30px);
          margin-left: 15px;
          transform: rotate(180deg);
          position: relative;

          &:before {
            color: $Bprimary;
            font-size: 3rem;
          }
        }

        .in &__open.menosopciones {
          transform: rotate(0);
        }
      }
    }
  }
}

.bmanager__tariffs {
  &__nav {
    display: flex;
    justify-content: center;
    min-height: 50px;
    position: relative;

    &__item {
      .one:before {
        content: '1';
      }

      .two:before {
        content: '2';
      }

      .three:before {
        content: '3';
      }

      .four:before {
        content: '4';
      }
    }
  }

  &__steps {
    border-bottom: 1px solid $Bprimary-lighter;
    padding-bottom: 20px;
    margin-bottom: 20px;

    &__title.h3withbold {
      margin-top: 20px;
      margin-bottom: 15px;
      border-bottom: 1px solid $Bgrey1;
    }

    &__error {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__container {
      border-bottom: 1px solid $Bgrey3;
      padding: 10px;
      margin: 0 auto 30px;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-radius: 10px;
      transition: background 0.2s linear;

      &:hover,
      &:focus,
      &:active {
        background: rgba($Bsecondary-light, 0.6);

        .h3withbold {
          font-family: basicbold, Arial, sans-serif;
          font-weight: lighter;
        }
      }

      .disabled & {
        cursor: default;
      }

      .disabled &:hover,
      .disabled &:focus,
      .disabled &:active {
        background: $Btransparent;

        .h3withbold {
          font-family: basicregular, Arial, sans-serif;
        }
      }

      &__title {
        margin: 5px 0 5px;
        min-width: 50%;

        &:before {
          background: white;
          border-radius: 50%;
          padding: 3px;
          margin-right: 15px;
        }
      }

      &__total {
        text-align: right;

        &.large {
          min-width: 100%;

          .price {
            font-size: 2.1rem;
            color: $Bprimary-light;
          }
        }
      }

      &__step3,
      &__step4 {
        width: 100%;
        margin: 20px auto;
      }

      &__step3 {
        text-align: center;

        &__notice {
          width: 100%;
          font-size: 1.5rem;
          text-align: center;
        }

        &__button {
          margin: 20px auto;
        }
      }

      &__step4 {
        .cssload-loader {
          margin: 20px auto;
        }
      }
    }
  }
}
