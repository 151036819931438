sbmn-flights-passengers-cabin-luggage {
  .cssload-loader__container {
    background: $Btransparent;
  }

  .h2withbold {
    color: $Bwhite;
  }

  .maleta {
    opacity: 0.5;
    margin-right: 20px;
  }
}

.cabinFR {
  &__container {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    @include mq('tablet', 'max') {
      flex-direction: column;
      align-items: center;
    }

    &__option {
      position: relative;
      background: $Bwhite;
      flex-basis: 90%;
      padding: 10px 2%;
      margin: 2% 2% 70px;
      cursor: pointer;
      max-width: 350px;

      &.selected,
      &:hover,
      &:focus {
        h3 {
          color: $Bprimary;
        }

        ul,
        em {
          color: $Bblack;
        }

        em {
          color: $Bgrey7;
        }

        i {
          color: $Bprimary;
        }

        strong {
          background: $Borange-light;
        }
      }

      &.selected {
        transform: scale(1.03);

        strong {
          @include circle(50px);
          display: flex;
          background: $Bgreen-light;
          color: $Btransparent;
          font-size: 0;

          &:before {
            color: $Bwhite;
            font-size: 2rem;
          }
        }
      }

      &:nth-of-type(2) {
        .maleta {
          padding-left: 25%;
        }

        .trolley {
          position: absolute;
          top: 80px;
          left: 25%;
        }
      }

      i {
        min-height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $Bgrey4;
        transition: color 0.1s linear;
      }

      .maleta:before {
        font-size: 6rem;
      }

      .trolley:before {
        font-size: 10rem;
      }

      &__selected {
        font-size: 2rem;
        color: $Bgreen-light;
        display: block;
        margin: -40px auto 20px;
        cursor: auto;
      }

      &__title {
        font-size: 2rem;
        margin-top: 0;
        font-family: basiclight, Arial, sans-serif;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $Bgrey6;
        transition: color 0.1s linear;
      }

      &__description {
        position: relative;
        background: $Bwhite;
        flex-basis: 90%;
        padding: 1% 5%;
        margin: 20px 2%;
        font-size: 1.5rem;
        text-align: left;
        color: $Bgrey5;
        transition: color 0.1s linear;

        &__item {
          padding: 5px 0;
        }
      }

      &__comment {
        font-size: 1.2rem;
        text-align: justify;
        display: block;
        color: $Bgrey3;
        transition: color 0.1s linear;
        @include mq('tablet', 'max') {
          padding: 20px 20px 10%;
        }
      }

      &__price {
        @include circle(90px);
        background: $Bgrey4;
        font-size: 2.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: basicbold, Arial, sans-serif;
        letter-spacing: -0.05em;
        color: $Bwhite;
        top: 65px;
        right: 10px;
        position: absolute;
        transition: background 0.1s linear;
      }
    }
  }
}
