sbmn-my-bookings {
  .table_responsive {
    border-collapse: unset;
    border-spacing: 0 2px;

    th.mth {
      width: 90px;
      position: relative;
    }

    .split {
      border: 0;
      border-radius: 50%;
      display: flex;
      width: 3rem;
      height: 3rem;
      justify-content: center;
      align-items: center;
      background: $Bwhite;

      &:before {
        font-size: 2.5rem;
        margin-left: 1px;
        color: rgba($Bprimary, 0.5);
      }
    }

    tr:hover .split:before {
      color: $Bprimary;
    }

    .msgbullet,
    .split {
      @include mq('tablet', 'min') {
        margin: auto;
      }
    }

    .ok {
      color: $Bgreen-light;
    }
  }
}

.mybookings__header {
  &__icon {
    position: absolute;
    margin: 12px 0 0 22px;
    padding-left: 60px;
    left: 2px;
    cursor: pointer;
    color: $Bsecondary-dark;
    text-transform: uppercase;
    font-size: 1.4rem;
    width: 100px;

    &:before {
      left: 0;
      position: absolute;
      font-size: 2rem;
      padding: 8px;
      border: 2px solid $Bsecondary-dark;
      border-radius: 50%;
      @include mq('tablet', 'max') {
        font-size: 1.2rem;
      }
    }

    &:hover {
      color: $Bsecondary;

      &:before {
        border: 2px solid $Bsecondary;
      }
    }
  }
}

#mybookings {
  display: flex;
  position: relative;
  min-height: 75vh;
  > div {
    margin: 0 auto;
  }
}

.mybookings__container {
  width: 100%;
  position: relative;
  min-height: 400px;

  &__nobookings {
    text-align: center;
    width: 100%;
    font-size: 2.5rem;
    color: $Borange-red;
  }
}

.mybookings__filters {
  opacity: 0;
  transition: margin-left 0.4s linear;
  background: $Bgrey05;
  padding: 80px 30px 0 30px;
  position: absolute;
  min-width: 340px;
  margin-left: -340px;
  z-index: z(level1);
  box-shadow: 19px 5px 13px -16px rgba($Bblack, 0.7);
  height: 100%;
  @include mq('tablet', 'max') {
    min-width: 100%;
    margin-left: -100%;
  }

  &.show {
    opacity: 1;
    margin-left: 0;
  }
}

.mybookings__legend {
  text-align: center;
  padding: 20px 10px 25px;
  width: 96%;
  margin: 0 auto;
  @include mq('tablet', 'max') {
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px 15px 25px;
  }

  strong {
    height: 30px;
    width: 2px;
    background-color: #4c4c4c;
    opacity: 0.5;
    border-radius: 100px;
  }

  span {
    position: relative;
    cursor: pointer;
    color: $Bgrey7;
    font-size: 1.5rem;
    margin: 5px 10px 5px 5px;
    display: inline-block;
    padding: 3px 10px 3px 10px;
    line-height: 1.2;
    border-bottom: 3px solid;
    transition: all 0.2s linear;
    min-width: 115px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0.6;
    @include mq('tablet', 'max') {
      min-width: 45%;
      margin: 10px 10px 10px 5px;
    }

    &:before {
      position: absolute;
      right: 1px;
      bottom: 7px;
      font-size: 1rem;
      transition: opacity 0.2s linear;
      opacity: 0;
      color: $Bwhite;
    }

    &:hover,
    &.okselected {
      padding-left: 2px;
      padding-right: 18px;
      opacity: 1;

      &:before {
        opacity: 1;
      }
    }
  }

  strong {
    @include mq('tablet', 'max') {
      display: none;
    }
  }
}

tr.is_tr {
  &.__INI {
    .document:before {
      background: $Borange-red;
    }

    @include mq('tablet', 'max') {
      border-left: 10px solid $Borange-red !important;
    }

    &:hover {
      background-color: rgba($Borange-red, 0.2);
    }
  }

  &.__RES {
    .document:before {
      background: $Bgrey5;
    }

    @include mq('tablet', 'max') {
      border-left: 10px solid $Bgrey5 !important;
    }

    &:hover {
      background-color: rgba($Bgrey5, 0.2);
    }
  }

  &.__RLE {
    .document:before {
      background: $Bblue-lighter;
    }

    @include mq('tablet', 'max') {
      border-left: 10px solid $Bblue-lighter !important;
    }

    &:hover {
      background-color: rgba($Bblue-lighter, 0.2);
    }
  }

  &.__BEM {
    .document:before {
      background: $Bgreen;
    }

    @include mq('tablet', 'max') {
      border-left: 10px solid $Bgreen;
    }

    &:hover {
      background-color: rgba($Bgreen, 0.2);
    }
  }

  &.__BCN {
    .document:before {
      background: $Berror-dark;
    }

    @include mq('tablet', 'max') {
      border-left: 10px solid $Berror-dark;
    }

    &:hover {
      background-color: rgba($Berror-dark, 0.2);
    }
  }

  &.__CAN {
    .document:before {
      background: $Berror;
    }

    @include mq('tablet', 'max') {
      border-left: 10px solid $Berror;
    }

    &:hover {
      background-color: rgba($Berror, 0.2);
    }
  }

  &.__LCP {
    .document:before {
      background: $Bsecondary;
    }

    @include mq('tablet', 'max') {
      border-left: 10px solid $Bsecondary;
    }

    &:hover {
      background-color: rgba($Bsecondary, 0.2);
    }
  }

  &.__LCE {
    .document:before {
      background: $Bgreen-dark;
    }

    @include mq('tablet', 'max') {
      border-left: 10px solid $Bgreen-dark;
    }

    &:hover {
      background-color: rgba($Bgreen-dark, 0.2);
    }
  }

  &.__LCC {
    .document:before {
      background: $Berror-light;
    }

    @include mq('tablet', 'max') {
      border-left: 10px solid $Berror-light;
    }

    &:hover {
      background-color: rgba($Berror-light, 0.2);
    }
  }
}

.is_tr {
  .document {
    position: relative;
    padding-left: 12px;
    border-right: 1px solid $Btransparent;

    span {
      @include cover();
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      font-size: 1.5rem;
      background: $Bwhite;
      font-weight: bold;

      &.copiedok {
      }

      &.copied {
        @include mq('tablet', 'max') {
          width: 100%;
          margin: 0;
        }
      }
    }

    &:before {
      color: $Btransparent;
      background: $Bgrey7;
      font-size: 2.8rem;
      transition: width 0.2s linear;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: calc(50% - 10px);
      left: 5px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    &:hover {
      line-height: 1rem;

      .copied {
        opacity: 1;
        @include mq('tablet', 'max') {
          opacity: 0.6;
        }
      }

      &:before {
        color: $Bwhite;
        width: 25px;
      }
    }

    &.noloc:hover {
      cursor: default;

      &:before {
        color: $Btransparent;
      }
    }

    @include mq('tablet', 'max') {
      line-height: 1;
      min-height: 50px;
      border-bottom: 1px solid $Bgrey5 !important;
      font-size: 2rem;
      font-weight: bold;
      &:before {
        left: auto;
        right: 10px;
        justify-content: flex-end;
        background: $Btransparent !important;
      }
      &:hover {
        line-height: 1;

        &:before {
          content: '\e90b';
          background: $Btransparent !important;
          color: $Bgrey7;
        }
      }
    }
  }
}

span {
  &.ok:hover:before,
  &.okselected:before {
    opacity: 1;
  }

  &.cod-estado__RES {
    border-color: $Bgrey5;

    &.ok:hover,
    &.okselected {
      &:before {
        color: $Bgrey5;
      }
    }
  }

  &.cod-estado__RLE {
    border-color: $Bblue-lighter;

    &.ok:hover,
    &.okselected {
      &:before {
        color: $Bblue-lighter;
      }
    }
  }

  &.cod-estado__BEM {
    border-color: $Bgreen;

    &.ok:hover,
    &.okselected {
      &:before {
        color: $Bgreen;
      }
    }
  }

  &.cod-estado__BCN {
    border-color: $Berror-dark;

    &.ok:hover,
    &.okselected {
      &:before {
        color: $Berror-dark;
      }
    }
  }

  &.cod-estado__CAN {
    border-color: $Berror;

    &.ok:hover,
    &.okselected {
      &:before {
        color: $Berror;
      }
    }
  }

  &.cod-estado__LCP {
    border-color: $Byellow;

    &.ok:hover,
    &.okselected {
      &:before {
        color: $Byellow;
      }
    }
  }

  &.cod-estado__LCE {
    border-color: $Bgreen-dark;

    &.ok:hover,
    &.okselected {
      &:before {
        color: $Bgreen-dark;
      }
    }
  }

  &.cod-estado__LCC {
    border-color: $Berror-light;

    &.ok:hover,
    &.okselected {
      &:before {
        color: $Berror-light;
      }
    }
  }

  &.cod-estado__msg {
    border-color: $Borange-light;

    &.ok:hover,
    &.okselected {
      &:before {
        color: $Borange-light;
      }
    }
  }

  &.cod-estado__ {
    border-color: $Bgrey7;

    &.ok:hover,
    &.okselected {
      &:before {
        color: $Bgrey7;
      }
    }
  }
}
