sbmn-my-bookings-passengers {
  display: block;
  min-width: 105%;

  .h3withbold {
    font-size: 2rem;
    padding: 0 30px 15px 2.5%;
    margin: 0;
    background: $Btransparent;
    max-width: calc(100% - 75px);
    @include mq('tablet', 'max') {
      top: 0;
      padding: 0 5% 15px;
      line-height: 1;
    }
  }

  .h4withbold {
    margin: 0 2.5% 20px;
  }

  div.bpassengers__container {
    border-bottom: 1px solid $Bgrey1;
  }

  span.bmanager__passengers__edit {
    top: 0;
    right: 10px;
    flex-direction: row-reverse;
    cursor: pointer;
    min-width: 100%;
    height: 55px;

    &:hover {
      i {
        border-color: $Bgrey6;
      }
    }

    i {
      @include circle(25px);
      display: flex;
      margin: 0 5px;

      &:before {
        font-size: 1.4rem;
      }
    }

    &.warning:before {
      position: absolute;
      top: 6px;
      right: -2px;
      color: $Borange-red;
      font-size: 1.6rem;
    }
  }

  .xtra {
    max-height: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.25s linear;
  }

  .extradata {
    max-height: 1500px;
    height: auto;
    overflow: hidden;
    width: 100%;
    opacity: 1;
    transition: all 0.25s linear;
  }

  .passengers {
    // overwriting flights-passengers.scss
    margin: 0 auto;

    &__title {
      padding-left: 0;
    }

    &__section {
      &__passenger {
        position: relative;
        border: 1px solid $Bgrey2;
        background: rgba($Bwhite, 0.4);
        padding-bottom: 0;
        overflow: hidden;

        &__data {
          position: relative;
          align-items: center;
          background: $Bwhite;

          .TsaDocs {
            display: flex;
            width: 100%;
          }

          &__title {
            line-height: 1.5rem;
          }
        }
      }
    }
  }

  .buttonwhite {
    padding: 5px 20px;
    margin-left: 20px;
  }

  .loyalty {
    padding-bottom: 20px;
    justify-content: center;

    .mat-form-field:last-of-type {
      margin-right: 10px;
    }
  }
}

.bmanager__passengers {
  &__container {
    &__travelwith {
      display: block;
      font-size: 0.7em;
      color: $Bgrey7;
    }

    &__extra {
      margin-top: 5px;

      &__title {
        color: $Borange-red;
        margin-top: 10px;
        margin-bottom: 20px;
        width: 100%;
      }

      &__resident {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: $Bgrey7;
        padding: 0 20px 20px 0;

        .ok {
          color: $Bgreen-light;
          font-size: 1.3rem;
        }

        .delete {
          color: $Berror;
        }
      }

      &__buttoninfo,
      &__button {
        margin: 0 auto 20px auto;
      }

      &__buttoninfo {
        width: 100%;
        display: block;
        text-align: center;
        font-size: 1.4rem;
      }
    }
  }
}
