sbmn-my-bookings-filters,
sbmn-my-messages-filters,
sbmn-mta-balance-filters,
sbmn-mta-users-filters {
  h2 {
    font-family: basiclight, Arial, sans-serif;
    font-size: 3rem;
    font-weight: lighter;
    position: absolute;
    top: 17px;
    margin: 0;
  }
}

.filters-form {
  font-size: 1.4rem;

  section {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }

  .ok,
  .avion,
  .billete,
  .calendar,
  .pasajero,
  .msg-general,
  .msg-admin {
    font-size: 20px;
    color: $Bgrey4;
    margin-right: 10px;
  }

  .ok {
    font-size: 15px;
  }

  .calendar,
  .pasajero {
    transform: none;
  }

  .Bplus {
    transition: all 0.2s linear;
    transform: rotate(45deg);
  }

  .mat-form-field mat-icon {
    position: relative;
    float: right;
    top: -3px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);
  }

  .mat-form-field .mat-date-range-input {
    width: 85%;
    display: inline-block;
  }
}
