sbmn-flights-loading {
  position: fixed;
  height: 0;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  background: $Bwhite;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: z(top);
  opacity: 0;
  transition: opacity 0.5s linear;
  flex-direction: column;
  @include mq('tablet', 'max') {
    justify-content: flex-start;
    overflow-y: scroll;
    width: calc(100% + 40px);
    padding-right: 20px;
  }
  @media only screen and (orientation: portrait) {
    width: 100%;
    padding-right: 0;
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('/assets/img/patterns/gravel.png') 0% 0%;
    content: ' ';
    opacity: 0.2;
    z-index: z(bottom);
  }

  &.loading {
    top: 0;
    height: 100%;
    opacity: 1;
  }
}

#loading-data {
  max-width: 800px;
  width: 90%;
  &:before {
    @extend .Bborder-b;
  }

  > header,
  > section {
    padding: 0 40px;
    @include mq('tablet', 'max') {
      padding: 0;
    }
  }

  h1 {
    color: $Bblack;
    font-size: 3.5rem;
    text-align: center;
    margin-bottom: 10px;
    @include mq('phablet', 'max') {
      font-size: 2.5rem;
    }
  }

  header > div {
    @extend %Btext_light;
    text-align: center;
  }

  section {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 1px solid $Bgrey1;
    padding: 20px;
    @include mq('tablet', 'max') {
      flex-direction: column;
      border-color: $Bprimary;
      border-style: solid;
      border-width: 2px 0;
      padding: 12px;
    }
  }

  .loading-info {
    font-size: 1.5rem;
    line-height: 1.2;
    opacity: 0;
    padding: 0 15px 10px 0;
    @include mq('phablet', 'max') {
      font-size: 1.2rem;
    }

    &:nth-of-type(1) {
      animation: appears 0.3s linear 0.5s forwards;
    }

    &:nth-of-type(2) {
      animation: appears 0.3s linear 1.1s forwards;
    }

    &:nth-of-type(n + 3) {
      animation: appears 0.3s linear 1.4s forwards;
      min-width: 180px;
      @include mq('tablet', 'max') {
        padding: 0;
      }
    }
    p {
      margin: 0;
    }

    > span {
      text-transform: uppercase;
      font-size: 1.5rem;
      color: $Bgrey6;
      @include mq('tablet', 'max') {
        font-weight: bold;
      }
    }
  }

  .Multidestino_loading {
    flex-wrap: wrap;
    background: none;

    .loading-info {
      min-width: 100%;
      margin-bottom: 10px;

      &:nth-of-type(n + 3) {
        padding: 0 15px 0 0;
      }

      &:last-child {
        padding-top: 10px;
        border-top: 1px solid $Bgrey3;

        p {
          display: inline;
          padding-right: 10px;
        }

        br {
          display: none;
        }
      }
    }
  }

  .idavuelta,
  .soloida {
    border: none;
    position: relative;
    margin-right: 30px;
    margin-left: 0;
    opacity: 0;
    animation: appears 0.3s linear 0.8s forwards;

    &:before {
      color: $Bprimary-lighter;
    }

    @include mq('tablet', 'max') {
      display: none;
    }
  }

  .mini {
    width: 20px;
    height: 20px;
    border: 0;
    display: inline;
    line-height: 0.9;
    padding: 0 10px;
    &:before {
      font-size: 1.6rem;
    }
  }

  figure {
    padding-top: 20px;
    border-top: 1px solid $Bgrey1;
    min-width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    @include mq('tablet', 'max') {
      margin: 0;
    }

    img {
      width: 100%;
      max-width: 710px;
    }
  }
}
