.cms {
  &__basicinfo {
    &__header {
      &__title {
        border-bottom: 1px solid $Bgrey2;

        &.h2withbold {
          margin-bottom: 30px;
        }
      }
    }

    &__container {
      &__content {
        br {
          display: none;
        }

        > span {
          display: block;
          width: 100%;
          margin: 30px 0 10px 0;

          > strong {
            @extend .h3withbold;
          }
        }

        a {
          padding: 0 2px;
        }

        li {
          margin-bottom: 10px;

          strong:first-child {
            display: block;
            font-size: 1.2em;
          }
        }
      }
    }
  }

  &__openHTML {
    font-size: 1.6rem;
    text-align: justify;
    padding: 0 40px;
    @include mq('tablet', 'max') {
      padding: 0 10px;
    }

    &.vuelos,
    &.vuelos table,
    &.vuelos td,
    &.vuelos th {
      border-color: $Bprimary-lighter;
    }

    &.info,
    &.info table,
    &.info td,
    &.info th {
      border-color: $Bgrey4;
    }

    i {
      background: $Bblack;
      border-radius: 50%;
      padding: 20px 24px;
      font-size: 4rem;
      color: $Bwhite;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mq('tablet', 'max') {
        padding: 10px;
        font-size: 3rem;
        margin: 5px;
      }
    }

    p {
      margin: 0 auto 20px;
    }

    ul {
      padding-left: 5%;
    }

    .upper {
      text-transform: uppercase;
    }

    .subs {
      text-decoration: underline;
    }

    &__main-figure {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 40px;

      &__img {
        max-width: 550px;
        width: 80%;
      }
    }

    &__header {
      width: 100%;

      &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 4.5rem;
        font-weight: bold;
        margin: 10px auto;
        text-align: center;
        width: 100%;
        line-height: 1;
        @include mq('tablet', 'max') {
          font-size: 3rem;
        }
      }
    }

    &__content {
      padding: 30px 0;
      margin: 0;
      max-width: 100%;
      min-width: 100%;

      &__title {
        font-size: 2.6rem;
        margin-bottom: 5px;
        @include mq('tablet', 'max') {
          font-size: 1.6rem;
        }

        &.extra {
          text-transform: uppercase;
          text-decoration: underline;
        }
      }

      &__floatfigure {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        float: right;
        max-width: 50%;
        width: 50%;
        max-height: 350px;
        margin-right: 0;
        @include mq('tablet', 'max') {
          max-width: 100%;
          width: 100%;
        }

        img {
          height: 100%;
          max-height: 350px;
          width: auto;
          border-radius: 10px;
        }
      }

      &__figure {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        max-width: 100%;
        width: 100%;
        margin: 40px 0;

        img {
          height: 100%;
          max-height: 350px;
          width: auto;
          border-radius: 10px;
          margin: 1% auto;
          max-width: 650px;
          @include mq('tablet', 'max') {
            max-width: 100%;
            max-height: unset;
            height: auto;
          }
        }
      }

      &__yellow {
        list-style-type: none;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include mq('tablet', 'max') {
            flex-direction: column;
          }
        }

        &__block {
          background: $Bsecondary;
          border-radius: 10px;
          font-size: 3rem;
          line-height: 1;
          text-align: center;
          padding: 20px;
          width: 80%;
          margin: 10px 0;
          @include mq('tablet', 'max') {
            font-size: 1.8rem;
            width: 100%;
          }

          a {
            font-size: 3rem;
            line-height: 1;
            @include mq('tablet', 'max') {
              font-size: 1.8rem;
            }
          }
        }
      }

      &__plane-ball {
        height: 500px;
        width: 500px;
        position: relative;
        border-radius: 100%;
        margin: 0 auto 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq('tablet', 'max') {
          margin: 30px auto;
          height: 400px;
          width: 400px;
        }
        @include mq('phablet', 'max') {
          height: 250px;
          width: 250px;
        }

        .avion {
          animation: spin 8s infinite linear;
          height: 80%;
          width: 80%;
          @include mq('phablet', 'max') {
            height: 90%;
            width: 90%;
          }

          &:before {
            font-size: 3rem;
            @include mq('phablet', 'max') {
              font-size: 2rem;
            }
          }
        }

        &__inside {
          height: 95%;
          width: 95%;
          background-color: $Bsecondary;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          font-weight: bold;
          font-size: 3rem;
          position: absolute;
          @include mq('phablet', 'max') {
            font-size: 1.5rem;
          }
        }
      }

      table {
        border-collapse: unset;
        border-spacing: 0;
        margin-bottom: 20px;
        border: solid $Bsecondary;
        border-width: 0 3px 3px 0;
        background: $Bwhite;
        @include mq('tablet', 'max') {
          border-width: 3px;
          border-collapse: collapse;
        }

        tr {
          @include mq('tablet', 'max') {
            border-bottom: 5px solid $Bsecondary;
            .vuelos & {
              border-bottom: 5px solid $Bprimary-lighter;
            }
            .info & {
              border-bottom: 5px solid $Bprimary-lighter;
            }
          }
        }

        td,
        th {
          padding: 5px 10px;
          border: solid $Bsecondary;
          border-width: 3px 0 0 3px;
          @include mq('tablet', 'max') {
            border-width: 0;
          }

          h2 {
            font-size: 1.7rem;
            margin: 5px 0;
            color: $Bgrey5;
          }

          h3 {
            font-size: 1.5rem;
            color: $Bgrey9;
          }
        }
      }
    }
  }

  &__boletin {
    text-align: center;

    img {
      max-width: 100%;
      margin: auto;
    }
  }
}
