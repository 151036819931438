sbmn-flights-highlight {
  position: relative;

  section {
    background: rgba($Bprimary-lighter, 0.5);
    color: $Bwhite;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10%;
    font-size: 3rem;
    @include mq('desktop', 'max') {
      font-size: 2.5rem;
    }
    @include mq('tablet', 'max') {
      width: 100%;
      font-size: 2rem;
      flex-direction: row;
      padding: 10px 20px;
      min-height: 110px;
    }
    @include mq('phablet', 'max') {
      font-size: 1.5rem;
    }
    &:before {
      @extend .Bborder-y;
    }
  }

  button.buttonbluelr {
    margin: 10px 20px;
    padding: 10px;
  }
}
