sbmn-flights-passengers {
  background: $Bgrey05;
  display: block;
  min-height: 100%;
  padding-bottom: 40px;
}

.passengers {
  // used at my-bookings-passengers.scss too
  position: relative;
  margin: 60px auto;
  max-width: map_get($breakpoints, 'tablet');
  @include mq('tablet', 'max') {
    left: 0;
    padding: 20px 0;
    margin-bottom: 40px;
    margin-top: 10px;
  }

  &__loading {
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    &__backlink.max-width-page {
      display: block;
      max-width: 970px;
      margin: 40px auto;
      text-align: right;
    }
  }

  .mat-form-field {
    width: auto;
    margin: 5px 10px;
    flex-grow: 2;

    &:nth-of-type(1) {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.mini {
      flex-grow: 1;
      min-width: 65px;
      max-width: 65px;
    }

    &.checks {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 0;

      mat-checkbox {
        margin-bottom: 5px;
        color: $Bprimary;
      }
    }

    &.total {
      margin-left: 0;
      min-width: 100%;
      margin-bottom: 10px;
    }

    &.dates {
      max-width: 23%;
    }

    .mat-checkbox-label {
      line-height: 1;
    }

    @include mq('tablet', 'max') {
      width: 100%;
      flex-grow: unset;
      display: block;
      margin: 0;
      &.mini {
        max-width: unset;
        flex-grow: unset;
        width: 20%;
        margin-right: 1%;
      }
      &.semi {
        width: 77%;
        flex-grow: 2;
      }
      &.dates {
        max-width: 100%;
      }
    }
    @include mq('phablet', 'max') {
      &.mini {
        width: 100%;
      }
    }

    .passengers__section__passenger__data__info {
      padding-bottom: 0;
    }
  }

  .unavailable {
    color: $Btransparent !important;
    animation: heightOut 0.3s linear forwards;
  }

  .alert_active {
    color: $Borange-red;
  }

  &__specialinfo {
    padding-bottom: 20px;
    line-height: 1.4;
    @include mq('tablet', 'max') {
      padding: 0 0 20px 20px;
    }

    h2 {
      color: $Borange-red;
    }
  }

  &__section {
    position: relative;
    margin-bottom: 60px;

    &:before {
      position: absolute;
      width: 32px;
      color: $Bsecondary;
      font-size: 3rem;
      left: 5px;
      top: -5px;
      text-align: center;
      padding: 0;
    }

    &.maleta:before {
      font-size: 2.2rem;
    }

    .out {
      animation: fadeOutHeight 0.6s linear forwards;
    }

    .in {
      animation: fadeOutHeight 0.6s linear backwards reverse;
    }

    &__title,
    &__title.h2withbold {
      font-family: special, Arial, sans-serif;
      font-size: 3.5rem;
      color: $Bprimary-dark-gradient2;
      margin-bottom: 30px;
      margin-left: 50px;
      align-items: center;
      line-height: 0.7;

      &__note {
        font-size: 0.5em;
        color: rgba($Bviolet, 0.8);
        max-width: 90%;

        &:before {
          content: ' | ';
          margin: 0 10px;
          font-style: normal;
        }

        @include mq('tablet', 'max') {
          display: block;
          padding-top: 10px;
          &:before {
            display: none;
          }
        }
      }

      @include mq('tablet', 'max') {
        width: 100%;
      }
    }

    &__passenger {
      position: relative;
      margin: 0 1% 30px;
      background: rgba($Bwhite, 0.7);
      padding: 20px 0;
      border-radius: 0 20px 0;
      @include mq('tablet', 'max') {
        overflow: hidden;
      }

      &__title {
        background: $Bprimary-light;
        color: $Bwhite;
        margin: 0 0 15px 2.5%;
        padding: 5px 50px 5px 5px;
        display: inline-block;
        @include mq('tablet', 'max') {
          position: relative;
          top: -20px;
          margin-left: 0;
          margin-bottom: -20px;
          padding: 15px 50px 10px 2.5%;
          width: 100%;
        }

        &__ok {
          position: absolute;
          top: 5px;
          right: 5px;
          color: $Bgreen-light;
          font-size: 1.4rem;
          border-radius: 50%;
          border: 2px solid $Bgreen-light;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &__travelwith {
        margin-left: 10px;
        color: $Bprimary;
        @include mq('tablet', 'max') {
          display: block;
          margin-bottom: 20px;
        }
      }

      &__data {
        display: flex;
        flex-wrap: wrap;
        padding: 0 2.5%;
        overflow: hidden;
        @include mq('tablet', 'max') {
          padding: 0 5%;
        }

        &__title {
          width: 100%;
          font-family: basicbold, Arial, sans-serif;
          font-size: 2rem;
          padding: 5px 0 2px;
          border-bottom: 1px solid $Bgrey2;
          color: rgba($Bviolet, 0.8);
          margin: 0 0 13px 0;

          &.-psg-luggage {
            color: $Bprimary-light;
          }

          &__note {
            font-family: basicregular, Arial, sans-serif;
            font-size: 0.7em;
            color: rgba($Bviolet, 0.8);

            &:before {
              content: ' | ';
              margin: 0 10px;
              font-style: normal;
              font-weight: bold;
            }
          }
        }

        &__info {
          font-size: 1.3rem;
          color: $Bgrey6;
          margin: 0;
          padding-bottom: 20px;
          transition: color 0.5s linear;
        }

        &.TsaDocs mat-form-field {
          @include mq('tablet', 'min') {
            max-width: 15%;
          }
        }
      }

      &__baggage {
        position: relative;
        width: 45%;
        margin: 5px 2.5% 5px 0;

        &:nth-of-type(2) {
          margin: 5px 2.5% 5px 5%;
        }

        @include mq('tablet', 'max') {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
          padding-right: 25px;
          &:nth-of-type(2) {
            margin: 0;
          }
        }

        .mat-form-field {
          width: 100%;
          margin: 0;
        }

        .ko {
          top: 20px;
          right: -22px;
          font-size: 1.5rem;
          @include mq('tablet', 'max') {
            right: 0;
          }

          &:hover {
            color: $Berror;
          }
        }
      }
    }

    &__cabin-luggage {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 10px 2.5%;

      &__info {
        @include mq('tablet', 'max') {
          width: 100%;
        }

        &__title {
          margin: 0 0 5px;
          color: $Bprimary-light;
          font-size: 2rem;

          span {
            color: $Bgreen-light;
            font-size: 1.6rem;
          }
        }
      }

      .buttonwhite {
        padding: 7px 20px;
        margin-left: 20px;
        @include mq('tablet', 'max') {
          margin: 20px 0;
          width: 100%;
          padding: 15px 20px;
        }
      }
    }

    &.seguro,
    &.money {
      mat-checkbox {
        margin-left: 2.5%;
      }
    }

    &__insurance,
    &__cards {
      overflow: hidden;
      margin-left: 2.5%;

      > div {
        color: $Bprimary;
        margin: 20px 0 10px;
      }

      .mat-form-field {
        width: 45%;
        margin: 0 2.5%;
      }

      strong.Berror {
        position: relative;
        bottom: auto;
        padding: 0 1% 0 0;
        font-size: 1.5rem;
      }
    }

    &__termslink {
      color: $Bgrey6;
      font-size: 1.4rem;
      padding-top: 10px;
      display: inline-block;
    }

    &__shoppingterms {
      line-height: 1.4;
      font-size: 1.4rem;
      color: $Bblue;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $Bviolet;
        text-decoration: none;
      }
    }

    &__usainfo {
      line-height: 1.4;
      font-size: 1.4rem;
      padding: 0 2.5% 0 0;
    }

    &__last {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include mq('tablet', 'max') {
        padding: 0 15px;
        flex-direction: column;
      }

      &__button {
        padding: 15px 60px;
        margin: 10px;
      }

      &__link {
        display: block;
        float: right;
        text-align: right;
        margin: -20px 20px -20px 0;
        @include mq('tablet', 'max') {
          margin: 20px 20px 20px 0;
        }
      }
    }
  }
}
