sbmn-flights-welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  &:before {
    content: '';
    @include cover();
    background: url('/assets/img/logos/main_logo.svg') no-repeat center center;
    background-size: 300px auto;
    opacity: 0.3;
  }
  @include mq('tablet', 'max') {
    height: 435px;
  }
  @include mq('phablet', 'max') {
    height: 300px;
  }

  figure,
  figcaption {
    @include cover();
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    overflow: hidden;
  }

  figcaption {
    flex-direction: column;
    justify-content: flex-start;
    padding: 80px 1% 8vh;
    color: $Bwhite;
    @include mq('phablet', 'max') {
      font-size: 2rem;
    }
    div {
      text-align: right;
      font-family: basicbold, Arial, sans-serif;
    }
    h2 {
      font-size: 3.5vw;
      padding: 0 0 20px 0;
      margin: 0 0 20px;
      text-align: center;
    }
    p {
      margin: 0;
      text-align: right;
      padding: 0 0 20px 0;
    }
    a {
      color: $Bwhite;
    }
  }

  img,
  .owl-carousel .owl-item img {
    //filter: grayscale(1);
    height: auto;
    width: 100%;
    min-height: 100%;
    position: absolute;
  }

  .owl-theme .owl-dots {
    position: absolute;
    right: 1vw;
    top: 1vh;
    max-width: 1100px;
    z-index: z(level1);
    @include mq('tablet', 'max') {
      display: flex;
      margin: 0;
      justify-content: center;
      width: 100%;
      left: unset;
    }
  }
}
