sbmn-mta-users {
  h1 {
    text-align: center;
    font-size: 4.5rem;
    padding: 20px 0;
  }

  .basic__table {
    &-paginator {
      width: 100%;
      max-width: 1440px;
      margin: auto;
      min-height: 650px;
    }

    &__head {
      tr {
        border: 0;
      }
    }

    &__body {
      tr {
        &:hover,
        &:focus,
        &.active {
          background: rgba($Bprimary-lighter, 0.2);
          border-left: 10px solid $Bprimary-lighter;
          color: $Bblack;
        }
      }
    }
  }
}

.mtausers {
  &__filters-container {
    display: flex;
    min-height: 400px;
  }

  &__filters {
    @extend .mybookings__filters;
    margin-top: -90px;
    padding: 80px 40px 50px 30px;
    background-color: $Bwhite;

    h2 {
      font-family: basiclight, Arial, sans-serif;
      font-size: 3rem;
      font-weight: lighter;
      position: absolute;
      top: 17px;
      margin: 0;
    }

    .ko {
      font-size: 1.8rem;
    }
  }
}
