sbmn-my-bookings-baggage {
  display: block;
  min-width: 100%;

  .h3withbold {
    font-size: 2rem;
  }

  .h4withbold {
    margin-bottom: 20px;
  }

  .price {
    color: $Bprimary-lighter;
    font-size: 1.8rem;
    font-family: basicbold, Arial, sans-serif;
    font-weight: lighter;
    letter-spacing: -0.5px;
  }

  .bmanager__baggage__nav__item {
    position: relative;
    min-width: 80px;
    top: auto;
    right: auto;
    cursor: default;

    &:hover {
      color: $Bgrey5;
    }

    .h2withbold {
      margin-bottom: 0;
    }

    i.m_icon:hover {
      border: 2px solid $Bprimary-light;
      background: $Bwhite;
      cursor: default;

      &:before {
        color: $Bprimary-light;
        transform: scale(1);
      }
    }

    i.active:hover {
      border: 2px solid $Bwhite;
      background: $Bprimary-light;

      &:before {
        color: $Bwhite;
        transform: scale(1.1);
      }
    }

    i:before {
      font-family: basicbold, Arial, sans-serif;
      font-weight: lighter;
      font-style: normal;
    }
  }

  .disabled {
    .basic__table thead th,
    .basic__table tbody tr {
      cursor: default;
    }
  }

  .basic__table {
    width: 100%;
    border-width: 0 0 1px 0;
    margin-bottom: 15px;

    &__head {
      tr {
        border: 0;

        th {
          text-align: center;
        }

        th:last-child {
          padding-right: 0;
        }
      }
    }

    &__body {
      background: $Bwhite;
      border-bottom: 0;

      tr {
        border: 0;

        td:last-child {
          padding-right: 0;

          &:before {
            content: '';
          }
        }
      }
    }
  }
}

.bmanager__baggage {
  &__nav {
    display: flex;
    justify-content: center;
    min-height: 50px;
    position: relative;

    &__item {
      .one:before {
        content: '1';
      }

      .two:before {
        content: '2';
      }

      .three:before {
        content: '3';
      }

      .four:before {
        content: '4';
      }
    }
  }

  &__steps {
    border-bottom: 1px solid $Bprimary-lighter;
    padding-bottom: 20px;
    margin-bottom: 20px;

    &__title.h3withbold {
      margin-top: 20px;
      margin-bottom: 15px;
      border-bottom: 1px solid $Bgrey1;
    }

    &__error {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__container {
      border-bottom: 1px solid $Bgrey3;
      padding: 10px;
      margin: 0 auto 30px;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-radius: 10px;
      transition: background 0.2s linear;

      &:hover,
      &:focus,
      &:active {
        background: rgba($Bsecondary-light, 0.6);

        .h3withbold {
          font-family: basicbold, Arial, sans-serif;
          font-weight: lighter;
        }
      }

      .disabled & {
        cursor: default;
      }

      .disabled &:hover,
      .disabled &:focus,
      .disabled &:active {
        background: $Btransparent;

        .h3withbold {
          font-family: basicregular, Arial, sans-serif;
        }
      }

      &__title {
        margin: 5px 0 5px;
        min-width: 50%;

        &:before {
          background: white;
          border-radius: 50%;
          padding: 3px;
          margin-right: 15px;
        }
      }

      &__total {
        text-align: right;

        &.large {
          min-width: 100%;

          .price {
            font-size: 2.1rem;
            color: $Bprimary-light;
          }
        }
      }

      &__step3,
      &__step4 {
        width: 100%;
        margin: 20px auto;
      }

      &__step3 {
        text-align: center;

        &__notice {
          width: 100%;
          font-size: 1.5rem;
          text-align: center;
        }

        &__button {
          margin: 20px auto;
        }
      }

      &__step4 {
        .cssload-loader {
          margin: 20px auto;
        }
      }
    }
  }
}
