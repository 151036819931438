sbmn-my-messages {
  .white_modal:before {
    @extend .Bborder-y;
  }
}
.mymessages {
  min-height: 800px;
  &.max-width-page {
    max-width: unset;
  }
  .loader_abs {
    height: 50%;
  }

  &__header {
    &__icon {
      position: absolute;
      margin: 12px 0 0 22px;
      padding-left: 60px;
      left: 2px;
      cursor: pointer;
      color: $Bsecondary-dark;
      text-transform: uppercase;
      font-size: 1.4rem;
      width: 100px;

      &:before {
        left: 0;
        position: absolute;
        font-size: 2rem;
        padding: 8px;
        border: 2px solid $Bsecondary-dark;
        border-radius: 50%;
      }

      &:hover {
        color: $Bsecondary;

        &:before {
          border: 2px solid $Bsecondary;
        }
      }
    }

    .Bplus {
      margin-left: 20px;
    }
  }

  &__container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    &__legend {
      padding: 5px 20px 5px;
      text-align: center;
      margin: 0 auto 20px;
      width: 96%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      &:first-child {
        margin: 20px auto 0;
      }

      strong {
        height: 30px;
        width: 2px;
        background-color: $Bgrey6;
        opacity: 0.5;
        border-radius: 100px;
        margin-right: 15px;
      }

      span {
        position: relative;
        cursor: pointer;
        color: $Bgrey7;
        font-size: 1.5rem;
        margin: 5px 20px 5px 5px;
        display: inline-block;
        padding: 3px 10px 3px 10px;
        line-height: 1.2;
        border-bottom: 3px solid;
        transition: all 0.2s linear;
        min-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0.6;

        &:before {
          position: absolute;
          right: 7px;
          bottom: 5px;
          font-size: 1.2rem;
          transition: opacity 0.2s linear;
          opacity: 0;
          color: $Bwhite;
        }

        &:hover,
        &.okselected {
          padding-right: 30px;
          opacity: 1;

          &:before {
            opacity: 1;
          }
        }
      }

      .code {
        &__all {
          border-color: $Bgrey7;

          &:before {
            color: $Bgrey7;
          }
        }

        &__flights {
          border-color: $Bprimary;

          &:before {
            color: $Bprimary;
          }
        }

        &__general {
          border-color: $Bviolet-light;

          &:before {
            color: $Bviolet-light;
          }
        }

        &__warnings {
          border-color: $Borange;

          &:before {
            color: $Borange;
          }
        }

        &__unread {
          background-color: $Bgrey3;
          color: white;
          border-radius: 3px;
          border: 3px solid $Bgrey3;
          transition: all 0.2s linear;

          &:before {
            color: $Bwhite;
            bottom: 7px !important;
          }

          &.okselected {
            color: $Bblack;
            background: $Bthird-lighter;
            border-color: $Bthird-lighter;
          }
        }
      }
    }

    &__nomsgs {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      height: 200px;
      width: 100%;
      text-align: center;
      line-height: 25px;
    }

    &__group {
      padding: 0 20px;
      margin: 0 auto 50px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      &__msg {
        position: relative;
        display: block;
        flex-grow: 1;
        flex-basis: 18%;
        margin: 10px;
        border-radius: 0 3px 3px 0;
        box-shadow: 2px 2px 3px 2px rgba($Bblack, 0.1);
        padding: 15px 35px 15px 15px;
        font-size: 1.4rem;
        line-height: 1.3;
        flex-wrap: wrap;
        min-width: 230px;
        max-width: 390px;
        cursor: pointer;
        background: $Bwhite;

        strong {
          text-transform: uppercase;
        }

        &.general {
          border-left: 10px $Bgreen-dark solid;

          &.read {
            border-left: 3px rgba($Bgreen-dark, 0.2) solid;
          }

          i {
            color: $Bgreen-dark;
          }
        }

        &.flights {
          border-left: 10px $Bprimary solid;

          &.read {
            border-left: 3px rgba($Bprimary, 0.2) solid;
          }

          i {
            color: $Bprimary;
          }
        }

        &.warnings {
          border-left: 10px $Borange solid;

          &.read {
            border-left: 3px rgba($Borange, 0.2) solid;
          }

          i {
            color: $Borange;
          }
        }

        > div {
          opacity: 1;
        }

        &.read {
          > div {
            opacity: 0.8;
          }
        }

        &.ok {
          top: 0;
          right: 0;
          color: inherit;
          cursor: pointer;
          transition: none;

          &:hover {
            transform: none;
            color: inherit;
          }

          &:before {
            display: flex;
            justify-content: space-around;
            position: absolute;
            font-size: 1rem;
            right: -4px;
            top: 50px;
            transform: translateX(-50%) translateY(-50%);
            border: 2px solid $Bgreen-dark;
            border-radius: 100%;
            padding: 0px;
            color: $Bgreen-dark;
            height: 20px;
            width: 20px;
            align-items: center;
          }
        }

        i {
          position: absolute;
          right: 7px;
          top: 7px;
          font-size: 3rem;
          opacity: 0.85;
        }

        .msg-v-id {
          font-size: 1.5em;
          color: $Bgreen-dark;
        }

        &:hover {
          z-index: z(level1);
          @include mq('tablet-med', 'min') {
            animation: MSG-TOUCH 0.3s;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }

  @include mq('desktop', 'max') {
    &__container {
      &__group {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
  @include mq('tablet', 'max') {
    &__container {
      &__group {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
  @include mq('tablet-med', 'max') {
    &__container {
      &__group {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  @include mq('tablet', 'max') {
    &__header {
      &__icon {
        &:before {
          font-size: 1.2rem;
        }
      }
    }
    &__container {
      &__nomsgs {
        height: 100px;
        font-size: 1.7rem;
      }

      &__legend {
        width: 96%;
        justify-content: center;

        strong {
          display: none;
        }

        span {
          min-width: 30%;
          margin: 10px 10px 10px 5px;
        }
      }

      &__group {
        padding: 0;

        &__msg {
          max-width: unset;
        }
      }
    }
  }
  @include mq('tablet-small', 'max') {
    &__container {
      &__group {
        grid-template-columns: repeat(1, 1fr);
      }

      &__legend {
        span {
          min-width: 100%;
          margin: 5px 0;
          padding: 20px 0;
        }
      }
    }
  }
}

#mymessages {
  display: flex;
  position: relative;
}

.mymessages__filters {
  @extend .mybookings__filters;
}
