html {
  font-size: 0.68rem !important;
  font-family: 'Raleway', sans-serif !important;
  line-height: 1.2 !important;
}
body {
  font-family: 'Raleway', sans-serif !important;
  line-height: 1.2 !important;
  background-color: #f3f6f8 !important;
  &::after {
    background-color: #f3f6f8 !important;
  }
  &::before {
    background-image: none !important;
  }
}

/* General */
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Raleway', sans-serif !important;
  font-weight: 300 !important;
}
.h1withbold,
.h2withbold,
.h3withbold,
.h4withbold {
  //font-size:3.2rem !important;
  color: #0099ff !important;
  strong {
    font-weight: 300 !important;
    color: #003366;
  }
}

/* Home */
/* Estructura*/
sbmn-flights-dashboard {
  .max-width-page > :first-child {
    order: 1;
    display: inline-block;
  }

  .max-width-page > :nth-child(2) {
    order: 2;
    top: 0;
    margin: 0 0 50px;
  }

  .max-width-page > :nth-child(3) {
    order: 3 !important;
    display: inline-block;
    width: 100%;
    max-width: initial;
    margin: 0;
    padding: 0;
    border-radius: 0;
  }

  .max-width-page > :nth-child(5) {
    order: 5 !important;
  }

  sbmn-flights-search-engine {
    max-width: none !important;
  }

  sbmn-flights-welcome {
    max-height: 550px !important;
    min-height: 550px !important;

    &::before {
      content: none !important;
    }

    figure,
    figcaption {
      position: relative;
    }

    figcaption h2 {
      font-size: 2.5vw;
      display: none;
    }

    .owl-theme .owl-dots {
      position: absolute;
      right: 0;
      left: 0;
      max-width: none;
      z-index: 1;
      top: 0;
    }

    .owl-dot {
      span {
        border-color: rgba(255, 255, 255, 0.5) !important;
      }

      &.active span {
        border-color: rgba(255, 255, 255, 0.5) !important;
        background: rgba(255, 255, 255, 0.5) !important;
      }
    }
  }
}

sbmn-flights-highlight {
  section {
    width: 100%;
    height: auto;
    padding: 0;
    flex-direction: unset !important;
    background: none !important;
  }

  button.buttonbluelr {
    margin: 0 0 0 10px;
  }
}
sbmn-flights-offers {
  width: 66%;
  max-width: 950px !important;
  display: inline-block;
}
sbmn-flights-messages {
  width: 33%;
  max-width: 464px !important;
  flex-basis: auto !important;

  owl-carousel-o {
    height: auto;
  }

  .owl-theme .owl-dots {
    bottom: -40px !important;
  }
}

/* Estilos */
#main_header {
  box-shadow: 1px 0 5px 0 rgba(128, 138, 153, 62) !important;
  a {
    text-transform: none !important;
    color: #003366 !important;

    &:hover,
    &.active {
      color: #0099ff !important;
    }
    &::before {
      display: none;
    }
    &.header__balance {
      margin-right: 0 !important;
      font:
        700 2.3rem 'Raleway',
        sans-serif !important;
      color: #0099ff !important;
      border: 0 !important;
      span {
        font:
          500 1.3rem 'Raleway',
          sans-serif !important;
        text-transform: lowercase;
      }
    }
  }
  .poweroff {
    top: 0;
    bottom: 0;
    right: 0;
    background: #003366;
    transition: all 0.05s;
    &:hover {
      background: #0099ff;
    }
    &::before {
      font-size: 2rem !important;
      color: #fff !important;
    }
  }
  .mobile_menu_container__burger__bar1,
  .mobile_menu_container__burger__bar2,
  .mobile_menu_container__burger__bar3 {
    background-color: #0099ff !important;
  }
}
#main_footer {
  background: #003e73 !important;
}

sbmn-flights-highlight {
  box-shadow: 1px 0 5px 0 rgba(128, 138, 153, 62) !important;
  z-index: 1;
  padding: 10px;
  background: #0099ff;
  background: linear-gradient(135deg, #0099ff 0%, #00ccff 100%);

  section {
    & > div {
      font:
        500 1.55rem 'Raleway',
        sans-serif !important;
    }
    & > .buttonbluelr {
      padding: 10px 20px !important;
      font:
        500 1.55rem 'Raleway',
        sans-serif !important;
      text-transform: none;
      border: 0;
      border-radius: 20px;
      background: #003366;
    }
    & > .buttonbluelr:hover {
      border: 0;
    }
  }
}

sbmn-flights-messages,
sbmn-flights-offers {
  box-shadow: none !important;
  background: none !important;
}

sbmn-flights-offers {
  #list_offers {
    div {
      margin-top: 1px;
      text-transform: none !important;
      box-shadow: 0 0 5px 3px rgba(173, 204, 224, 0.47);
      border-radius: 2px;
      background: #fff;

      &:hover {
        color: #fff;

        .desc,
        .price {
          color: #fff;
        }

        .idavuelta::before,
        .soloida::before {
          color: rgba(255, 255, 255, 0.4);
        }
      }
    }

    .desc {
      color: #003366;
    }

    .price {
      position: relative;
      top: -3px;
      font-size: 1.4em;
      font-weight: 300;
      color: #0099ff;
    }

    .idavuelta,
    .soloida {
      left: 5px;
      top: 13px;
      margin: 0;
      background: none;
    }

    .idavuelta::before,
    .soloida::before {
      font-size: 19px;
      color: #abbec9;
    }
  }
}

sbmn-flights-messages {
}
sbmn-flights-messages section {
  position: relative;
  margin: 0 0 10px !important;
  padding: 5px 65px 25px 25px !important;
  font-size: 0.8em;
  transition: all 0.1;
}
sbmn-flights-messages section i.ng-star-inserted,
sbmn-flights-messages section img.ng-star-inserted {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  left: auto;
  margin-top: -25px;
  border: 0;
}
sbmn-flights-messages .link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-indent: -9999px;
  margin: 0 !important;
  background: none;
  transition: 0.15s all;
}
sbmn-flights-messages .link:hover {
}
sbmn-flights-messages section div {
  font-size: 1.3rem !important;
  line-height: 1.9rem;
}
sbmn-flights-messages section._boomerang div {
  color: #f3f6f8;
}

sbmn-flights-messages section._boomerang {
  background: #003055;
}
sbmn-flights-messages section._boomerang h2 {
  color: #f3f6f8;
}
sbmn-flights-messages section._avisos {
  background: #f3ebde;
}
sbmn-flights-messages section._avisos h2 {
  color: #736348;
}
sbmn-flights-messages section._vuelos {
  background: #dee8f3;
}
sbmn-flights-messages section._vuelos h2 {
  color: #1e3b5b;
}
sbmn-flights-messages section._info {
  background: #e3f5ff;
}
sbmn-flights-messages section._info h2 {
  color: #1e3b5b;
}
sbmn-flights-messages i.megafono::before {
  color: #ddd1bd !important;
}
sbmn-flights-messages i.avion::before {
  color: #bdcddf !important;
}
sbmn-flights-messages i.info3::before {
  color: #bed6e5 !important;
}
sbmn-flights-messages section:hover {
  background: #0099ff !important;
}
sbmn-flights-messages section:hover h2,
sbmn-flights-messages section:hover div {
  color: #f3f6f8 !important;
}

/* Searcher */
sbmn-flights-search-engine {
  margin-bottom: 50px;
}
#main_search-engine {
  text-transform: none !important;
}
#nav_search-engine {
  margin: 0 auto !important;
}
#nav_search-engine span {
  min-width: 75px !important;
  min-height: 75px;
  margin: 0 5px !important;
  opacity: 0.8;
  border-radius: 4px 4px 0 0;
  border: 0 !important;
  background: #0099ff !important;
}
#nav_search-engine span::before {
  opacity: 1 !important;
  color: #f3f6f8 !important;
}
#nav_search-engine span.selected {
  opacity: 1;
  background: #fff !important;
}
#nav_search-engine span.selected::before {
  color: #0099ff !important;
}
#nav_search-engine span:hover {
  opacity: 1;
}
#searchEngineForm {
  min-height: 215px !important;
  padding: 20px;
  font-family: 'Raleway', sans-serif !important;
  box-shadow: 0 0 73px 3px rgba(0, 153, 255, 0.52) !important;
}
#searchEngineForm #destdates_search-engine {
}
#searchEngineForm #passengers_search-engine {
}
#searchEngineForm #passengers_search-engine .pasajero::before,
#searchEngineForm #passengers_search-engine .personas::before {
  color: #96b8d3;
}
#searchEngineForm #passengers_search-engine span {
  color: #0099ff;
}
#searchEngineForm #passengers_search-engine:hover .pasajero::before,
#searchEngineForm #passengers_search-engine:hover .personas::before,
#searchEngineForm #passengers_search-engine span {
  color: #003366;
}
#destdates_search-engine {
  flex-direction: column;
}
#searchEngineForm #destdates_search-engine > section {
  width: 100%;
  min-width: 100%;
  justify-content: flex-start;
}
.Multidestino_search #destdates_search-engine mat-form-field {
  margin: 0 !important;
  padding: 0 20px !important;
}
#searchEngineForm #destdates_search-engine .destdate_container {
  padding: 15px 0 0 0;
}
#simpledates_search-engine .simpledates_dateview {
  padding: 0 25px;
  flex-direction: row !important;
  justify-content: left !important;
}
#searchEngineForm #destdates_search-engine .simpledates_dateview .day,
#searchEngineForm #destdates_search-engine .simpledates_dateview .month,
#searchEngineForm .multidates_search-engine .multidates_dateview {
  font-family: 'Raleway', sans-serif !important;
  font-weight: 300 !important;
  color: #0099ff;
}
#searchEngineForm #destdates_search-engine .simpledates_dateview:hover .day,
#searchEngineForm #destdates_search-engine .simpledates_dateview:hover .month,
#searchEngineForm .multidates_search-engine:hover .multidates_dateview {
  color: #003366;
}
#searchEngineForm #destdates_search-engine .simpledates_dateview .day {
  font-size: 4rem;
  padding-right: 8px;
}
#searchEngineForm #options_search-engine {
  max-height: none !important;
  background: #003055;
}
#options_search-engine fieldset {
}
#searchEngineForm #options_search-engine label {
  color: #f3f6f8;
}
#searchEngineForm #moreopts_search-engine {
  padding: 25px 0 0;
}
.Multidestino_search #searchEngineForm #moreopts_search-engine {
  min-width: 60%;
  max-width: 60%;
  width: 60%;
}
.mat-checkbox,
.mat-select,
.mat-input-element {
  font-family: 'Raleway', sans-serif !important;
}
.Multidestino_search #destdates_search-engine .addRoute {
  opacity: 1 !important;
  font-family: 'Raleway', sans-serif !important;
  font-weight: 500 !important;
  color: #0099ff;
}
.Multidestino_search #destdates_search-engine .addRoute:hover {
  color: #003366;
}
#searchEngineForm #submit_search-engine {
  border: 0;
  background: linear-gradient(135deg, #0099ff 0%, #00ccff 100%);
}

.modal_psg_manager {
}
sbmn-flights-psg-manager .stopper-modal.modal_psg_manager h2 {
  color: #003366 !important;
}
.stopper-modal.modal_psg_manager .psg_manager__container__box__title,
.stopper-modal.modal_psg_manager .psg_manager__container__box__legend {
  color: #003366;
}
.stopper-modal.modal_psg_manager .buttontransparent {
  min-width: 120px;
  padding: 10px 20px;
  font:
    500 1.55rem 'Raleway',
    sans-serif !important;
  text-transform: none;
  border: 0;
  border-radius: 20px;
  background: #fff;
}
.stopper-modal.modal_psg_manager .buttontransparent:hover {
  color: #fff;
  background: #003366;
}

/* Listado */
sbmn-flights-results header.sticky,
sbmn-flights-calendar header.sticky {
  height: 60px !important;
}
.summary #nav_search-engine span {
  min-width: 40px !important;
  color: rgba(255, 255, 255, 0.8) !important;
  background: none !important;
}
.summary #nav_search-engine span::before {
  transform: scale(0.8) !important;
  color: rgba(255, 255, 255, 0.54) !important;
}
.summary #searchEngineForm {
  align-items: center;
  min-height: 60px !important;
  padding: 0;
  border: 0;
}
.summary #searchEngineForm #destdates_search-engine,
.summary #searchEngineForm #destdates_search-engine > section {
  width: auto;
  max-width: none;
  min-width: auto;
  flex-direction: row;
}
.summary #submit_search-engine {
  position: relative;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  width: 80px;
  height: auto !important;
  margin: 0 !important;
  padding: 10px 20px !important;
  font:
    500 1.55rem 'Raleway',
    sans-serif !important;
  text-transform: none;
  border: 0;
  border-radius: 20px;
  background: #003366 !important;
}
.summary #searchEngineForm #destdates_search-engine .simpledates_dateview .day,
.summary #searchEngineForm #destdates_search-engine .simpledates_dateview .month,
.summary #searchEngineForm .multidates_search-engine .multidates_dateview,
.summary #searchEngineForm #passengers_search-engine .personas,
.summary #searchEngineForm #passengers_search-engine .pasajero::before,
.summary #searchEngineForm #passengers_search-engine .personas::before,
.summary .mat-input-element,
.summary #desdate_container_multiinfo,
.summary #desdate_container_multiinfo span::before,
.summary .mat-form-field .mat-form-field-label {
  color: #fff;
}
.summary #desdate_container_multiinfo {
  font-weight: 600;
}
.summary .destdate_container .mat-input-element {
  color: #000;
}
.summary #searchEngineForm #destdates_search-engine .simpledates_dateview:hover .day,
.summary #searchEngineForm #destdates_search-engine .simpledates_dateview:hover .month,
.summary #searchEngineForm .multidates_search-engine:hover .multidates_dateview,
.summary #searchEngineForm #passengers_search-engine .personas:hover,
.summary #searchEngineForm #passengers_search-engine .personas:hover::before {
  color: #003366;
}
.summary #searchEngineForm #passengers_search-engine .personas {
  font-size: 1.8em;
}
@media (min-width: 996px) and (max-width: 1200px) {
  .summary #searchEngineForm #destdates_search-engine > section {
    flex-direction: column;
  }
  .summary #destdates_search-engine .simpledates_dateview {
    padding: 0;
  }
  .summary #simpledates_search-engine .day {
    font-size: 1.4em !important;
  }
  .summary #simpledates_search-engine .day::before {
    display: none;
  }
}

sbmn-flights-results > header.hsummary,
sbmn-flights-calendar > header.hsummary {
  box-shadow: 1px 0 5px 0 rgba(128, 138, 153, 62) !important;
  background: #0099ff !important;
  background: linear-gradient(135deg, #0099ff 0%, #00ccff 100%) !important;
}
sbmn-flights-results .menosopciones,
sbmn-flights-calendar .menosopciones {
  color: #0099ff;
  border-radius: 50%;
  background: #dbeaf5;
}
sbmn-flights-search-engine.search-box {
}
sbmn-flights-search-engine.search-box
  #searchEngineForm
  #destdates_search-engine
  .destdate_container {
  padding-top: 15px;
}
.fresult_header {
  margin: 20px 0;
}
.fresult_header h1.h1withbold {
  color: #003366 !important;
}
sbmn-flights-results .fresult_header .filtrar,
sbmn-flights-calendar .fresult_header .filtrar,
.mybookings__header__icon.filtrar {
  width: 38px;
  height: 38px;
  padding: 0;
  font-size: 0.5em;
  color: #fff;
  border: 0;
  border-radius: 19px;
  transition: 0.15s all;
  background: #0099ff;
}
.mybookings__header__icon::before {
  color: #fff !important;
  border: 0 !important;
}
sbmn-flights-results .fresult_header .filtrar:hover,
sbmn-flights-calendar .fresult_header .filtrar:hover,
.mybookings__header__icon.filtrar:hover {
  color: #fff;
  background: #003366;
}
sbmn-pagination .pagination__info {
  color: #003366;
}
sbmn-pagination .pagination__info strong {
  font-weight: 600;
}
sbmn-pagination .pagination .pagination__container__arrow {
  opacity: 1;
  width: 38px;
  height: 38px;
  color: #0099ff;
  background: #dbeaf5;
}
sbmn-pagination .pagination .pagination__container__arrow:hover {
  color: #fff;
  background: #003366;
}
section.fresults_filters,
#main_header .mobile_menu_container__sidenav,
.mybookings__filters,
.mymessages__filters,
.mtausers__filters,
.mtabalance__filters {
  box-shadow: 1px 0 5px 0 rgba(128, 138, 153, 62);
  border: 0;
  background: #fff !important;
}

.fresult_scheme {
}
#fresults_list .fresult_scheme {
  border-radius: 2px;
  box-shadow: 0 0 5px 3px rgba(173, 204, 224, 0.47);
  background: #fff;
}
#fresults_list .fresult_schemedata,
#fresults_list .fresult_schemeprice {
  box-shadow: none;
}
.fresult_schemedata .fresult_headerscheme {
  min-height: 46px;
  padding-top: 3px;
  border: 0;
  background: #003366;
}
.fresult_schemedata .fresult_headerscheme i {
  min-width: 26px;
  margin-left: 5px;
  padding: 0;
  border: 0;
}
.fresult_schemedata .fresult_headerscheme i::before {
  font-family: 'icons' !important;
  font-size: 1.45em;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #abbec9;
}
.fresult_schemedata .fresult_headerscheme i.ida::before {
  content: '\e902' !important;
}
.fresult_schemedata .fresult_headerscheme i.vuelta::before {
  content: '\e902' !important;
  transform: rotate(180deg);
}
.fresult_schemedata .fresult_headerscheme p {
  position: relative;
  font:
    700 1.4rem 'Raleway',
    sans-serif !important;
  color: #fff;
}
.fresult_schemedata .fresult_headerscheme p span {
  position: absolute;
  top: -1px;
  right: -100px;
  font:
    300 1.4rem 'Raleway',
    sans-serif !important;
  color: #fff;
}
.fresult_schemedata .fresult_route {
  border-color: #d9e2e8;
}
.fresult_schemedata .fresult_route .fresults_routesummary {
  padding-top: 2px;
  font-family: 'Raleway', sans-serif;
  font-size: 1.2em;
  font-weight: 300;
  letter-spacing: -0.005em;
}
.fresult_schemedata .fresult_route .fresults_routesummary span {
  opacity: 0.82;
  padding-top: 5px;
  font:
    400 1.1rem 'Raleway',
    sans-serif !important;
  text-transform: none;
}
.fresult_schemedata .fresult_route .fresults_stopover {
  font-family: 'Raleway', sans-serif;
  color: #003366;
}
.fresult_schemedata .fresult_route .fresults_routeinfo,
.fresult_schemedata .fresults_schemeicons .fresults_class {
  width: 38px;
  min-width: 38px;
  height: 38px;
  min-height: 38px;
}
.fresult_schemedata .fresult_route .fresults_routeinfo {
}
.fresult_schemedata .fresult_route .fresults_routeinfo::before {
  content: '';
}
.fresult_schemedata .fresult_route .fresults_routeinfo i {
  margin: 0 0 0 4px;
  font-size: 1.7rem;
}
.fresult_schemedata .fresults_schemeicons .fresults_class {
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 3px;
}
.fresult_schemedata .fresults_schemeicons .butaca .fresults_seats_text,
.fresult_schemedata .fresults_schemeicons .fresults_baggagetxt,
.fresult_schemedata .fresults_schemeicons .fresults_class {
  top: -2px;
  font-family: 'Raleway', sans-serif;
  font-size: 1em;
  font-weight: 500;
}
/*Colors*/
/* - Defecto */
.fresult_schemedata .fresult_route .fresults_routesummary {
  color: #0099ff;
}
.fresult_schemedata .fresult_route .fresults_routesummary span {
  color: #003366;
}
.fresult_schemedata .fresults_schemeicons .butaca::before,
.fresult_schemedata .fresults_schemeicons .maleta::before {
  color: #dbeaf5;
}
.fresult_schemedata .fresult_route .fresults_routeinfo,
.fresult_schemedata .fresults_schemeicons .fresults_class {
  background: #dbeaf5;
}
.fresult_schemedata .fresult_route .fresults_routeinfo i {
  color: #0099ff;
}
.fresult_schemedata .fresults_schemeicons .butaca .fresults_seats_text,
.fresult_schemedata .fresults_schemeicons .fresults_baggagetxt,
.fresult_schemedata .fresults_schemeicons .fresults_class {
  color: #003366;
}
/* - Hover */
.fresult_route:hover .fresults_routesummary {
  color: #003366 !important;
}
.fresult_route:hover .fresults_routesummary span {
  color: #003366;
}
.fresult_schemedata .fresult_route:hover {
  background: #c4d5e2;
}
.fresult_route:hover .fresults_state {
  background: #c4d5e2 !important;
}
.fresult_route:hover .fresults_schemeicons .butaca::before,
.fresult_route:hover .fresults_schemeicons .maleta::before {
  color: #dbeaf5 !important;
}
.fresult_route:hover .fresults_routeinfo,
.fresult_route:hover .fresults_schemeicons .fresults_class {
  background: #dbeaf5;
}
.fresult_schemedata .fresult_route:hover .fresults_routeinfo i {
  color: #003366;
}
.fresult_route:hover .fresults_schemeicons .butaca .fresults_seats_text,
.fresult_route:hover .fresults_schemeicons .fresults_baggagetxt,
.fresult_route:hover .fresults_schemeicons .fresults_class {
  color: #003366;
}
/* - Activo */
.fresult_route.selected .fresults_routesummary {
  color: #354b39 !important;
}
.fresult_route.selected .fresults_routesummary span {
  color: #003366;
}
.fresult_schemedata .fresult_route.selected {
  background: #ade1b7;
}
.fresult_schemedata .fresult_route.selected::before {
  top: 25px;
  right: 17px;
  color: #3f7348;
}
.fresult_route.selected .fresults_state {
  border: 1px #ade1b7 solid;
  background: #def3e2 !important;
}
.fresult_route.selected .fresults_schemeicons .butaca::before,
.fresult_route.selected .fresults_schemeicons .maleta::before {
  color: #c4dfc9 !important;
}
.fresult_route.selected .fresults_routeinfo,
.fresult_route.selected .fresults_schemeicons .fresults_class {
  background: #c4dfc9;
}
.fresult_schemedata .fresult_route.selected .fresults_routeinfo i {
  color: #5a8a63;
}
.fresult_route.selected .fresults_schemeicons .butaca .fresults_seats_text,
.fresult_route.selected .fresults_schemeicons .fresults_baggagetxt,
.fresult_route.selected .fresults_schemeicons .fresults_class {
  color: #003366;
}
#fresults_list .fresult_schemeprice {
  color: #003366;
  background: #dbeaf5;
}
.fresult_schemeprice .fresults_rate {
  font-size: 0.85em;
}
.fresult_schemeprice .fresults_rate strong {
  font-weight: 600;
}
.fresult_schemeprice .fresults_price {
  margin-top: 0;
  font-weight: 300;
}
.fresult_schemeprice .fresults_routeinfo {
  margin-bottom: 4px;
}
.fresult_schemeprice .fresults_routeinfo .info3 {
  display: inline-block;
  width: 28px;
  height: 28px;
  padding: 5px 0 0 1px;
  font-size: 1em;
}
.fresult_schemeprice .fresults_routeinfo .personas {
  display: inline-block;
  width: 40px;
  height: 28px;
  padding: 5px 0 0 1px;
  font-size: 1em;
}
.fresult_schemeprice .fresults_routeinfo .cash {
  display: inline-block;
  width: 28px;
  height: 28px;
  padding: 5px 0 0 1px;
  font-size: 1em;
}
.fresult_schemeprice .fresults_routeinfo.enabled .info3 {
  opacity: 1;
  color: #0099ff;
  border-radius: 14px;
  background: #fff;
  transition: all 0.08s;
}
.fresult_schemeprice .fresults_routeinfo.enabled .info3:hover {
  color: #fff;
  background: #0099ff;
}
.fresult_schemeprice .fresults_routeinfo.enabled .personas {
  opacity: 1;
  color: #0099ff;
  border-radius: 14px;
  background: #fff;
  transition: all 0.08s;
}
.fresult_schemeprice .fresults_routeinfo.enabled .personas:hover {
  color: #fff;
  background: #0099ff;
}
.fresult_schemeprice .fresults_routeinfo.enabled .cash {
  opacity: 1;
  color: #0099ff;
  border-radius: 14px;
  background: #fff;
  transition: all 0.08s;
}
.fresult_schemeprice .fresults_routeinfo.enabled .cash:hover {
  color: #fff;
  background: #0099ff;
}
.fresult_schemeprice .buttonyellow {
  width: 100%;
  padding: 12px 18px;
  font:
    600 1.24rem 'Raleway',
    sans-serif !important;
  letter-spacing: 0.01em;
  border-radius: 23px;
}
/*Colors*/
.fresult_schemeprice .buttonyellow {
  box-shadow: 0 1px 5px 3px rgba(173, 204, 224, 1);
}
.fresult_schemeprice [disabled].buttonyellow {
  color: #6e8799;
  box-shadow: none;
  background: #c4d5e2;
}
/*Paginas*/
.stopper-modal {
  background: rgba(219, 234, 245, 0.95) !important;
}

.basic__table {
  box-shadow: 0 0 3px 0 rgba(128, 138, 153, 0.3);
  border-spacing: 0 !important;
  border-bottom: 7px solid #fff !important;
}
.basic__table .basic__table__head tr,
.basic__table .basic__table__body tr {
  height: 56px;
}
.basic__table .basic__table__head {
  background: #eaf1f5;
}
.basic__table__head__container {
  text-transform: none;
}
.basic__table .basic__table__head .-icon-hide,
.basic__table .basic__table__head .-icon-hide:hover {
  text-transform: none;
  color: #003366;
}

.basic__table .basic__table__body tr {
  font-size: 0.8em;
  color: #003366 !important;
  border-left: 0;
  background: #f3f6f8;
}
.basic__table__body tr:nth-child(2n + 1) {
  background: #fff;
}

.is_tr .document::before {
  width: 6px !important;
  border-radius: 2px !important;
  box-shadow: 0 0 3px 0 rgba(128, 138, 153, 0.3);
}

.cms__openHTML__content__yellow__block,
.cms__openHTML__content__plane-ball__inside {
  font-size: 1.6rem !important;
  color: #fff;
}
.mta-contact .cms__openHTML__content__yellow__block a {
  font-size: inherit !important;
}

.mybookings__header-container.max-width-page {
  margin-bottom: 20px;
  margin-top: 20px;
  padding-left: 80px;
  text-align: left;
}
.mybookings__header-container .mybookings__header__icon {
  top: 4px;
}

.mybookings__legend {
  margin-bottom: 15px !important;
  padding: 10px !important;
  text-align: left !important;
  background: #dbeaf5;
}
.mybookings__legend > span {
  opacity: 1 !important;
  min-width: 0 !important;
  margin: 3px !important;
  padding: 8px 6px !important;
  font-size: 1.25em !important;
  border-bottom: 0 !important;
  border-left-style: solid;
  border-left-width: 3px;
  border-radius: 3px;
  box-shadow: 0 0 3px 0 rgba(128, 138, 153, 0.3);
  background: #fff;
}
.mybookings__legend > span:hover {
  border-color: #003366 !important;
  color: #fff !important;
  background: #003366 !important;
}
.mybookings__legend > span.okselected {
  padding-right: 22px !important;
  color: #fff;
  border-color: #0099ff !important;
  background: #0099ff !important;
}
.mybookings__legend span:hover::before {
  display: none !important;
}
.mybookings__legend span::before {
  top: 11px !important;
  right: 5px !important;
  color: #fff !important;
}
.mybookings__legend strong {
  position: relative;
  top: -4px;
  width: 0 !important;
  border-right: #7a9ab2 3px dotted;
  margin: 0 10px;
  display: inline-block;
  opacity: 1 !important;
  border-radius: 0 !important;
  background: none !important;
}
.mybookings__container__nobookings.Berror {
  display: block;
  width: 96%;
  margin: 10px auto;
  font-size: 1.4em;
  border-radius: 2px;
  border: 1px #e5d1d2 solid;
  background: #f3dedf !important;
}
sbmn-flights-passengers {
  background: #f3f6f8 !important;
}
.fsummary__top__ida-vuelta__flights__published {
  color: #fff !important;
}
.cms__openHTML__header__title {
  text-align: left !important;
  text-transform: none !important;
}
sbmn-access {
}
sbmn-access > header {
  width: 50% !important;
  right: 0 !important;
}
sbmn-access > header figure {
  height: auto;
  left: auto;
  position: default;
  background: url('/assets/img/logos/main_logo_blanco.png') no-repeat 50% 50%;
  background-size: 200px 67px;
}
sbmn-access > header figure img {
  visibility: hidden;
}

sbmn-access > header span {
  // display: none;
  position: relative !important;
  left: auto !important;
  color: rgba(255, 255, 255, 0.5) !important;
}
sbmn-access > section {
  width: 50% !important;
  left: 0 !important;
}
#login-access h1,
#login-change-password h1,
#login-submit-password h1 {
  margin-bottom: 20px !important;
}
#login-access h1 strong,
#login-change-password h1 strong,
#login-submit-password h1 strong {
  display: block !important;
}
#login-new-account {
  font-size: 1.3rem !important;
}
#login-new-account a {
  display: block;
  margin-top: 3px;
  font-size: 1.3rem !important;
}

.bmanager__info__container__route__flight__extrainfo__fare {
  color: #fff !important;
}
.passengers__section__passenger__data__title,
.passengers__section__passenger__data__title__note {
  color: rgba(0, 153, 255, 0.8) !important;
}

#fresults_ads figure {
}
#fresults_ads figure img {
  width: 100%;
  height: auto;
}

@media (min-width: 1022px) {
  #searchEngineForm {
    border-radius: 3px 60px 3px 3px !important;
    border-left: 2px #0099ff solid;
  }
  #searchEngineForm #destdates_search-engine {
    min-width: 60%;
    max-width: 60%;
    width: 60%;
    flex-direction: column;
  }
  #searchEngineForm #destdates_search-engine .destdate_container {
    flex-direction: row;
  }
  #searchEngineForm #destdates_search-engine .idavuelta {
    position: absolute;
    left: 50%;
    top: 40px;
    right: auto;
    margin-left: -11px;
    transform: none;
    border: 0;
  }
  .Multidestino_search #searchEngineForm #destdates_search-engine .idavuelta {
    margin: 0;
    top: 35px;
    left: 38%;
  }
  #searchEngineForm #passengers_search-engine {
    min-width: 10%;
    max-width: 10%;
    width: 10%;
  }
  #searchEngineForm #options_search-engine {
    min-width: 28%;
    max-width: 28%;
    width: 28%;
    padding: 25px;
    position: absolute;
    top: 75px;
    bottom: 0;
    right: 0;
    border-radius: 0 60px 0 0;
  }
  #searchEngineForm #options_search-engine fieldset {
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
  }
  #searchEngineForm #options_search-engine fieldset .mat-checkbox-frame {
    border-color: #0099ff !important;
  }
  #searchEngineForm
    #options_search-engine
    .mat-checkbox-indeterminate.mat-primary
    .mat-checkbox-background,
  #searchEngineForm
    #options_search-engine
    .mat-checkbox-checked.mat-primary
    .mat-checkbox-background {
    background-color: #0099ff;
  }
  .Simple_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1),
  .Calendario_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) {
    top: 36%;
    left: -180% !important;
    width: 100px !important;
    min-width: 100px !important;
    max-width: 100px !important;
    padding: 17px 0;
  }
  .Simple_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) label,
  .Calendario_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) label {
    color: #003366 !important;
  }
  .Simple_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) label:hover,
  .Calendario_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) label:hover {
    color: #0099ff !important;
  }
  .Simple_search
    #searchEngineForm
    #options_search-engine
    fieldset:nth-of-type(1)
    label:hover
    .mat-checkbox-frame,
  .Calendario_search
    #searchEngineForm
    #options_search-engine
    fieldset:nth-of-type(1)
    label:hover
    .mat-checkbox-frame {
    border-color: #0099ff !important;
  }

  .Simple_search
    #searchEngineForm
    #options_search-engine
    fieldset:nth-of-type(1)
    .mat-checkbox-frame,
  .Calendario_search
    #searchEngineForm
    #options_search-engine
    fieldset:nth-of-type(1)
    .mat-checkbox-frame {
    border-color: #003366 !important;
  }
  #searchEngineForm #moreopts_search-engine {
    width: 70%;
    flex-direction: row;
  }
  #searchEngineForm #submit_search-engine {
    top: 75px;
    height: calc(100% - 75px);
  }
  sbmn-flights-messages {
    margin-top: 100px !important;
  }
}
#simpledates_search-engine .simpledates_dateview:last-child {
  left: 0 !important;
}
@media (max-width: 1022px) {
  sbmn-access > header {
    width: 100% !important;
  }
  sbmn-access > section {
    width: 100% !important;
  }
  sbmn-flights-dashboard .max-width-page > :nth-child(3),
  .mybookings__header-container.max-width-page,
  .brand_header {
    margin-top: 60px;
  }
  .mybookings__header__icon::before {
    font-size: 2rem !important;
  }
  .fresult_schemedata .fresult_route.selected::before {
    right: 5px !important;
  }
}

.leftdialog {
  padding-bottom: 20px;
  .extendedinfo__container__div {
    position: absolute;
    top: 180px;
    bottom: 25px;
    display: block;

    .extendedinfo__container__code {
      max-height: 100%;
      max-width: 760px;
    }
  }
}

.Berror .stopper-modal__scroll__container__content {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 15px;
}
.calendargrid {
  padding-right: 55px;
}

.voucher__header__container__state-loc__state,
.voucher__container__flights__container__content__info,
.voucher__container__flights__container__content__flight__stops__container__logo::before {
  background-color: #036 !important;
  color: #fff !important;
}

#gradient_search-engine {
  &.Simpleborder,
  &.Calendarioborder,
  &.Multidestinoborder {
    span {
      top: 80px;
      left: 40px;
      right: auto;
      z-index: 1;
      color: #09f;
      height: 40px;
    }
  }
}
#searchEngineForm {
  .Simple_search & {
    padding-top: 40px;
  }
  .Multidestino_search & {
    padding-top: 40px;
  }
  .Calendario_search & {
    padding-top: 40px;
  }
}
.summary #searchEngineForm {
  padding: 0;
}

.agreement_form .agreement_sms {
  display: none;
}

sbmn-flights-dashboard sbmn-flights-offers {
  & h2,
  & #list_offers {
    display: none;
  }
  & .banner_home_footer {
    display: flex;
    justify-content: space-between;
    margin: 60px 0 20px 0;
    flex-wrap: wrap;

    & div {
      width: 50%;
      padding: 10px;
      height: 464px;
      overflow: hidden;
    }
  }
}

.bmanager__nav__section__item.baggage {
  display: none;
}

.mtamain__logo__uploader {
  color: #0099ff !important;
}

sbmn-flights-results {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'header header'
    'sidebar main';
}

sbmn-flights-results > header {
  grid-area: header;
}

sbmn-flights-results > section.fresults_filters {
  grid-area: sidebar;
  position: relative;
  width: 280px;
  min-width: auto;
  box-shadow: none;
  background-color: transparent !important;
}

sbmn-flights-filters section {
  min-width: auto;
}

sbmn-flights-results > section#fresults {
  grid-area: main;
}

sbmn-flights-results > #fcalendar {
  grid-area: main;
}

sbmn-flights-filters section .clock + span {
  display: block;
  margin-top: 2px;
  margin-left: 22px;
  text-align: left;
}

#main_header .mobile_menu_container__sidenav {
  max-width: 220px;
}
