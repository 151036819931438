sbmn-my-bookings-fares {
  display: block;
  min-width: 100%;

  .limitdate {
    color: $Borange;
  }

  .price {
    color: $Bprimary-lighter;
    letter-spacing: -0.5px;
  }

  .basic__table {
    width: 100%;
    border-width: 0 0 1px 0;
    margin-bottom: 25px;

    &.bmanager__fares__main-table {
      th {
        &:nth-of-type(4),
        &:nth-of-type(3) {
          width: 25%;
        }
      }
    }

    &__head {
      tr {
        border: 0;
        cursor: auto;

        th {
          cursor: auto;
          text-align: center;
        }

        th:last-child {
          padding-right: 0;
        }
      }
    }

    &__body {
      tr {
        cursor: auto;

        td:last-child {
          padding-right: 0;

          &:before {
            content: '';
          }
        }
      }

      .delete {
        color: $Berror;
      }

      .ok {
        color: $Bgreen;
      }
    }
  }
}

.bmanager__fares {
  &__section {
    border-bottom: 1px solid $Bprimary-lighter;
    padding-bottom: 20px;
    margin-bottom: 20px;

    &:nth-of-type(1) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include mq('tablet', 'max') {
      flex-direction: column;
    }

    &__item {
      text-align: right;
      padding: 3px 0;
      font-size: 1.6rem;
      line-height: 1;

      strong {
        font-size: 1.2em;
      }
    }

    &__container {
      border-bottom: 1px solid $Bgrey3;
      padding-bottom: 15px;
      margin-bottom: 30px;

      &__title {
        margin: 5px 0 5px;

        &.pasajero:before {
          margin-right: 10px;
        }
      }

      &__subtitle {
        margin: 10px 0 5px;
        font-size: 1.8rem;
        font-family: basicregular, Arial, sans-serif;
        font-weight: lighter;
      }
    }

    &__code {
      padding: 10px;
      font-size: 1.5rem;
      border-radius: 5px;
      background: $Bgrey05;
      max-height: 300px;
      overflow-y: scroll;
    }
  }
}
